import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'


const itemStyle = {
  display: 'inline-block',
  textAlign: 'center',
  padding: '2px 5px',
  cursor: 'pointer',
  border: 'solid 1px',
  borderColor: 'grey.500',
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
  OUserSelect: 'none',
  width: 25,
  height: 28,
}

const Grader = ({ className, grade, setGrade, objective, generalImpression }) => generalImpression
  ? <Box className={className} sx={{ width: 'fit-content', display: 'flex' }}>
    <Box
      onClick={() => setGrade('unsatisfactory')}
      sx={{
        ...itemStyle,
        color: 'error.main',
        border: `solid ${grade === 'unsatisfactory' ? 2 : 1}px`,
        borderColor: grade === 'unsatisfactory' ? 'black' : 'grey.500',
      }}
    >I</Box>
    <Box
      onClick={() => setGrade('satisfactory')}
      sx={{
        ...itemStyle,
        color: 'info.main',
        border: `solid ${grade === 'satisfactory' ? 2 : 1}px`,
        borderColor: grade === 'satisfactory' ? 'black' : 'grey.500',
      }}
    >S</Box>
    <Box
      onClick={() => setGrade('efficient')}
      sx={{
        ...itemStyle,
        color: 'success.main',
        border: `solid ${grade === 'efficient' ? 2 : 1}px`,
        borderColor: grade === 'efficient' ? 'black' : 'grey.500',
      }}
    >P</Box>
  </Box>
  : <Box className={className} sx={{ width: 'fit-content', display: 'inline-block' }}>
    <Box
      onClick={() => setGrade('knowledge')}
      sx={{
        ...itemStyle,
        color: 'info.light',
        border: `solid ${grade === 'knowledge' ? 2 : 1}px`,
        borderColor: grade === 'knowledge' ? 'black' : 'grey.500',
      }}
    >/</Box>
    {objective === 'skill' &&
      <Box
        onClick={() => setGrade('realised')}
        sx={{
          ...itemStyle,
          color: 'info.main',
          border: `solid ${grade === 'realised' ? 2 : 1}px`,
          borderColor: grade === 'realised' ? 'black' : 'grey.500',
        }}
      >X</Box>
    }
    {objective === 'skill' &&
      <Box
        onClick={() => setGrade('skill')}
        sx={{
          ...itemStyle,
          color: 'success.main',
          border: `solid ${grade === 'skill' ? 2 : 1}px`,
          borderColor: grade === 'skill' ? 'black' : 'grey.500',
        }}
      >O</Box>
    }
    <Box
      onClick={() => setGrade('trainingNeeded')}
      sx={{
        ...itemStyle,
        color: 'error.main',
        border: `solid ${grade === 'trainingNeeded' ? 2 : 1}px`,
        borderColor: grade === 'trainingNeeded' ? 'black' : 'grey.500',
      }}
    >•</Box>
  </Box>

Grader.propTypes = {
  className: PropTypes.string,
  grade: PropTypes.string,
  setGrade: PropTypes.func.isRequired,
  objective: PropTypes.string,
  generalImpression: PropTypes.bool,
}

export default Grader
