import React, { useEffect, useState } from 'react'
import TopBar from '../menu/TopBar'
import PropTypes from 'prop-types'
import NavigationDrawer from '../menu/NavigationDrawer'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { MENU_STATUS_BIG, MENU_STATUS_HIDDEN, MENU_STATUS_SMALL } from '../../_constants/globals'


const Page = ({ children, withTopBarAndMenu, containerSx }) => {
  
  const theme = useTheme()
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'))
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  
  const [menuStatus, setMenuStatus] = useState(MENU_STATUS_BIG)
  
  useEffect(() => {
    if (isUpLg) setMenuStatus(MENU_STATUS_BIG)
    else if (isUpMd) setMenuStatus(MENU_STATUS_SMALL)
    else setMenuStatus(MENU_STATUS_HIDDEN)
  }, [isUpLg, isUpMd])
  
  const drawerWidth = 256
  
  return (
    <Box sx={{ height: '100vh', backgroundColor: 'background.default' }}>
      {withTopBarAndMenu && <nav><NavigationDrawer drawerWidth={drawerWidth} menuStatus={menuStatus} setMenuStatus={setMenuStatus} /></nav>}
      {withTopBarAndMenu && <TopBar setMenuStatus={setMenuStatus} />}
      <Box sx={{
        ml: () => {
          if (!withTopBarAndMenu || menuStatus === MENU_STATUS_HIDDEN) return 0
          else if (menuStatus === MENU_STATUS_BIG) return drawerWidth + 'px'
          else return '60px'
        },
        ...containerSx,
      }}>
        {children}
      </Box>
    </Box>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  withTopBarAndMenu: PropTypes.bool,
  containerSx: PropTypes.object,
}

export default Page
