import React, { useMemo } from 'react'
import GeneralPanel from './GeneralPanel'
import PilotPanel from './PilotPanel'
import InstructorPanel from './InstructorPanel'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'
import useAuth from '../../hooks/useAuth'


const ProfilePage = () => {
  
  const profile = useAuth().getProfile()
  
  const tabs = useMemo(() => compact([
    {
      key: 'general',
      label: 'Général',
      route: 'general',
      content: <GeneralPanel />,
    },
    {
      key: 'pilot',
      label: 'Pilote',
      route: 'pilot',
      content: <PilotPanel />,
    },
    profile.roles.includes('instructeur') && {
      key: 'instructor',
      label: 'Instructeur',
      route: 'instructor',
      content: <InstructorPanel />,
    },
  ]), [profile.roles])

  return <TabbedPage tabs={tabs} />
}

export default ProfilePage
