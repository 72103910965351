import React, { useMemo, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import LoginForm from './LoginForm'
import Logo from '../../images/logo.svg'
import { parseParams } from '../../_helpers/urlHelper'
import { useLocation } from 'react-router-dom'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'


export const LoginPage = () => {
  
  const location = useLocation()
  const auth = useAuth()

  const urlParams = useMemo(() => parseParams(location.search), [location])

  useEffect(() => {
    if (urlParams.mode === 'signIn')
      auth.signInWithEmailLinkAction(urlParams.email)
  }, [urlParams])

  return (
    <Page containerSx={{ minHeight: 710, position: 'relative' }}>
      <Grid container justifyContent='center' alignItems='center' mt='calc(50vh - 340px)'>
        <Grid item lg={4} md={8} xs={10} style={{ textAlign: 'center' }}>
          <h2>Midi Pyrénées Voltige</h2>
          <Box component='img' src={Logo} alt='Logo' sx={{ width: '70%', maxWidth: '300px', mb: 4 }} />
          <LoginForm />
        </Grid>
      </Grid>
      <Box component='footer' sx={{ position: 'absolute', width: '100%', bottom: t => t.spacing(2) }}>
        <Typography variant='body2' color='textSecondary' align='center'>
          Version {window.version}
        </Typography>
      </Box>
    </Page>
  )
}

export default LoginPage
