import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'


export const CompletenessGraph = ({ completeness: { validatedItems, total }, sx }) => {

  const pourcent = useMemo(() => validatedItems / total * 100, [validatedItems, total])

  return (
    <Box sx={{ width: '100%', height: 20, ...sx }}>
      <Box sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        border: 'solid 1px',
        borderColor: 'primary.main',
      }}>
        <Box sx={{
          height: '100%',
          position: 'absolute',
          bgcolor: 'primary.main',
          width: `${pourcent}%`,
        }} />
        <Box sx={{
          position: 'absolute',
          fontSize: '80%',
          color: pourcent < 50  ? 'primary.main' : 'white',
          left: pourcent < 50 && `calc(${pourcent}% + 8px)`,
          right: pourcent >= 50 && `calc(${100 - pourcent}% + 8px)`,
        }}>
          {`${validatedItems} / ${total}`}
        </Box>
      </Box>
    </Box>
  )
}

CompletenessGraph.propTypes = {
  completeness: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default CompletenessGraph
