import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'

const svgProps = {
  version: '1.2',
  baseProfile: 'basic',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  width: 100,
  height: 160,
  style: {
    border: '1px solid black',
  },
  viewBox: '0 0 100 161',
  fill: 'none',
}

const styles = () => ({
  blackfill: {
    stroke: 'black',
    strokeWidth: '1px',
    fill: 'black',
  },
  pos: {
    stroke: 'black',
    strokeWidth: '1.5px',
    fill: 'none',
    vectorEffect: 'non-scaling-stroke',
  },
  neg: {
    strokeDasharray: '5, 3',
    stroke: 'red',
    strokeWidth: '1.5px',
    fill: 'none',
    vectorEffect: 'non-scaling-stroke',
  },
  corr: {
    stroke: 'red',
    strokeWidth: 2,
    fill: 'none',
  },
  corrfill: {
    stroke: 'red',
    strokeWidth: 2,
    fill: 'red',
  },
})

export const DrawTrick = ({ figure, classes }) => {

  const paths = useMemo(() => {
    if (!figure.paths) return null
    const result = []
    let X = 0
    let Y = 0
    console.log('figNr', figure.figNr)
    for (const el of figure.paths){
      if (el.path) {
        console.log(`M ${X},${Y} ${el.path}`)
        if (!el.path.includes('NaN')) result.push({ d: `M ${X},${Y} ${el.path}`, className: el.style })
      }
      if (el.dx) X += el.dx
      if (el.dy) Y += el.dy
    }
    return result
  }, [figure])

  const k = useMemo(() => figure.k && figure.k.reduce((acc, val) => acc + val, 0), [figure.k])

  if (!paths) return null
  return (
    <svg {...svgProps} >
      <g transform='translate(35.13,58.53) scale(0.61)'>
        {paths.map((path, index) => <path
          key={index}
          d={path.d}
          className={classes[path.className]}
        />)}
      </g>
      <text
        style={{ fontFamily: 'verdana, Helvetica, Sans', fontSize: '12px', fontWeight: 'bold', fill: 'black', textAnchor: 'start' }}
        x='5'
        y='151'
      >
        K: {k}
      </text>
      {figure.aresti.map((str, index) =>
        <text
          key={index}
          id={'Fig0Aresti' + index}
          style={{ fontFamily: 'verdana, Helvetica, Sans', fontSize: '12px', fill: 'black', textAnchor: 'start' }}
          x='5'
          y={136 - 15 * index}
        >
          {str}
        </text>,
      )}
    </svg>
  )
}

DrawTrick.propTypes = {
  figure: PropTypes.object.isRequired,
  classes: PropTypes.object,
}

export default withStyles(styles)(DrawTrick)
