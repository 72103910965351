import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import AllMembersPanel from './AllMembersPanel'
import AccountValidationPanel from './AccountValidationPanel'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'


const MemberPage = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)

  const tabs = useMemo(() => compact([
    {
      key: 'all',
      label: 'Tous les membres du club',
      route: 'all',
      content: <AllMembersPanel />,
    },
    adminMode && {
      key: 'subscriptions',
      label: 'Demandes de création de compte',
      route: 'subscriptions',
      content: <AccountValidationPanel />,
    },
  ]), [adminMode])

  return <TabbedPage tabs={tabs} />
}

export default MemberPage
