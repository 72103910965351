import { getAdditionalUserInfo, GoogleAuthProvider, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword,
  signInWithEmailLink, signInWithPopup, signOut as apiSignOut, updatePassword } from 'firebase/auth'
import { doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'
import { USER_COLLECTION } from '../_constants/globals'
import { auth, db, functions, listenerRefs } from '../firebase'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../store/slices/profileSlice'
import { mapValues } from 'lodash'
import { resetLocalStorage } from '../_helpers/localStorageHelper'
import { AUTH_ERROR, EMAIL_ERROR, EMAIL_SENT, RESET } from '../store/types'
import { ONBOARDING } from '../_constants/routes'
import { httpsCallable } from 'firebase/functions'
import { deSerialize } from '../_helpers/firestoreHelper'
import { useMemo } from 'react'
import { useNotification } from './useNotification'


const useAuth = () => {
  const dispatch = useDispatch()
  const serializedProfile = useSelector(state => state.profile?.data)
  const { resetNotifications } = useNotification()
  
  const deserializedProfile = useMemo(() => deSerialize(serializedProfile), [serializedProfile])
  
  const listenProfile = () =>
    onAuthStateChanged(auth, authUser => setTimeout(() => {
      if (authUser) {
        const collectionListener = onSnapshot(doc(db, USER_COLLECTION, authUser.uid), async dbUser => {
          const profile = {
            id: dbUser.id,
            ...dbUser.data(),
            identityProvider: authUser.auth.currentUser.providerData[0].providerId,
            isAnonymous: auth.currentUser.isAnonymous,
            emailVerified: auth.currentUser.emailVerified,
            isLoaded: true,
            // token: await auth.currentUser.getIdTokenResult(),
            isEmpty: false,
          }
          console.info('profile', profile)
          return dispatch(actions.success(profile))
        })
        listenerRefs.auth = { unsubscribe: collectionListener }
      }
      else dispatch(actions.success({ isEmpty: true, isLoaded: true }))
    }, 1000))
  
  const getProfile = () => deserializedProfile
  
  const signIn = ({ email, password }) =>
    signInWithEmailAndPassword(auth, email, password)
      .catch(err => dispatch({
        type: AUTH_ERROR,
        payload: err,
      }))
  
  /**
   * Signs in with Google account
   */
  const googleSignIn = () => new Promise(resolve => {
    auth.useDeviceLanguage()
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ login_hint: 'user@example.com' })
    signInWithPopup(auth, provider)
      .then(result => ({ ...getAdditionalUserInfo(result), uid: result.user.uid }))
      .then(user => user.isNewUser
        ? setDoc(doc(db, 'users', user.uid), {
          firstname: user.profile.given_name,
          lastname: user.profile.family_name,
          email: user.profile.email,
          photoUrl: user.profile.picture,
          roles: [],
          _createdAtTimeTime: serverTimestamp(),
          _updatedAtTime: serverTimestamp(),
        }).then(resolve)
        : resolve(),
      )
  })
  
  const signOut = () => {
    mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    resetLocalStorage()
    resetNotifications()
    dispatch({ type: RESET })
    return apiSignOut(auth)
  }
  
  const resetPasswordAction = email =>
    sendPasswordResetEmail(auth, email)
      .then(() => dispatch({ type: EMAIL_SENT }))
      .catch(error => {
        console.error(error)
        dispatch({ type: EMAIL_ERROR })
      })
  
  const updatePasswordAction = password => updatePassword(auth.currentUser, password)
  
  const signInWithEmailLinkAction = (email, url = window.location.href) =>
    signInWithEmailLink(auth, email, url + ONBOARDING)
  
  const checkPasswordAction = (email, password) =>
    httpsCallable(functions, 'checkPassword')({ email, password, _VERBOSE: process.env.NODE_ENV !== 'production' })
  
  return { listenProfile, getProfile, signIn, googleSignIn, signOut, updatePasswordAction, resetPasswordAction, signInWithEmailLinkAction, checkPasswordAction }
}

export default useAuth
