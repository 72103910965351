import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'


const styles = theme => ({
  root: {
    width: '100%',
  },
  graph: {
    width: '100%',
    height: 20,
    position: 'relative',
  },
  outOfMargin: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.error.light,
  },
  withMargin: {
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.warning.light,
  },
  withoutMargin: {
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.success.light,
  },
  cursor: {
    height: 26,
    width: 2,
    backgroundColor: 'black',
    position: 'absolute',
    top: -3,
  },
  values: {
    float: 'right',
    fontSize: '.7rem',
    marginTop: 20,
    marginRight: '-1rem',
  },
})

export const PotentialGraph = ({ classes, className, aircraft: { followUp: { lastCheckupCounter: last, nextCheckupCounter: next, margin }, lastCounter: counter } }) => {

  const yellowWidth = useMemo(() => counter > next + margin ? (next + margin - last) / (counter - last) * 100 : 100, [last, next, margin, counter])
  const greenWidth = useMemo(() => counter > next + margin
    ? (next - last) / (counter - last) * 100
    : (next - last) / (next - last + margin) * 100
  , [last, next, margin, counter])

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.graph}>
        {counter > next + margin && <div className={classes.outOfMargin} />}
        <div className={classes.withMargin} style={{ width: `${yellowWidth}%` }} />
        <div className={classes.withoutMargin} style={{ width: `${greenWidth}%` }} >
          <span className={classes.values} style={{ float: 'left', marginLeft: '-1rem' }}>{last}</span>
          <span className={classes.values}>{next}</span>
        </div>
        <div className={classes.cursor} style={counter > next + margin
          ? { right: 0 }
          : { left: `${(counter - last) / (next + margin - last) * 100}%` }
        }>
          <span className={classes.values} style={{ marginTop: -15 }}>{counter}</span>
        </div>
      </div>
    </div>
  )
}


PotentialGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  aircraft: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default compose(
  withStyles(styles),
)(PotentialGraph)
