import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Edit } from '@mui/icons-material'


const styles = () => ({
  title: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: '200px',
  },
  value: {
    display: 'inline-block',
    color: 'black',
  },
  editButton: {
    color: 'rgba(0,0,0,.54)',
    marginLeft: '10px',
    '&:hover': {
      color: 'black',
      backgroundColor: 'initial',
    },
  },
  dialogButton: {
    fontWeight: 600,
  },
})

const InlineEditor = ({ handleSubmit, title, value, classes, multiline, rows, type, inputProps, disabled, titleStyle, hint }) => {

  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(value)
  const [error, setError] = useState(false)

  useEffect(() => {
    setUpdate(value)
  }, [value, setUpdate])

  const submit = () =>
    handleSubmit(update)
      .then(() => setOpen(false))
      .catch(err => {
        setError(true)
        console.error(err)
      })

  return (
    <div>
      <Typography className={classes.title} color='textSecondary' gutterBottom style={titleStyle}>{title}</Typography>
      <Typography className={classes.value} color='textSecondary' gutterBottom>{value}</Typography>
      {!disabled && <Button className={classes.editButton} onClick={() => setOpen(true)} style={{ minWidth: 40 }}>
        <Edit />
      </Button>}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='form-dialog-title' fullWidth>
        <DialogContent>
          <TextField
            id={title + 'Field'}
            type={type || 'text'}
            fullWidth
            variant='outlined'
            value={update || ''}
            onChange={e => setUpdate(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && submit()}
            error={error}
            multiline={multiline}
            rows={rows}
            inputProps={inputProps}
            label={title}
            helperText={hint}
          />
        </DialogContent>
        <DialogActions>
          <Button className={classes.dialogButton} onClick={() => setOpen(false)} style={{ color: 'rgba(0,0,0,.54)' }}>
            Annuler
          </Button>
          <Button className={classes.dialogButton} onClick={submit} color='primary' variant='contained'>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

InlineEditor.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  classes: PropTypes.object.isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  titleStyle: PropTypes.object,
  hint: PropTypes.string,
}

export default withStyles(styles)(InlineEditor)
