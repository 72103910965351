import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Typography, Button, CardActions, TextField } from '@mui/material'
import { USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const Welcome = ({ next }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const auth = useAuth()
  const profile = auth.getProfile()
  
  const [password, setPassword] = useState(null)
  const [errors, setErrors] = useState({})
  
  const handleSubmit = e => {
    e.preventDefault()
    console.log('handleSubmit')
    if (profile.hasNoPassword){
      if (!password || password.length < 6)
        setErrors(prev => ({ ...prev, password: 'Le mot de passe doit contenir au moins 6 caractères' }))
      else
        auth.updatePasswordAction(password)
          .then(() => userHooks.updateDoc(profile.id, { hasNoPassword: false }))
          .then(next)
          .catch(auth.signOut)
    }
    else next()
  }
  
  return (
    <Card variant='outlined' sx={{ maxWidth: 900, mx: 'auto', my: 2 }}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Typography variant='h1'>Bienvenue chez MPV</Typography>
          <Typography variant='body1'>Tu es actuellement sur la page d&apos;adhésion au club.</Typography>
          {profile.hasNoPassword && <>
            <Typography variant='body1'>Commence par choisir un mot de passe et clique sur Suivant</Typography>
            <TextField
              variant='standard'
              label='Mot de passe'
              value={password || ''}
              onChange={e => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              type='password'
              sx={{ mt: 2 }}
            />
          </>}
        </CardContent>
        <CardActions sx={{ float: 'right' }}>
          <Button size='large' color='secondary' onClick={auth.signOut}>Déconnexion</Button>
          <Button type='submit' size='large' color='primary'>Suivant</Button>
        </CardActions>
      </form>
    </Card>
  )
}

Welcome.propTypes = {
  next: PropTypes.func.isRequired,
}

export default Welcome
