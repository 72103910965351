import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem,
  Select, TextField, Typography, Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material'
import UserAutocomplete from '../_library/UserAutocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { addMinutes, endOfYear, format, startOfDay, startOfYear } from 'date-fns'
import { AIRCRAFT_COLLECTION, AIRPORT_COLLECTION, BILLING_TYPE_COLLECTION, BOOKING_COLLECTION, FLIGHT_COLLECTION, FLIGHT_TYPE_COLLECTION, ROLE_INSTRUCTOR, STATUS_VISITOR, USER_COLLECTION } from '../../_constants/globals'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { omit, pickBy } from 'lodash'
import { ERROR, FLIGHT_CREATE_SUCCESS } from '../../store/types'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


export const CreateFlightForm = ({ booking, next, onClose, setFlightRef }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const dbFlightTypes = flightTypeHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  const airportHooks = useFirestore(AIRPORT_COLLECTION)
  const dbAirports = airportHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const profile = useAuth().getProfile()
  const billingTypeHooks = useFirestore(BILLING_TYPE_COLLECTION)
  const dbBillingTypes = billingTypeHooks.getDocs()
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  const bookingHooks = useFirestore(BOOKING_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const dispatch = useDispatch()
  
  const [startDate, setStartDate] = useState(startOfDay(Date.now()))
  const [aircraft, setAircraft] = useState(null)
  const [departure, setDeparture] = useState(null)
  const [arrival, setArrival] = useState(null)
  const [duration, setDuration] = useState(0)
  const [counterDeparture, setCounterDeparture] = useState(null)
  const [counterArrival, setCounterArrival] = useState(null)
  const [landings, setLandings] = useState(1)
  const [flightType, setFlightType] = useState(null)
  const [billingType, setBillingType] = useState(null)
  const [gPos, setGPos] = useState(0)
  const [gNeg, setGNeg] = useState(0)
  const [fuelBefore, setFuelBefore] = useState(0)
  const [fuelAfter, setFuelAfter] = useState(0)
  const [pilot, setPilot] = useState(null)
  const [instructor, setInstructor] = useState(undefined)
  const [comments, setComments] = useState(null)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  
  const scroll = useRef()
  
  const aircrafts = useMemo(() => dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const flightTypes = useMemo(
    () => dbFlightTypes?.filter(t => adminMode || profile.flightTypes?.some(slug => slug === t.slug)),
    [adminMode, dbAircrafts],
  )
  const billingTypes = useMemo(
    () => dbBillingTypes?.filter(t => adminMode || profile.billingTypes?.some(slug => slug === t.slug)),
    [adminMode, dbBillingTypes],
  )
  const selectedAircraft = useMemo(() => dbAircrafts?.find(a => a.id === selectedAircraftId), [dbAircrafts, selectedAircraftId])
  const instructors = useMemo(() => dbUsers?.filter(u => u.roles?.includes('instructeur')), [dbUsers])
  const arrivalTime = useMemo(() => startDate && addMinutes(startDate, duration * 60), [startDate, duration])
  
  useEffect(() => {
    airportHooks.listen({ orderBy: [['occurrences', 'desc']] })
    return airportHooks.unsubscribe
  }, [])
  
  useEffect(() => {
    if (aircrafts && dbAirports && instructors && flightTypes) {
      if (booking) {
        const aircraft = aircrafts.find(a => a.id === booking.aircraftRef.id)
        const flightType = flightTypes.find(t => t.id === booking.typeRef.id)
        const pilot = booking.pilotRef && dbUsers.find(u => u.id === booking.pilotRef.id)
        const instructor = booking.instructorRef && instructors.find(u => u.id === booking.instructorRef.id)
        setStartDate(booking.startDate)
        setAircraft(aircraft)
        setFlightType(flightType)
        setPilot(pilot)
        setInstructor(instructor)
      }
      else if (selectedAircraft) {
        const aircraft = aircrafts.find(a => a.id === selectedAircraft.id)
        if (aircraft)
          setAircraft(aircraft)
      }
      else if (profile.favoritAircraftId) {
        const aircraft = aircrafts.find(a => a.id === profile.favoritAircraftId)
        if (aircraft)
          setAircraft(aircraft)
      }
      else
        setDeparture(dbAirports.find(a => a.ICAO === 'LFBR'))
    }
  }, [aircrafts, dbAirports, booking, profile.favoritAircraftId, instructors, flightTypes, dbUsers, selectedAircraft])

  useEffect(function onAircraftChange() {
    if (dbAirports && aircraft?.lastFlight)
      setDeparture(dbAirports.find(a => a.id === aircraft.lastFlight.arrivalId))
    if (aircraft?.lastCounter) {
      setCounterDeparture(aircraft.lastCounter)
      setCounterArrival(aircraft.lastCounter)
    }
  }, [aircraft, dbAirports])
  
  useEffect(function onFlightTypeChange() {
    if (flightType?.defaultBillingTypeRef) setBillingType(billingTypes?.find(t => t.id === flightType.defaultBillingTypeRef.id))
    setErrors(prev => ({ ...prev, flightType: null }))
  }, [flightType, billingTypes])
  
  useEffect(function onFlightTypeAndDepartureChange() {
    if (!['local', 'navigation'].includes(flightType?.slug)) setArrival(departure)
  }, [flightType, departure])
  
  useEffect(function onCounterDepartureChange() {
    if (counterDeparture && aircraft && parseFloat(counterDeparture) !== aircraft.lastCounter)
      setErrors(prev => ({ ...prev, counterDeparture: 'L\'horamètre ne correspond pas au dernier vol' }))
    else
      setErrors(prev => ({ ...prev, counterDeparture: undefined }))
  }, [counterDeparture, aircraft])
  
  useEffect(function onCountersChange() {
    aircraft && setDuration(counterArrival - counterDeparture + aircraft.price.fixCounter)
  }, [counterDeparture, counterArrival, aircraft])
  
  useEffect(function onUsersUpdate() {
    if (!dbUsers) return
    if (booking) {
      !pilot && setPilot(dbUsers.find(u => u.id === booking.pilotRef.id))
      instructor === undefined && setInstructor(dbUsers.find(u => u.id === booking.instructorRef?.id))
    }
    else {
      !pilot && setPilot(dbUsers.find(u => u.id === profile.id))
      instructor === undefined && profile.roles.includes(ROLE_INSTRUCTOR) && setInstructor(dbUsers.find(u => u.id === profile.id))
    }
  }, [dbUsers, profile, booking, pilot, instructor])
  
  useEffect(function onDepartureChange() {
    setErrors(prev => ({ ...prev, departure: null }))
  }, [departure])
  
  useEffect(function onArrivalChange() {
    setErrors(prev => ({ ...prev, arrival: null }))
  }, [arrival])
  
  useEffect(function onCounterArrivalChange() {
    setErrors(prev => ({ ...prev, counterArrival: null }))
  }, [counterArrival])
  
  useEffect(function onDurationChange() {
    setErrors(prev => ({ ...prev, duration: null }))
  }, [duration])
  
  useEffect(function onStartDateChange() { setErrors(prev => ({ ...prev, startDate: null })) }, [startDate])
  
  useEffect(function onBillingTypesReady() {
    billingTypes && setBillingType(billingTypes[0])
  }, [billingTypes])
  
  useEffect(function onPilotChange() {
    pilot?.status === STATUS_VISITOR && setBillingType(billingTypes?.find(t => t.slug === 'cash'))
  }, [pilot, billingTypes])
  
  const handleSubmit = () => {
    if (!aircraft)
      setErrors(prev => ({ ...prev, aircraft: 'il faut sélectionner un avion' }))
    if (startDate.getHours() === 0 && startDate.getMinutes() === 0)
      setErrors(prev => ({ ...prev, startDate: 'il faut indiquer une heure de départ' }))
    if (!flightType)
      setErrors(prev => ({ ...prev, flightType: 'il faut sélectionner un type de vol' }))
    if (!billingType)
      setErrors(prev => ({ ...prev, billingType: 'il faut sélectionner un type de facturation' }))
    if (!departure)
      setErrors(prev => ({ ...prev, departure: 'Le terrain de départ n\'est pas défini' }))
    if (!arrival)
      setErrors(prev => ({ ...prev, arrival: 'Le terrain d\'arrivée n\'est pas défini' }))
    if (counterArrival < counterDeparture)
      setErrors(prev => ({ ...prev, counterArrival: 'L\'horamètre d\'arrivée ne peux pas être inférieur à l\'horamètre de départ' }))
    if (aircraft && duration < aircraft.price.fixCounter)
      setErrors(prev => ({ ...prev, duration: 'La durée du vol est négative' }))
    if (aircraft && duration === aircraft.price.fixCounter)
      setErrors(prev => ({ ...prev, duration: 'La durée du vol est nulle' }))
    if (duration > 4)
      setErrors(prev => ({ ...prev, duration: 'La durée du vol est supérieure à l\'autonomie de l\'avion' }))
    if (aircraft && startDate.getHours() !== 0 && flightType && departure && arrival && counterArrival >= counterDeparture && duration !== 0) {
      setSubmitted(true)
      return flightHooks.addDoc(pickBy({
        startDate,
        duration: parseFloat(parseFloat(duration).toFixed(2)),
        counterDeparture: parseFloat(parseFloat(counterDeparture).toFixed(2)),
        counterArrival: parseFloat(parseFloat(counterArrival).toFixed(2)),
        landings: parseFloat(landings),
        gPos: parseFloat(gPos),
        gNeg: parseFloat(gNeg),
        fuelBefore: parseFloat(fuelBefore),
        fuelAfter: parseFloat(fuelAfter),
        pilotRef: userHooks.getDocRef(pilot.id || profile.id),
        instructorRef: instructor?.id ? userHooks.getDocRef(instructor?.id) : null,
        comments,
        bookingRef: booking?.id ? bookingHooks.getDocRef(booking?.id) : null,
        aircraftRef: aircraftHooks.getDocRef(aircraft.id),
        departureRef: airportHooks.getDocRef(departure.id),
        arrivalRef: airportHooks.getDocRef(arrival.id),
        flightTypeRef: flightTypeHooks.getDocRef(flightType.id),
        billingTypeRef: billingTypeHooks.getDocRef(billingType.id),
      }))
        .then(doc => {
          dispatch({ type: FLIGHT_CREATE_SUCCESS })
          setFlightRef(doc)
          setSubmitted(false)
          return next()
        })
        .catch(err => dispatch({ type: ERROR, payload: err }))
    }
    else
      scroll.current
        ? scroll.current.scrollIntoView({ block: 'center' })
        : window.scrollTo(0, 0)
  }
  
  return <>
    <DialogContent>
      {!aircrafts || !flightTypes || !dbAirports || !billingTypes
        ? <CircularProgress color='inherit' />
        : <>
          <Grid container id='aircraft' item xs={12} spacing={3} mt={1}>
            <Grid item md={6} sx={{ pt: '0 !important' }}>
              <Typography variant='subtitle1'>Avion</Typography>
              <ToggleButtonGroup
                value={aircraft}
                exclusive
                onChange={(_, value) => {
                  setErrors(prev => omit(prev, 'aircraft'))
                  setAircraft(value)
                }}
                ref={el => {
                  if (errors.aircraft && el) scroll.current = el
                }}
              >
                {aircrafts && aircrafts.map(aircraft =>
                  <ToggleButton
                    key={aircraft.id}
                    value={aircraft}
                    sx={{
                      color: 'primary.main',
                      borderColor: errors.aircraft ? 'error.main' : 'primary.main',
                      '&:hover': {
                        color: 'white',
                        bgcolor: 'primary.main',
                      },
                      '&.Mui-selected': {
                        color: 'white',
                        bgcolor: 'primary.main',
                        '&:hover': {
                          color: 'white',
                          bgcolor: 'primary.main',
                          cursor: 'default',
                        },
                      },
                    }}
                  >
                    {aircraft.name}
                  </ToggleButton>,
                )}
              </ToggleButtonGroup>
              {errors.aircraft && <Typography color='error.main' fontSize='0.75rem' lineHeight={1.66}>{errors.aircraft}</Typography>}
            </Grid>
            <Grid item md={6}>
              <DatePicker
                disableToolbar
                variant='inline'
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='startDate-date'
                label='Date du vol'
                value={startDate}
                onChange={setStartDate}
                minDate={startOfYear(new Date())}
                maxDate={endOfYear(new Date())}
                sx={{ mt: 4 }}
              />
            </Grid>
          </Grid>
          <Grid container id='flight-type' item xs={12} spacing={3} mt={1}>
            <Grid item md={6} xs={12}>
              <FormControl
                variant='standard'
                sx={{ width: '100%' }}
                error={Boolean(errors.flightType)}
                ref={el => {
                  if (errors.flightType && el) scroll.current = el
                }}
              >
                <InputLabel id='flight-type-select-label'>Type de vol</InputLabel>
                <Select
                  variant='standard'
                  labelId='flight-type-select-label'
                  id='flight-type-select'
                  value={flightType || ''}
                  onChange={e => setFlightType(e.target.value)}
                >
                  {flightTypes?.map(type =>
                    <MenuItem key={type.id} value={type}>
                      {type.name}
                    </MenuItem>,
                  )}
                </Select>
                <FormHelperText>{errors.flightType}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                variant='standard'
                error={Boolean(errors.billingType)}
                ref={el => {
                  if (errors.billingType && el) scroll.current = el
                }}
              >
                <InputLabel id='billing-type-select-label'>Facturation</InputLabel>
                <Select
                  variant='standard'
                  labelId='billing-type-select-label'
                  id='billing-type-select'
                  value={billingType || ''}
                  onChange={e => setBillingType(e.target.value)}
                >
                  {billingTypes?.map(type =>
                    <MenuItem key={type.id} value={type}>
                      {type.name}
                    </MenuItem>,
                  )}
                </Select>
                <FormHelperText>{errors.billingType}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container id='crew' item xs={12} spacing={3} mt={1}>
            <Typography variant='subtitle1' width='100%' mt={4}>Equipage</Typography>
            <Grid item md={6} xs={12}>
              {dbUsers && <UserAutocomplete
                disabled={!profile.roles?.includes('admin') && !profile.roles?.includes('instructeur')}
                users={dbUsers}
                user={pilot || null}
                label='Pilote'
                set={setPilot}
                disableClearable
                freeSolo
              />}
            </Grid>
            <Grid item md={6} xs={12}>
              {instructors && <UserAutocomplete
                users={instructors}
                user={instructor}
                label='Instructeur'
                set={setInstructor}
              />}
            </Grid>
          </Grid>
          <Grid container id='airports' item xs={12} spacing={3} mt={1}>
            <Typography variant='subtitle1' width='100%' mt={4}>Terrains</Typography>
            <Grid item md={6} xs={12}>
              <Autocomplete
                id='departure-select'
                options={dbAirports}
                getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
                renderInput={params =>
                  <TextField
                    variant='standard'
                    {...params}
                    label='Départ'
                    fullWidth
                    error={Boolean(errors.departure)}
                    helperText={errors.departure}
                    ref={el => {
                      if (errors.departure && el) scroll.current = el
                    }}
                  />
                }
                value={departure}
                onChange={(event, newValue) => setDeparture(newValue)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                autoComplete
                autoSelect
                id='arrival-select'
                options={dbAirports}
                getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
                renderInput={params =>
                  <TextField
                    variant='standard'
                    {...params}
                    label='Arrivée'
                    fullWidth
                    error={Boolean(errors.arrival)}
                    helperText={errors.arrival}
                    ref={el => {
                      if (errors.arrival && el) scroll.current = el
                    }}
                  />
                }
                value={arrival}
                onChange={(event, newValue) => setArrival(newValue)}
                isOptionEqualToValue={(option, value) => value && option.id === value.id}
              />
            </Grid>
          </Grid>
          <Grid container id='counter' item xs={12} spacing={3} mt={1}>
            <Typography variant='subtitle1' width='100%' mt={4}>Horamètre</Typography>
            <Grid item xs={12} md={4}>
              <TextField
                variant='standard'
                margin='dense'
                id='counterDeparture'
                label='Départ'
                type='number'
                fullWidth
                value={counterDeparture || ''}
                onChange={e => setCounterDeparture(e.target.value)}
                inputProps={{ step: aircraft?.price.float || .01 }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.counterDeparture)}
                helperText={errors.counterDeparture}
                ref={el => {
                  if (errors.counterDeparture && el) scroll.current = el
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant='standard'
                margin='dense'
                id='counterArrival'
                label='Arrivée'
                type='number'
                fullWidth
                value={counterArrival || ''}
                onChange={e => setCounterArrival(e.target.value)}
                inputProps={{ step: aircraft?.price.float || .01 }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.counterArrival)}
                helperText={errors.counterArrival}
                ref={el => {
                  if (errors.counterArrival && el) scroll.current = el
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant='standard'
                margin='dense'
                id='duration'
                label='Durée'
                type='number'
                fullWidth
                value={duration.toFixed(2)}
                onChange={e => setDuration(parseFloat(e.target.value))}
                error={Boolean(errors.duration)}
                helperText={errors.duration}
                inputProps={{ step: aircraft?.price.float || .01 }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TimePicker
                ampm={false}
                margin='dense'
                id='startDate-time'
                label='Heure de départ'
                value={startDate}
                minutesStep={5}
                onChange={date =>
                  date instanceof Date && !isNaN(date.valueOf()) &&
                  setStartDate(prev => new Date(format(prev, 'yyyy-MM-dd') + `T${('00' + date.getHours()).slice(-2)}:${('00' + date.getMinutes()).slice(-2)}:00`))
                }
                ref={el => {
                  if (errors.startDate && el) scroll.current = el
                }}
                slotProps={{ textField: { error: !!errors.startDate, helperText: errors.startDate } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TimePicker
                variant='inline'
                ampm={false}
                margin='dense'
                id='arrivalTime'
                label="Heure d'arrivée"
                value={arrivalTime}
                disabled
                onChange={() => null}
              />
            </Grid>
          </Grid>
          <Grid container id='loadFactors' spacing={3} mt={1}>
            <Typography variant='subtitle1' width='100%' mt={4}>Facteurs de charge</Typography>
            <Grid item xs={12} md={6}>
              <TextField
                variant='standard'
                margin='dense'
                id='gPos'
                label='Positif'
                type='number'
                value={gPos}
                onChange={e => setGPos(e.target.value)}
                fullWidth
                inputProps={{ step: .1 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant='standard'
                margin='dense'
                id='gNeg'
                label='Négatif'
                type='number'
                value={gNeg}
                onChange={e => setGNeg(e.target.value)}
                fullWidth
                inputProps={{ step: .1 }}
              />
            </Grid>
          </Grid>
          <Grid container id='fuel' spacing={3} mt={1}>
            <Typography variant='subtitle1' width='100%' mt={4}>Essence</Typography>
            <Grid item xs={12} md={6}>
              <TextField
                variant='standard'
                margin='dense'
                id='fuelBefore'
                label='Avant le vol'
                type='number'
                value={fuelBefore}
                onChange={e => setFuelBefore(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant='standard'
                margin='dense'
                id='fuelAfter'
                label='Après le vol'
                type='number'
                value={fuelAfter}
                onChange={e => setFuelAfter(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container id='miscellaneous' spacing={3} mt={1}>
            <Typography variant='subtitle1' width='100%' mt={4}>Divers</Typography>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                margin='dense'
                label='Atterrissages'
                type='number'
                fullWidth
                value={landings}
                onChange={e => setLandings(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                margin='dense'
                label='Commentaires'
                fullWidth
                value={comments || ''}
                multiline
                onChange={e => setComments(e.target.value)} />
            </Grid>
          </Grid>
        </>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>Annuler</Button>
      <Button onClick={handleSubmit} color='primary' disabled={submitted}>Valider</Button>
    </DialogActions>
  </>
}

CreateFlightForm.propTypes = {
  booking: PropTypes.object,
  next: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setFlightRef: PropTypes.func.isRequired,
}

export default CreateFlightForm
