import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid, Typography, Button, CardActions, Collapse } from '@mui/material'
import DistributionGraph from './DistributionGraph'
import CompletenessGraph from './CompletenessGraph'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import InstructorsGraph from './InstructorsGraph'


export const StatCard = ({ progressBook }) => {

  const [expand, setExpand] = useState(false)

  return (
    <Card sx={{ maxWidth: 'calc(100vw - 270px)', mb: 4, p: 1, position: 'relative' }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h5'>Items validés</Typography>
            <CompletenessGraph completeness={progressBook.completeness} sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>Répartition des vols et des briefings</Typography>
            <DistributionGraph progressBookId={progressBook.id} sx={{ mb: 2 }} />
          </Grid>
          <Collapse in={expand} sx={{ width: '100%' }}>
            <Grid item sm={6}>
              <Typography variant='h6'>Instructeurs ayant participé à la formation</Typography>
              <InstructorsGraph progressBook={progressBook} />
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
      <CardActions sx={{ p: 0, m: '0 -10px -10px' }}>
        <Button id='expandStatCardBtn' color='primary' onClick={() => setExpand(prev => !prev)} sx={{
          width: '100%',
          bgcolor: 'grey.100',
          borderRadius: 0,
          height: 20,
        }}>
          {expand
            ? <ArrowDropUp />
            : <ArrowDropDown />
          }
        </Button>
      </CardActions>
    </Card>
  )
}

StatCard.propTypes = {
  progressBook: PropTypes.object.isRequired,
}

export default StatCard
