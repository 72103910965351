import { storage } from '../firebase'
import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage'

export const useStorage = folder => {
  
  const uploadFileAction = (file, name) =>
    uploadBytes(ref(storage, `${folder}/${name || file.name}`), file)
      .then(snapshot => getDownloadURL(ref(storage, snapshot.ref.fullPath)))
  
  const deleteFile = fileName => deleteObject(ref(storage, `${folder}/${fileName}`))

  return { uploadFileAction, deleteFile }
}
