import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import AircraftBigCard from './AircraftBigCard'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'


const FleetSiteUrl = 'https://sites.google.com/view/suiviflottempv/suivi-flotte'

export const AircraftOverviewPanel = () => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  
  return <Grid container spacing={1} sx={{ minHeight: '426px', m: '0 auto', maxWidth: '936px' }}>
    <Grid item xs={12}>
      <Typography variant='h3' display='inline-block'>Tous les avions&nbsp;</Typography>
      {adminMode && <Button onClick={() => window.open(FleetSiteUrl, '_blank')} sx={{ verticalAlign: 'bottom' }} >
        <FontAwesomeIcon icon={faExternalLinkAlt} fontSize='2rem' />
      </Button>}
    </Grid>
    {dbAircrafts?.map(aircraft =>
      <Grid item md={6} key={aircraft.id} sx={{ zIndex: 1, ml: { xs: 1, md: 0 } }}>
        <AircraftBigCard aircraft={aircraft} sx={{ height: '500px' }} />
      </Grid>,
    )}
  </Grid>
}

export default AircraftOverviewPanel
