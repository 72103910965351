import React, { useEffect, useMemo } from 'react'
import { AppBar, Typography, Grid, Paper } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import FlightCard from './FlightCard'
import AnnouncementCard from './AnnouncementCard'
import AircraftCard from './AircraftCard'
import ProgressionCard from './ProgressionCard'
import { AIRCRAFT_COLLECTION, BOOKING_COLLECTION, FLIGHT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { addMonths, startOfDay } from 'date-fns'
import Page from '../_library/Page'
import { AirworthinessCard } from './AirworthinessCard'
import ActivityCard from './ActivityCard'
import { useFirestore } from '../../hooks/useFirestore'


export const DashboardPage = () => {
  
  const profile = useAuth().getProfile()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const bookingHooks = useFirestore(BOOKING_COLLECTION)
  const dbBookings = bookingHooks.getDocs()
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  
  useEffect(() => {
    flightHooks.listen({
      where: [['startDate', '>', addMonths(startOfDay(Date.now()), -3)]],
      orderBy: [['startDate', 'asc']],
    })
    return flightHooks.unsubscribe
  }, [])
  useEffect(() => {
    bookingHooks.listen({
      where: [['pilotRef', '==', userHooks.getDocRef(profile.id)]],
    })
    return bookingHooks.unsubscribe
  }, [profile.id])
  
  const aircrafts = useMemo(() => dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const openBooks = useMemo(() => dbBookings?.filter(b => b.status === 'open'), [dbBookings])
  
  return <Page withTopBarAndMenu>
    <AppBar
      component='div'
      color='primary'
      position='relative'
      elevation={0}
      sx={{ height: '40vh' }}
    >
      <Typography color='inherit' variant='h4' component='h1' ml={1}>
        {profile.firstname + ' ' + profile.lastname}
      </Typography>
    </AppBar>
    <Paper sx={{ mt: '-250px', mx: 'auto', maxWidth: '936px', backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Grid container justifyContent='center' spacing={1} px={2} >
        <Grid item md={12}>
          <AnnouncementCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <AirworthinessCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <ActivityCard />
        </Grid>
        <Grid item xs={12} md={4}>
          {openBooks?.length > 0 ? <ProgressionCard /> : <FlightCard />}
        </Grid>
      </Grid>
      <Grid container justifyContent='center' spacing={1} px={2}>
        {aircrafts?.map(aircraft =>
          <Grid item xs={12} md={4} key={aircraft.id}>
            <AircraftCard aircraft={aircraft} />
          </Grid>,
        )}
      </Grid>
    </Paper>
  </Page>
}

export default DashboardPage
