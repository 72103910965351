import * as React from 'react'
import { ButtonBase, Typography, Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { ACTIVITIES } from '../../_constants/routes'
import { formatDistanceToNow } from 'date-fns'
import { fr } from 'date-fns/locale'


const ActivityButton = ({ id, title, coverUrl, firstDayDate }) => {
  
  const navigate = useNavigate()
  
  return (
    <ButtonBase focusRipple onClick={() => navigate(ACTIVITIES + '/' + id)} sx={{
      position: 'relative',
      width: '100% !important', // Overrides inline-style
      height: 70,
      mt: 1,
      '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
          opacity: 0.15,
        },
      },
    }}>
      <Box component='span' sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        backgroundImage: `url(${coverUrl})`,
      }} />
      <Box component='span' className='MuiImageBackdrop-root' sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'black',
        opacity: 0.5,
        transition: theme => theme.transitions.create('opacity'),
      }} />
      <Box component='span' sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        flexDirection: 'column',
      }}>
        <Typography variant='subtitle1' color='inherit'>{title}</Typography>
        <Typography variant='caption' fontStyle='italic'>
          {formatDistanceToNow(firstDayDate, { addSuffix: true, locale: fr })}
        </Typography>
      </Box>
    </ButtonBase>
  )
}

ActivityButton.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coverUrl: PropTypes.string.isRequired,
  firstDayDate: PropTypes.object.isRequired,
}

export default ActivityButton
