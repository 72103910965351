import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { some } from 'lodash'
import Loading from '../_library/Loading'
import { PB_ITEM_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


export const NextItems = ({ progressBookId }) => {
  
  const itemHooks = useFirestore(PB_ITEM_COLLECTION, { storeAs: 'items' })
  const items = itemHooks.getDocs()
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION, { storeAs: 'lessons' })
  const lessons = lessonHooks.getDocs()
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION, { storeAs: 'lessons' })
  
  useEffect(() => {
    itemHooks.listen({ orderBy: [['order', 'asc']] })
    return itemHooks.unsubscribe
  }, [])

  useEffect(() => {
    lessonHooks.listen({
      where: [['progressBookRef', '==', bookHooks.getDocRef(progressBookId)]],
      orderBy: [['date', 'asc']],
    })
    return lessonHooks.unsubscribe
  }, [progressBookId])
  
  const progressBook = useMemo(() => bookHooks.fetchDoc(bookHooks.getDocRef(progressBookId)), [progressBookId])

  const unvalidatedItems = useMemo(() =>
    progressBook && items && lessons && items.filter(i =>
      i.level === progressBook.level
      && i.type === 'practical'
      && !lessons.some(l => some(l.practicalItems, (obj, key) => key === i.id && obj.grade === i.objective)),
    ),
  [progressBook, items, lessons],
  )

  if (!unvalidatedItems) return <Loading />
  else return (<>
    <ul>
      {unvalidatedItems.slice(0, 4).map(item => <li key={item.id}>{item.name}</li>)}
    </ul>
    {unvalidatedItems.length > 4 && `...et ${unvalidatedItems.length - 4} autres`}
  </>)
}

NextItems.propTypes = {
  progressBookId: PropTypes.string.isRequired,
}

export default NextItems
