import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import AircraftManagement from './AircraftManagementPanel'
import AircraftOverviewPanel from './AircraftOverviewPanel'
import AircraftSelector from '../_library/AircraftSelector'
import TabbedPage from '../_library/TabbedPage'


const AircraftPage = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)
  
  const tabs = useMemo(() => [
    {
      key: 'overview',
      label: 'Vue générale',
      route: 'overview',
      content: <AircraftOverviewPanel />,
    },
    adminMode && {
      key: 'management',
      label: 'Gestion',
      route: 'management',
      headerContent: <AircraftSelector />,
      content: <AircraftManagement />,
    },
  ], [adminMode])

  return <TabbedPage tabs={tabs} />
}

export default AircraftPage
