import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Grid, Tab, Tabs, Toolbar, Typography } from '@mui/material'


const Header = ({ setValue, value = 0, title, tabs, children }) => <>
  <AppBar
    component='div'
    color='primary'
    position='sticky'
    elevation={0}
    sx={{ zIndex: 1000 }}
  >
    <Toolbar>
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs>
          <Typography color='inherit' variant='h5' component='h1' display='inline-flex' mr={3} sx={{ verticalAlign: 'middle' }}>
            {title}
          </Typography>
          {children}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
  <AppBar
    component='div'
    color='primary'
    position='sticky'
    elevation={0}
  >
    <Tabs value={value} onChange={(_, val) => setValue(val)} textColor='inherit'>
      {tabs.map(tab => (
        <Tab key={'tab' + tab.key} label={tab.label} id={'tab-' + tab.key} value={tab.key} />
      ))}
    </Tabs>
  </AppBar>
</>

Header.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
}

export default Header
