import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, DialogActions, DialogContent, Grid, DialogTitle, Dialog, CircularProgress, Typography,
  Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { omit } from 'lodash'
import { endOfYear, startOfYear } from 'date-fns'
import { useFirestore } from '../../hooks/useFirestore'
import { ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION, ACCOUNT_FLUX_COLLECTION } from '../../_constants/globals'
import { serverTimestamp } from 'firebase/firestore'


export const FluxCreateDialog = ({ open, onClose }) => {
  
  const accountHooks = useFirestore(ACCOUNT_COLLECTION)
  const dbAccounts = accountHooks.getDocs()
  const fluxHooks = useFirestore(ACCOUNT_FLUX_COLLECTION)
  const entryHooks = useFirestore(ACCOUNT_ENTRY_COLLECTION)
  
  const [debits, setDebits] = useState([{ account: null, amount: 0 }])
  const [credits, setCredits] = useState([{ account: null, amount: 0 }])
  const [comment, setComment] = useState(null)
  const [accountDate, setAccountDate] = useState(new Date())
  
  const accounts = useMemo(() => dbAccounts?.map(a => omit(a, ['ownerRef'])), [dbAccounts])
  const balanced = useMemo(() => debits.reduce((acc, val) => acc + val.amount, 0) === credits.reduce((acc, val) => acc + val.amount, 0), [debits, credits])
  const valid = useMemo(() => !debits.some(el => !el.account) && !credits.some(el => !el.account), [debits, credits])

  const handleSubmit = () => {
    console.log('createFlux', { debits, credits, comment, accountDate })
    const batch = fluxHooks.getBatch()
    const fluxRef = fluxHooks.getDocRef()
    batch.set(fluxRef, {
      accountDate,
      comment,
      _createdAtTime: serverTimestamp(),
      balanced: debits.reduce((acc, val) => acc + val.amount, 0) === credits.reduce((acc, val) => acc + val.amount, 0),
    })
    debits.map(debit =>
      batch.set(entryHooks.getDocRef(), {
        accountDate,
        accountRef: accountHooks.getDocRef(debit.account.id),
        comment,
        debit: debit.amount,
        paymentType: 'flux',
        _createdAtTime: serverTimestamp(),
        fluxRef,
      }),
    )
    credits.map(credit =>
      batch.set(entryHooks.getDocRef(), {
        accountDate,
        accountRef: accountHooks.getDocRef(credit.account.id),
        comment,
        credit: credit.amount,
        paymentType: 'flux',
        _createdAtTime: serverTimestamp(),
        fluxRef,
      }),
    )
    return batch.commit().then(onClose)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id='form-dialog-title'>Créer un flux</DialogTitle>
      <DialogContent>
        {!accounts
          ? <CircularProgress color='inherit' />
          : <Grid container spacing={3}>
            {debits.map((debit, index) =>
              <Grid item container xs={12} key={index} spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Autocomplete
                    id='debit-select'
                    options={accounts}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                      <TextField variant='standard' {...params} label='Compte à débiter' />
                    }
                    value={debit.account}
                    onChange={(event, newValue) =>
                      setDebits(debits =>
                        debits.map((item, i) =>
                          i === index ? { account: newValue, amount: item.amount } : item,
                        ),
                      )
                    }
                    renderOption={(props, option) => <li {...props} key={'debit-' + option.id}>{option.name}</li>}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='standard'
                    margin='dense'
                    id='amount'
                    label='Montant'
                    type='number'
                    value={debit.amount}
                    onChange={e => {
                      e.persist()
                      setDebits(debits =>
                        debits.map((item, i) =>
                          i === index ? { account: item.account, amount: Number(e.target.value) } : item,
                        ),
                      )
                    }}
                    onKeyPress={e => e.key === 'Enter' && handleSubmit()} />
                </Grid>
                <Grid item xs={2}>
                  {index !== 0 && <Button
                    onClick={() => setDebits(prev => prev.filter((item, i) => i !== index))}
                    style={{ marginTop: 16 }}
                  >
                    <Close />
                  </Button>}
                </Grid>
              </Grid>,
            )}
            <Typography variant='caption' m={2}>Total = {debits.reduce((acc, val) => acc + val.amount, 0)}</Typography>
            <Grid item xs={12}>
              <Button onClick={() =>
                setDebits(debits => debits.concat({ account: null, amount: 0 }))
              }>Ajouter un compte à débiter</Button>
            </Grid>
            {credits.map((credit, index) =>
              <Grid item container xs={12} key={index} spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Autocomplete
                    id='credit-select'
                    options={accounts}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                      <TextField variant='standard' {...params} label='Compte à créditer' />
                    }
                    value={credit.account}
                    onChange={(event, newValue) =>
                      setCredits(credits =>
                        credits.map((item, i) =>
                          i === index ? { account: newValue, amount: item.amount } : item,
                        ),
                      )
                    }
                    renderOption={(props, option) => <li {...props} key={'debit-' + option.id}>{option.name}</li>}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='standard'
                    margin='dense'
                    id='amount'
                    label='Montant'
                    type='number'
                    value={credit.amount}
                    onChange={e => {
                      e.persist()
                      setCredits(credits =>
                        credits.map((item, i) =>
                          i === index ? { account: item.account, amount: Number(e.target.value) } : item,
                        ),
                      )
                    }}
                    onKeyPress={e => e.key === 'Enter' && handleSubmit()} />
                </Grid>
                <Grid item xs={2}>
                  {index !== 0 && <Button
                    onClick={() => setCredits(prev => prev.filter((item, i) => i !== index))}
                    style={{ marginTop: 16 }}
                  >
                    <Close />
                  </Button>}
                </Grid>
              </Grid>,
            )}
            <Typography variant='caption' m={2}>Total = {credits.reduce((acc, val) => acc + val.amount, 0)}</Typography>
            <Grid item xs={12}>
              <Button onClick={() =>
                setCredits(credits => credits.concat({ account: null, amount: 0 }))
              }>Ajouter un compte à créditer</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography display='inline-flex' mr={1}>Flux à l&apos;équilibre</Typography>
              <ToggleButtonGroup
                color='primary'
                value={balanced}
                exclusive
              >
                <ToggleButton disabled value={true} sx={{
                  p: 2,
                  '&.Mui-disabled': {
                    color: 'black',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'success.main',
                  },
                }}>Oui</ToggleButton>
                <ToggleButton disabled value={false} sx={{
                  p: 2,
                  '&.Mui-disabled': {
                    color: 'black',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'error.main',
                  },
                }}>Non</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                variant='inline'
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='startDate-date'
                label='Date du transfer'
                value={accountDate}
                onChange={setAccountDate}
                minDate={startOfYear(new Date())}
                maxDate={endOfYear(new Date())}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                margin='dense'
                id='comment'
                label='Commentaire'
                fullWidth
                value={comment || ''}
                multiline
                onChange={e => setComment(e.target.value)} />
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} >Annuler</Button>
        <Button onClick={handleSubmit} color='primary' disabled={!valid}>Valider</Button>
      </DialogActions>
    </Dialog>
  )
}

FluxCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FluxCreateDialog
