import React from 'react'
import { Card, CardContent, Typography, FormControl, Select, MenuItem, Grid, Box } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import InlineEditor from '../_library/InlineEditor'
import TransferList from '../_library/TransferList'
import { AIRCRAFT_COLLECTION, FLIGHT_TYPE_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const PilotPanel = () => {
  
  const profile = useAuth().getProfile()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const flightTypes = flightTypeHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  
  return (
    <Box sx={{ my: 2, mx: 'auto', maxWidth: 900 }}>
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Expérience</Typography>
      <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
        <CardContent>
          <Grid item xs={12} mb={1}>
            <InlineEditor
              title='Heures de vol'
              value={profile.experience?.total}
              handleSubmit={val => userHooks.updateDoc(profile.id, { experience: { ...profile.experience, total: val } })}
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <InlineEditor
              title='Heures de voltige'
              value={profile.experience?.aerobatics}
              handleSubmit={val => userHooks.updateDoc(profile.id, { experience: { ...profile.experience, aerobatics: val } })}
            />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Préférences</Typography>
      <Card variant='outlined' sx={{ minWidth: 275, mb: 2 }}>
        <CardContent>
          <Grid item xs={12} mb={1}>
            <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
              Avion favori
            </Typography>
            <FormControl variant='outlined'>
              <Select
                variant='standard'
                id='aircraft-select'
                value={profile.favoritAircraftId || ''}
                onChange={e => userHooks.updateDoc(profile.id, { favoritAircraftId: e.target.value })}
                margin='dense'>
                <MenuItem value=''><i>none</i></MenuItem>
                {aircrafts && aircrafts.map(aircraft => <MenuItem key={aircraft.id} value={aircraft.id}>{aircraft.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          {flightTypes &&
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
                Types de vol
              </Typography>
              <TransferList
                leftInit={flightTypes && flightTypes
                  .filter(type => !profile.flightTypes?.some(slug => slug === type.slug))
                  .map(t => t.name)}
                rightInit={flightTypes && flightTypes
                  .filter(type => profile.flightTypes?.some(slug => slug === type.slug))
                  .map(t => t.name)}
                handleSelect={types => userHooks.updateDoc(profile.id, { flightTypes: types.map(name => flightTypes.find(t => t.name === name).slug) })}
              />
            </Grid>
          }
        </CardContent>
      </Card>
    </Box>
  )
}

export default PilotPanel
