import React, { useEffect, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as ROUTES from './_constants/routes.js'
import LoginPage from './components/auth/LoginPage'
import './i18n'
import FlightPage from './components/flights/FlightPage'
import Alerts from './components/Alerts'
import AccountPage from './components/accounts/AccountPage'
import QualificationPage from './components/qualifications/QualificationPage'
import MemberPage from './components/members/MemberPage'
import ProfilePage from './components/profile/ProfilePage'
import DashboardPage from './components/dashboard/DashboardPage'
import OnboardingPage from './components/onboarding/OnboardingPage'
import WatchPage from './components/watch/WatchPage'
import NotFoundPage from './components/NotFoundPage'
import AircraftPage from './components/aircrafts/AircraftPage'
import ProgressBookPage from './components/progressBooks/ProgressBookPage'
import { useSelector } from 'react-redux'
import AdminDashboardPage from './components/adminDashboard/AdminDashboardPage'
import Loading from './components/_library/Loading'
import {
  AIRCRAFT_COLLECTION, BILLING_TYPE_COLLECTION, FLIGHT_TYPE_COLLECTION, NOTIFICATION_COLLECTION,
  QUALIFICATION_TYPE_COLLECTION, ROLE_INSTRUCTOR, SETTINGS_COLLECTION, STATUS_EXTERN, STATUS_FORMER, STATUS_HONOR, USER_COLLECTION,
} from './_constants/globals'
import ProgressBookPDF from './components/progressBooks/ProgressBookPDF'
import RestrictedArea from './components/_library/RestrictedArea'
import ActivityPage from './components/activities/ActivityPage'
import { mapValues } from 'lodash'
import { listenerRefs } from './firebase'
import useAuth from './hooks/useAuth'
import { useFirestore } from './hooks/useFirestore'
import { useNotification } from './hooks/useNotification'


const App = () => {
  
  const profile = useSelector(state => state.profile.data)
  const adminMode = useSelector(state => state.data.adminMode)
  const auth = useAuth()
  const settingHooks = useFirestore(SETTINGS_COLLECTION)
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const billingTypeHooks = useFirestore(BILLING_TYPE_COLLECTION)
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const qualificationTypeHooks = useFirestore(QUALIFICATION_TYPE_COLLECTION)
  const notificationHooks = useFirestore(NOTIFICATION_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const notifications = useNotification()
  
  useEffect(() => {
    auth.listenProfile()
    settingHooks.listen()
    return () => {
      mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    }
  }, [])
  
  useEffect(() => {
    if (profile.id) {
      aircraftHooks.listen({ orderBy: [['order', 'asc']] })
      billingTypeHooks.listen({ orderBy: [['order', 'asc']] })
      flightTypeHooks.listen({ orderBy: [['order', 'asc']], where: [['activated', '==', true]] })
      qualificationTypeHooks.listen({ orderBy: [['order', 'asc']] })
      notificationHooks.listen({ where: [['userRef', '==', userHooks.getDocRef(profile.id)]], orderBy: [['_createdAtTime', 'asc']] })
      userHooks.listen({
        where: adminMode || profile.roles?.includes(ROLE_INSTRUCTOR) ? [] : [['status', 'not-in', [STATUS_EXTERN, STATUS_FORMER, STATUS_HONOR]]],
        orderBy: [['status', 'asc'], ['lastname', 'asc']],
      })
      return () => {
        aircraftHooks.unsubscribe()
        billingTypeHooks.unsubscribe()
        flightTypeHooks.unsubscribe()
        qualificationTypeHooks.unsubscribe()
        notificationHooks.unsubscribe()
        userHooks.unsubscribe()
      }
    }
  }, [profile?.id])
  
  useEffect(() => {
    console.log('Environnement: ' + process.env.NODE_ENV + ' ' + process.env.REACT_APP_FIREBASE_PROJECT_ID)
  }, [])
  
  useEffect(() => {
    profile?.id && notifications.requestTokenAction()
  }, [profile.id])
  
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />} >
        <Routes>
          <Route path={ROUTES.HOME} element={<RestrictedArea needRoles>{adminMode ? <AdminDashboardPage /> : <DashboardPage />}</RestrictedArea>} />
          <Route path={ROUTES.LOGIN} element={<RestrictedArea loggedOut><LoginPage /></RestrictedArea>} />
          <Route path={ROUTES.ACTIVITIES + '/*'} element={<RestrictedArea needRoles><ActivityPage /></RestrictedArea>} />
          <Route path={ROUTES.FLIGHTS + '/*'} element={<RestrictedArea needRoles><FlightPage /></RestrictedArea>} />
          <Route path={ROUTES.ACCOUNT + '/*'} element={<RestrictedArea needRoles><AccountPage /></RestrictedArea>} />
          <Route path={ROUTES.QUALIFICATIONS + '/*'} element={<RestrictedArea needRoles><QualificationPage /></RestrictedArea>} />
          <Route path={ROUTES.MEMBERS + '/*'} element={<RestrictedArea needRoles><MemberPage /></RestrictedArea>} />
          <Route path={ROUTES.AIRCRAFTS + '/*'} element={<RestrictedArea needRoles><AircraftPage /></RestrictedArea>} />
          <Route path={ROUTES.PROFILE + '/*'} element={<RestrictedArea needRoles><ProfilePage /></RestrictedArea>} />
          <Route path={ROUTES.ONBOARDING} element={<RestrictedArea loggedIn><OnboardingPage /></RestrictedArea>} />
          <Route path={ROUTES.PROGRESS_BOOKS + '/*'} element={<RestrictedArea needRoles><ProgressBookPage /></RestrictedArea>} />
          <Route path={ROUTES.WATCH} element={<RestrictedArea needRoles><WatchPage /></RestrictedArea>} />
          <Route path={ROUTES.PROGRESS_BOOKS_PDF} element={<RestrictedArea needRoles><ProgressBookPDF /></RestrictedArea>} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
        <Alerts />
      </Suspense>
    </BrowserRouter>
  )
}

export default App
