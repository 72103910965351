import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Button } from '@mui/material'
import Vimeo from '@u-wave/react-vimeo'
import { Check } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { FLIGHT_COLLECTION } from '../../_constants/globals'


export const Player = ({ flight }) => {
  
  const flightHooks = useFirestore(FLIGHT_COLLECTION)

  const [link, setLink] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()
    flightHooks.updateDoc(flight.id, { videoLink: link })
  }

  if (flight.videoLink)
    return <Vimeo
      id={flight.videoLink.split('/').pop().replace('"', '')}
      video={flight.videoLink}
      key={flight.videoLink}
      onError={err => {
        if (err) {
          console.error('Couldn\'t get video duration before starting the player')
        }
      }}
      responsive
    />

  else
    return <>
      <h1>Copie-colle le lien Vimeo ci-dessous</h1>
      <form onSubmit={handleSubmit}>
        <TextField label='Vimeo link' variant='outlined' value={link || ''} onChange={e => setLink(e.target.value)} />
        <Button type='submit'><Check /></Button>
      </form>
    </>

  // else
  //   return <>
  //     <h1>Un compte Vimeo est nécessaire pour activer les vidéos des vols</h1>
  //     <p></p>
  //     <form onSubmit={() => updateProfileAction({ vimeoToken: token })}>
  //       <TextField label="Vimeo token" variant="outlined" value={token} onChange={e => setToken(e.target.value)} />
  //     </form>
  //   </>

}

Player.propTypes = {
  flight: PropTypes.object.isRequired,
}

export default Player
