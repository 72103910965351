import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TimelineDot } from '@mui/lab'
import { Attachment, Check, EventBusy, Visibility, Warning } from '@mui/icons-material'
import { Tooltip } from '@mui/material'


const QualificationIcon = ({ qualificationStatus, onClick, sx }) => {

  const [icon, setIcon] = useState(null)
  const [color, setColor] = useState(null)
  const [tip, setTip] = useState('')

  useEffect(() => {
    switch (qualificationStatus) {
      case 'expired':
        setIcon(<EventBusy />)
        setColor('error.main')
        setTip('la validité est périmée')
        break
      case 'missingAttachment':
        setIcon(<Attachment />)
        setColor('error.main')
        setTip('il manque la preuve de la validité')
        break
      case 'expireSoon':
        setIcon(<Warning />)
        setColor('warning.main')
        setTip('la validité va bientôt périmer')
        break
      case 'tobeChecked':
        setIcon(<Visibility />)
        setColor('warning.main')
        setTip('la validité n\'a pas encore été vérifiée')
        break
      case 'valid':
        setIcon(<Check />)
        setColor('success.main')
        setTip('la validité est confirmée')
        break
      default:
        console.warn('Unknown qualification status', qualificationStatus)
        break
    }
  }, [qualificationStatus])

  return (
    <Tooltip title={tip}>
      <TimelineDot
        onClick={onClick}
        sx={{
          display: 'inline-flex',
          verticalAlign: 'sub',
          bgcolor: color,
          cursor: onClick && 'pointer',
          ...sx,
        }}
      >
        {icon}
      </TimelineDot>
    </Tooltip>
  )
}

QualificationIcon.propTypes = {
  qualificationStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.object,
}

export default QualificationIcon
