import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { addDays } from 'date-fns'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'


const DaySelector = ({ day, setDay, sx }) => (
  <Box sx={{ width: 'fit-content', ...sx }}>
    <Button onClick={() => setDay(addDays(day, -1))}><ChevronLeft /></Button>
    <DatePicker
      margin='none'
      id='date-picker-dialog'
      inputFormat='dd/MM/yyyy'
      value={day}
      onChange={setDay}
      slotProps={{ textField: { sx: { verticalAlign: 'middle' } } }}
    />
    <Button onClick={() => setDay(addDays(day, 1))}><ChevronRight /></Button>
  </Box>
)

DaySelector.propTypes = {
  day: PropTypes.object.isRequired,
  setDay: PropTypes.func.isRequired,
  sx: PropTypes.object,
}

export default DaySelector
