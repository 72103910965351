import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import { connect } from 'react-redux'
import Loading from '../_library/Loading'
import Chart from 'react-apexcharts'
import { chain } from 'lodash'


const styles = theme => ({
  root: {
    width: '100%',
    height: 200,
  },
  graph: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
  },
})

export const InstructorsGraph = ({ classes, className, lessons }) => {

  const data = useMemo(() =>
    chain(lessons)
      .groupBy(l => l.instructor?.firstname + ' ' + l.instructor?.lastname)
      .mapValues((arr, index) => ({ instructor: index, length: arr.length }))
      .values()
      .orderBy('length', 'desc')
      .value()
  , [lessons])

  const series = data.map(val => val.length)
  const options = {
    labels: data.map(val => val.instructor),
    chart: {
      width: 380,
      type: 'pie',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  }

  if (!data) return <Loading />
  else return (
    <div className={clsx(classes.root, className)}>
      <Chart options={options} series={series} type='pie' width={380} />
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  lessons: state.firestore.lessons?.filter(l => l.progressBookRef.id === props.progressBook.id),
})

InstructorsGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  lessons: PropTypes.array,
  progressBook: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(InstructorsGraph)
