import React, { useEffect, useState } from 'react'
import { Stepper, Step, StepButton, Box } from '@mui/material'
import Welcome from './Welcome'
import ExperienceForm from './ExperienceForm'
import IdentityForm from './IdentityForm'
import SumUp from './SumUp'
import useAuth from '../../hooks/useAuth'
import QualificationForm from './QualificationForm'
import { STATUS_APPLICATION_SUBMITTED, STATUS_QUALIFICATIONS_SUBMITTED } from '../../_constants/globals'


const getSteps = () => ['Bienvenue', 'Identité', 'Expérience', 'Validités', 'Résumé']

const OnboardingPage = () => {
  
  const profile = useAuth().getProfile()
  
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const steps = getSteps()

  useEffect(() => {
    if (!profile.hasNoPassword) {
      setCompleted(obj => ({ ...obj, 0: true }))
      setActiveStep(1)
    }
    if (profile.gender) {
      setCompleted(obj => ({ ...obj, 1: true }))
      setActiveStep(2)
    }
    if (profile.experience) {
      setCompleted(obj => ({ ...obj, 2: true }))
      setActiveStep(3)
    }
    if (profile.status === STATUS_QUALIFICATIONS_SUBMITTED || profile.status === STATUS_APPLICATION_SUBMITTED) {
      setCompleted(obj => ({ ...obj, 3: true }))
      setActiveStep(4)
    }
    if (profile.status === STATUS_APPLICATION_SUBMITTED)
      setCompleted(obj => ({ ...obj, 4: true }))
  }, [profile])

  const handleNext = () => setActiveStep(activeStep + 1)
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)
  const handleStep = step => () => setActiveStep(step)

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <Welcome next={handleNext} />
      case 1:
        return <IdentityForm back={handleBack} next={handleNext} />
      case 2:
        return <ExperienceForm back={handleBack} next={handleNext} />
      case 3:
        return <QualificationForm back={handleBack} next={handleNext} />
      case 4:
        return <SumUp back={handleBack} />
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <Box sx={{ width: '100%', my: 5 }}>
      <Stepper activeStep={activeStep} alternativeLabel nonLinear>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {getStepContent(activeStep)}
      </div>
    </Box>
  )
}

export default OnboardingPage
