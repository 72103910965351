import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl, InputLabel, Select, MenuItem,
  FormHelperText } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import UserSelect from '../_library/UserAutocomplete'
import { PB_ITEM_COLLECTION, PB_TEMPLATE_COLLECTION, PROGRESS_BOOK_COLLECTION, STATUS_ACTIVATED, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import { serverTimestamp } from 'firebase/firestore'
import { CREATE_PROGRESS_BOOK_ERROR, CREATE_PROGRESS_BOOK_SUCCESS } from '../../store/types'
import useAuth from '../../hooks/useAuth'


const CreateProgressBookDialog = ({ onClose }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const templateHooks = useFirestore(PB_TEMPLATE_COLLECTION)
  const dbTemplates = templateHooks.getDocs()
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  const dispatch = useDispatch()
  const booksHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  const itemHooks = useFirestore(PB_ITEM_COLLECTION)
  const dbItems = itemHooks.getDocs()
  
  const [template, setTemplate] = useState(null)
  const [pilot, setPilot] = useState(profile)
  const [errors, setErrors] = useState({})
  
  const users = useMemo(() => dbUsers.filter(u => u.status === STATUS_ACTIVATED), [dbUsers])
  
  const handleSubmit = () => {
    if (!template) setErrors(prev => ({ ...prev, type: 'Il faut sélectionner un type de livret' }))
    else booksHooks.addDoc({
      status: 'open',
      startDate: serverTimestamp(),
      pilotRef: userHooks.getDocRef(pilot.id || profile.id),
      name: template.name,
      level: template.level,
      completeness: {
        total: dbItems.filter(i => i.level === template.level).length,
        validatedItems: 0,
      },
    })
      .then(() => dispatch({ type: CREATE_PROGRESS_BOOK_SUCCESS }))
      .then(onClose)
      .catch(err => {
        console.error(err)
        dispatch({ type: CREATE_PROGRESS_BOOK_ERROR })
      })
  }
  
  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' sx={{
      '& .MuiDialog-paper': {
        minHeight: 260,
      },
      '& .MuiInput-underline::before': {
        borderBottomColor: 'rgba(0,0,0,.1)',
      },
    }}>
      <DialogTitle id='form-dialog-title'>Ouvrir un livret de progression</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <>
            {(adminMode || profile.roles?.includes('instructeur')) && <Grid item xs={12}>
              {users && <UserSelect
                users={users}
                user={pilot}
                label='Pilote'
                set={setPilot}
                disableClearable
                sx={{ width: '100%' }}
              />}
            </Grid>}
            <Grid item xs={12}>
              <FormControl
                variant='standard'
                error={Boolean(errors.type)}
                sx={{ width: '100%' }}
              >
                <InputLabel id='type-select-label'>Type de livret</InputLabel>
                {dbTemplates && <Select
                  variant='standard'
                  labelId='type-select-label'
                  id='type-select'
                  value={template || ''}
                  onChange={e => setTemplate(e.target.value)}>
                  {dbTemplates.map(t => <MenuItem key={t.id} value={t}>{t.name}</MenuItem>)}
                </Select>}
                <FormHelperText>{errors.type}</FormHelperText>
              </FormControl>
            </Grid>
          </>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Annuler
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateProgressBookDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CreateProgressBookDialog
