import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, CircularProgress, Button, TableRow, TableCell, Box } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format, formatDistanceToNow, differenceInCalendarMonths } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { Check, Clear, Edit } from '@mui/icons-material'
import EditQualificationDialog from './EditQualificationDialog'
import { QUALIFICATION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'
import { serverTimestamp } from 'firebase/firestore'


const UserQualificationsTable = () => {
  
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const dbQualifications = qualificationHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  
  const selectedUser = useMemo(() => selectedUserId ? dbUsers.find(u => u.id === selectedUserId) : profile, [selectedUserId, dbUsers, profile])
  
  const qualifications = useMemo(() => dbQualifications?.filter(q => selectedUserId
    ? q.userRef.id === selectedUserId
    : q.userRef.id === profile.id,
  ), [dbQualifications, selectedUserId, profile.id])
  
  useEffect(() => {
    if (selectedUser?.id) {
      qualificationHooks.listen({
        where: [['userRef', '==', userHooks.getDocRef(selectedUser.id)]],
        orderBy: [
          ['archived', 'asc'],
          ['type.order', 'asc'],
          ['_createdAtTime', 'desc'],
        ],
      })
    }
  }, [selectedUser?.id])
  
  
  const [editQualification, setEditQualification] = useState(null)
  
  // Select only the first element for each typeRef
  // The input must be an array sorted by desc _createdAtTime
  const data = useMemo(() => qualifications?.filter(q => !q.archived).map(val => {
    const { id, type, identValue, grantDate, expireDate, noAlert, attachmentUrl, checkingComment, checkingDate, checker } = val
    return [
      id,
      type?.shortName,
      identValue,
      grantDate,
      expireDate,
      noAlert ? '✖' : '✔',
      attachmentUrl,
      checkingComment,
      checkingDate ? '✔' : '✖',
      checker,
      val,
    ]
  }), [qualifications])
  
  return (
    <Box sx={{
      m: 2,
      '& .MuiTableCell-body': {
        fontSize: '0.9rem',
        p: 1,
      },
    }}>
      {!data
        ? <Grid container item xs={12} justifyContent='center' sx={{ mt: '30px' }}>
          <CircularProgress size={20} />
        </Grid>
        
        : <MUIDataTable
          title={`Validités de ${selectedUser?.firstname}`}
          data={data}
          columns={[
            { name: 'id', options: { filter: false, sort: false, display: 'false' } },
            { name: 'Type', options: { filter: true, sort: true } },
            { name: 'Identifiant', options: { filter: true, sort: true, display: 'false' } },
            {
              name: 'Obtention',
              options: {
                filter: true,
                sort: true,
                display: 'false',
                customBodyRender: date => date && format(date, 'dd-MM-yyyy'),
              },
            },
            {
              name: 'Limite de validité',
              options: {
                filter: true,
                sort: true,
                customBodyRender: date => {
                  if (!date) return null
                  let expires
                  const differenceInMonths = date &&  differenceInCalendarMonths(date, new Date())
                  if (!date) expires = ''
                  else if (differenceInMonths < 0) expires = '️⛔ expired'
                  else if (differenceInMonths < 3) expires = '⚠️ expires'
                  else expires = '✅️ expires'
                  return expires + ' ' + formatDistanceToNow(date, { addSuffix: true }) + ' (' + format(date, 'dd-MM-yyyy') + ')'
                },
              },
            },
            { name: 'Alerte ?', options: { filter: true, sort: true, display: 'false' } },
            {
              name: 'Document',
              options: {
                filter: true,
                sort: true,
                // eslint-disable-next-line react/display-name
                customBodyRender: url => url && <a href={url} target='_blank' rel='noopener noreferrer' >Preview</a>,
              },
            },
            { name: 'Commentaire', options: { filter: true, sort: true, display: 'false' } },
            { name: 'Etat', options: { filter: true, sort: true } },
            { name: 'Checker', options: { filter: true, sort: true, display: 'false', customBodyRender: u => u && u.firstname + ' ' + u.lastname } },
            {
              name: 'Actions',
              options: {
                filter: false,
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: qualification =>
                  <>
                    <DeleteButton deleteAction={() => qualificationHooks.deleteDoc(qualification.id)} />
                    <Button
                      onClick={() => setEditQualification(qualification)}
                      title='modifier la validité'
                      sx={{ minWidth: '40px' }}
                    >
                      <Edit />
                    </Button>
                    {adminMode && // eslint-disable-line react/prop-types
                      <Button
                        onClick={() => qualificationHooks.updateDoc(qualification.id, {
                          checkerRef: qualification.checkerRef ? null : userHooks.getDocRef(profile.id),
                          checkingDate: qualification.checkerRef ? null : serverTimestamp(),
                        })}
                        title='vérifier la validité'
                        sx={{ minWidth: '40px' }}
                      >
                        {qualification.checkerRef ? <Clear /> : <Check /> }
                      </Button>
                    }
                  </>,
              },
            },
          ]}
          options={{
            selectableRows: 'none',
            pagination: false,
            expandableRows: adminMode,
            expandableRowsHeader: adminMode,
            expandableRowsOnClick: adminMode,
            isRowExpandable: dataIndex => data[dataIndex] && !!data[dataIndex][4] && adminMode,
            renderExpandableRow: rowData => {
              const history = qualifications.filter(q => q.type?.name === rowData[1] && q.id !== rowData[0])
              return (
                history.map(qualification => <TableRow key={qualification.id} style={{ fontStyle: 'italic' }}>
                  <TableCell>
                    Historique
                  </TableCell>
                  <TableCell>
                    {qualification.type.shortName}
                  </TableCell>
                  <TableCell>
                    {qualification.identValue}
                  </TableCell>
                  <TableCell>
                    {qualification.grantDate && format(qualification.grantDate, 'dd-MM-yyyy')}
                  </TableCell>
                  <TableCell>
                    {qualification.expireDate && format(qualification.expireDate, 'dd-MM-yyyy')}
                  </TableCell>
                  <TableCell>
                    {qualification.attachmentUrl}
                  </TableCell>
                  <TableCell>
                    {qualification.checkingComments}
                  </TableCell>
                  <TableCell>
                    {qualification.checkingDate ? '✔' : '✖'}
                  </TableCell>
                  <TableCell>
                    {qualification.checker && qualification.checker.firstname + ' ' + qualification.checker.lastname}
                  </TableCell>
                  <TableCell>
                    <DeleteButton deleteAction={() => qualificationHooks.deleteDoc(qualification.id)} />
                  </TableCell>
                </TableRow>)
              )
            },
          }}
        />
      }
      {editQualification && <EditQualificationDialog onClose={() => setEditQualification(null)} qualification={editQualification} />}
    </Box>
  )
}

export default UserQualificationsTable
