import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Tabs, Tab } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import FlightDetails from '../flights/FlightDetails'
import { compose } from 'recompose'
import ProgramTab from './ProgramTab'


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    height: '100%',
  },
  tab: {
    minWidth: 0,
    width: '33%',
  },
  tabContainer: {
    height: '100%',
    overflow: 'auto',
  },
  tabItem: {
    padding: '8px 24px 0 24px',
  },
  textField: {
    width: '100%',
    maxWidth: '400px',
  },
})

export const WatchTabs = ({ classes, flight }) => {

  const [tab, setTab] = useState(2)

  return (
    <div className={classes.root}>
      <AppBar position='static'>

        <Tabs
          value={tab}
          variant='fullWidth'
          onChange={(event, value) => setTab(value)}
        >
          <Tab className={classes.tab} label='Programme' />
          <Tab className={classes.tab} label='Livret' />
          <Tab className={classes.tab} label='Logbook' />
          <Tab className={classes.tab} label='Autre' />
        </Tabs>
      </AppBar>
      <div className={classes.tabContainer}>
        {tab === 0 && <ProgramTab flight={flight} />}
        {tab === 2 && <FlightDetails flight={flight} />}
      </div>
    </div>
  )
}

WatchTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  flight: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(WatchTabs)
