export const LOGIN = '/login'
export const HOME = '/'
export const ACTIVITIES = '/activities'
export const FLIGHTS = '/flights'
export const PROFILE = '/profile'
export const ACCOUNT = '/accounts'
export const QUALIFICATIONS = '/qualifications'
export const MEMBERS = '/members'
export const AIRCRAFTS = '/aircrafts'
export const ONBOARDING = '/onboarding'
export const PROGRESS_BOOKS = '/progressBooks'
export const WATCH = '/flight/:id'
export const PROGRESS_BOOKS_PDF = '/progressBookPDF/:id/salut.pdf'
