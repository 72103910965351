import React from 'react'
import { Snackbar, IconButton, Alert } from '@mui/material'
import { Close } from '@mui/icons-material'
import { toPairs } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNotification } from '../hooks/useNotification'


const Alerts = () => {
  
  const { t } = useTranslation()
  const { notifications, deleteNotification } = useNotification()
  
  return toPairs(notifications).map(([key, notif]) => notif &&
    <Snackbar
      key={key}
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      message={notif.message}
      autoHideDuration={notif.autoHideDuration || null}
      onClose={() => deleteNotification(key)}
      action={(
        <IconButton size='small' aria-label='close' color='inherit' onClick={() => deleteNotification(key)}>
          <Close fontSize='small'/>
        </IconButton>
      )}
    >
      {notif.severity && <Alert severity={notif.severity} variant='filled' elevation={6} onClose={() => deleteNotification(key)}>
        {t(notif.message)}
      </Alert>}
    </Snackbar>,
  )
}

export default Alerts
