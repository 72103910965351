import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { HOME, LOGIN, ONBOARDING } from '../../_constants/routes'
import Loading from './Loading'
import useAuth from '../../hooks/useAuth'

const RestrictedArea = ({ children, needRoles, loggedOut, loggedIn }) => {
  
  const profile = useAuth().getProfile()
  const location = useLocation()
  
  if (!profile.isLoaded) return <Loading />
  
  else if (needRoles && !profile.roles?.length) return <Navigate to={LOGIN} state={{ from: location }} replace />
  
  else if (loggedIn && profile.isEmpty) return <Navigate to={LOGIN} state={{ from: location }} replace />
  
  else if (loggedOut && !profile.isEmpty) return <Navigate to={profile.roles?.length ? HOME : ONBOARDING} state={{ from: location }} replace />
  
  else return children
}

RestrictedArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  needRoles: PropTypes.bool,
  loggedOut: PropTypes.bool,
  loggedIn: PropTypes.bool,
}

export default RestrictedArea
