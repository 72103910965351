import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { Add } from '@mui/icons-material'
import EditQualificationDialog from './EditQualificationDialog'
import UserQualificationsTable from './UserQualificationsTable'
import AllQualificationsTable from './AllQualificationsTable'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'
import UserSelector from '../_library/UserSelector'


const QualificationPage = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)
  const selectedUser = useSelector(state => state.data.selectedUser)

  const [speedDialOpen, setSpeedialOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const tabs = useMemo(() => compact([
    adminMode && {
      key: 'all',
      label: 'Toutes les validités',
      route: 'all',
      content: <AllQualificationsTable />,
    },
    {
      key: 'user',
      label: 'Validités de l\'utilisateur',
      route: 'user',
      headerContent: adminMode && <UserSelector />,
      content: <UserQualificationsTable />,
    },
  ]), [adminMode, selectedUser])

  return (<>
    <TabbedPage tabs={tabs} containerSx={{ pb: '100px' }} />
    <SpeedDial
      ariaLabel='speedDial'
      icon={<SpeedDialIcon />}
      onClose={() => setSpeedialOpen(false)}
      onClick={() => setSpeedialOpen(true)}
      open={speedDialOpen}
      FabProps={{ color: 'secondary' }}
      sx={{
        position: 'fixed',
        right: theme => theme.spacing(3),
        bottom: theme => theme.spacing(3),
        '& .MuiSpeedDialAction-staticTooltipLabel': {
          width: 200,
        },
      }}
    >
      <SpeedDialAction
        icon={<Add />}
        tooltipTitle='Créer une validité'
        tooltipOpen
        onClick={() => setOpenDialog(true)}
      />
    </SpeedDial>
    {openDialog && <EditQualificationDialog onClose={() => setOpenDialog(false)} />}
  </>)
}

export default QualificationPage
