import { createGenericSlice } from './createGenericSlice'

const initialState = {
  data: {},
  isLoaded: false,
}

const slice = createGenericSlice({
  name: 'auth',
  initialState,
})
export const actions = slice.actions
export default slice.reducer
