import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, Button, CardActions, CardContent, Grid, Avatar, FormControl, Select, MenuItem,
  Collapse, TextField } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { getGravatar } from '../../_helpers/dataHelper'
import InlineEditor from '../_library/InlineEditor'
import { format, isValid } from 'date-fns'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import AvatarEditor from '../_library/AvatarEditor'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'


export const PilotCard = ({ progressBook, licence, sx }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  
  const [expand, setExpand] = useState(false)
  
  const pilot = useMemo(() => dbUsers?.find(u => u.id === progressBook.pilotRef.id), [dbUsers, progressBook.pilotRef.id])
  
  if (!pilot) return <Loading />
  else return (
    <Card sx={{ minWidth: 275, maxWidth: 'calc(100vw - 270px)', mb: 4, p: 1, position: 'relative', ...sx }}>
      <CardContent>
        <Grid container>
          <Grid item sm={4} xs={12} mx='auto'>
            {profile.id === progressBook.pilotRef.id
              ? <AvatarEditor sx={{ width: '90%', maxWidth: 250, height: 'auto', mx: 'auto' }} />
              : <Avatar alt='avatar' src={pilot.photoUrl || getGravatar(pilot.email)} sx={{ width: '90%', maxWidth: 250, height: 'auto', mx: 'auto' }} />
            }
          </Grid>
          <Grid item container sm={8} xs={12}>
            <Grid item xs={12}>
              <h2 style={{ marginBlock: 0 }}>{pilot.firstname} {pilot.lastname}</h2>
            </Grid>
            <Grid item md={6} sm={12}>
              <InlineEditor
                title='Heures avion totales :'
                value={pilot.experience?.total}
                type='number'
                handleSubmit={val => userHooks.updateDoc(profile.id, { experience: { ...pilot.experience, total: val } })}
                disabled={profile.id !== progressBook.pilotRef.id}
                hint='Nombre d&apos;heures de vol à l&apos;ouverture du livret'
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <InlineEditor
                title='dont CDB :'
                value={progressBook.experience?.cdb}
                type='number'
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, cdb: val } })}
                disabled={profile.id !== progressBook.pilotRef.id}
                hint='Nombre d&apos;heures de vol Commandant de bord à l&apos;ouverture du livret'
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <InlineEditor
                title='Heures voltige double commande :'
                value={progressBook.experience?.aerobaticsDC}
                type='number'
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, aerobaticsDC: val } })}
                disabled={profile.id !== progressBook.pilotRef.id}
                hint='Nombre d&apos;heures voltige double commande ouverture du livret'
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <InlineEditor
                title='sur avion type : '
                value={progressBook.experience?.airplane}
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, airplane: val } })}
                disabled={profile.id !== progressBook.pilotRef.id}
              />
            </Grid>
            <InlineEditor
              title='Si FCL800 débuté dans un autre club, préciser :&nbsp;'
              value={progressBook.experience?.club}
              handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, club: val } })}
              disabled={profile.id !== progressBook.pilotRef.id}
              titleStyle={{ width: 'initial' }}
            />
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom style={{ display: 'inline-flex' }}>
                Date du début de formation à la qualification voltige:&nbsp;
              </Typography>
              {profile.id === progressBook.pilotRef.id
                ? <DatePicker
                  inputFormat='dd-MM-yyyy'
                  margin='dense'
                  id='startDate'
                  value={progressBook.startDate ? progressBook.startDate : null}
                  onChange={val => isValid(val) && bookHooks.updateDoc(progressBook.id, { startDate: val })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  inputProps={{ style: { width: 100 } }}
                  style={{ marginTop: 0 }}
                  renderInput={params => <TextField {...params} />}
                />
                : progressBook.startDate && format(progressBook.startDate, 'dd-MM-yyyy')
              }
            </Grid>
          </Grid>
          <Collapse in={expand} style={{ width: '100%' }}>
            <Grid item xs={12}><h3>Licence</h3></Grid>
            <Grid item container xs={12} mb={1}>
              <Grid item sm={6}>
                <Typography color='textSecondary' gutterBottom>Numéro: {licence?.identValue}</Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography color='textSecondary' gutterBottom>Délivrée le: {licence?.grantDate && format(licence.grantDate, 'dd-MM-yyyy')}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}><h3>Identité</h3></Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom display='inline-block' width={200}>Sexe</Typography>
              <FormControl variant='outlined'>
                {profile.id === progressBook.pilotRef.id
                  ? <Select
                    variant='standard'
                    id='sex-select'
                    value={pilot.gender || 'woman'}
                    onChange={e => userHooks.updateDoc(profile.id, { gender: e.target.value })}
                    margin='dense'
                    disabled={profile.id !== progressBook.pilotRef.id}>
                    <MenuItem value='woman'>Féminin</MenuItem>
                    <MenuItem value='man'>Masculin</MenuItem>
                  </Select>
                  : pilot.gender === 'man' ? 'Masculin' : 'Féminin'
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography color='textSecondary' gutterBottom display='inline-block' width={200}>Date de naissance</Typography>
              {profile.id === progressBook.pilotRef.id
                ? <DatePicker
                  inputFormat='dd-MM-yyyy'
                  margin='dense'
                  id='birthdayDate'
                  value={pilot.birthdayDate ? pilot.birthdayDate : new Date()}
                  onChange={val => userHooks.updateDoc(profile.id, { birthdayDate: val })}
                  renderInput={params => <TextField {...params} />}
                />
                : format(pilot.birthdayDate, 'dd-MM-yyyy')
              }
            </Grid>
            <Grid item xs={12} mb={1}>
              <InlineEditor
                title='Nationalité'
                value={pilot.nationality}
                handleSubmit={val => userHooks.updateDoc(profile.id, { nationality: val })}
                disabled={profile.id !== progressBook.pilotRef.id}
              />
            </Grid>
            <Grid item xs={12}><h3>Coordonnées</h3></Grid>
            <Grid item xs={12} mb={1}>
              <InlineEditor
                title='Email'
                value={pilot.email}
                handleSubmit={val => userHooks.updateDoc(profile.id, { email: val })}
                disabled={profile.id !== progressBook.pilotRef.id}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <InlineEditor
                title='Téléphone'
                value={pilot.phone}
                handleSubmit={val => userHooks.updateDoc(profile.id, { phone: val })}
                disabled={profile.id !== progressBook.pilotRef.id}
              />
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
      <CardActions sx={{ p: 0, mt: 0, mb: -1, mx: -1 }}>
        <Button color='primary' onClick={() => setExpand(prev => !prev)} id='expandPilotCardBtn' sx={{
          width: '100%',
          bgcolor: 'grey.100',
          borderRadius: 0,
          height: 20,
        }}>
          {expand
            ? <ArrowDropUp />
            : <ArrowDropDown />
          }
        </Button>
      </CardActions>
    </Card>
  )
}

PilotCard.propTypes = {
  progressBook: PropTypes.object.isRequired,
  licence: PropTypes.object,
  sx: PropTypes.object,
}

export default PilotCard
