import React, { useState } from 'react'
import { Box, Fab } from '@mui/material'
import { Add } from '@mui/icons-material'
import CreateProgressBookDialog from './CreateProgressBookDialog'
import ProgressBookTable from './ProgressBookTable'


const ProgressBooks = () => {

  const [open, setOpen] = useState(false)

  return (
    <Box sx={{ pb: '100px' }}>
      <ProgressBookTable />
      <Fab color='secondary' aria-label='add' onClick={() => setOpen(true)} sx={{
        right: theme => theme.spacing(3),
        bottom: theme => theme.spacing(3),
        position: 'fixed',
        zIndex: 2,
      }}>
        <Add />
      </Fab>
      {open && <CreateProgressBookDialog onClose={() => setOpen(false)} />}
    </Box>
  )
}

export default ProgressBooks
