import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Button, Tooltip, Box } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format, getYear, isThisYear } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { SwapHoriz } from '@mui/icons-material'
import FluxUpdateDialog from './FluxUpdateDialog'
import { selectYear, selectAccountId } from '../../store/actions/dataActions'
import { ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION } from '../../_constants/globals'
import { compact } from 'lodash'
import { useLocation } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirestore'


export const AccountTable = () => {
  
  const location = useLocation()
  const dispatch = useDispatch()
  const entryHooks = useFirestore(ACCOUNT_ENTRY_COLLECTION)
  const accountEntries = entryHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  const selectedAccountId = useSelector(state => state.data.selectedAccountId)
  const selectedYear = useSelector(state => state.data.selectedYear)
  const accountHooks = useFirestore(ACCOUNT_COLLECTION)
  const accounts = accountHooks.getDocs()
  
  const [selectedFluxId, selectFluxId] = useState(null)
  const [currentScrollElement, setCurrentScrollElement] = useState(null)
  
  const scroll = useRef()
  
  useEffect(() => {
    if (selectedAccountId) {
      entryHooks.listen({
        where: compact([
          ['accountRef', '==', accountHooks.getDocRef(selectedAccountId)],
          selectedYear !== 'all' && ['accountDate', '>=', new Date(selectedYear || getYear(Date.now()), 0, 1, 0, 0)],
          selectedYear !== 'all' && ['accountDate', '<=', new Date(selectedYear || getYear(Date.now()), 11, 31, 23, 59)],
        ]),
        orderBy: [['accountDate', 'asc']],
      })
      return entryHooks.unsubscribe
    }
  }, [selectedAccountId, selectedYear])
  
  const selectedEntryId = useMemo(() => location.state?.entryId, [location.state])
  
  useEffect(() => {
    selectedEntryId && entryHooks.fetchDoc(entryHooks.getDocRef(selectedEntryId))
      .then(entryDoc => {
        dispatch(selectYear(entryDoc.accountDate.getFullYear()))
        return dispatch(selectAccountId(accounts.find(a => a.id === entryDoc.accountRef.id)))
      })
  }, [selectedEntryId, accounts])
  
  // Scroll to selected entry
  useEffect(() => {
    if (currentScrollElement) {
      scroll.current = currentScrollElement
      try {
        scroll.current.scrollIntoView({ block: 'center' })
      } catch (error) {
        console.error(error)
      }
    }
  }, [currentScrollElement])
  
  const data = useMemo(() => {
    if (!accountEntries) return
    const { total, data } = accountEntries.reduce((acc, val) => {
      const { accountDate, paymentType, comment, debit, credit, balance } = val
      acc.total += credit || 0 - debit || 0
      acc.data.push([
        val,
        val.id,
        accountDate && format(accountDate, 'dd-MM-yyyy'),
        paymentType,
        comment,
        parseFloat(debit || 0).toFixed(2),
        credit?.toFixed(2),
        balance?.toFixed(2),
        null,
      ])
      return acc
    }, { total: accountEntries[0]?.balance || 0, data: [] })
    data.push([
      null,
      'total',
      null,
      null,
      null,
      null,
      null,
      null,
      total.toFixed(2),
    ])
    return data
  }, [accountEntries])
  
  return (
    <Box sx={{ maxWidth: '1200px', my: 2, mx: 'auto' }}>
      {!data
        ? <Grid container item xs={12} justifyContent='center' sx={{ mt: '30px' }}>
          <CircularProgress size={20} />
        </Grid>
        
        : <MUIDataTable
          title={accounts?.find(a => a.id === selectedAccountId)?.name}
          data={data}
          columns={[
            { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
            { name: 'id', options: { display: 'false', filter: false } },
            { name: 'Date', options: { filter: false, sort: true } },
            { name: 'Type', options: { filterType: 'multiselect', sort: true } },
            { name: 'Commentaire', options: { filterType: 'textField', sort: true } },
            { name: 'Débit', options: { filter: false, sort: true } },
            { name: 'Crédit', options: { filter: false, sort: true } },
            { name: 'solde', options: { display: 'excluded', filter: false } },
            { name: 'total', options: { display: 'excluded', filter: false } },
          ]}
          options={{
            selectableRows: 'none',
            viewColumns: adminMode,
            pagination: false,
            customRowRender: data => rowRender(data, entryHooks, selectFluxId, data[0] === selectedEntryId, setCurrentScrollElement, adminMode),
            print: false,
          }}
        />
      }
      {selectedFluxId && <FluxUpdateDialog open onClose={() => selectFluxId(null)} fluxId={selectedFluxId} />}
    </Box>
  )
}

const rowRender = (data, entryHooks, selectFluxId, selectedRow, setCurrentScrollElement, adminMode) => {
  const [obj, id, accountDate, paymentType, comments, debit, credit, balance, total] = data
  if (total)
    return (
      <Box component='tr' key={id} id={id} sx={{ textAlign: 'center', '&:nth-child(2n+1)': { backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}>
        <td colSpan='3' style={{ textAlign: 'right' }}>
          <strong>Total</strong>
        </td>
        <td colSpan='2'>
          <Box component='strong' sx={{ color: total > 0 ? 'success.main' : 'error.main' }}>{total}€</Box>
        </td>
      </Box>
    )
  else if (balance !== undefined)
    return (
      <Box component='tr' key={id} id={id} sx={{ textAlign: 'center', '&:nth-child(2n+1)': { backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}>
        <td colSpan='3' style={{ textAlign: 'right' }}>
          <strong>Solde au premier janvier</strong>
        </td>
        <td colSpan='2'>
          <Box component='strong' sx={{ color: 'info.main' }}>{balance}€</Box>
        </td>
      </Box>
    )
  else
    return (
      <Box component='tr' key={id} id={id} ref={el => selectedRow && setCurrentScrollElement(el)} sx={{
        textAlign: 'center',
        bgcolor: selectedRow && 'primary.main',
        '&:nth-child(2n+1)': {
          bgcolor: selectedRow ? 'primary.main' : 'rgba(0, 0, 0, 0.08)',
        },
      }}>
        <td>{accountDate}</td>
        <td>{paymentType}</td>
        <td style={{ textAlign: 'left' }}>{comments}</td>
        <td>{debit ? <strong>{debit}€</strong> : ''}</td>
        <td>{credit ? <strong>{credit}€</strong> : ''}</td>
        <td>
          {adminMode && paymentType === 'transfer' && isThisYear(obj.accountDate) && <DeleteButton deleteAction={() => entryHooks.deleteDoc(id)} />}
          {adminMode && obj.fluxRef && isThisYear(obj.accountDate) &&
            <Tooltip title='Editer le flux'>
              <Button onClick={() => selectFluxId(obj.fluxRef.id)} >
                <SwapHoriz />
              </Button>
            </Tooltip>
          }
        </td>
      </Box>
    )
}

export default AccountTable
