import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { REGEX_MAIL } from '../../_constants/misc'
import { STATUS_VISITOR, USER_COLLECTION } from '../../_constants/globals'
import { INVITE_MEMBER_ERROR, INVITE_MEMBER_SUCCESS } from '../../store/types'
import { useFirestore } from '../../hooks/useFirestore'


const InviteMemberDialog = ({ onClose }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const dispatch = useDispatch()

  const [firstname, setFirstname] = useState(null)
  const [lastname, setLastname] = useState(null)
  const [email, setEmail] = useState(null)
  const [errors, setErrors] = useState({})
  
  const handleSubmit = () => {
    if (!firstname) setErrors(prev => ({ ...prev, firstname: 'Un prénom doit être renseigné' }))
    else if (email && !email.match(REGEX_MAIL)) setErrors(prev => ({ ...prev, email: 'Le format de l\'email n\'est pas bon' }))
    else
      userHooks.addDoc({
        email: email?.toLowerCase().trim() || null,
        firstname: firstname.trim(),
        lastname: lastname.trim(),
        status: !email ? STATUS_VISITOR : null,
      })
        .then(() => dispatch({ type: INVITE_MEMBER_SUCCESS }))
        .then(onClose)
        .catch(err => {
          console.error(err)
          dispatch({ type: INVITE_MEMBER_ERROR })
        })
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' sx={{
      '& .MuiInput-underline::before': {
        borderBottomColor: 'rgba(0,0,0,.1)',
      },
    }}>
      <DialogTitle id='form-dialog-title'>Inviter un nouveau membre</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='dense'
              id='firstname'
              label='Prénom'
              fullWidth
              value={firstname || ''}
              onChange={e => setFirstname(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSubmit()}
              error={Boolean(errors.firstname)}
              helperText={errors.firstname} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='dense'
              id='lastname'
              label='Nom'
              fullWidth
              value={lastname || ''}
              onChange={e => setLastname(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSubmit()}
              error={Boolean(errors.lastname)}
              helperText={errors.lastname} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='standard'
              margin='dense'
              id='email'
              label='Email'
              fullWidth
              value={email || ''}
              onChange={e => setEmail(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSubmit()}
              error={Boolean(errors.email)}
              helperText={errors.email} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Annuler
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InviteMemberDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default InviteMemberDialog
