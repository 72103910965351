import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { formatDistanceToNowStrict, startOfDay, isValid, addHours, startOfToday } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { AVAILABILITY_COLLECTION } from '../../_constants/globals'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'


const AvailabilityItem = ({ item }) => {
  
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)

  const [submitted, setSubmitted] = useState(false)

  const update = (id, val) => {
    setSubmitted(true)
    availabilityHooks.updateDoc(id, val)
      .then(() => setSubmitted(false))
  }

  return <Grid item xs={12} sx={{ mb: 1, bgcolor: 'white', p: 1 }}>
    <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
      {item.dayDate && formatDistanceToNowStrict(item.dayDate, { unit: 'day', addSuffix: true }).replace('0 days ago', 'today')}
    </Typography>
    <DatePicker
      inputFormat='dd-MM-yyyy'
      margin='dense'
      value={item.dayDate ? item.dayDate : new Date()}
      onChange={val => isValid(val) && update(item.id, {
        dayDate: startOfDay(val),
        startDate: new Date(item.startDate.setFullYear(val.getFullYear(), val.getMonth(), val.getDate())),
        endDate: new Date(item.endDate.setFullYear(val.getFullYear(), val.getMonth(), val.getDate())),
      })}
      slotProps={{
        textField: { sx: { mr: 1, width: '150px' } },
      }}
    />
    <TimePicker
      variant='inline'
      ampm={false}
      margin='dense'
      value={item.startDate ? item.startDate : new Date()}
      onChange={val => isValid(val) && update(item.id, { start: val })}
      minutesStep={15}
      minTime={addHours(startOfToday(), 8)}
      maxTime={addHours(startOfToday(), 19)}
      slotProps={{
        textField: { sx: { mr: 1, width: '110px' } },
      }}
    />
    <TimePicker
      variant='inline'
      ampm={false}
      margin='dense'
      value={item.endDate ? item.endDate : new Date()}
      onChange={val => isValid(val) && update(item.id, { end: val })}
      minutesStep={15}
      minTime={addHours(startOfToday(), 9)}
      maxTime={addHours(startOfToday(), 20)}
      slotProps={{
        textField: { sx: { mr: 1, width: '110px' } },
      }}
    />
    {submitted
      ? <CircularProgress size={22} />
      : <DeleteButton deleteAction={() => availabilityHooks.deleteDoc(item.id)} />
    }
  </Grid>

}

AvailabilityItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default AvailabilityItem
