import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid, Typography, FormControl, Select, MenuItem, InputLabel, TextField, Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { endOfYear, isValid, startOfYear } from 'date-fns'
import UserSelect from '../_library/UserAutocomplete'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AIRCRAFT_COLLECTION, AIRPORT_COLLECTION, BILLING_TYPE_COLLECTION, FLIGHT_COLLECTION, FLIGHT_TYPE_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


export const FlightDetails = ({ flight, close = () => null }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const dbFlightTypes = flightTypeHooks.getDocs()
  const billingTypeHooks = useFirestore(BILLING_TYPE_COLLECTION)
  const dbBillingTypes = billingTypeHooks.getDocs()
  const airportHooks = useFirestore(AIRPORT_COLLECTION)
  const dbAirports = airportHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  
  useEffect(() => {
    airportHooks.listen({
      where: [['country', '==', 'France']],
      orderBy: [['occurrences', 'desc']],
    })
  }, [])
  
  const aircrafts = useMemo(() => dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const instructors = useMemo(() => dbUsers?.filter(u => u.roles?.includes('instructeur')), [dbUsers])
  const flightTypes = useMemo(
    () => adminMode ? dbFlightTypes : dbFlightTypes?.filter(t => profile.flightTypes.some(slug => slug === t.slug)),
    [dbFlightTypes, adminMode],
  )
  const billingTypes = useMemo(
    () => adminMode ? dbBillingTypes : dbBillingTypes?.filter(t => profile.billingTypes.some(slug => slug === t.slug)),
    [dbBillingTypes, adminMode],
  )
  
  if (!flight) return null
  return (
    <Grid container sx={{ overflowY: 'scroll' }}>
      <Grid container id='activity' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>Activité</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <InputLabel shrink id='flight-type-select-label'>Type de vol</InputLabel>
            {flightTypes && <Select
              variant='standard'
              id='flight-type-select'
              labelId='flight-type-select-label'
              value={flight.flightTypeRef?.id}
              onChange={e => flightHooks.updateDoc(flight.id, { flightTypeId: e.target.value })}
              fullWidth
              margin='dense'
              disabled={!profile?.roles.includes('pilote')}>
              {flightTypes.map(type =>
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <FormControl variant='standard' sx={{ mt: 1 }}>
            <InputLabel shrink id='billing-type-select-label'>
              Facturation
            </InputLabel>
            {billingTypes && <Select
              variant='standard'
              id='billing-type-select'
              labelId='billing-type-select-label'
              value={flight.billingTypeRef?.id}
              onChange={e => flightHooks.updateDoc(flight.id, { billingTypeId: e.target.value })}
              margin='dense'
              disabled={!profile?.roles.includes('pilote')}
              fullWidth
            >
              {billingTypes.map(type =>
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <Typography color='textSecondary' gutterBottom>Avion</Typography>
          {aircrafts && <ToggleButtonGroup
            size='large'
            value={flight.aircraftRef?.id}
            exclusive
            onChange={(event, value) => flightHooks.updateDoc(flight.id, { aircraftId: value })}
          >
            {aircrafts.map(aircraft =>
              <ToggleButton key={aircraft.id} value={aircraft.id}>{aircraft.name}</ToggleButton>,
            )}
          </ToggleButtonGroup>}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <DateTimePicker
            inputFormat='dd-MM-yyyy HH:mm'
            margin='dense'
            id='startDate'
            value={flight.startDate ? flight.startDate : new Date()}
            onChange={val => isValid(val) && flightHooks.updateDoc(flight.id, { startDate: val })}
            ampm={false}
            label='date et heure'
            slotProps={{ textField: { sx: { mt: 1 } } }}
            minDate={startOfYear(new Date())}
            maxDate={endOfYear(new Date())}
          />
        </Grid>
      </Grid>
      <Grid container id='crew' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>Equipage</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            {dbUsers && <UserSelect
              disabled={!profile.roles?.includes('admin') && !profile.roles?.includes('instructeur')}
              user={dbUsers.find(u => u.id === flight.pilotRef?.id) || null}
              label='Pilote'
              set={user => flightHooks.updateDoc(flight.id, { pilotId: user.id })}
              disableClearable
              onKeyPress={e => e.key === 'Enter' && close()}
              sx={{ width: '100%' }}
            />}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <FormControl variant='standard' sx={{ minWidth: '200px' }}>
            {instructors && instructors.length > 0 && <UserSelect
              user={instructors.find(u => u.id === flight.instructorRef?.id) || null}
              users={instructors}
              label='Instructeur'
              set={user => flightHooks.updateDoc(flight.id, { instructorId: user?.id || null })}
              onKeyPress={e => e.key === 'Enter' && close()}
              sx={{ width: '100%' }}
            />}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='airports' sx={{ mb: 5 }}>
        <Grid item xs={4} ><Typography color='textSecondary' gutterBottom>Aérodromes</Typography></Grid>
        <Grid item xs={8} >
          <FormControl variant='standard' sx={{ minWidth: '200px' }}>
            {dbAirports && <Autocomplete
              id='departure-select'
              options={dbAirports}
              getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={params => <TextField variant='standard' {...params} label='Départ' fullWidth />}
              value={flight.departure}
              onChange={(event, newValue) =>
                flightHooks.updateDoc(flight.id, { departureId: newValue.id })
              }
              onKeyPress={e => e.key === 'Enter' && close()}
            />}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard' sx={{ minWidth: '200px' }}>
            {dbAirports && <Autocomplete
              id='arrival-select'
              options={dbAirports}
              getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={params => <TextField variant='standard' {...params} label='Arrivée' fullWidth />}
              value={flight.arrival}
              onChange={(event, newValue) =>
                flightHooks.updateDoc(flight.id, { arrivalId: newValue.id })
              }
              onKeyPress={e => e.key === 'Enter' && close()}
            />}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='counter' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>Horamètre</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='counterDeparture'
              label='Départ'
              type='number'
              fullWidth
              value={flight.counterDeparture}
              onChange={e => flightHooks.updateDoc(flight.id, { counterDeparture: parseFloat(e.target.value) })}
              inputProps={{ step: flight.aircraft?.price?.float || .01 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='counterArrival'
              label='Arrivée'
              type='number'
              fullWidth
              value={flight.counterArrival}
              onChange={e => flightHooks.updateDoc(flight.id, { counterArrival: parseFloat(e.target.value) })}
              inputProps={{ step: flight.aircraft?.price?.float || .01 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='duration'
              label='Durée'
              type='number'
              fullWidth
              value={flight.duration}
              disabled />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='fuel' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>
            Essence
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='fuelBefore'
              label='Départ'
              type='number'
              fullWidth
              value={flight.fuelBefore}
              onChange={e => flightHooks.updateDoc(flight.id, { fuelBefore: parseFloat(e.target.value) })}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='fuelAfter'
              label='Arrivée'
              type='number'
              fullWidth
              value={flight.fuelAfter}
              onChange={e => flightHooks.updateDoc(flight.id, { fuelAfter: parseFloat(e.target.value) })}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='loadFactors' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>
            Accéléro
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='gPos'
              label='Positif'
              type='number'
              fullWidth
              value={flight.gPos}
              onChange={e => flightHooks.updateDoc(flight.id, { gPos: parseFloat(e.target.value) })}
              inputProps={{ step: .1 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='gNeg'
              label='Négatif'
              type='number'
              fullWidth
              value={flight.gNeg}
              onChange={e => flightHooks.updateDoc(flight.id, { gNeg: parseFloat(e.target.value) })}
              inputProps={{ step: .1 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='landings' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>
            Atterrissages
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='landings'
              label='Nombre'
              type='number'
              fullWidth
              value={flight.landings}
              onChange={e => flightHooks.updateDoc(flight.id, { landings: parseInt(e.target.value) })}
              inputProps={{ step: 1 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} id='comments'>
        <TextField
          margin='dense'
          label='Commentaires'
          multiline
          fullWidth
          value={flight.comments || ''}
          rows={4}
          variant='outlined'
          onChange={e => flightHooks.updateDoc(flight.id, { comments: e.target.value })}
        />
      </Grid>
    </Grid>
  )
}

FlightDetails.propTypes = {
  flight: PropTypes.object.isRequired,
  close: PropTypes.func,
}

export default FlightDetails
