import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'


const styles = theme => ({
  root: {
    border: 'solid 1px',
    borderColor: theme.palette.grey[500],
    width: 'fit-content',
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  item: {
    display: 'inline-block',
    textAlign: 'center',
    padding: '2px 5px',
    '&:not(:first-child)': {
      borderLeft: 'solid 1px',
      borderColor: theme.palette.grey[500],
    },
  },
  lightBlue: {
    color: theme.palette.info.light,
  },
  blue: {
    color: theme.palette.info.main,
  },
  green: {
    color: theme.palette.success.main,
  },
  red: {
    color: theme.palette.error.main,
  },
})

const Legend = ({ classes, className, generalImpression }) => generalImpression
  ? <div className={clsx(classes.root, className)}>
    <div className={clsx(classes.item, classes.red)}>
      <span className={classes.symbol}>I&nbsp;</span>
      <span className={classes.title}>Insufisant</span>
    </div>
    <div className={clsx(classes.item, classes.blue)}>
      <span className={classes.symbol}>S&nbsp;</span>
      <span className={classes.title}>Satisfaisant</span>
    </div>
    <div className={clsx(classes.item, classes.green)}>
      <span className={classes.symbol}>P&nbsp;</span>
      <span className={classes.title}>Performant</span>
    </div>
  </div>
  : <div className={clsx(classes.root, className)}>
    <div className={clsx(classes.item, classes.lightBlue)}>
      <span className={classes.symbol}>/&nbsp;</span>
      <span className={classes.title}>Vu</span>
    </div>
    <div className={clsx(classes.item, classes.blue)}>
      <span className={classes.symbol}>X&nbsp;</span>
      <span className={classes.title}>Restitué</span>
    </div>
    <div className={clsx(classes.item, classes.green)}>
      <span className={classes.symbol}>O&nbsp;</span>
      <span className={classes.title}>Assimilé</span>
    </div>
    <div className={clsx(classes.item, classes.red)}>
      <span className={classes.symbol}>•&nbsp;</span>
      <span className={classes.title}>A revoir</span>
    </div>
  </div>

Legend.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  generalImpression: PropTypes.bool,
}

export default compose(
  withStyles(styles),
)(Legend)
