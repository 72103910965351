import { differenceInDays, format, isSameDay, isSameMonth } from 'date-fns'
import { fr } from 'date-fns/locale'

export const expireColor = date => {
  if (!date) return ''
  const differenceInCalendarDays = differenceInDays(date, new Date())
  if (differenceInCalendarDays < 0) return '#F00'
  else if (differenceInCalendarDays > 0 && differenceInCalendarDays < 60) return 'orange'
  else return '#28a752'
}

export const printEventDuration = (start, end) => {
  if (isSameDay(start, end))
    return `le ${format(start, 'dd MMMM', { locale: fr })}`
  if (isSameMonth(start, end))
    return `du ${format(start, 'dd')} au ${format(end, 'dd MMMM', { locale: fr })}`
  else
    return `du ${format(start, 'dd MMMM', { locale: fr })} au ${format(end, 'dd MMMM', { locale: fr })}`
}
