import React, { useState } from 'react'
import { AppBar, Badge, IconButton, Menu, MenuItem, ToggleButton, Box, Button, useMediaQuery, useTheme } from '@mui/material'
import Avatar from '../_library/Avatar'
import { ArrowDropDown, ExitToApp, ContactMailOutlined as ContactMail, Notifications, NotificationsNone, Menu as MenuIcon, NotificationImportant } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { PROFILE } from '../../_constants/routes'
import DocsButton from './DocsButton'
import { selectUserId, setAdminMode } from '../../store/actions/dataActions'
import { MENU_STATUS_BIG, NOTIFICATION_COLLECTION, ROLE_ADMIN } from '../../_constants/globals'
import NotificationDrawer from './NotificationDrawer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const TopBar = ({ setMenuStatus }) => {
  
  const { t } = useTranslation()
  const navigate = useNavigate()
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  const notificationHooks = useFirestore(NOTIFICATION_COLLECTION)
  const dbNotifications = notificationHooks.getDocs()
  const notificationsAllowed = useSelector(state => state.data.notificationsAllowed || false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const auth = useAuth()
  
  const [anchorEl, setAnchorEl] = useState(null)
  const [notificationPanelOpen, setNotificationPanelOpen] = useState(false)
  
  return (
    <AppBar color='primary' position='sticky' elevation={0} sx={{ display: 'block', height: '48px' }}>
      {!isUpMd && <IconButton onClick={() => setMenuStatus(MENU_STATUS_BIG)} sx={{ height: '100%', color: 'white' }}><MenuIcon /></IconButton>}
      <Box sx={{ float: 'right' }}>
        {profile.roles.includes(ROLE_ADMIN) && <ToggleButton
          value='adminMode'
          selected={adminMode || false}
          onChange={() => {
            dispatch(setAdminMode(!adminMode))
            if (adminMode) {
              dispatch(selectUserId(null))
            }
          }}
          size='small'
          sx={{
            color: 'white',
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: 'secondary.dark',
              '&:hover': {
                backgroundColor: 'secondary.dark',
              },
            },
          }}
        >
          MODE ADMIN
        </ToggleButton>}
        <DocsButton />
        <IconButton
          aria-label='notifications'
          color='inherit'
          onClick={() => setNotificationPanelOpen(true)}
          size='large'
          sx={{ mr: 1 }}
        >
          <Badge badgeContent={dbNotifications?.filter(n => !n.read).length} color='secondary'>
            {!notificationsAllowed
              ? <NotificationImportant />
              : dbNotifications?.length
                ? <Notifications />
                : <NotificationsNone />
            }
          </Badge>
        </IconButton>
        <NotificationDrawer
          open={notificationPanelOpen}
          onClose={() => setNotificationPanelOpen(false)}
        />
        <Button onClick={e => setAnchorEl(e.currentTarget)} sx={{ display: 'inline-block', p: 0 }}>
          <Avatar user={profile} sx={{ display: 'inline-block', verticalAlign: 'middle' }} />
          <ArrowDropDown sx={{ color: 'white', verticalAlign: 'middle' }} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={() => {navigate(PROFILE); setAnchorEl(null)}}>
            <ContactMail sx={{ mr: 1 }} />Profil
          </MenuItem>
          <MenuItem onClick={auth.signOut}>
            <ExitToApp sx={{ mr: 1 }} />{t('menu.logout')}
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  )
}

TopBar.propTypes = {
  setMenuStatus: PropTypes.func.isRequired,
}

export default TopBar
