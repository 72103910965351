import React from 'react'
import MUIDataTable from 'mui-datatables'
import { USER_COLLECTION } from '../../_constants/globals'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'


const SubscriptionTable = () => {
  
  const navigate = useNavigate()
  const location = useLocation()
  const userHooks = useFirestore(USER_COLLECTION, { storeAs: 'subscriptions' })
  const subscriptions = userHooks.getDocs()
  
  const data = subscriptions && subscriptions.reduce((acc, val) => {
    if (val) {
      const { firstname, lastname, email, phone } = val
      acc.push([firstname, lastname, email, phone ])
    }
    return acc
  }, [])
  
  const columns = ['Firstname', 'Lastname', 'E-mail', 'Phone']
  
  return (
    <Box m={3}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          serverSide: false,
          onRowClick: (row, { dataIndex }) => navigate(`${location.pathname}?userId=${subscriptions[dataIndex].id}`),
          setRowProps: () => ({
            sx: {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            },
          }),
        }}
      />
    </Box>
  )
}

export default SubscriptionTable
