import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Link, MenuItem, Select, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  PB_ITEM_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION, QUALIFICATION_COLLECTION, QUALIFICATION_TYPE_COLLECTION, ROLE_CHEF_PILOTE,
  ROLE_INSTRUCTOR, USER_COLLECTION,
} from '../../_constants/globals'
import ItemsCard from './ItemsCard'
import DeleteButton from '../_library/DeleteButton'
import Loading from '../_library/Loading'
import PilotCard from './PilotCard'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import LessonsCard from './LessonsCard'
import StatCard from './StatCard'
import SelectedLessonManager from './SelectedLessonManager'
import GeneralImpressionCard from './GeneralImpressionCard'
import ClosureDialog from './ClosureDialog'
import CTAButton from '../_library/CTAButton'
import { useMatch, useNavigate } from 'react-router-dom'
import { ScrollSync } from 'react-scroll-sync'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const ProgressBookDetails = () => {
  
  const navigate = useNavigate()
  const match = useMatch(PROGRESS_BOOKS + '/:id')
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION)
  const dbLessons = lessonHooks.getDocs()
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  const dbBooks = bookHooks.getDocs()
  const adminMode = useSelector(state => state.data.adminMode)
  const itemHooks = useFirestore(PB_ITEM_COLLECTION)
  const dbItems = itemHooks.getDocs()
  const profile = useAuth().getProfile()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION, { storeAs: 'licence' })
  const dbLicences = qualificationHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const qualificationTypeHooks = useFirestore(QUALIFICATION_TYPE_COLLECTION)
  const selectedLessonId = useSelector(state => state.data.selectedLessonId)
  
  const progressBook = useMemo(() => dbBooks?.find(b => b.id === match.params.id), [dbBooks, match.params.id])
  
  const [editingLesson, setEditingLesson] = useState(null)
  const [closureDialogOpen, setClosureDialogOpen] = useState(null)
  
  const theoreticalCard = useRef(null)
  const practicalCard = useRef(null)
  const generalImpressionCard = useRef(null)
  
  useEffect(() => {
    if (progressBook?.pilotRef) {
      qualificationHooks.listen({
        where: [
          ['userRef', '==', userHooks.getDocRef(progressBook.pilotRef.id)],
          ['typeRef', '==', qualificationTypeHooks.getDocRef('CtRXmGg4PAZQjjsKST0O')],
        ],
      })
      lessonHooks.listen({
        where: [['pilotRef', '==', userHooks.getDocRef(progressBook.pilotRef.id)]],
        orderBy: [['docDate', 'asc']],
      })
    }
    return () => {
      qualificationHooks.unsubscribe()
      lessonHooks.unsubscribe()
    }
  }, [progressBook?.pilotRef])
  
  const licence = useMemo(() => dbLicences && dbLicences[0], [dbLicences])
  const filteredItems = useMemo(() => progressBook && dbItems?.filter(i => i.level === progressBook.level), [progressBook, dbItems])
  const filteredLessons = useMemo(
    () => dbLessons && progressBook && dbLessons
      .filter(l => l.progressBookRef?.id === progressBook?.id)
      .map(l => l.selectedItemIds ? { ...l, theoreticalItems: {}, practicalItems: {}, generalImpression: {} } : l),
    [dbLessons, progressBook],
  )
  
  useEffect(() => {
    if (selectedLessonId && dbLessons) setEditingLesson(dbLessons.find(l => l.id === selectedLessonId))
  }, [selectedLessonId, dbLessons])
  
  const handleDelete = () => bookHooks.deleteDoc(progressBook.id).then(() => navigate(PROGRESS_BOOKS))
  
  const scrollTo = id => {
    switch (id){
      case 'theoreticalCard':
        theoreticalCard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        break
      case 'practicalCard':
        practicalCard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        break
      case 'generalImpressionCard':
        generalImpressionCard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        break
    }
  }
  
  if (!progressBook) return <Loading />
  else return (
    <Box sx={{ p: 1, pb: '120px' }}>
      {adminMode && <Select
        id='progressBook-status-select'
        variant='standard'
        value={progressBook.status}
        label='Statut'
        onChange={e => bookHooks.updateDoc(progressBook.id, { status: e.target.value })}
        sx={{ float: 'right' }}
      >
        <MenuItem value='open'>open</MenuItem>
        <MenuItem value='standBy'>standBy</MenuItem>
      </Select>}
      <Typography variant='h1'>{progressBook.name}</Typography>
      <Typography variant='subtitle1'>Ce livret de formation est basé sur la formation FCL800 editée par la FFA.
        Cependant, à des fins pédagogiques, d&apos;adaptation aux capacités de l&apos;élève et aux conditions météorologiques du jour ou
        toute autre raison que l&apos;instructeur estimera, l&apos;ordre des items travaillés pourra être adapté par
        l&apos;instructeur.</Typography>
      <PilotCard progressBook={progressBook} licence={licence} />
      <StatCard progressBook={progressBook} />
      <ScrollSync>
        <>
          <div ref={theoreticalCard}>
            <ItemsCard
              progressBook={progressBook}
              items={filteredItems?.filter(i => i.type === 'theoretical')}
              type='theoretical'
              lessons={filteredLessons}
              editingLesson={editingLesson}
              setEditingLesson={setEditingLesson}
            />
          </div>
          <div ref={practicalCard}>
            <ItemsCard
              progressBook={progressBook}
              type='practical'
              items={filteredItems?.filter(i => i.type === 'practical')}
              lessons={filteredLessons}
              editingLesson={editingLesson}
              setEditingLesson={setEditingLesson}
            />
          </div>
          <div ref={generalImpressionCard}>
            <GeneralImpressionCard
              progressBook={progressBook}
              lessons={filteredLessons}
              editingLesson={editingLesson}
              setEditingLesson={setEditingLesson}
            />
          </div>
        </>
      </ScrollSync>
      {filteredLessons && filteredItems && <LessonsCard lessons={filteredLessons} items={filteredItems} />}
      
      {filteredLessons?.length === 0 && <DeleteButton deleteAction={handleDelete} text='Supprimer le livret' sx={{ width: '100%' }}  />}
      {filteredLessons?.length !== 0 && progressBook.status === 'open' && <CTAButton sx={{ display: 'block', width: '100%' }} onClick={() => setClosureDialogOpen(true)}>Terminer le livret...</CTAButton>}
      {filteredLessons?.length !== 0 && profile.roles.includes(ROLE_INSTRUCTOR) && progressBook.status === 'closing' &&
        <CTAButton
          onClick={() => bookHooks.updateDoc(progressBook.id, { status: 'instructorValidated' }, true)}
          sx={{ display: 'block', width: '100%' }}
        >Valider le livret</CTAButton>
      }
      {filteredLessons?.length !== 0 && profile.roles.includes(ROLE_CHEF_PILOTE) && progressBook.status === 'instructorValidated' &&
        <CTAButton
          onClick={() => bookHooks.updateDoc(progressBook.id, { status: 'validated' }, true)}
          sx={{ display: 'block', width: '100%' }}
        >Valider le livret</CTAButton>
      }
      {(profile.roles?.includes(ROLE_INSTRUCTOR) || adminMode) &&
        <SelectedLessonManager
          progressBook={progressBook}
          lessonsWithItems={filteredLessons}
          scrollTo={scrollTo}
          editingLesson={editingLesson}
          setEditingLesson={setEditingLesson}
        />
      }
      {progressBook.pdfUrl && <Link
        href={progressBook.pdfUrl}
        target='_blank'
        rel='noopener noreferrer'
        download
        style={{ cursor: 'pointer' }}
        underline='hover'>Télécharger le livret</Link>}
      {closureDialogOpen && <ClosureDialog onClose={() => setClosureDialogOpen(false)} progressBook={progressBook} licence={licence} />}
    </Box>
  )
}

export default ProgressBookDetails
