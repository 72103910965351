import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Typography, Button, CardActions, Link, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { STATUS_APPLICATION_SUBMITTED, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const SumUp = ({ back }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const profile = useSelector(state => state.profile.data)
  const auth = useAuth()

  const [comments, setComments] = useState(null)

  const submitted = useMemo(() => profile.status === STATUS_APPLICATION_SUBMITTED, [profile.status])

  const onNext = () =>
    userHooks.updateDoc(profile.id, {
      status: STATUS_APPLICATION_SUBMITTED,
      onboardingComments: comments,
    })

  return (
    <Card variant='outlined' sx={{ maxWidth: 900, my: 2, mx: 'auto' }}>
      {submitted
        ? <CardContent>
          <Typography variant='h1'>A bientôt</Typography>
          <Typography variant='body1'>Ton inscription a bien été enregistrée. Tu recevras un email quand elle aura été validée.</Typography>
        </CardContent>
        : <CardContent>
          <Typography variant='h1'>Et voilà</Typography>
          <Typography variant='body1'>Pour finaliser ton inscription il te reste à créditer ton compte pilote.</Typography>
          <ul>
            <li>
              Par virement FR76 1005 7192 5700 0203 1430 120
              <Link
                href='/iban_CIC_MPV.pdf'
                target='_blank'
                rel='noopener noreferrer'
                download
                style={{ cursor: 'pointer' }}
                underline='hover'>
              &nbsp;Télécharger le RIB
              </Link>
            </li>
          </ul>
          <Typography variant='body1'>Vérifie que ton dossier est bien complet et valide avec le bouton ci-dessous.</Typography>
          <Typography variant='body2'>Merci de vérifier méticuleusement les infos et les pièces jointes de ton dossier
            afin de faliciter le travail du bénévole en charge des inscription. Notamment la lisibilté des pièces
            jointes.</Typography>
          <TextField
            label='Commentaires'
            placeholder='Tu peux écrire ici tous les commentaires que tu veux laisser à la personne qui validera ton inscription'
            value={comments || ''}
            onChange={e => setComments(e.target.value)}
            variant='outlined'
            multiline
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mt: 4 }}
          />
        </CardContent>
      }
      <CardActions sx={{ float: 'right' }}>
        <Button size='large' onClick={back}>Précédent</Button>
        {!submitted
          ? <Button size='large' color='primary' onClick={onNext}>Valider l&apos;inscription</Button>
          : <Button size='large' color='secondary' onClick={auth.signOut}>Déconnexion</Button>
        }
      </CardActions>
    </Card>
  )
}

SumUp.propTypes = {
  back: PropTypes.func.isRequired,
}

export default SumUp
