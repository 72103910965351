import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Avatar from './Avatar'
import { useSelector } from 'react-redux'
import { useStorage } from '../../hooks/useStorage'


const AvatarEditor = ({ sx }) => {
  
  const profile = useSelector(state => state.profile.data)
  const storage = useStorage('profile-pictures')
  
  const handleSubmit = event =>
    storage.uploadFileAction(
      event.target.files[0],
      `${profile.id}-${(new Date()).getMilliseconds()}.${event.target.files[0].name.split('.').pop()}`,
    )
  
  return (
    <div>
      <Box
        component='label'
        htmlFor='file'
        sx={{ minWidth: '40px', cursor: 'pointer', position: 'relative' }}
      >
        <Avatar file={profile.photoUrl} sx={sx}/>
      </Box>
      <Box
        component='input'
        type='file'
        name='file'
        id='file'
        onChange={handleSubmit}
        sx={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
    </div>
  )
}

AvatarEditor.propTypes = {
  sx: PropTypes.object,
}

export default AvatarEditor
