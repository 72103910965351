import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import Avatar from '../_library/Avatar'


const Request = ({ user, flightType, onClick, disabled }) => (
  <Tooltip title={`${user?.firstname} ${user?.lastname} souhaite voler en ${flightType}`}>
    <span> {/* span tag is necessary here so the tooltip pops up when button is disabled */}
      <IconButton onClick={onClick} disabled={disabled} sx={{ p: 0 }}>
        <Avatar user={user} sx={{ display: 'inline-block', opacity: .5 }} />
      </IconButton>
    </span>
  </Tooltip>
)

Request.propTypes = {
  user: PropTypes.object,
  flightType: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Request
