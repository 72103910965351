import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Box, Dialog, DialogTitle, Menu, MenuItem, Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import hatch from '../../images/hatch.png'
import NewBookingForm from './NewBookingForm'
import { differenceInMinutes, addHours, startOfDay, addMinutes, format } from 'date-fns'
import * as _ from 'lodash'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import EditBookingForm from './EditBookingForm'
import { Create, Delete, Send } from '@mui/icons-material'
import NewFlightDialog from '../flights/NewFlightDialog'
import { BOOKING_COLLECTION, ROLE_INSTRUCTOR } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { ACTIVITIES } from '../../_constants/routes'
import WriteAvailabilityDialog from './WriteAvailabilityDialog'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const styles = () => ({
  line: {
    borderLeft: '1px solid #ccc',
  },
  lineStart: {
    backgroundColor: '#f2f2f2',
    borderLeftColor: 'transparent',
  },
  pseudoBorder: {
    height: 4,
    width: '100%',
    background: 'transparent',
  },
  blank4ElasticLine: {
    verticalAlign: 'bottom',
    borderLeft: '1px solid #ccc',
  },
  last: {
    borderStyle: 'solid',
    borderRightColor: '#ccc',
    borderRightWidth: 1,
  },
  unavailable: {
    background: `#f2f2f2 url(${hatch})`,
    borderLeft: '1px solid #ccc',
  },
  reserved: {
    textAlign: 'center',
    cursor: 'default',
    backgroundColor: '#BF3FA6',
  },
  myBooking: {
    cursor: 'pointer',
    backgroundColor: '#6EC402',
  },
  icons: {
    marginRight: 10,
  },
})

const makeCells = (resource, bookings, availabilities, classes, bookFlight, setAnchorEl, day, profile, navigate, type, adminMode, setOpenAvailability) => {
  // Create a 48 long array of free or unavailable slots
  let arr = Array.from(Array(48)).map((val, key) =>
    ({
      slot: resource.bookable ? 'free' : 'unavailable',
      start: key,
    }),
  )

  // Replace free slot with bookings
  if (bookings)
    arr = _.reduceRight(bookings, (acc, val) => {
      const startIndex = val.startDate < addHours(startOfDay(day), 8)
        ? 0
        : differenceInMinutes(val.startDate, addHours(startOfDay(val.startDate), 8)) / 15
      const bookLength = val.startDate > addHours(startOfDay(day), 8)
        ? differenceInMinutes(val.endDate, val.startDate) / 15
        : differenceInMinutes(val.endDate, addHours(startOfDay(day), 8)) / 15
      for (let i = startIndex; i < Math.min(startIndex + bookLength, 48); i++)
        acc[i] = {
          activityRef: val.activityRef,
          activity: val.activity,
          slot: 'book',
          start: startIndex,
          type: val.flightType?.name,
          user: val.pilot || val.user,
          userId: (val.pilotRef || val.userRef)?.id,
          instructorId: val.instructorRef?.id,
          id: val.id,
        }
      return acc
    }, arr)
  // Replace free slots with availabilities
  if (availabilities)
    arr = availabilities.reduceRight((acc, val) => {
      const startIndex = differenceInMinutes(val.startDate, addHours(startOfDay(val.startDate), 8)) / 15
      const bookLength = differenceInMinutes(val.endDate, val.startDate) / 15
      for (let i = Math.max(0, startIndex); i < startIndex + bookLength; i++) {
        if (acc[i] && acc[i].slot === 'unavailable')
          acc[i].slot = 'free'
      }
      return acc
    }, arr)
  
  // Groups timeslots
  const data = []
  let beginning
  for (let i = 0; i < arr.length; i++) {
    if (beginning !== undefined
      && arr[i].slot === arr[beginning].slot
      && arr[i].slot !== 'free'
      && (arr[i].slot === 'unavailable' || (arr[beginning].id && arr[beginning].id === arr[i].id))
    )
      data[data.length - 1].length++
    else {
      data.push({
        ...arr[i],
        length: 1,
      })
      beginning = i
    }
  }

  // Make http cells
  const cells = data.map((timeslot, index) => {
    switch (timeslot.slot){
      case 'free':
        return <Box
          component='td'
          key={timeslot.start}
          colSpan='1'
          title={type === 'aircraft' ? 'RESERVER' : null}
          onClick={() => {
            if (type === 'aircraft') bookFlight(timeslot.start)
            else if (adminMode || profile.id === resource.id) {
              let firstFreeSlot = timeslot
              for (let i = index; data[i].slot === 'free'; i--) firstFreeSlot = data[i]
              const slotStart = addMinutes(new Date(format(day, 'yyyy-MM-dd') + 'T08:00'), 15 * firstFreeSlot.start)
              const foundedAvailability = availabilities.find(a => a.startDate.getTime() === slotStart.getTime())
              setOpenAvailability(foundedAvailability)
            }
          }}
          sx={{
            backgroundColor: '#f2f2f2',
            borderLeft: '1px solid #FFF',
            borderColor: 'rgba(0, 0, 0, .1)',
            cursor: type === 'aircraft' || adminMode || profile.id === resource.id ? 'pointer' : 'default',
          }}
        >&nbsp;</Box>
      case 'book':
        return <td
          key={timeslot.start}
          id={timeslot.id}
          className={clsx(classes.reserved, { [classes.myBooking]: timeslot.userId === profile.id || timeslot.instructorId === profile.id })}
          style={{ cursor: (timeslot.activityRef || adminMode || profile?.roles.includes(ROLE_INSTRUCTOR)) && 'pointer' }}
          colSpan={timeslot.length}
          onClick={e => {
            if (timeslot.activityRef)
              navigate(ACTIVITIES + '/' + timeslot.activityRef.id)
            else if (timeslot.userId === profile.id || timeslot.instructorId === profile.id || profile?.roles.some(r => ['instructeur', 'admin'].includes(r)))
              setAnchorEl(e.currentTarget)
          }}
        >
          <Tooltip title={timeslot.user ? `type de vol: ${timeslot.type}\nPilote: ${timeslot.user?.firstname} ${timeslot.user?.lastname}` : null} enterDelay={500} placement='top'>
            <Box sx={{ fontWeight: timeslot.activityRef && 'bold' }}>
              {timeslot.activity?.title || timeslot.user?.firstname}
            </Box>
          </Tooltip>
        </td>
      case 'unavailable':
        return <td
          key={timeslot.start}
          className={classes.unavailable}
          title='Non réservable'
          colSpan={timeslot.length}
        >&nbsp;</td>
      default:
        return null
    }
  })

  return cells
}

const PlanningRow = ({ classes, resource, bookings, availabilities, day, type }) => {
  
  const profile = useAuth().getProfile()
  const navigate = useNavigate()
  const adminMode = useSelector(state => state.data.adminMode)
  const bookingHooks = useFirestore(BOOKING_COLLECTION)

  const [openNew, setOpenNew] = useState(false)
  const [startDate, setStartDate] = useState(day)
  const [selectedEditBooking, selectEditBooking] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedNewFlightBooking, selectNewFlightBooking] = useState(null)
  const [openAvailability, setOpenAvailability] = useState(null)

  const bookFlight = key => {
    setStartDate(addMinutes(addHours(startOfDay(day), 8 + key / 4), (key % 4) * 15))
    setOpenNew(true)
  }

  const cells = makeCells(resource, bookings, availabilities, classes, bookFlight, setAnchorEl, day, profile, navigate, type, adminMode, setOpenAvailability)

  return (
    <>
      <tr className='rule before-line'>
        <th>
          <div className={classes.pseudoBorder} />
        </th>
        <td colSpan='2' className='s'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className='line-end' colSpan='2'>
          <div className={classes.pseudoBorder} />
        </td>
      </tr>
      <tr className={classes.line}>
        <Box component='th' colSpan={3} sx={{
          position: 'relative',
          padding: '0 0 0 3px',
          background: '#f2f2f2',
        }}>
          <Box sx={{
            width: '100%',
            padding: '.3em .3em .3em 3px',
            lineHeight: '1.2em',
            textAlign: 'left',
          }}>
            {resource.name}
            {resource.type}
          </Box>
        </Box>
        {cells}
      </tr>
      <tr className='rule after-line'>
        <th>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </th>
        <td colSpan='2' className='s'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className='line-end' colSpan='2'>
          <div className={classes.pseudoBorder} />
        </td>
      </tr>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={() => {
          selectEditBooking(bookings.find(b => b.id === anchorEl.id))
          setAnchorEl(null)
        }}>
          <Create className={classes.icons} />Modifier
        </MenuItem>
        <MenuItem onClick={() => bookingHooks.deleteDoc(anchorEl.id).then(() => setAnchorEl(null))}>
          <Delete className={classes.icons} />Supprimer
        </MenuItem>
        <MenuItem onClick={() => {
          selectNewFlightBooking(bookings.find(b => b.id === anchorEl.id))
          setAnchorEl(null)
        }}>
          <Send className={classes.icons} />Saisir le vol
        </MenuItem>
      </Menu>

      <Dialog open={openNew} onClose={() => setOpenNew(false)} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Nouvelle réservation</DialogTitle>
        <NewBookingForm onClose={() => setOpenNew(false)} startDateInit={startDate} aircraftInit={resource} />
      </Dialog>

      <Dialog open={Boolean(selectedEditBooking)} onClose={() => selectEditBooking(null)} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Modifier une réservation</DialogTitle>
        {selectedEditBooking && <EditBookingForm onClose={() => selectEditBooking(null)} booking={selectedEditBooking} />}
      </Dialog>

      {selectedNewFlightBooking && <NewFlightDialog open onClose={() => selectNewFlightBooking(null)} booking={selectedNewFlightBooking} />}
      {openAvailability && <WriteAvailabilityDialog open onClose={() => setOpenAvailability(null)} dayInit={day} availability={openAvailability} />}
    </>
  )
}

PlanningRow.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.object,
  bookings: PropTypes.array,
  availabilities: PropTypes.array,
  day: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['aircraft', 'instructor']).isRequired,
}

export default compose(
  withStyles(styles),
)(PlanningRow)
