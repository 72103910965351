import React, { useEffect, useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import Loading from '../_library/Loading'
import DetailsCard from './DetailsCard'
import MapCard from './MapCard'
import CalendarItem from './CalendarItem'
import { printEventDuration } from '../../_helpers/dateHelper'
import ActionBar from './ActionBar'
import UserCard from './UserCard'
import PlanningCard from './PlanningCard'
import DiscussionCard from './DiscussionCard'
import PublishCard from './PublishCard'
import { ACTIVITY_COLLECTION, NOTIFICATION_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'


const ActivityDetails = () => {
  const activityId = useSelector(state => state.data.selectedActivityId)
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const dbActivities = activityHooks.getDocs()
  const profile = useAuth().getProfile()
  const adminMode = useSelector(state => state.data.adminMode)
  const notificationHooks = useFirestore(NOTIFICATION_COLLECTION)
  const dbNotifications = notificationHooks.getDocs()

  useEffect(() => {
    notifications?.forEach(n => notificationHooks.updateDoc(n.id, { read: true }))
  }, [])

  const activity = useMemo(() => dbActivities?.find(a => a.id === activityId), [dbActivities, activityId])

  const notifications = useMemo(() => dbNotifications?.filter(n => n.activityRef?.id === activityId) || [], [dbNotifications, activityId])
  
  if (!activity) return <Loading />
  else return (
    <Box>
      <Box id='activity-details-header' sx={{ backgroundImage: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255.9), rgba(255,255,255,.7), rgba(255,255,255,.4), rgba(255,255,255,0));' }}>
        <Box component='img' src={activity.coverUrl} alt='cover' sx={{ display: 'block', width: '100%', maxWidth: 665, mx: 'auto', borderRadius: '10px' }} />
        <Box sx={{ px: 2, pb: 2, maxWidth: 1000, mx: 'auto' }}>
          <CalendarItem date={activity.firstDayDate} sx={{ mt: '-60px', position: 'relative' }} />
          <Typography color='secondary' textTransform='uppercase' mt={1}>{printEventDuration(activity.firstDayDate, activity.lastDayDate)}</Typography>
          <Typography variant='h1' mt={1} fontSize='2rem'>{activity.title}</Typography>
          <ActionBar activity={activity} />
        </Box>
      </Box>
      <Grid container spacing={2} pr={2} sx={{ pt: 2, pb: '120px', maxWidth: 1000, mx: 'auto', width: '100%' }}>
        <Grid item md={12}>
          <PlanningCard />
        </Grid>
        <Grid item md={7}>
          <DetailsCard />
          {(adminMode || activity.organizerRef?.id === profile.id) && <PublishCard sx={{ mt: 2 }} />}
          <DiscussionCard sx={{ mt: 2 }} />
        </Grid>
        <Grid item md={5}>
          <UserCard sx={{ mb: 2 }} />
          {activity.airport && <MapCard airport={activity.airport} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActivityDetails
