import {
  RESET_NOTIFICATIONS, DELETE_NOTIFICATION, AUTH_ERROR, FLIGHT_CREATE_SUCCESS, TRANSFER_SUCCESS, EMAIL_SENT,
  EMAIL_ERROR, INVITE_MEMBER_SUCCESS, INVITE_MEMBER_ERROR, SUCCESS, ERROR, WARNING, INFO, LOADING,
} from '../types'

// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  switch (action.type) {
    case RESET_NOTIFICATIONS:
      return {}
    case DELETE_NOTIFICATION:
      return { ...state, [action.payload]: null }

    // AUTH
    case AUTH_ERROR:
      if (action.payload.code === 'auth/user-not-found')
        return { ...state, auth: { severity: 'error', message: 'login.user-fail' } }
      else if (action.payload.code === 'auth/wrong-password')
        return { ...state, auth: { severity: 'error', message: 'login.password-fail' } }
      else {
        console.error(action.payload.code, action.payload.message)
        return { ...state, auth: { severity: 'error', message: action.payload.message } }
      }
    case EMAIL_SENT:
      return { ...state, email: { severity: 'success', message: 'Un email a été envoyé' } }
    case EMAIL_ERROR:
      return { ...state, email: { severity: 'error', message: 'Problème avec l\'envoi d\'email. Plus d\'info dans la console' } }
    case INVITE_MEMBER_SUCCESS:
      return { ...state, email: { severity: 'success', message: 'L\'utilisateur a été créé. Il recevra un email d\'invitation' } }
    case INVITE_MEMBER_ERROR:
      return { ...state, email: { severity: 'error', message: 'Problème avec la création de l\'utilisateur. Plus d\'info dans la console' } }

    case FLIGHT_CREATE_SUCCESS:
      return { ...state, flight: { severity: 'success', message: 'vol créé', autoHideDuration: 3000 } }

    case TRANSFER_SUCCESS:
      return { ...state, transfer: { severity: 'success', message: 'Transfer OK', autoHideDuration: 3000 } }

    case SUCCESS:
      return { ...state, misc: { severity: 'success', message: action.payload } }

    case ERROR:
      return { ...state, misc: { severity: 'error', message: action.payload } }

    case WARNING:
      return { ...state, misc: { severity: 'warning', message: action.payload } }

    case INFO:
      return { ...state, misc: { severity: 'info', message: action.payload } }

    case LOADING:
      return { ...state, misc: { severity: 'loading', message: action.payload } }

    default:
      return state
  }
}
