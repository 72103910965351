import { createSlice } from '@reduxjs/toolkit'
import { isIterable } from '../../_helpers/jsHelper'
import { serialize } from '../../_helpers/firestoreHelper'


export const createGenericSlice = ({ name = '', initialState, reducers }) =>
  createSlice({
    name,
    initialState,
    reducers: {
      loading: state => {
        state.status = 'loading'
        state.isLoaded = false
      },
      success: {
        reducer: (state, action) => {
          state.data = action.payload
          state.status = 'ready'
          state.isLoaded = true
        },
        prepare: data => ({
          payload: isIterable(data)
            ? data.map(serialize)
            : serialize(data),
        }),
      },
      error: state => {
        state.status = 'error'
        state.isLoaded = true
      },
      ...reducers,
    },
  })
