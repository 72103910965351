import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


export const CommentAircraftDialog = ({ onClose, aircraft }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)

  const [comments, setComments] = useState(aircraft.comments)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = () => {
    setSubmitted(true)
    aircraftHooks.updateDoc(aircraft.id, { comments })
      .then(onClose)
      .finally(() => setSubmitted(false))
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Laisser un commentaire</DialogTitle>
      <DialogContent>
        <TextField
          variant='standard'
          margin='dense'
          label='Commentaires'
          fullWidth
          value={comments || ''}
          multiline
          onChange={e => setComments(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Annuler
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={submitted}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CommentAircraftDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  aircraft: PropTypes.object.isRequired,
}

export default CommentAircraftDialog
