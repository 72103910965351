import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Loading from '../_library/Loading'


export const ProgramDetails = ({ flight, uploadProgramPicture }) => {

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  console.log('progress', progress)

  const handleSubmit  = event => {
    setLoading(true)
    uploadProgramPicture(
      event.target.files[0],
      flight.id,
    ).on('state_changed',
      function progress(snapshot) {
        setProgress(100 * snapshot.bytesTransferred / snapshot.totalBytes)
      },
      function error(err) {
        console.error(err)
      },
      function complete() {
        console.log('upload complete')
      },
    )
  }

  if (flight.programUrl)
    return <img src={flight.programUrl} alt='program' style={{ maxWidth: '100%' }} />
  else if (loading) return <Loading />
  else
    return <>
      <p>Télécharge le programme du vol</p>
      <input
        type='file'
        name='file'
        onChange={handleSubmit}
      />
    </>
}

const mapActionsToProps = ({
  uploadProgramPicture: (file, flightId) => (dispatch, getState, { getFirebase }) =>
    getFirebase()
      .storage()
      .ref(`program-pictures/${ flightId }-${ (new Date()).getMilliseconds() }.${ file.name.split('.').pop() }`)
      .put(file),
})

ProgramDetails.propTypes = {
  flight: PropTypes.object.isRequired,
  uploadProgramPicture: PropTypes.func.isRequired,
}

export default compose(
  connect(null, mapActionsToProps),
)(ProgramDetails)
