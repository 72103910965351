import React, { useState } from 'react'
import { Box, Fab } from '@mui/material'
import { Add } from '@mui/icons-material'
import MemberTable from './MemberTable'
import InviteMemberDialog from './InviteMemberDialog'
import { useSelector } from 'react-redux'


const AllMembersPanel = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)
  
  const [open, setOpen] = useState(false)

  return (
    <Box sx={{ pb: '100px' }}>
      <MemberTable />
      {adminMode && <Fab
        color='secondary'
        aria-label='add'
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
        }}
      >
        <Add />
      </Fab>}
      {open && <InviteMemberDialog onClose={() => setOpen(false)} />}
    </Box>
  )
}

export default AllMembersPanel
