import React, { useMemo, useState } from 'react'
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { Close } from '@mui/icons-material'
import { ACTIVITY_COLLECTION, SLOT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useSelector } from 'react-redux'
import Avatar from '../_library/Avatar'
import { useFirestore } from '../../hooks/useFirestore'
import Loading from '../_library/Loading'
import { getActivityUsers } from '../../_helpers/firestoreHelper'
import { deleteField } from 'firebase/firestore'


const Slot = ({ id, user, disabled }) => {
  
  const selectedActivityId = useSelector(state => state.data.selectedActivityId)
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const dbActivities = activityHooks.getDocs()
  const slotHooks = useFirestore(SLOT_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [anchorEl, setAnchorEl] = useState(null)
  
  const activity = useMemo(() => dbActivities?.find(a => a.id === selectedActivityId), [dbActivities, selectedActivityId])
  const users = useMemo(() => getActivityUsers(activity), [activity])
  
  const closeMenu = () => setAnchorEl(null)
  
  const handleDelete = () => slotHooks.deleteDoc(id)
  const handleBook = userId => slotHooks.updateDoc(id, { userRef: userId ? userHooks.getDocRef(userId) : deleteField() })
  
  if (!activity) return <Loading />
  else return (<>
    <Box sx={{ bgcolor: 'grey.500', display: 'inline-block', p: '2px' }}>
      <IconButton
        onClick={e => !disabled && setAnchorEl(e.currentTarget)}
        title={user ? undefined :'Créneau libre'}
        disableRipple={disabled}
        sx={{
          p: .5,
          width: '32px',
          height: '32px',
          bgcolor: 'white',
          cursor: disabled && 'default',
          '&:hover': {
            bgcolor: 'white',
          },
          '&:disabled': {
            bgcolor: 'white',
          },
        }}
      >{user && <Avatar user={user} tooltip={`${user.firstname} ${user.lastname || ''}`} />}</IconButton>
    </Box>
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={closeMenu}
      MenuListProps={{ 'aria-labelledby': 'basic-button' }}
    >
      {user
        ? <MenuItem onClick={() => handleBook(null).then(closeMenu)}>
          <ListItemIcon><Close /></ListItemIcon>Libérer
        </MenuItem>
        : <MenuItem onClick={() => handleDelete().then(closeMenu)}>Supprimer</MenuItem>
      }
      {users?.map(u => <MenuItem key={u.id} onClick={() => handleBook(u.id).then(closeMenu)}>
        <ListItemIcon sx={{ mr: 1 }}><Avatar user={u} /></ListItemIcon>{u.firstname} {u.lastname}
      </MenuItem>)}
    </Menu>
  </>)
}

Slot.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object,
  disabled: PropTypes.bool,
}

export default Slot
