import React, { useEffect, useState } from 'react'
import { Card, CardContent, TextField, Button, CardActions, Collapse, CardHeader, Typography, Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import parse from 'html-react-parser'
import { SETTINGS_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const AnnouncementCard = () => {
  
  const settingHooks = useFirestore(SETTINGS_COLLECTION)
  const dbSettings = settingHooks.getDocs()
  const settings = dbSettings?.default
  
  const [announce, setAnnounce] = useState(null)
  const [expand, setExpand] = useState(false)
  const [edit, setEdit] = useState(false)
  
  useEffect(() => {
    settings?.announcement && setAnnounce(settings.announcement)
  }, [settings?.announcement])
  
  const handleSubmit = val =>
    settingHooks.setDoc('default', { announcement: val })
      .then(() => setEdit(false))
  
  return <Card sx={{ minWidth: '275px', mb: 4, p: 1 }}>
    <CardHeader
      title={<Box sx={{ fontSize: '1rem', color: 'grey.700' }}>
        <FontAwesomeIcon icon={faBullhorn} style={{ verticalAlign: 'baseline' }} />&nbsp;Annonce
      </Box>}
      action={<Button color='primary' onClick={() => setExpand(prev => !prev)} id='expandAnnouncementCardBtn'>
        {expand
          ? <ArrowDropUp />
          : <ArrowDropDown />
        }
      </Button>}
      sx={{ py: 1, px: 2 }}
    />
    <Collapse in={expand} sx={{ width: '100%' }}>
      <CardContent>
        {edit
          ? <TextField
            id='announcement-edit'
            multiline
            value={announce || ''}
            onChange={e => setAnnounce(e.target.value)}
            variant='standard'
            placeholder='Cette annonce sera visible par tous les membres'
            fullWidth
          />
          : <Typography variant='body1'>
            {settings?.announcement && parse(settings.announcement)}
          </Typography>
        }
      </CardContent>
      <CardActions>
        <Button onClick={() => handleSubmit(null)}>Effacer</Button>
        {edit
          ? <Button onClick={() => handleSubmit(announce)} color='primary'>Valider</Button>
          : <Button onClick={() => setEdit(true)} color='primary'>Modifier</Button>
        }
      </CardActions>
    </Collapse>
  </Card>
}

export default AnnouncementCard
