import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, CardActions, Card, CardContent, Typography, Divider, Grid, TextField, Slider, Stack } from '@mui/material'
import { USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'

const AEROBATICS_LEVELS = [
  { value: 0, label: 'Stagiaire' },
  { value: 1, label: 'FCL800' },
  { value: 2, label: 'BFV1' },
  { value: 3, label: 'BFV2' },
  { value: 4, label: 'BFV3' },
]

const COMPETITION_LEVELS = [
  { value: 0, label: 'Espoir' },
  { value: 1, label: 'Promotion' },
  { value: 2, label: 'National 2' },
  { value: 3, label: 'Advanced-Excellence' },
  { value: 4, label: 'Elite' },
]

const ExperienceForm = ({ next, back }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [totalHours, setTotalHours] = useState(profile.experience?.total)
  const [aerobaticHours, setAerobaticHours] = useState(profile.experience?.aerobatics)
  const [aerobaticLevel, setAerobaticLevel] = useState(0)
  const [competitionLevel, setCompetitionLevel] = useState(0)
  const [competitionPlace, setCompetitionPlace] = useState(profile.competition?.qualificationPlace || '')
  const [competitionYear, setCompetitionYear] = useState(profile.competition?.qualificationYear || '')
  const [errors, setErrors] = useState({})
  
  useEffect(() => {
    if (profile.aerobaticsLevel) {
      setAerobaticLevel(AEROBATICS_LEVELS.find(l => l.label === profile.aerobaticsLevel)?.value)
    }
  }, [profile.aerobaticsLevel])
  
  useEffect(() => {
    if (profile.competition) {
      setCompetitionLevel(COMPETITION_LEVELS.find(l => l.label === profile.competition.level)?.value)
    }
  }, [profile.competition])
  
  const handleSubmit = () => {
    setErrors({})
    if (competitionLevel !== 0 && !competitionPlace) setErrors(prev => ({ ...prev, competitionPlace: 'Le champ doit être rempli' }))
    if (competitionLevel !== 0 && !competitionYear) setErrors(prev => ({ ...prev, competitionYear: 'Le champ doit être rempli' }))
    if (competitionLevel === 0 || (competitionPlace && competitionYear))
      return userHooks.updateDoc(profile.id, {
        experience: {
          total: totalHours,
          aerobatics: aerobaticHours,
        },
        aerobaticsLevel: AEROBATICS_LEVELS.find(l => l.value === aerobaticLevel).label,
        competition: {
          level: COMPETITION_LEVELS.find(l => l.value === competitionLevel).label,
          qualificationPlace: competitionPlace,
          qualificationYear: competitionYear,
        },
      }).then(next)
  }
  
  return (
    <Card variant='outlined' sx={{ my: 2, mx: 'auto', maxWidth: 900 }}>
      <CardContent>
        <Grid id='experience' item container xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography color='textSecondary'>
              Heures de vol
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <Stack direction='row' spacing={1} alignContent='space-evenly'>
              <TextField
                variant='standard'
                label='Totales'
                type='number'
                value={totalHours || 0}
                onChange={e => setTotalHours(e.target.value)}
              />
              <TextField
                variant='standard'
                label='dont voltige'
                type='number'
                value={aerobaticHours || 0}
                onChange={e => setAerobaticHours(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid id='aerobatics' item container xs={12} >
          <Grid item sm={3} xs={12}>
            <Typography color='textSecondary'>
              Niveau en voltige
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <Slider
              aria-labelledby='discrete-slider-restrict'
              valueLabelDisplay='off'
              marks={AEROBATICS_LEVELS}
              max={4}
              value={aerobaticLevel || 0}
              onChange={(event, value) => setAerobaticLevel(value)}
              sx={{ width: 500, maxWidth: '100%' }}
            />
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid id='competition' item container xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography color='textSecondary'>
              Niveau en compétition
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <Slider
              value={competitionLevel}
              aria-labelledby='discrete-slider-restrict'
              valueLabelDisplay='off'
              max={4}
              marks={COMPETITION_LEVELS}
              onChange={(event, value) => setCompetitionLevel(value)}
              sx={{ width: 500, maxWidth: '100%' }}
            />
            {competitionLevel !== 0 && <Stack direction='row' spacing={1}>
              <TextField
                variant='standard'
                label='Qualifié à'
                value={competitionPlace}
                onChange={e => setCompetitionPlace(e.target.value)}
                error={Boolean(errors.competitionPlace)}
                helperText={errors.competitionPlace}
              />
              <TextField
                variant='standard'
                label='Année'
                type='number'
                value={competitionYear}
                onChange={e => setCompetitionYear(e.target.value)}
                error={Boolean(errors.competitionYear)}
                helperText={errors.competitionYear}
              />
            </Stack>}
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button size='large' onClick={back}>
          Précédent
        </Button>
        <Button size='large' color='primary' onClick={handleSubmit}>
          Valider
        </Button>
      </CardActions>
    </Card>
  )
}

ExperienceForm.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default ExperienceForm
