import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Cache from 'i18next-localstorage-cache'
import fr from './_constants/translationFRfr.json'
// import en from './_constants/translationENuk.json'


const resources = {
  fr: {
    translation: fr,
  },
  // en: {
  //   translation: en,
  // },
}

i18n
  .use(Backend)
  .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fr',
    debug: false,
    saveMissing: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
