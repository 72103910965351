import React from 'react'
import { AppBar, Typography, Grid } from '@mui/material'
import AircraftCard from '../dashboard/AircraftCard'
import MakeAnnouncementCard from './MakeAnnouncementCard'
import AdminQualificationCard from './AdminQualificationCard'
import Page from '../_library/Page'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const CARD_HEIGHT = 460
const containerStyle = {
  zIndex: 1,
  ml: { xs: 1, md: 'inherit' },
}
export const AdminDashboardPage = () => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  
  return (
    <Page withTopBarAndMenu>
      <AppBar component='div' color='primary' position='relative' elevation={0} sx={{ height: '40vh', zIndex: 0 }}>
        <Grid container sx={{ maxWidth: '936px', mx: 'auto', ml: { xs: 1, md: 'inherit' } }}>
          <Typography color='inherit' variant='h4' component='h1' ml={1}>
            Dashboard Admin
          </Typography>
        </Grid>
      </AppBar>
      <Grid container spacing={1} sx={{ minHeight: '426px', mx: 'auto', mt: '-30vh', maxWidth: '936px' }}>
        <Grid item md={12} sx={containerStyle}>
          <MakeAnnouncementCard />
        </Grid>
        <Grid item md={4} sx={containerStyle}>
          <AdminQualificationCard sx={{ height: CARD_HEIGHT }} />
        </Grid>
        {dbAircrafts?.map(aircraft =>
          <Grid item md={4} sx={containerStyle} key={aircraft.id}>
            <AircraftCard aircraft={aircraft} sx={{ height: 460 }} />
          </Grid>,
        )}
      </Grid>
    </Page>
  )
}

export default AdminDashboardPage
