import React from 'react'
import { Typography, Box } from '@mui/material'
import Avatar from '../_library/Avatar'
import useAuth from '../../hooks/useAuth'
import { useStorage } from '../../hooks/useStorage'


const InlineAvatarEditor = () => {
  
  const profile = useAuth().getProfile()
  const storage = useStorage('profile-pictures')
  
  const handleSubmit = event =>
    storage.uploadFileAction(
      event.target.files[0],
      `${profile.id}-${(new Date()).getMilliseconds()}.${event.target.files[0].name.split('.').pop()}`,
    )

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography gutterBottom lineHeight='20px' width={200} sx={{ color: 'rgba(0,0,0,.54)', display: 'inline-block' }}>
        Photo de profil
      </Typography>
      <Box
        component='label'
        htmlFor='file'
        sx={{
          cursor: 'pointer',
          position: 'relative',
          minWidth: 40,
        }}
      >
        <Avatar user={profile} />
      </Box>
      <Box
        component='input'
        type='file'
        name='file'
        id='file'
        onChange={handleSubmit}
        sx={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
    </Box>
  )
}

export default InlineAvatarEditor
