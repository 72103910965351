/* eslint-disable */
// figures19.js

/**** PATTERNS ****
 * +- entry exit
 * letters - name of figure
 * () middle roll
 * _ full roll
 * ^ half roll
 * & any roll is allowed
 * $ any roll or spin is allowed
 * ~ allow line length change without roll

 **** Instructions ****
 * + force positive attitude
 * - force negative attitude
 * ~ forward fly
 * ' 1/4-lengh forward fly
 * h add a hammerhead sign
 * t add a tailslide sign
 * T add a wheels-up tailslide sign
 * u pointed top (non-Aresti)
 * j a turn (jo=outside, jio=inside-outside, joi=outside-inside)
 * _ roll location
 * « hide small part of entry line and loop entry segment
 * » hide small part of loop exit and exit line segment

 change angle/draw arc:
 45d  90v 135z 180m  225c  270p  315r  360o
 D    V    Z    M     C     P   R     O

 letter!_  is a looping portion with a roll inside   eg o!_
 letter=   means to draw the figure "exact" with actual looping portion (no hard corners, see pp figure)
 letter/   means to draw the looping portion half-size (see b figure)
 */

// define figs variable
var figs = [

//
// Family 1: Lines and Angles
//

// Family 1.1. Single Lines
  "F1.1 Single Lines",

// 1.1.1.1 through 1.1.1.4 (was 1.1.1 through 1.1.4)
  "+_+ 1.1.1.1(2) ~_~",
  "-_- 1.1.1.2(3) ~_~",
//-_-   1.1.1(3) = -~_-~
  "+^- 1.1.1.3(2) ~_-~",
  "-^+ 1.1.1.4(2) ~_-~",
//-^+   1.1.1(2) = -~_~

// 1.1.2.1 through 1.1.2.4 (was 1.2.1 through 1.2.4)
  "+d_+ 1.1.2.1(7) ~d~_~D~",
  "-d_- 1.1.2.2(8) ~D~_~d~",
  "+id_+ 1.1.2.3(7) ~D~_~d~",
  "-id_- 1.1.2.4(8) ~d~_~D~",
//-d_-  1.1.2(8) = -~D~_~d~-
//+id_+  1.1.2(7) = ~D~_~d~
//-id_-  1.1.2(8) = -~d~_~D~-

// 1.1.3.1 through 1.1.3.4 (was 1.3.1 through 1.3.4)
  "+d^- 1.1.3.1(7) ~d~_~d~",
  "-d^+ 1.1.3.2(8) ~D~_~D~",
  "+id^- 1.1.3.3(8) ~D~_~D~",
  "-id^+ 1.1.3.4(7) ~d~_~d~",
//-d^+ 1.1.3(8) = -~D~_~D~
//+id^- 1.1.3(8) = ~D~_~D~-
//-id^+ 1.1.3(7) = -~d~_~d~

// 1.1.4.1 through 1.1.4.4 (was 1.4.1 through 1.4.4)
  "+d_- 1.1.4.1(9:0) ~d~_~z~",
  "-d_+ 1.1.4.2(11:0) ~D~_~Z~",
  "+id_- 1.1.4.3(10:0) ~D~_~Z~",
  "-id_+ 1.1.4.4(9:0) ~d~_~z~",
//-d_+ 1.1.4(11) = -~D~_~Z~
//+id_- 1.1.4(10) = ~D~_~Z~-
//-id_+ 1.1.4(9) = -~d~_~z~

// 1.1.5.1 through 1.1.5.4 (was 1.5.1 through 1.5.4)
  "+d^+ 1.1.5.1(10:0) ~d~_~Z~",
  "-d^- 1.1.5.2(10:0) ~D~_~z~",
  "+id^+ 1.1.5.3(9:0) ~D~_~z~",
  "-id^- 1.1.5.4(11:0) ~d~_~Z~",
//-d^- 1.1.5(10) = -~D~_~z~-
//+id^+ 1.1.5(9) = ~D~_~z~
//-id^- 1.1.5(11) = -~d~_~Z~-

// 1.1.6.1-4
  "+v&+ 1.1.6.1(10) ~v~_~V~",
  "-v&- 1.1.6.2(11) ~V~_~v~",
  "+iv$+ 1.1.6.3(10) ~V~_~v~",
  "-iv$- 1.1.6.4(10) ~v~_~V~",

// 1.1.7.1-4
  "+v&- 1.1.7.1(9) ~v~_~v~",
  "-v&+ 1.1.7.2(12) ~V~_~V~",
  "+iv$- 1.1.7.3(11) ~V~_~V~",
  "-iv$+ 1.1.7.4(9) ~v~_~v~",

// 1.1.8.1-4
  "+z_+ 1.1.8.1(12:0) ~''z~_~Z''~",
  "-z_- 1.1.8.2(12:0) ~''Z~_~z''~",
  "+iz_+ 1.1.8.3(12:0) ~''Z~_~z''~",
  "-iz_- 1.1.8.4(12:0) ~''z~_~Z''~",

// 1.1.9.1-4
  "+z^- 1.1.9.1(11:0) ~''z~_~z''~",
  "-z^+ 1.1.9.2(14:0) ~''Z~_~Z''~",
  "+iz^- 1.1.9.3(14:0) ~''Z~_~Z''~",
  "-iz^+ 1.1.9.4(11:0) ~''z~_~z''~",

// 1.1.10.1-4
  "+z_- 1.1.10.1(9:0) ~''z~_~d''~",
  "-z_+ 1.1.10.2(11:0) ~''Z~_~D''~",
  "+iz_- 1.1.10.3(11:0) ~''Z~_~D''~",
  "-iz_+ 1.1.10.4(8:0) ~''z~_~d''~",

// 1.1.11.1-4
  "+z^+ 1.1.11.1(9:0) ~''z~_~D''~",
  "-z^- 1.1.11.2(11:0) ~''Z~_~d''~",
  "+iz^+ 1.1.11.3(10:0) ~''Z~_~d''~",
  "-iz^- 1.1.11.4(10:0) ~''z~_~D''~",

// Family 1.2. Two Lines
  "F1.2 Two Lines",

// 1.2.1.1-4
  "+_t&+ 1.2.1.1(13) ~d~_~Z~_~v~",
  "-_t&- 1.2.1.2(14) ~D~_~z~_~V~",
  "+_it&+ 1.2.1.3(13:0) ~D~_~z~_~V~",
  "-_it&- 1.2.1.4(15:0) ~d~_~Z~_~v~",

// 1.2.2.1-4
  "+_t&- 1.2.2.1(14) ~d~_~Z~_~V~",
  "-_t&+ 1.2.2.2(13) ~D~_~z~_~v~",
  "+_it&- 1.2.2.3(12:0) ~D~_~z~_~v~",
  "-_it&+ 1.2.2.4(15:0) ~d~_~Z~_~V~",

// 1.2.3.1-4
  "+^t&+ 1.2.3.1(12) ~d~_~z~_~v~",
  "-^t&- 1.2.3.2(16) ~D~_~Z~_~V~",
  "+^it&+ 1.2.3.3(15) ~D~_~Z~_~V~",
  "-^it&- 1.2.3.4(12) ~d~_~z~_~v~",

// 1.2.4.1-4
  "+^t&- 1.2.4.1(13) ~d~_~z~_~V~",
  "-^t&+ 1.2.4.2(14) ~D~_~Z~_~v~",
  "+^it&- 1.2.4.3(14) ~D~_~Z~_~v~",
  "-^it&+ 1.2.4.4(13) ~d~_~z~_~V~",

// 1.2.5.1-4
  "+&k_- 1.2.5.1(14) ~v~''_~z~~_~~D~",
  "-&k_+ 1.2.5.2(16) ~V~''_~Z~~_~~d~",
  "+$ik_- 1.2.5.3(17:0) ~V~''_~Z~~_~~d~",
  "-$ik_+ 1.2.5.4(14:0) ~v~''_~z~~_~~D~",

// 1.2.6.1-4
  "+&k_+ 1.2.6.1(14) ~v~''_~Z~~_~~d~",
  "-&k_- 1.2.6.2(16) ~V~''_~z~~_~~D~",
  "+$ik_+ 1.2.6.3(15) ~V~''_~z~~_~~D~",
  "-$ik_- 1.2.6.4(15) ~v~''_~Z~~_~~d~",

// 1.2.7.1-4
  "+&k^+ 1.2.7.1(13) ~v~''_~z~~_~~d~",
  "-&k^- 1.2.7.2(17) ~V~''_~Z~~_~~D~",
  "+$ik^+ 1.2.7.3(16:0) ~V~''_~Z~~_~~D~",
  "-$ik^- 1.2.7.4(14) ~v~''_~z~~_~~d~",

// 1.2.8.1-4
  "+&k^- 1.2.8.1(16) ~v~''_~Z~~_~~D~",
  "-&k^+ 1.2.8.2(15) ~V~''_~z~~_~~d~",
  "+$ik^- 1.2.8.3(15) ~V~''_~z~~_~~d~",
  "-$ik^+ 1.2.8.4(15:0) ~v~''_~Z~~_~~D~",

// 1.2.9.1-4
  "+_zt&+ 1.2.9.1(15:0) ~~z~_~z~_~v~",
  "-_zt&- 1.2.9.2(20:0) ~~Z~_~Z~_~V~",
  "+_izt&+ 1.2.9.3(20:0) ~~Z~_~Z~_~V~",
  "-_izt&- 1.2.9.4(16:0) ~~z~_~z~_~v~",

// 1.2.10.1-4
  "+_zt&- 1.2.10.1(16:0) ~~z~_~z~_~V~",
  "-_zt&+ 1.2.10.2(19:0) ~~Z~_~Z~_~v~",
  "+_izt&- 1.2.10.3(21:0) ~~Z~_~Z~_~v~",
  "-_izt&+ 1.2.10.4(15:0) ~~z~_~z~_~V~",

// 1.2.11.1-4
  "+^zt&- 1.2.11.1(19:0) ~~z~_~Z~_~V~",
  "-^zt&+ 1.2.11.2(17:0) ~~Z~_~z~_~v~",
  "+^izt&- 1.2.11.3(17:0) ~~Z~_~z~_~v~",
  "-^izt&+ 1.2.11.4(19:0) ~~z~_~Z~_~V~",

// 1.2.12.1-4
  "+^zt&+ 1.2.12.1(18:0) ~~z~_~Z~_~v~",
  "-^zt&- 1.2.12.2(18:0) ~~Z~_~z~_~V~",
  "+^izt&+ 1.2.12.3(18:0) ~~Z~_~z~_~V~",
  "-^izt&- 1.2.12.4(18:0) ~~z~_~Z~_~v~",

// 1.2.13.1-4
  "+&kz_+ 1.2.13.1(16:0) ~v~_~z~_~z~~",
  "-&kz_- 1.2.13.2(20:0) ~V~_~Z~_~Z~~",
  "+$ikz_+ 1.2.13.3(20:0) ~V~_~Z~_~Z~~",
  "-$ikz_- 1.2.13.4(15:0) ~v~_~z~_~z~~",

// 1.2.14.1-4
  "+&kz_- 1.2.14.1(18:0) ~v~_~Z~_~Z~~",
  "-&kz_+ 1.2.14.2(18:0) ~V~_~z~_~z~~",
  "+$ikz_- 1.2.14.3(17:0) ~V~_~z~_~z~~",
  "-$ikz_+ 1.2.14.4(19:0) ~v~_~Z~_~Z~~",

// 1.2.15.1-4
  "+&kz^- 1.2.15.1(17:0) ~v~_~z~_~Z~~",
  "-&kz^+ 1.2.15.2(19:0) ~V~_~Z~_~z~~",
  "+$ikz^- 1.2.15.3(19:0) ~V~_~Z~_~z~~",
  "-$ikz^+ 1.2.15.4(19:0) ~v~_~z~_~Z~~",

// 1.2.16.1-4
  "+&kz^+ 1.2.16.1(17:0) ~v~_~Z~_~z~~",
  "-&kz^- 1.2.16.2(19:0) ~V~_~z~_~Z~~",
  "+$ikz^+ 1.2.16.3(18:0) ~V~_~z~_~Z~~",
  "-$ikz^- 1.2.16.4(17:0) ~v~_~Z~_~z~~",

// Family 1.3. Three lines
  "F1.3 Three Lines",

// 1.3.1.1-4
  "+_w(&)_- 1.3.1.1(22:0) ~d''_''~~Z~_~Z~~_~d~",
  "-_w(&)_+ 1.3.1.2(19) ~D''_''~~z~_~z~~_~D~",
  "+_iw(&)_- 1.3.1.3(19) ~D''_''~~z~_~z~~_~D~",
  "-_iw(&)_+ 1.3.1.4(22:0) ~d''_''~~Z~_~Z~~_~d~",

// 1.3.2.1-4
  "+^w(&)_+ 1.3.2.1(18) ~d''_''~~z~_~z~~_~D~",
  "-^w(&)_- 1.3.2.2(23:0) ~D''_''~~Z~_~Z~~_~d~",
  "+^iw(&)_+ 1.3.2.3(22:0) ~D''_''~~Z~_~Z~~_~d~",
  "-^iw(&)_- 1.3.2.4(19) ~d''_''~~z~_~z~~_~D~",

// 1.3.3.1-4
  "+_w(&)^+ 1.3.3.1(22:0) ~d''_''~~Z~_~Z~~_~D~",
  "-_w(&)^- 1.3.3.2(19) ~D''_''~~z~_~z~~_~d~",
  "+_iw(&)^+ 1.3.3.3(18) ~D''_''~~z~_~z~~_~d~",
  "-_iw(&)^- 1.3.3.4(23:0) ~d''_''~~Z~_~Z~~_~D~",

// 1.3.4.1-4
  "+^w(&)^- 1.3.4.1(18) ~d''_''~~z~_~z~~_~d~",
  "-^w(&)^+ 1.3.4.2(23:0) ~D''_''~~Z~_~Z~~_~D~",
  "+^iw(&)^- 1.3.4.3(23:0) ~D''_''~~Z~_~Z~~_~D~",
  "-^iw(&)^+ 1.3.4.4(18) ~d''_''~~z~_~z~~_~d~",

// 1.3.5.1-4
  "+_w(&)_+ 1.3.5.1(20) ~d''_''~~Z~_~z~~_~D~",
  "-_w(&)_- 1.3.5.2(21:0) ~D''_''~~z~_~Z~~_~d~",
  "+_iw(&)_+ 1.3.5.3(19) ~D''_''~~z~_~Z~~_~d~",
  "-_iw(&)_- 1.3.5.4(22:0) ~d''_''~~Z~_~z~~_~D~",

// 1.3.6.1-4
  "+^w(&)_- 1.3.6.1(20:0) ~d''_''~~z~_~Z~~_~d~",
  "-^w(&)_+ 1.3.6.2(21) ~D''_''~~Z~_~z~~_~D~",
  "+^iw(&)_- 1.3.6.3(21:0) ~D''_''~~Z~_~z~~_~D~",
  "-^iw(&)_+ 1.3.6.4(19) ~d''_''~~z~_~Z~~_~d~",

// 1.3.7.1-4
  "+_w(&)^- 1.3.7.1(20) ~d''_''~~Z~_~z~~_~d~",
  "-_w(&)^+ 1.3.7.2(21:0) ~D''_''~~z~_~Z~~_~D~",
  "+_iw(&)^- 1.3.7.3(20) ~D''_''~~z~_~Z~~_~D~",
  "-_iw(&)^+ 1.3.7.4(20:0) ~d''_''~~Z~_~z~~_~d~",

// 1.3.8.1-4
  "+^w(&)^+ 1.3.8.1(20:0) ~d''_''~~z~_~Z~~_~D~",
  "-^w(&)^- 1.3.8.2(21) ~D''_''~~Z~_~z~~_~d~",
  "+^iw(&)^+ 1.3.8.3(20:0) ~D''_''~~Z~_~z~~_~d~",
  "-^iw(&)^- 1.3.8.4(20) ~d''_''~~z~_~Z~~_~D~",

// 1.3.9.1-4
  "+&n(_)&+ 1.3.9.1(24:0) ~v~_''~z~~_~Z~''_~V~",
  "-&pn(_)&- 1.3.9.2(24:0) -~V~_''~Z~~_~z~''_~v~",
  "+$ipn(_)&+ 1.3.9.3(22:0) ~V~_''~Z~~_~z~''_~v~",
  "-$in(_)&- 1.3.9.4(23:0) -~v~_''~z~~_~Z~''_~V~",

// 1.3.10-1-4
  "+&n(_)&- 1.3.10.1(23:0) ~v~_''~z~~_~Z~''_~v~",
  "-&pn(_)&+ 1.3.10.2(24:0) -~V~_''~Z~~_~z~''_~V~",
  "+$ipn(_)&- 1.3.10.3(24:0) ~V~_''~Z~~_~z~''_~V~",
  "-$in(_)&+ 1.3.10.4(22:0) -~v~_''~z~~_~Z~''_~v~",

// 1.3.11.1-4
  "+&n(^)&- 1.3.11.1(20:0) ~v~_''~z~~_~z~''_~v~",
  "-&pn(^)&+ 1.3.11.2(27:0) -~V~_''~Z~~_~Z~''_~V~",
  "+$ipn(^)&- 1.3.11.3(26:0) ~V~_''~Z~~_~Z~''_~V~",
  "-$in(^)&+ 1.3.11.4(20:0) -~v~_''~z~~_~z~''_~v~",

// 1.3.12.1-4
  "+&n(^)&+ 1.3.12.1(21:0) ~v~_''~z~~_~z~''_~V~",
  "-&pn(^)&- 1.3.12.2(26:0) -~V~_''~Z~~_~Z~''_~v~",
  "+$ipn(^)&+ 1.3.12.3(25:0) ~V~_''~Z~~_~Z~''_~v~",
  "-$in(^)&- 1.3.12.4(21:0) -~v~_''~z~~_~z~''_~V~",

// 1.3.13-1.4
  "+&pn(_)&+ 1.3.13.1(22:0) ~v~_''~Z~~_~z~''_~V~",
  "-&n(_)&- 1.3.13.2(25:0) -~V~_''~z~~_~Z~''_~v~",
  "+$in(_)&+ 1.3.13.3(23:0) ~V~_''~z~~_~Z~''_~v~",
  "-$ipn(_)&- 1.3.13.4(23:0) -~v~_''~Z~~_~z~''_~V~",

// 1.3.14.1-4
  "+&pn(_)&- 1.3.14.1(22:0) ~v~_''~Z~~_~z~''_~v~",
  "-&n(_)&+ 1.3.14.2(25:0) -~V~_''~z~~_~Z~''_~V~",
  "+$in(_)&- 1.3.14.3(24:0) ~V~_''~z~~_~Z~''_~V~",
  "-$ipn(_)&+ 1.3.14.4(22:0) -~v~_''~Z~~_~z~''_~v~",

// 1.3.15.1-4
  "+&pn(^)&- 1.3.15.1(24:0) ~v~_''~Z~~_~Z~''_~v~",
  "-&n(^)&+ 1.3.15.2(23:0) -~V~_''~z~~_~z~''_~V~",
  "+$in(^)&- 1.3.15.3(22:0) ~V~_''~z~~_~z~''_~V~",
  "-$ipn(^)&+ 1.3.15.4(23:0) -~v~_''~Z~~_~Z~''_~v~",

// 1.3.16.1-4
  "+&pn(^)&+ 1.3.16.1(25:0) ~v~_''~Z~~_~Z~''_~V~",
  "-&n(^)&- 1.3.16.2(22:0) -~V~_''~z~~_~z~''_~v~",
  "+$in(^)&+ 1.3.16.3(21:0) ~V~_''~z~~_~z~''_~v~",
  "-$ipn(^)&- 1.3.16.4(25:0) -~v~_''~Z~~_~Z~''_~V~",

//
// Family 2. Turns and Rolling Turns
//

// Family 2.1. 90 degree turns
  "F2.1 90 degree Turns",

// 2.1.1.1-2 (90 degree turn, no roll)
// can be written as j or 1j
  "+j+ 2.1.1.1(3) ~j1~",
  "-j- 2.1.1.2(4) ~J1~",
  "+1j+ 2.1.1.1(3) ~j1~",
  "-1j- 2.1.1.2(4) ~J1~",

// 2.1.2.1-4 (90 degree turn, 1/2 roll)
  "+1j5- 2.1.2.1(14:19) ~''j15''~",
  "-1j5+ 2.1.2.2(14:19) ~''J15''~",
  "+1jo5- 2.1.2.3(15:21) ~''J15''~",
  "-1jo5+ 2.1.2.4(15:21) ~''j15''~",

// 2.1.3.1-4 (90 degree turn, 1 roll)
  "+1j1+ 2.1.3.1(14:19) ~''j11''~",
  "-1j1- 2.1.3.2(15:20) ~''J11''~",
  "+1jo1+ 2.1.3.3(15:21) ~''J11''~",
  "-1jo1- 2.1.3.4(16:22) ~''j11''~",

// Family 2.2. 180 degree turns
  "F2.2 180 degree Turns",

// 2.2.1.1-2 (180 degree turn, no roll)
  "+2j+ 2.2.1.1(4) ~j2~",
  "-2j- 2.2.1.2(5) ~J2~",

// 2.2.2.1-4 (180 degree turn, one roll)
  "+2j1+ 2.2.2.1(26:36) ~''j21''~",
  "-2j1- 2.2.2.2(27:37) ~''J21''~",
  "+2jo1+ 2.2.2.3(28:40) ~''J21''~",
  "-2jo1- 2.2.2.4(29:41) ~''j21''~",

// 2.2.3.1-4 (180 degree turn, 1.5 roll)
  "+2j15- 2.2.3.1(24:31) ~''j215''~",
  "-2j15+ 2.2.3.2(24:31) ~''J215''~",
  "+2jo15- 2.2.3.3(26:35) ~''J215''~",
  "-2jo15+ 2.2.3.4(26:35) ~''j215''~",

// 2.2.4.1-4 (180 degree turn, 1 followed by opposite 0.5 roll)
  "+2jio15- 2.2.4.1(26:37) ~''jio215''~",
  "-2jio15+ 2.2.4.2(26:37) ~''JIO215''~",
  "+2joi15- 2.2.4.3(27:39) ~''JIO215''~",
  "-2joi15+ 2.2.4.4(27:39) ~''jio215''~",

// 2.2.5.1-4 (180 degree turn, 2 rolls)
  "+2j2+ 2.2.5.1(22:30) ~''j22''~",
  "-2j2- 2.2.5.2(23:31) ~''J22''~",
  "+2jo2+ 2.2.5.3(24:34) ~''J22''~",
  "-2jo2- 2.2.5.4(25:35) ~''j22''~",

// 2.2.6.1-4 (180 degree turn, 1 followed by opposite 1 roll)
  "+2jio2+ 2.2.6.1(25:37) ~''jio22''~",
  "-2jio2- 2.2.6.2(26:38) ~''JIO22''~",
  "+2joi2+ 2.2.6.3(25:37) ~''JIO22''~",
  "-2joi2- 2.2.6.4(26:38) ~''jio22''~",

// 2.2.7.1-4 (180 degree turn, 0.5 followed by 1 opposite roll)
  "+2jio51- 2.2.7.1(27:40) ~''jio251''~",
  "-2jio51+ 2.2.7.2(27:40) ~''JIO251''~",
  "+2joi51- 2.2.7.3(26:39) ~''JIO251''~",
  "-2joi51+ 2.2.7.4(26:39) ~''jio251''~",

// Family 2.3. 270 degree turns
  "F2.3 270 degree Turns",

// 2.3.1.1-2: (270 degree turn, no roll)
  "+3j+ 2.3.1.1(5) ~''j3''~",
  "-3j- 2.3.1.2(7) ~''J3''~",

// 2.3.2.1-4: (270 degree turn, 1.5 roll)
  "+3j15- 2.3.2.1(34:47) ~~j315~~",
  "-3j15+ 2.3.2.2(34:47) ~~J315~~",
  "+3jo15- 2.3.2.3(37:53) ~~J315~~",
  "-3jo15+ 2.3.2.4(37:53) ~~j315~~",

// 2.3.3.1-4: (270 degree turn, 1.5 roll in/out)
  "+3jio15- 2.3.3.1(37:54) ~~jio315~~",
  "-3jio15+ 2.3.3.2(37:54) ~~JIO315~~",
  "+3joi15- 2.3.3.3(38:56) ~~JIO315~~",
  "-3joi15+ 2.3.3.4(38:56) ~~jio315~~",

// 2.3.4.1-4: (270 degree turn, 3 rolls)
  "+3j3+ 2.3.4.1(30:41) ~~j33~~",
  "-3j3- 2.3.4.2(31:42) ~~J33~~",
  "+3jo3+ 2.3.4.3(33:47) ~~J33~~",
  "-3jo3- 2.3.4.4(34:48) ~~j33~~",

// 2.3.5.1-4: (270 degree turn, 3 rolls in/out/in)
  "+3jio3+ 2.3.5.1(35:53) ~~jio33~~",
  "-3jio3- 2.3.5.2(36:54) ~~JIO33~~",
  "+3joi3+ 2.3.5.3(36:55) ~~JIO33~~",
  "-3joi3- 2.3.5.4(37:56) ~~jio33~~",

// 2.3.6.1-4: (270 degree turn, 0.5 roll followed by 1 opposite roll)
// CHECK
  "+3jio51- 2.3.6.1(38:0) ~~jio351~~",
  "-3jio51+ 2.3.6.2(38:0) ~~JIO351~~",
  "+3joi51- 2.3.6.3(37:0) ~~JIO351~~",
  "-3joi51+ 2.3.6.4(37:0) ~~jio351~~",

// Family 2.4. 360 degree turns
  "F2.4 360 degree Turns",

// 2.4.1.1-2
  "+4j+ 2.4.1.1(6) ~j4~",
  "-4j- 2.4.1.2(8) ~J4~",

// 2.4.2.1-4 (1 roll)
  "+4j1+ 2.4.2.1(46:0) ~''j41''~",
  "-4j1- 2.4.2.2(47:0) ~''J41''~",
  "+4jo1+ 2.4.2.3(50:0) ~''J41''~",
  "-4jo1- 2.4.2.4(51:0) ~''j41''~",

// 2.4.3.1-4 (2 rolls)
  "+4j2+ 2.4.3.1(42:58) ~''j42''~",
  "-4j2- 2.4.3.2(43:59) ~''J42''~",
  "+4jo2+ 2.4.3.3(46:66) ~''J42''~",
  "-4jo2- 2.4.3.4(47:67) ~''j42''~",

// 2.4.4.1-4 (2 rolls in/out)
  "+4jio2+ 2.4.4.1(46:67) ~''jio42''~",
  "-4jio2- 2.4.4.2(47:68) ~''JIO42''~",
  "+4joi2+ 2.4.4.3(46:67) ~''JIO42''~",
  "-4joi2- 2.4.4.4(47:68) ~''jio42''~",

// 2.4.5.1-4 (3 rolls)
  "+4j3+ 2.4.5.1(39:52) ~''j43''~",
  "-4j3- 2.4.5.2(40:53) ~''J43''~",
  "+4jo3+ 2.4.5.3(43:60) ~''J43''~",
  "-4jo3- 2.4.5.4(44:61) ~''j43''~",

// 2.4.6.1-4 (3 rolls in/out/in)
  "+4jio3+ 2.4.6.1(45:65) ~''jio43''~",
  "-4jio3- 2.4.6.2(45:65) ~''JIO43''~",
  "+4joi3+ 2.4.6.3(46:67) ~''JIO43''~",
  "-4joi3- 2.4.6.4(47:68) ~''jio43''~",

// 2.4.7.1-4 (4 rolls)
  "+4j4+ 2.4.7.1(38:52) ~''j44''~",
  "-4j4- 2.4.7.2(39:53) ~''J44''~",
  "+4jo4+ 2.4.7.3(42:60) ~''J44''~",
  "-4jo4- 2.4.7.4(43:61) ~''j44''~",

// 2.4.8.1-4 (4 rolls in/out/in/out)
  "+4jio4+ 2.4.8.1(46:71) ~''jio44''~",
  "-4jio4- 2.4.8.2(47:72) ~''JIO44''~",
  "+4joi4+ 2.4.8.3(46:71) ~''JIO44''~",
  "-4joi4- 2.4.8.4(47:72) ~''jio44''~",


//
// Family 3. Combinations of lines
//

// Family 3.3 Three Corners
  "F3 Combinations of Lines",

// 3.3.1.1-4
  "+dz- 3.3.1.1(9) ~d~v~d~",
  "-dz+ 3.3.1.2(11) ~D~V~D~",
  "+idz- 3.3.1.3(11) ~D~V~D~",
  "-idz+ 3.3.1.4(9) ~d~v~d~",

// Family 3.4 Four Corners

// 3.4.1.1-4
  "+dvz- 3.4.1.1(10:0) ~d~d~d~d~",
  "-dvz+ 3.4.1.2(13:0) ~D~D~D~D~",
  "+idvz- 3.4.1.3(12:0) ~D~D~D~D~",
  "-idvz+ 3.4.1.4(10:0) ~d~d~d~d~",

// 3.4.2.1-4
  "+dvd+ 3.4.2.1(11:0) ~d~d~D~D~",
  "-dvd- 3.4.2.2(12:0) ~D~D~d~d~",
  "+idvd+ 3.4.2.3(11:0) ~D~D~d~d~",
  "-idvd- 3.4.2.4(12:0) ~d~d~D~D~",

// Family 3.8 Eight Corners

// 3.8.1.1-4
  "+rqq- 3.8.1.1(22:0) ~d~d~D~D~D~D~D~D~",
  "-rqq+ 3.8.1.2(23:0) ~D~D~d~d~d~d~d~d~",
  "+irqq- 3.8.1.3(22:0) ~D~D~d~d~d~d~d~d~",
  "-irqq+ 3.8.1.4(23:0) ~d~d~D~D~D~D~D~D~",

//
// Family 5. Stall Turns
//

// Family 5.2. Two Line Stall Turns
  "F5.2 Two line Stall Turns",

// 5.2.1.1-4
  "+&h&+ 5.2.1.1(17) ~v~_~h~_''v~",
  "-&h&- 5.2.1.2(23) ~V~_~H~_''V~",
  "+&h&- 5.2.1.3(18) ~v~_~h~_''V~",
  "-&h&+ 5.2.1.4(22) ~V~_~H~_''v~",

// Family 5.3. Three Line Stall Turns
  "F5.3 Three line Stall Turns",

// 5.3.1.1-4
  "+_dh(&)&+ 5.3.1.1(18:0) ~d~_~d~_~h~_''v~",
  "-_dh(&)&- 5.3.1.2(25:0) ~D~_~D~_~H~_''V~",
  "+_dh(&)&- 5.3.1.3(20:0) ~d~_~d~_~h~_''V~",
  "-_dh(&)&+ 5.3.1.4(23:0) ~D~_~D~_~H~_''v~",

// 5.3.2.1-4
  "+^dh(&)&+ 5.3.2.1(24:0) ~d~_~D~_~h~_''v~",
  "-^dh(&)&- 5.3.2.2(22:0) ~D~_~d~_~H~_''V~",
  "+^dh(&)&- 5.3.2.3(25:0) ~d~_~D~_~h~_''V~",
  "-^dh(&)&+ 5.3.2.4(21:0) ~D~_~d~_~H~_''v~",

// 5.3.3.1-4
  "+&hd(&)_+ 5.3.3.1(18:0) ~v~_~h~_''d~_~d~",
  "-&hd(&)_- 5.3.3.2(24:0) ~V~_~H~_''D~_~D~",
  "+&hd(&)_- 5.3.3.3(20:0) ~v~_~h~_''D~_~D~",
  "-&hd(&)_+ 5.3.3.4(23:0) ~V~_~H~_''d~_~d~",

// 5.3.4.1-4
  "+&hd(&)^+ 5.3.4.1(20:0) ~v~_~h~_''D~_~d~",
  "-&hd(&)^- 5.3.4.2(25:0) ~V~_~H~_''d~_~D~",
  "+&hd(&)^- 5.3.4.3(20:0) ~v~_~h~_''d~_~D~",
  "-&hd(&)^+ 5.3.4.4(25:0) ~V~_~H~_''D~_~d~",

// Family 5.4. Four Line Stall Turns
  "F5.4 Four line Stall Turns",

// 5.4.1.1-4
  "+_dhd(&)(&)_+ 5.4.1.1(19:0) ~d~_~d~_~h~_''d~_~d~",
  "-_dhd(&)(&)_- 5.4.1.2(26:0) ~D~_~D~_~H~_''D~_~D~",
  "+_dhd(&)(&)_- 5.4.1.3(21:0) ~d~_~d~_~h~_''D~_~D~",
  "-_dhd(&)(&)_+ 5.4.1.4(24:0) ~D~_~D~_~H~_''d~_~d~",

// 5.4.2.1-4
  "+_dhd(&)(&)^+ 5.4.2.1(21:0) ~d~_~d~_~h~_''D~_~d~",
  "-_dhd(&)(&)^- 5.4.2.2(27:0) ~D~_~D~_~H~_''d~_~D~",
  "+_dhd(&)(&)^- 5.4.2.3(22:0) ~d~_~d~_~h~_''d~_~D~",
  "-_dhd(&)(&)^+ 5.4.2.4(26:0) ~D~_~D~_~H~_''D~_~d~",

// 5.4.3.1-4
  "+^dhd(&)(&)_+ 5.4.3.1(25:0) ~d~_~D~_~h~_''d~_~d~",
  "-^dhd(&)(&)_- 5.4.3.2(23:0) ~D~_~d~_~H~_''D~_~D~",
  "+^dhd(&)(&)_- 5.4.3.3(26:0) ~d~_~D~_~h~_''D~_~D~",
  "-^dhd(&)(&)_+ 5.4.3.4(22:0) ~D~_~d~_~H~_''d~_~d~",

// 5.4.4.1-4
  "+^dhd(&)(&)^+ 5.4.4.1(27:0) ~d~_~D~_~h~_''D~_~d~",
  "-^dhd(&)(&)^- 5.4.4.2(24:0) ~D~_~d~_~H~_''d~_~D~",
  "+^dhd(&)(&)^- 5.4.4.3(27:0) ~d~_~D~_~h~_''d~_~D~",
  "-^dhd(&)(&)^+ 5.4.4.4(24:0) ~D~_~d~_~H~_''D~_~d~",

//
// Family 6. Tail Slides
//
  "F6.2 Tail Slides",

// Family 6.2. Two Line Tail Slides

// 6.2.1.1-4
  "+&ta&+ 6.2.1.1(15:17) ~~v''_''~t~~_''v~~",
  "-&ta&- 6.2.1.2(18:23) ~~V''_''~t~~_''V~~",
  "+&ta&- 6.2.1.3(16:18) ~~v''_''~t~~_''V~~",
  "-&ta&+ 6.2.1.4(17:22) ~~V''_''~t~~_''v~~",

// 6.2.2.1-4
  "+&ita&+ 6.2.2.1(15:17) ~~v''_''~T~~_''v~~",
  "-&ita&- 6.2.2.2(18:23) ~~V''_''~T~~_''V~~",
  "+&ita&- 6.2.2.3(16:18) ~~v''_''~T~~_''V~~",
  "-&ita&+ 6.2.2.4(17:22) ~~V''_''~T~~_''v~~",


//
// Family 7. Loops and Eights
//

// Family 7.2. Half Loops
  "F7.2 Half Loops",

// 7.2.1.1-4
  "+_m_- 7.2.1.1(6) ~_m_~",
  "-_m_+ 7.2.1.2(8) ~_M_~",
  "+_a_- 7.2.1.3(8) ~_M_~",
  "-_a_+ 7.2.1.4(6) ~_m_~",

// 7.2.2.1-4
  "+_m^+ 7.2.2.1(6) ~_m_~",
  "-_m^- 7.2.2.2(9) ~_M_~",
  "+_a^+ 7.2.2.3(8) ~_M_~",
  "-_a^- 7.2.2.4(7) ~_m_~",

// 7.2.3.1-4
  "+^m_+ 7.2.3.1(8) ~_M_~",
  "-^m_- 7.2.3.2(7) ~_m_~",
  "+^a_+ 7.2.3.3(6) ~_m_~",
  "-^a_- 7.2.3.4(9) ~_M_~",

// 7.2.4.1-4
  "+^m^- 7.2.4.1(8) ~_M_~",
  "-^m^+ 7.2.4.2(6) ~_m_~",
  "+^a^- 7.2.4.3(6) ~_m_~",
  "-^a^+ 7.2.4.4(8) ~_M_~",

// Family 7.3. Three-Quarter Loops
  "F7.3 Three-Quarter Loops",

// 7.3.1.1-4
  "+_g_- 7.3.1.1(16) ~d~~_''P~~_''d~",
  "-_g_+ 7.3.1.2(14) ~D~~_''p~~_''D~",
  "+_ig_- 7.3.1.3(14) ~D~~_''p~~_''D~",
  "-_ig_+ 7.3.1.4(16) ~d~~_''P~~_''d~",

// 7.3.2.1-4
  "+^g_+ 7.3.2.1(14) ~d~~_''p~~_''D~",
  "-^g_- 7.3.2.2(18) ~D~~_''P~~_''d~",
  "+^ig_+ 7.3.2.3(17) ~D~~_''P~~_''d~",
  "-^ig_- 7.3.2.4(15) ~d~~_''p~~_''D~",

// 7.3.3.1-4
  "+_g^+ 7.3.3.1(17) ~d~~_''P~~_''D~",
  "-_g^- 7.3.3.2(15) ~D~~_''p~~_''d~",
  "+_ig^+ 7.3.3.3(14) ~D~~_''p~~_''d~",
  "-_ig^- 7.3.3.4(18) ~d~~_''P~~_''D~",

// 7.3.4.1-4
  "+^g^- 7.3.4.1(16) ~d~~_''p~~_''d~",
  "-^g^+ 7.3.4.2(20) ~D~~_''P~~_''D~",
  "+^ig^- 7.3.4.3(19) ~D~~_''P~~_''D~",
  "-^ig^+ 7.3.4.4(15) ~d~~_''p~~_''d~",

// Family 7.4. Whole Loops
  "F7.4 Whole Loops",

// 7.4.1.1-4
  "+o_+ 7.4.1.1(10) ~''o!_''~",
  "-o_- 7.4.1.2(15) ~''O!_''~",
  "+io_+ 7.4.1.3(14) ~''O!_''~",
  "-io_- 7.4.1.4(11) ~''o!_''~",

// 7.4.2.1-4
  "+o^- 7.4.2.1(12) ~''o!_''~",
  "-o^+ 7.4.2.2(12) ~''O!_''~",
  "+io^- 7.4.2.3(12) ~''O!_''~",
  "-io^+ 7.4.2.4(12) ~''o!_''~",

// 7.4.3.1-4
  "+qo_+ 7.4.3.1(14) ~~~v~~v~_~v~~v~~~",
  "-qo_- 7.4.3.2(19) ~~~V~~V~_~V~~V~~~",
  "+iqo_+ 7.4.3.3(18) ~~~V~~V~_~V~~V~~~",
  "-iqo_- 7.4.3.4(14) ~~~v~~v~_~v~~v~~~",

// 7.4.4.1-4
  "+qo^- 7.4.4.1(17) ~~~v~~v~_~V~~''V~~~",
  "-qo^+ 7.4.4.2(17) ~~~V~~V~_~v~~''v~~~",
  "+iqo^- 7.4.4.3(17) ~~~V~~V~_~v~~''v~~~",
  "-iqo^+ 7.4.4.4(17) ~~~v~~v~_~V~~''V~~~",

// 7.4.5.1-4
  "+_dq_+ 7.4.5.1(15) ~~d~_~v~~v~_~v~~d~~",
  "-_dq_- 7.4.5.2(20) ~~D~_~V~~V~_~V~~D~~",
  "+_idq_+ 7.4.5.3(19) ~~D~_~V~~V~_~V~~D~~",
  "-_idq_- 7.4.5.4(16) ~~d~_~v~~v~_~v~~d~~",

// 7.4.6.1-4
  "+qq+ 7.4.6.1(19) ~~d~d~d~d~d~d~d~d~~",
  "-qq- 7.4.6.2(24) ~~D~D~D~D~D~D~D~D~~",
  "+iqq+ 7.4.6.3(24:0) ~~D~D~D~D~D~D~D~D~~",
  "-iqq- 7.4.6.4(19:0) ~~d~d~d~d~d~d~d~d~~",

// Family 7.4. (cont) Reversing Whole Loops
// New for 2012 - coded by Wouter Liefting
// coded as "ao" (Alternating O) when the reverse is after 3/4 loop,
// and as "rao" (Rev Alternating O) when the reverse is after 1/4 loop.
  "F7.4 Reversing Whole Loops",

// 7.4.7.1-4
  "+_ao(_)_- 7.4.7.1(11) ~_v=m!_V=_~",
  "-_ao(_)_+ 7.4.7.2(13) ~_V=M!_v=_~",
  "+_iao(_)_- 7.4.7.3(13) ~_V=M!_v=_~",
  "-_iao(_)_+ 7.4.7.4(11) ~_v=m!_V=_~",

// 7.4.8.1-4
  "+_ao(_)^+ 7.4.8.1(11) ~_v=m!_V=_~",
  "-_ao(_)^- 7.4.8.2(14) ~_V=M!_v=_~",
  "+_iao(_)^+ 7.4.8.3(13) ~_V=M!_v=_~",
  "-_iao(_)^- 7.4.8.4(12) ~_v=m!_V=_~",

// 7.4.9.1-4
  "+^ao(_)_+ 7.4.9.1(13) ~_V=M!_v=_~",
  "-^ao(_)_- 7.4.9.2(12) ~_v=m!_V=_~",
  "+^iao(_)_+ 7.4.9.3(11) ~_v=m!_V=_~",
  "-^iao(_)_- 7.4.9.4(14) ~_V=M!_v=_~",

// 7.4.10.1-4
  "+^ao(_)^- 7.4.10.1(13) ~_V=M!_v=_~",
  "-^ao(_)^+ 7.4.10.2(11) ~_v=m!_V=_~",
  "+^iao(_)^- 7.4.10.3(11) ~_v=m!_V=_~",
  "-^iao(_)^+ 7.4.10.4(13) ~_V=M!_v=_~",

// 7.4.11.1-4
  "+_rao(_)_- 7.4.11.1(13) ~_v=M!_V=_~",
  "-_rao(_)_+ 7.4.11.2(11) ~_V=m!_v=_~",
  "+_irao(_)_- 7.4.11.3(11) ~_V=m!_v=_~",
  "-_irao(_)_+ 7.4.11.4(13) ~_v=M!_V=_~",

// 7.4.12.1-4
  "+_rao(_)^+ 7.4.12.1(13) ~_v=M!_V=_~",
  "-_rao(_)^- 7.4.12.2(12) ~_V=m!_v=_~",
  "+_irao(_)^+ 7.4.12.3(11) ~_V=m!_v=_~",
  "-_irao(_)^- 7.4.12.4(14) ~_v=M!_V=_~",

// 7.4.13.1-4
  "+^rao(_)_- 7.4.13.1(11) ~_V=m!_v=_~",
  "-^rao(_)_+ 7.4.13.2(14) ~_v=M!_V=_~",
  "+^irao(_)_- 7.4.13.3(13) ~_v=M!_V=_~",
  "-^irao(_)_+ 7.4.13.4(12) ~_V=m!_v=_~",

// 7.4.14.1-4
  "+^rao(_)^- 7.4.14.1(11) ~_V=m!_v=_~",
  "-^rao(_)^+ 7.4.14.2(13) ~_v=M!_V=_~",
  "+^irao(_)^- 7.4.14.3(13) ~_v=M!_V=_~",
  "-^irao(_)^+ 7.4.14.4(11) ~_V=m!_v=_~",

// PREVIOUS CODING KEPT FOR BACKWARD COMPATIBILITY
// Family 7.4. (cont) Reversing Whole Loops
// New for 2012 - coded by Wouter Liefting
// coded as "or" when the reverse is after 3/4 loop,
// and as "ro" when the reverse is after 1/4 loop.

// 7.4.7.1-4
  "+_or(_)_- 7.4.7.1(11) ~_v=m!_V=_~",
  "-_or(_)_+ 7.4.7.2(13) ~_V=M!_v=_~",
  "+_ior(_)_- 7.4.7.3(13) ~_V=M!_v=_~",
  "-_ior(_)_+ 7.4.7.4(11) ~_v=m!_V=_~",

// 7.4.8.1-4
  "+_or(_)^+ 7.4.8.1(11) ~_v=m!_V=_~",
  "-_or(_)^- 7.4.8.2(14) ~_V=M!_v=_~",
  "+_ior(_)^+ 7.4.8.3(13) ~_V=M!_v=_~",
  "-_ior(_)^- 7.4.8.4(12) ~_v=m!_V=_~",

// 7.4.9.1-4
  "+^or(_)_+ 7.4.9.1(13) ~_V=M!_v=_~",
  "-^or(_)_- 7.4.9.2(12) ~_v=m!_V=_~",
  "+^ior(_)_+ 7.4.9.3(11) ~_v=m!_V=_~",
  "-^ior(_)_- 7.4.9.4(14) ~_V=M!_v=_~",

// 7.4.10.1-4
  "+^or(_)^- 7.4.10.1(13) ~_V=M!_v=_~",
  "-^or(_)^+ 7.4.10.2(11) ~_v=m!_V=_~",
  "+^ior(_)^- 7.4.10.3(11) ~_v=m!_V=_~",
  "-^ior(_)^+ 7.4.10.4(13) ~_V=M!_v=_~",

// 7.4.11.1-4
  "+_ro(_)_- 7.4.11.1(13) ~_v=M!_V=_~",
  "-_ro(_)_+ 7.4.11.2(11) ~_V=m!_v=_~",
  "+_iro(_)_- 7.4.11.3(11) ~_V=m!_v=_~",
  "-_iro(_)_+ 7.4.11.4(13) ~_v=M!_V=_~",

// 7.4.12.1-4
  "+_ro(_)^+ 7.4.12.1(13) ~_v=M!_V=_~",
  "-_ro(_)^- 7.4.12.2(12) ~_V=m!_v=_~",
  "+_iro(_)^+ 7.4.12.3(11) ~_V=m!_v=_~",
  "-_iro(_)^- 7.4.12.4(14) ~_v=M!_V=_~",

// 7.4.13.1-4
  "+^ro(_)_- 7.4.13.1(11) ~_V=m!_v=_~",
  "-^ro(_)_+ 7.4.13.2(14) ~_v=M!_V=_~",
  "+^iro(_)_- 7.4.13.3(13) ~_v=M!_V=_~",
  "-^iro(_)_+ 7.4.13.4(12) ~_V=m!_v=_~",

// 7.4.14.1-4
  "+^ro(_)^- 7.4.14.1(11) ~_V=m!_v=_~",
  "-^ro(_)^+ 7.4.14.2(13) ~_v=M!_V=_~",
  "+^iro(_)^- 7.4.14.3(13) ~_v=M!_V=_~",
  "-^iro(_)^+ 7.4.14.4(11) ~_V=m!_v=_~",

// Family 7.5. Horizontal Ss
// New for 2012 - coded by Wouter Liefting
// coded as "ac" - for Alternating Cuban
  "F7.5 Horizontal \"S\"s",

// 7.5.1.1-4
  "+_ac(_)_+ 7.5.1.1(16) ~~~~_c'''_'''C_~~~~",
  "-_ac(_)_- 7.5.1.2(16) ~~~~_C'''_'''c_~~~~",
  "+_iac(_)_+ 7.5.1.3(16) ~~~~_C'''_'''c_~~~~",
  "-_iac(_)_- 7.5.1.4(16) ~~~~_c'''_'''C_~~~~",

// 7.5.2.1-4
  "+_ac(^)_- 7.5.2.1(15) ~~~~_c'''_'''c_~~~~",
  "-_ac(^)_+ 7.5.2.2(20) ~~~~_C'''_'''C_~~~~",
  "+_iac(^)_- 7.5.2.3(20) ~~~~_C'''_'''C_~~~~",
  "-_iac(^)_+ 7.5.2.4(15) ~~~~_c'''_'''c_~~~~",

// 7.5.3.1-4
  "+^ac(_)_- 7.5.3.1(16) ~~~~_C'''_'''c_~~~~",
  "-^ac(_)_+ 7.5.3.2(16) ~~~~_c'''_'''C_~~~~",
  "+^iac(_)_- 7.5.3.3(16) ~~~~_c'''_'''C_~~~~",
  "-^iac(_)_+ 7.5.3.4(16) ~~~~_C'''_'''c_~~~~",

// 7.5.4.1-4
  "+_ac(_)^- 7.5.4.1(16) ~~~~_c'''_'''C_~~~~",
  "-_ac(_)^+ 7.5.4.2(16) ~~~~_C'''_'''c_~~~~",
  "+_iac(_)^- 7.5.4.3(16) ~~~~_C'''_'''c_~~~~",
  "-_iac(_)^+ 7.5.4.4(16) ~~~~_c'''_'''C_~~~~",

// 7.5.5.1-4
  "+^ac(^)_+ 7.5.5.1(20) ~~~~_C'''_'''C_~~~~",
  "-^ac(^)_- 7.5.5.2(15) ~~~~_c'''_'''c_~~~~",
  "+^iac(^)_+ 7.5.5.3(15) ~~~~_c'''_'''c_~~~~",
  "-^iac(^)_- 7.5.5.4(20) ~~~~_C'''_'''C_~~~~",

// 7.5.6.1-4
  "+^ac(_)^+ 7.5.6.1(16) ~~~~_C'''_'''c_~~~~",
  "-^ac(_)^- 7.5.6.2(17) ~~~~_c'''_'''C_~~~~",
  "+^iac(_)^+ 7.5.6.3(16) ~~~~_c'''_'''C_~~~~",
  "-^iac(_)^- 7.5.6.4(17) ~~~~_C'''_'''c_~~~~",

// 7.5.7.1-4
  "+_ac(^)^+ 7.5.7.1(15) ~~~~_c'''_'''c_~~~~",
  "-_ac(^)^- 7.5.7.2(20) ~~~~_C'''_'''C_~~~~",
  "+_iac(^)^+ 7.5.7.3(20) ~~~~_C'''_'''C_~~~~",
  "-_iac(^)^- 7.5.7.4(15) ~~~~_c'''_'''c_~~~~",

// 7.5.8.1-4
  "+^ac(^)^- 7.5.8.1(20) ~~~~_C'''_'''C_~~~~",
  "-^ac(^)^+ 7.5.8.2(15) ~~~~_c'''_'''c_~~~~",
  "+^iac(^)^- 7.5.8.3(15) ~~~~_c'''_'''c_~~~~",
  "-^iac(^)^+ 7.5.8.4(20) ~~~~_C'''_'''C_~~~~",

// PREVIOUS CODING KEPT FOR BACKWARD COMPATIBILITY
// Family 7.5. Horizontal Ss
// New for 2012 - coded by Wouter Liefting
// coded as "cm" - for Cuban iMmelmann

// 7.5.1.1-4
  "+_cm(_)_+ 7.5.1.1(16) ~~~~_c'''_'''C_~~~~",
  "-_cm(_)_- 7.5.1.2(16) ~~~~_C'''_'''c_~~~~",
  "+_icm(_)_+ 7.5.1.3(16) ~~~~_C'''_'''c_~~~~",
  "-_icm(_)_- 7.5.1.4(16) ~~~~_c'''_'''C_~~~~",

// 7.5.2.1-4
  "+_cm(^)_- 7.5.2.1(15) ~~~~_c'''_'''c_~~~~",
  "-_cm(^)_+ 7.5.2.2(20) ~~~~_C'''_'''C_~~~~",
  "+_icm(^)_- 7.5.2.3(20) ~~~~_C'''_'''C_~~~~",
  "-_icm(^)_+ 7.5.2.4(15) ~~~~_c'''_'''c_~~~~",

// 7.5.3.1-4
  "+^cm(_)_- 7.5.3.1(16) ~~~~_C'''_'''c_~~~~",
  "-^cm(_)_+ 7.5.3.2(16) ~~~~_c'''_'''C_~~~~",
  "+^icm(_)_- 7.5.3.3(16) ~~~~_c'''_'''C_~~~~",
  "-^icm(_)_+ 7.5.3.4(16) ~~~~_C'''_'''c_~~~~",

// 7.5.4.1-4
  "+_cm(_)^- 7.5.4.1(16) ~~~~_c'''_'''C_~~~~",
  "-_cm(_)^+ 7.5.4.2(16) ~~~~_C'''_'''c_~~~~",
  "+_icm(_)^- 7.5.4.3(16) ~~~~_C'''_'''c_~~~~",
  "-_icm(_)^+ 7.5.4.4(16) ~~~~_c'''_'''C_~~~~",

// 7.5.5.1-4
  "+^cm(^)_+ 7.5.5.1(20) ~~~~_C'''_'''C_~~~~",
  "-^cm(^)_- 7.5.5.2(15) ~~~~_c'''_'''c_~~~~",
  "+^icm(^)_+ 7.5.5.3(15) ~~~~_c'''_'''c_~~~~",
  "-^icm(^)_- 7.5.5.4(20) ~~~~_C'''_'''C_~~~~",

// 7.5.6.1-4
  "+^cm(_)^+ 7.5.6.1(16) ~~~~_C'''_'''c_~~~~",
  "-^cm(_)^- 7.5.6.2(17) ~~~~_c'''_'''C_~~~~",
  "+^icm(_)^+ 7.5.6.3(16) ~~~~_c'''_'''C_~~~~",
  "-^icm(_)^- 7.5.6.4(17) ~~~~_C'''_'''c_~~~~",

// 7.5.7.1-4
  "+_cm(^)^+ 7.5.7.1(15) ~~~~_c'''_'''c_~~~~",
  "-_cm(^)^- 7.5.7.2(20) ~~~~_C'''_'''C_~~~~",
  "+_icm(^)^+ 7.5.7.3(20) ~~~~_C'''_'''C_~~~~",
  "-_icm(^)^- 7.5.7.4(15) ~~~~_c'''_'''c_~~~~",

// 7.5.8.1-4
  "+^cm(^)^- 7.5.8.1(20) ~~~~_C'''_'''C_~~~~",
  "-^cm(^)^+ 7.5.8.2(15) ~~~~_c'''_'''c_~~~~",
  "+^icm(^)^- 7.5.8.3(15) ~~~~_c'''_'''c_~~~~",
  "-^icm(^)^+ 7.5.8.4(20) ~~~~_C'''_'''C_~~~~",


// Family 7.5. (cont) Vertical Ss
  "F7.5 Vertical \"S\"s",

// 7.5.9.1-4
  "+mm+ 7.5.9.1(12:0) ~~mM~~",
  "-mm- 7.5.9.2(13:0) ~~Mm~~",
  "+imm+ 7.5.9.3(12:0) ~~Mm~~",
  "-imm- 7.5.9.4(13:0) ~~mM~~",

// 7.5.10.1-4
  "+mm^- 7.5.10.1(10:0) ~~m_m~~",
  "-mm^+ 7.5.10.2(14:0) ~~M_M~~",
  "+imm^- 7.5.10.3(14:0) ~~M_M~~",
  "-imm^+ 7.5.10.4(10:0) ~~m_m~~",

// Family 7.8. Horizontal 8s
  "F7.8 Horizontal \"8\"s",

// 7.8.1.1-4
  "+_cc(_)_+ 7.8.1.1(20) ~~~~_c''_''~P''_~~''d~~",
  "-_cc(_)_- 7.8.1.2(20) ~~~~_C''_''~p''_~~''D~~",
  "+_icc(_)_+ 7.8.1.3(20) ~~~~_C''_''~p''_~~''D~~",
  "-_icc(_)_- 7.8.1.4(20) ~~~~_c''_''~P''_~~''d~~",

// 7.8.2.1-4
  "+_cc(_)^- 7.8.2.1(22) ~~~~_c''_''~P''_~~''D~~",
  "-_cc(_)^+ 7.8.2.2(20) ~~~~_C''_''~p''_~~''d~~",
  "+_icc(_)^- 7.8.2.3(21) ~~~~_C''_''~p''_~~''d~~",
  "-_icc(_)^+ 7.8.2.4(22) ~~~~_c''_''~P''_~~''D~~",

// 7.8.3.1-4
  "+_cc(^)_- 7.8.3.1(19) ~~~~_c''_''~p''_~~''D~~",
  "-_cc(^)_+ 7.8.3.2(23) ~~~~_C''_''~P''_~~''d~~",
  "+_icc(^)_- 7.8.3.3(24) ~~~~_C''_''~P''_~~''d~~",
  "-_icc(^)_+ 7.8.3.4(19) ~~~~_c''_''~p''_~~''D~~",

// 7.8.4.1-4
  "+_cc(^)^+ 7.8.4.1(19) ~~~~_c''_''~p''_~~''d~~",
  "-_cc(^)^- 7.8.4.2(26) ~~~~_C''_''~P''_~~''D~~",
  "+_icc(^)^+ 7.8.4.3(25) ~~~~_C''_''~P''_~~''D~~",
  "-_icc(^)^- 7.8.4.4(20) ~~~~_c''_''~p''_~~''d~~",

// 7.8.5.1-4
  "+_rcc(_)_+ 7.8.5.1(20) ~~d''''_~~P''_''~c_~~~~",
  "-_rcc(_)_- 7.8.5.2(20) ~~D''''_~~p''_''~C_~~~~",
  "+_ircc(_)_+ 7.8.5.3(20) ~~D''''_~~p''_''~C_~~~~",
  "-_ircc(_)_- 7.8.5.4(20) ~~d''''_~~P''_''~c_~~~~",

// 7.8.6.1-4
  "+_rcc(^)_- 7.8.6.1(24) ~~d''''_~~P''_''~C_~~~~",
  "-_rcc(^)_+ 7.8.6.2(19) ~~D''''_~~p''_''~c_~~~~",
  "+_ircc(^)_- 7.8.6.3(18) ~~D''''_~~p''_''~c_~~~~",
  "-_ircc(^)_+ 7.8.6.4(24) ~~d''''_~~P''_''~C_~~~~",

// 7.8.7.1-4
  "+^rcc(_)_- 7.8.7.1(21) ~~d''''_~~p''_''~C_~~~~",
  "-^rcc(_)_+ 7.8.7.2(22) ~~D''''_~~P''_''~c_~~~~",
  "+^ircc(_)_- 7.8.7.3(21) ~~D''''_~~P''_''~c_~~~~",
  "-^ircc(_)_+ 7.8.7.4(21) ~~d''''_~~p''_''~C_~~~~",

// 7.8.8.1-4
  "+^rcc(^)_+ 7.8.8.1(19) ~~d''''_~~p''_''~c_~~~~",
  "-^rcc(^)_- 7.8.8.2(26) ~~D''''_~~P''_''~C_~~~~",
  "+^ircc(^)_+ 7.8.8.3(25) ~~D''''_~~P''_''~C_~~~~",
  "-^ircc(^)_- 7.8.8.4(20) ~~d''''_~~p''_''~c_~~~~",

// Family 7.8. (cont) Horizontal Super 8s
  "F7.8 Horizontal Super \"8\"s",

// 7.8.9.1-4
  "+_gg(_)_+ 7.8.9.1(23) ~~d''_''~P~''_''~p~~''_''D~~",
  "-_gg(_)_- 7.8.9.2(24) ~~D''_''~p~''_''~P~~''_''d~~",
  "+_igg(_)_+ 7.8.9.3(23) ~~D''_''~p~''_''~P~~''_''d~~",
  "-_igg(_)_- 7.8.9.4(24) ~~d''_''~P~''_''~p~~''_''D~~",

// 7.8.10.1-4
  "+^gg(_)_- 7.8.10.1(25) ~~d''_''~p~''_''~P~~''_''d~~",
  "-^gg(_)_+ 7.8.10.2(26) ~~D''_''~P~''_''~p~~''_''D~~",
  "+^igg(_)_- 7.8.10.3(25) ~~D''_''~P~''_''~p~~''_''D~~",
  "-^igg(_)_+ 7.8.10.4(24) ~~d''_''~p~''_''~P~~''_''d~~",

// 7.8.11.1-4
  "+_gg(^)_- 7.8.11.1(28) ~~d''_''~P~''_''~P~~''_''d~~",
  "-_gg(^)_+ 7.8.11.2(23) ~~D''_''~p~''_''~p~~''_''D~~",
  "+_igg(^)_- 7.8.11.3(22) ~~D''_''~p~''_''~p~~''_''D~~",
  "-_igg(^)_+ 7.8.11.4(27) ~~d''_''~P~''_''~P~~''_''d~~",

// 7.8.12.1-4
  "+_gg(_)^- 7.8.12.1(25) ~~d''_''~P~''_''~p~~''_''d~~",
  "-_gg(_)^+ 7.8.12.2(26) ~~D''_''~p~''_''~P~~''_''D~~",
  "+_igg(_)^- 7.8.12.3(25) ~~D''_''~p~''_''~P~~''_''D~~",
  "-_igg(_)^+ 7.8.12.4(24) ~~d''_''~P~''_''~p~~''_''d~~",

// 7.8.13.1-4
  "+^gg(^)_+ 7.8.13.1(23) ~~d''_''~p~''_''~p~~''_''D~~",
  "-^gg(^)_- 7.8.13.2(30) ~~D''_''~P~''_''~P~~''_''d~~",
  "+^igg(^)_+ 7.8.13.3(29) ~~D''_''~P~''_''~P~~''_''d~~",
  "-^igg(^)_- 7.8.13.4(24) ~~d''_''~p~''_''~p~~''_''D~~",

// 7.8.14.1-4
  "+^gg(_)^+ 7.8.14.1(26) ~~d''_''~p~''_''~P~~''_''D~~",
  "-^gg(_)^- 7.8.14.2(27) ~~D''_''~P~''_''~p~~''_''d~~",
  "+^igg(_)^+ 7.8.14.3(26) ~~D''_''~P~''_''~p~~''_''d~~",
  "-^igg(_)^- 7.8.14.4(27) ~~d''_''~p~''_''~P~~''_''D~~",

// 7.8.15.1-4
  "+_gg(^)^+ 7.8.15.1(29) ~~d''_''~P~''_''~P~~''_''D~~",
  "-_gg(^)^- 7.8.15.2(24) ~~D''_''~p~''_''~p~~''_''d~~",
  "+_igg(^)^+ 7.8.15.3(23) ~~D''_''~p~''_''~p~~''_''d~~",
  "-_igg(^)^- 7.8.15.4(30) ~~d''_''~P~''_''~P~~''_''D~~",

// 7.8.16.1-4
  "+^gg(^)^- 7.8.16.1(25) ~~d''_''~p~''_''~p~~''_''d~~",
  "-^gg(^)^+ 7.8.16.2(31) ~~D''_''~P~''_''~P~~''_''D~~",
  "+^igg(^)^- 7.8.16.3(31) ~~D''_''~P~''_''~P~~''_''D~~",
  "-^igg(^)^+ 7.8.16.4(24) ~~d''_''~p~''_''~p~~''_''d~~",

// Family 7.8. (cont) Vertical 8s
  "F7.8 Vertical \"8\"s",

// 7.8.17.1-4
  "+oo+ 7.8.17.1(22:0) ~mOm~",
  "-oo- 7.8.17.2(23:0) ~MoM~",
  "+ioo+ 7.8.17.3(22:0) ~MoM~",
  "-ioo- 7.8.17.4(23:0) ~mOm~",

// 7.8.18.1-4
  "+^oo- 7.8.18.1(20:0) ~m_oM~",
  "-^oo+ 7.8.18.2(24:0) ~M_Om~",
  "+^ioo- 7.8.18.3(24:0) ~M_Om~",
  "-^ioo+ 7.8.18.4(20:0) ~m_oM~",

// 7.8.19.1-4
  "+oo^- 7.8.19.1(24:0) ~mO_M~",
  "-oo^+ 7.8.19.2(20:0) ~Mo_m~",
  "+ioo^- 7.8.19.3(20:0) ~Mo_m~",
  "-ioo^+ 7.8.19.4(24:0) ~mO_M~",

// 7.8.20.1-4
  "+^oo^+ 7.8.20.1(18:0) ~m_o_m~",
  "-^oo^- 7.8.20.2(27:0) ~M_O_M~",
  "+^ioo^+ 7.8.20.3(26:0) ~M_O_M~",
  "-^ioo^- 7.8.20.4(19:0) ~m_o_m~",

// 7.8.21.1-4
  "+ooo+ 7.8.21.1(22:0) ~o«O»~",
  "-ooo- 7.8.21.2(23:0) ~O«o»~",
  "+iooo+ 7.8.21.3(22:0) ~O«o»~",
  "-iooo- 7.8.21.4(23:0) ~o«O»~",

// 7.8.22.1-4
  "+ooo^- 7.8.22.1(18:0) ~o«_o»~",
  "-ooo^+ 7.8.22.2(26:0) ~O«_O»~",
  "+iooo^- 7.8.22.3(26:0) ~O«_O»~",
  "-iooo^+ 7.8.22.4(18:0) ~o«_o»~",

//
// Family 8. Combinations of lines, angles and loops
//

// Family 8.4. Humpty Bumps
  "F8.4 Humpty Bumps",

// 8.4.1.1-4
  "+&b&+ 8.4.1.1(13) ~''v~''_''m/~''_~v''~",
  "-&pb&- 8.4.1.2(18) -~''V~''_''M/~''_~V''~",
  "+$ipb&+ 8.4.1.3(17) ~''V~''_''M/~''_~V''~",
  "-$ib&- 8.4.1.4(13) -~''v~''_''m/~''_~v''~",

// 8.4.2.1-4
  "+&b&- 8.4.2.1(14) ~''v~''_''m/~''_~V''~",
  "-&pb&+ 8.4.2.2(17) -~''V~''_''M/~''_~v''~",
  "+$ipb&- 8.4.2.3(17) ~''V~''_''M/~''_~v''~",
  "-$ib&+ 8.4.2.4(14) -~''v~''_''m/~''_~V''~",

// 8.4.3.1-4
  "+&pb&+ 8.4.3.1(15) ~''v~''_''M/~''_~v''~",
  "-&b&- 8.4.3.2(16) -~''V~''_''m/~''_~V''~",
  "+$ib&+ 8.4.3.3(15) ~''V~''_''m/~''_~V''~",
  "-$ipb&- 8.4.3.4(16) -~''v~''_''M/~''_~v''~",

// 8.4.4.1-4
  "+&pb&- 8.4.4.1(16) ~''v~''_''M/~''_~V''~",
  "-&b&+ 8.4.4.2(14) -~''V~''_''m/~''_~v''~",
  "+$ib&- 8.4.4.3(14) ~''V~''_''m/~''_~v''~",
  "-$ipb&+ 8.4.4.4(16) -~''v~''_''M/~''_~V''~",

// Family 8.4. (cont) Diagonal Humpty Bumps
  "F8.4 Diagonal Humpty Bumps",

// 8.4.5.1-4
  "+_bz_+ 8.4.5.1(13:0) ~~~d~''_''m~''_~~z~~~~",
  "-_bz_- 8.4.5.2(17:0) ~~~D~''_''M~''_~~Z~~~~",
  "+_ibz_+ 8.4.5.3(17:0) ~~~D~''_''M~''_~~Z~~~~",
  "-_ibz_- 8.4.5.4(13:0) ~~~d~''_''m~''_~~z~~~~",

// 8.4.6.1-4
  "+_bz^- 8.4.6.1(15:0) ~~~d~''_''m~''_~~Z~~~~",
  "-_bz^+ 8.4.6.2(17:0) ~~~D~''_''M~''_~~z~~~~",
  "+_ibz^- 8.4.6.3(17:0) ~~~D~''_''M~''_~~z~~~~",
  "-_ibz^+ 8.4.6.4(16:0) ~~~d~''_''m~''_~~Z~~~~",

// 8.4.7.1-4
  "+^rbz_- 8.4.7.1(16:0) ~d~~_''m''_''~Z~~",
  "-^rbz_+ 8.4.7.2(17:0) ~D~~_''M''_''~z~~",
  "+^irbz_- 8.4.7.3(16:0) ~D~~_''M''_''~z~~",
  "-^irbz_+ 8.4.7.4(16:0) ~d~~_''m''_''~Z~~",

// 8.4.8.1-4
  "+^rbz^+ 8.4.8.1(15:0) ~d~~_''m''_''~z~~",
  "-^rbz^- 8.4.8.2(20:0) ~D~~_''M''_''~Z~~",
  "+^irbz^+ 8.4.8.3(19:0) ~D~~_''M''_''~Z~~",
  "-^irbz^- 8.4.8.4(16:0) ~d~~_''m''_''~z~~",

// 8.4.9.1-4
  "+_rbz_+ 8.4.9.1(15:0) ~d~~_''M''_''~z~~",
  "-_rbz_- 8.4.9.2(15:0) ~D~~_''m''_''~Z~~",
  "+_irbz_+ 8.4.9.3(15:0) ~D~~_''m''_''~Z~~",
  "-_irbz_- 8.4.9.4(15:0) ~d~~_''M''_''~z~~",

// 8.4.10.1-4
  "+_rbz^- 8.4.10.1(17:0) ~d~~_''M''_''~Z~~",
  "-_rbz^+ 8.4.10.2(15:0) ~D~~_''m''_''~z~~",
  "+_irbz^- 8.4.10.3(15:0) ~D~~_''m''_''~z~~",
  "-_irbz^+ 8.4.10.4(18:0) ~d~~_''M''_''~Z~~",

// 8.4.11.1-4
  "+^bz_- 8.4.11.1(18:0) ~~~d~''_''M~''_~~Z~~~~",
  "-^bz_+ 8.4.11.2(15:0) ~~~D~''_''m~''_~~z~~~~",
  "+^ibz_- 8.4.11.3(14:0) ~~~D~''_''m~''_~~z~~~~",
  "-^ibz_+ 8.4.11.4(18:0) ~~~d~''_''M~''_~~Z~~~~",

// 8.4.12.1-4
  "+^bz^+ 8.4.12.1(17:0) ~~~d~''_''M~''_~~z~~~~",
  "-^bz^- 8.4.12.2(18:0) ~~~D~''_''m~''_~~Z~~~~",
  "+^ibz^+ 8.4.12.3(17:0) ~~~D~''_''m~''_~~Z~~~~",
  "-^ibz^- 8.4.12.4(18:0) ~~~d~''_''M~''_~~z~~~~",

// 8.4.13.1-4
  "+_db_- 8.4.13.1(11) ~~~d~''_''m~''_''D~",
  "-_db_+ 8.4.13.2(13) ~~~D~''_''M~''_''d~",
  "+_idb_- 8.4.13.3(13) ~~~D~''_''M~''_''d~",
  "-_idb_+ 8.4.13.4(11) ~~~d~''_''m~''_''D~",

// 8.4.14.1-4
  "+_db^+ 8.4.14.1(12) ~~~d~''_''m~''_''d~",
  "-_db^- 8.4.14.2(16) ~~~D~''_''M~''_''D~",
  "+_idb^+ 8.4.14.3(15) ~~~D~''_''M~''_''D~",
  "-_idb^- 8.4.14.4(13) ~~~d~''_''m~''_''d~",

// 8.4.15.1-4
  "+^rdb_+ 8.4.15.1(12) ~d~''_''m~''_''d~~~",
  "-^rdb_- 8.4.15.2(16) ~D~''_''M~''_''D~~~",
  "+^irdb_+ 8.4.15.3(15) ~D~''_''M~''_''D~~~",
  "-^irdb_- 8.4.15.4(13) ~d~''_''m~''_''d~~~",

// 8.4.16.1-4
  "+^rdb^- 8.4.16.1(14) ~d~''_''m~''_''D~~~",
  "-^rdb^+ 8.4.16.2(16) ~D~''_''M~''_''d~~~",
  "+^irdb^- 8.4.16.3(16) ~D~''_''M~''_''d~~~",
  "-^irdb^+ 8.4.16.4(14) ~d~''_''m~''_''D~~~",

// 8.4.17.1-4
  "+_rdb_- 8.4.17.1(13) ~d~''_''M~''_''D~~~",
  "-_rdb_+ 8.4.17.2(11) ~D~''_''m~''_''d~~~",
  "+_irdb_- 8.4.17.3(11) ~D~''_''m~''_''d~~~",
  "-_irdb_+ 8.4.17.4(13) ~d~''_''M~''_''D~~~",

// 8.4.18.1-4
  "+_rdb^+ 8.4.18.1(14) ~d~''_''M~''_''d~~~",
  "-_rdb^- 8.4.18.2(14) ~D~''_''m~''_''D~~~",
  "+_irdb^+ 8.4.18.3(13) ~D~''_''m~''_''D~~~",
  "-_irdb^- 8.4.18.4(15) ~d~''_''M~''_''d~~~",

// 8.4.19.1-4
  "+^db_+ 8.4.19.1(14) ~~~d~''_''M~''_''d~",
  "-^db_- 8.4.19.2(14) ~~~D~''_''m~''_''D~",
  "+^idb_+ 8.4.19.3(13) ~~~D~''_''m~''_''D~",
  "-^idb_- 8.4.19.4(15) ~~~d~''_''M~''_''d~",

// 8.4.20.1-4
  "+^db^- 8.4.20.1(16) ~~~d~''_''M~''_''D~",
  "-^db^+ 8.4.20.2(14) ~~~D~''_''m~''_''d~",
  "+^idb^- 8.4.20.3(14) ~~~D~''_''m~''_''d~",
  "-^idb^+ 8.4.20.4(16) ~~~d~''_''M~''_''D~",

// 8.4.21.1-4
  "+_zb_+ 8.4.21.1(13:0) ~~~~z~''_''~m''_''~d''~",
  "-_zb_- 8.4.21.2(17:0) ~~~~Z~''_''~M''_''~D''~",
  "+_izb_+ 8.4.21.3(17:0) ~~~~Z~''_''~M''_''~D''~",
  "-_izb_- 8.4.21.4(13:0) ~~~~z~''_''~m''_''~d''~",

// 8.4.22.1-4
  "+_zb^- 8.4.22.1(15:0) ~~~~z~''_''~m''_''~D''~",
  "-_zb^+ 8.4.22.2(17:0) ~~~~Z~''_''~M''_''~d''~",
  "+_izb^- 8.4.22.3(18:0) ~~~~Z~''_''~M''_''~d''~",
  "-_izb^+ 8.4.22.4(15:0) ~~~~z~''_''~m''_''~D''~",

// 8.4.23.1-4
  "+^rzb_- 8.4.23.1(15:0) ~~z~''_''m~''_''~D''",
  "-^rzb_+ 8.4.23.2(18:0) ~~Z~''_''M~''_''~d''",
  "+^irzb_- 8.4.23.3(18:0) ~~Z~''_''M~''_''~d''",
  "-^irzb_+ 8.4.23.4(14:0) ~~z~''_''m~''_''~D''",

// 8.4.24.1-4
  "+^rzb^+ 8.4.24.1(15:0) ~~z~''_''m~''_''~d''",
  "-^rzb^- 8.4.24.2(20:0) ~~Z~''_''M~''_''~D''",
  "+^irzb^+ 8.4.24.3(19:0) ~~Z~''_''M~''_''~D''",
  "-^irzb^- 8.4.24.4(16:0) ~~z~''_''m~''_''~d''",

// 8.4.25.1-4
  "+_rzb_+ 8.4.25.1(15:0) ~~z~''_''M~''_''~d''",
  "-_rzb_- 8.4.25.2(15:0) ~~Z~''_''m~''_''~D''",
  "+_irzb_+ 8.4.25.3(15:0) ~~Z~''_''m~''_''~D''",
  "-_irzb_- 8.4.25.4(15:0) ~~z~''_''M~''_''~d''",

// 8.4.26.1-4
  "+_rzb^- 8.4.26.1(17:0) ~~z~''_''M~''_''~D''",
  "-_rzb^+ 8.4.26.2(15:0) ~~Z~''_''m~''_''~d''",
  "+_irzb^- 8.4.26.3(16:0) ~~Z~''_''m~''_''~d''",
  "-_irzb^+ 8.4.26.4(17:0) ~~z~''_''M~''_''~D''",

// 8.4.27.1-4
  "+^zb_- 8.4.27.1(17:0) ~~~~z~''_''~M''_''~D''~",
  "-^zb_+ 8.4.27.2(16:0) ~~~~Z~''_''~m''_''~d''~",
  "+^izb_- 8.4.27.3(16:0) ~~~~Z~''_''~m''_''~d''~",
  "-^izb_+ 8.4.27.4(16:0) ~~~~z~''_''~M''_''~D''~",

// 8.4.28.1-4
  "+^zb^+ 8.4.28.1(17:0) ~~~~z~''_''~M''_''~d''~",
  "-^zb^- 8.4.28.2(18:0) ~~~~Z~''_''~m''_''~D''~",
  "+^izb^+ 8.4.28.3(17:0) ~~~~Z~''_''~m''_''~D''~",
  "-^izb^- 8.4.28.4(18:0) ~~~~z~''_''~M''_''~d''~",

// Family 8.5. Half Cubans
  "F8.5 Half Cubans",

// 8.5.1.1-4
  "+_rc_- 8.5.1.1(12) ~d~~''_~C_''~~~",
  "-_rc_+ 8.5.1.2(10) ~D~~''_~c_''~~~",
  "+_irc_- 8.5.1.3(10) ~D~~''_~c_''~~~",
  "-_irc_+ 8.5.1.4(12) ~d~~''_~C_''~~~",

// 8.5.2.1-4
  "+^rc_+ 8.5.2.1(10) ~d~~''_~c_''~~~",
  "-^rc_- 8.5.2.2(14) ~D~~''_~C_''~~~",
  "+^irc_+ 8.5.2.3(13) ~D~~''_~C_''~~~",
  "-^irc_- 8.5.2.4(11) ~d~~''_~c_''~~~",

// 8.5.3.1-4
  "+_rc^+ 8.5.3.1(12) ~d~~''_~C_''~~~",
  "-_rc^- 8.5.3.2(11) ~D~~''_~c_''~~~",
  "+_irc^+ 8.5.3.3(10) ~D~~''_~c_''~~~",
  "-_irc^- 8.5.3.4(13) ~d~~''_~C_''~~~",

// 8.5.4.1-4
  "+^rc^- 8.5.4.1(11) ~d~~''_~c_''~~~",
  "-^rc^+ 8.5.4.2(14) ~D~~''_~C_''~~~",
  "+^irc^- 8.5.4.3(14) ~D~~''_~C_''~~~",
  "-^irc^+ 8.5.4.4(11) ~d~~''_~c_''~~~",

// 8.5.5.1-4
  "+_c_- 8.5.5.1(10) ~~~''_c'''_'~~D~",
  "-_c_+ 8.5.5.2(12) ~~~''_C'''_'~~d~",
  "+_ic_- 8.5.5.3(12) ~~~''_C'''_'~~d~",
  "-_ic_+ 8.5.5.4(10) ~~~''_c'''_'~~D~",

// 8.5.6.1-4
  "+_c^+ 8.5.6.1(10) ~~~''_c'''_'~~d~",
  "-_c^- 8.5.6.2(14) ~~~''_C'''_'~~D~",
  "+_ic^+ 8.5.6.3(14) ~~~''_C'''_'~~D~",
  "-_ic^- 8.5.6.4(11) ~~~''_c'''_'~~d~",

// 8.5.7.1-4
  "+^c_+ 8.5.7.1(12) ~~~''_C'''_'~~d~",
  "-^c_- 8.5.7.2(11) ~~~''_c'''_'~~D~",
  "+^ic_+ 8.5.7.3(10) ~~~''_c'''_'~~D~",
  "-^ic_- 8.5.7.4(13) ~~~''_C'''_'~~d~",

// 8.5.8.1-4
  "+^c^- 8.5.8.1(14) ~~~''_C'''_'~~D~",
  "-^c^+ 8.5.8.2(11) ~~~''_c'''_'~~d~",
  "+^ic^- 8.5.8.3(11) ~~~''_c'''_'~~d~",
  "-^ic^+ 8.5.8.4(14) ~~~''_C'''_'~~D~",

// Family 8.5. (cont) Vertical 5/8th Loops
  "F8.5 Vertical 5/8ths Loops",

// 8.5.9.1-4
  "+_y&+ 8.5.9.1(12) ~d~''_''c~''_''~v~",
  "-_y&- 8.5.9.2(18) ~D~''_''C~''_''~V~",
  "+_iy&+ 8.5.9.3(17) ~D~''_''C~''_''~V~",
  "-_iy&- 8.5.9.4(13) ~d~''_''c~''_''~v~",

// 8.5.10.1-4
  "+_y&- 8.5.10.1(14) ~d~''_''c~''_''~V~",
  "-_y&+ 8.5.10.2(16) ~D~''_''C~''_''~v~",
  "+_iy&- 8.5.10.3(16) ~D~''_''C~''_''~v~",
  "-_iy&+ 8.5.10.4(14) ~d~''_''c~''_''~V~",

// 8.5.11.1-4
  "+^y&+ 8.5.11.1(17) ~d~''_''C~''_''~v~",
  "-^y&- 8.5.11.2(16) ~D~''_''c~''_''~V~",
  "+^iy&+ 8.5.11.3(15) ~D~''_''c~''_''~V~",
  "-^iy&- 8.5.11.4(17) ~d~''_''C~''_''~v~",

// 8.5.12.1-4
  "+^y&- 8.5.12.1(18) ~d~''_''C~''_''~V~",
  "-^y&+ 8.5.12.2(15) ~D~''_''c~''_''~v~",
  "+^iy&- 8.5.12.3(15) ~D~''_''c~''_''~v~",
  "-^iy&+ 8.5.12.4(18) ~d~''_''C~''_''~V~",

// 8.5.13.1-4
  "+_zy&+ 8.5.13.1(17:0) ~z~''_''C~_~~v~",
  "-_zy&- 8.5.13.2(17:0) ~Z~''_''c~_~~V~",
  "+_izy&+ 8.5.13.3(17:0) ~Z~''_''c~_~~V~",
  "-_izy&- 8.5.13.4(18:0) ~z~''_''C~_~~v~",

// 8.5.14.1-4
  "+_zy&- 8.5.14.1(19:0) ~z~''_''C~_~~V~",
  "-_zy&+ 8.5.14.2(16:0) ~Z~''_''c~_~~v~",
  "+_izy&- 8.5.14.3(17:0) ~Z~''_''c~_~~v~",
  "-_izy&+ 8.5.14.4(19:0) ~z~''_''C~_~~V~",

// 8.5.15.1-4
  "+^zy&+ 8.5.15.1(16:0) ~z~''_''c~_~~v~",
  "-^zy&- 8.5.15.2(22:0) ~Z~''_''C~_~~V~",
  "+^izy&+ 8.5.15.3(21:0) ~Z~''_''C~_~~V~",
  "-^izy&- 8.5.15.4(16:0) ~z~''_''c~_~~v~",

// 8.5.16.1-4
  "+^zy&- 8.5.16.1(17:0) ~z~''_''c~_~~V~",
  "-^zy&+ 8.5.16.2(20:0) ~Z~''_''C~_~~v~",
  "+^izy&- 8.5.16.3(20:0) ~Z~''_''C~_~~v~",
  "-^izy&+ 8.5.16.4(17:0) ~z~''_''c~_~~V~",

// 8.5.17.1-4
  "+&ry_+ 8.5.17.1(12) ~~v~~_~c''_''~d~",
  "-&ry_- 8.5.17.2(18) ~~V~~_~C''_''~D~",
  "+$iry_+ 8.5.17.3(17) ~~V~~_~C''_''~D~",
  "-$iry_- 8.5.17.4(13) ~~v~~_~c''_''~d~",

// 8.5.18.1-4
  "+&ry^- 8.5.18.1(15) ~~v~~_~c''_''~D~",
  "-&ry^+ 8.5.18.2(18) ~~V~~_~C''_''~d~",
  "+$iry^- 8.5.18.3(18) ~~V~~_~C''_''~d~",
  "-$iry^+ 8.5.18.4(15) ~~v~~_~c''_''~D~",

// 8.5.19.1-4
  "+&ry_- 8.5.19.1(16) ~~v~~_~C''_''~D~",
  "-&ry_+ 8.5.19.2(14) ~~V~~_~c''_''~d~",
  "+$iry_- 8.5.19.3(14) ~~V~~_~c''_''~d~",
  "-$iry_+ 8.5.19.4(16) ~~v~~_~C''_''~D~",

// 8.5.20.1-4
  "+&ry^+ 8.5.20.1(16) ~~v~~_~C''_''~d~",
  "-&ry^- 8.5.20.2(16) ~~V~~_~c''_''~D~",
  "+$iry^+ 8.5.20.3(16) ~~V~~_~c''_''~D~",
  "-$iry^- 8.5.20.4(17) ~~v~~_~C''_''~d~",

// 8.5.21.1-4
  "+&ryz_- 8.5.21.1(16:0) ~~v~~_~c~_~Z~",
  "-&ryz_+ 8.5.21.2(19:0) ~~V~~_~C~_~z~",
  "+$iryz_- 8.5.21.3(18:0) ~~V~~_~C~_~z~",
  "-$iryz_+ 8.5.21.4(16:0) ~~v~~_~c~_~Z~",

// 8.5.22.1-4
  "+&ryz^+ 8.5.22.1(16:0) ~~v~~_~c~_~z~",
  "-&ryz^- 8.5.22.2(22:0) ~~V~~_~C~_~Z~",
  "+$iryz^+ 8.5.22.3(21:0) ~~V~~_~C~_~Z~",
  "-$iryz^- 8.5.22.4(16:0) ~~v~~_~c~_~z~",

// 8.5.23.1-4
  "+&ryz_+ 8.5.23.1(17:0) ~~v~~_~C~_~z~",
  "-&ryz_- 8.5.23.2(18:0) ~~V~~_~c~_~Z~",
  "+$iryz_+ 8.5.23.3(17:0) ~~V~~_~c~_~Z~",
  "-$iryz_- 8.5.23.4(17:0) ~~v~~_~C~_~z~",

// 8.5.24.1-4
  "+&ryz^- 8.5.24.1(20:0) ~~v~~_~C~_~Z~",
  "-&ryz^+ 8.5.24.2(17:0) ~~V~~_~c~_~z~",
  "+$iryz^- 8.5.24.3(17:0) ~~V~~_~c~_~z~",
  "-$iryz^+ 8.5.24.4(20:0) ~~v~~_~C~_~Z~",

// Family 8.6. P Loops
  'F8.6 "P" Loops',

// 8.6.1.1-4
  "+&rp(_)_+ 8.6.1.1(11) ~v''_''~p!__''~",
  "-&rp(_)_- 8.6.1.2(16) ~V''_''~P!__''~",
  "+$irp(~)_+ 8.6.1.3(15) ~V''_''~P!__''~",
  "-$irp(~)_- 8.6.1.4(12) ~v''_''~p!__''~",

// 8.6.2.1-4
  "+&rp(_)^- 8.6.2.1(12) ~v~_~~p!__''~",
  "-&rp(_)^+ 8.6.2.2(16) ~V~_~~P!__''~",
  "+$irp(~)^- 8.6.2.3(16) ~V~_~~P!__''~",
  "-$irp(~)^+ 8.6.2.4(12) ~v~_~~p!__''~",

// 8.6.3.1-4
  "+&rp(_)_- 8.6.3.1(15) ~v''_''~P!__''~",
  "-&rp(_)_+ 8.6.3.2(13) ~V''_''~p!__''~",
  "+$irp(~)_- 8.6.3.3(13) ~V''_''~p!__''~",
  "-$irp(~)_+ 8.6.3.4(14) ~v''_''~P!__''~",

// 8.6.4.1-4
  "+&rp(_)^+ 8.6.4.1(14) ~v~_~~P!__''~",
  "-&rp(_)^- 8.6.4.2(13) ~V~_~~p!__''~",
  "+$irp(~)^+ 8.6.4.3(13) ~V~_~~p!__''~",
  "-$irp(~)^- 8.6.4.4(15) ~v~_~~P!__''~",

// 8.6.5.1-4
  "+_p(_)&+ 8.6.5.1(11) ~~_p!_~~_''v~",
  "-_p(_)&- 8.6.5.2(16) ~~_P!_~~_''V~",
  "+_ip(~)&+ 8.6.5.3(16) ~~_P!_~~_''V~",
  "-_ip(~)&- 8.6.5.4(12) ~~_p!_~~_''v~",

// 8.6.6.1-4
  "+_p(_)&- 8.6.6.1(12) ~~_p!_~~_''V~",
  "-_p(_)&+ 8.6.6.2(15) ~~_P!_~~_''v~",
  "+_ip(~)&- 8.6.6.3(15) ~~_P!_~~_''v~",
  "-_ip(~)&+ 8.6.6.4(13) ~~_p!_~~_''V~",

// 8.6.7.1-4
  "+^p(_)&- 8.6.7.1(16) ~~_P!_~~_''V~",
  "-^p(_)&+ 8.6.7.2(12) ~~_p!_~~_''v~",
  "+^ip(~)&- 8.6.7.3(12) ~~_p!_~~_''v~",
  "-^ip(~)&+ 8.6.7.4(16) ~~_P!_~~_''V~",

// 8.6.8.1-4
  "+^p(_)&+ 8.6.8.1(15) ~~_P!_~~_''v~",
  "-^p(_)&- 8.6.8.2(13) ~~_p!_~~_''V~",
  "+^ip(~)&+ 8.6.8.3(12) ~~_p!_~~_''V~",
  "-^ip(~)&- 8.6.8.4(15) ~~_P!_~~_''v~",

// Family 8.6. (cont) Reversing P Loops
// New for 2012 - coded by Wouter Liefting
// coded as follows (based on pre-2012 porpoise)
// pp = 3/4 loop with reverse, line, 1/4 loop
// rpp = 1/4 loop, line, 3/4 loop with reverse
  'F8.6 Reversing "P" Loops',

// 8.6.9.1-4
  "+&rpp(_)_- 8.6.9.1(13) ~~~~v~_~m!_V=_~",
  "-&rpp(_)_+ 8.6.9.2(15) ~~~~V~_~M!_v=_~",
  "+$irpp_- 8.6.9.3(15) ~~~~V~_~Mv=_~",
  "-$irpp_+ 8.6.9.4(13) ~~~~v~_~mV=_~",

// 8.6.10.1-4
  "+&rpp(_)^+ 8.6.10.1(12) ~~~~v~_~m!_V=_~",
  "-&rpp(_)^- 8.6.10.2(15) ~~~~V~_~M!_v=_~",
  "+$irpp^+ 8.6.10.3(14) ~~~~V~_~Mv=_~",
  "-$irpp^- 8.6.10.4(14) ~~~~v~_~mV=_~",

// 8.6.11.1-4
  "+&rpp(_)_+ 8.6.11.1(13) ~v~_~M!_v=_~",
  "-&rpp(_)_- 8.6.11.2(14) ~V~_~m!_V=_~",
  "+$irpp_+ 8.6.11.3(13) ~V~_~mV=_~",
  "-$irpp_- 8.6.11.4(14) ~v~_~Mv=_~",

// 8.6.12.1-4
  "+&rpp(_)^- 8.6.12.1(14) ~v~_~M!_v=_~",
  "-&rpp(_)^+ 8.6.12.2(14) ~V~_~m!_V=_~",
  "+$irpp^- 8.6.12.3(14) ~V~_~mV=_~",
  "-$irpp^+ 8.6.12.4(13) ~v~_~Mv=_~",

// 8.6.13.1-4
  "+_pp(_)&+ 8.6.13.1(13) ~_v=M!_~_~v~",
  "-_pp(_)&- 8.6.13.2(14) ~_V=m!_~_~V~",
  "+_ipp&+ 8.6.13.3(13) ~_V=m~_~V~",
  "-_ipp&- 8.6.13.4(14) ~_v=M~_~v~",

// 8.6.14.1-4
  "+_pp(_)&- 8.6.14.1(15) ~_v=M!_~_~V~",
  "-_pp(_)&+ 8.6.14.2(12) ~_V=m!_~_~v~",
  "+_ipp&- 8.6.14.3(13) ~_V=m~_~v~",
  "-_ipp&+ 8.6.14.4(15) ~_v=M~_~V~",

// 8.6.15.1-4
  "+^pp(_)&- 8.6.15.1(13) ~_V=m!_~_~V~",
  "-^pp(_)&+ 8.6.15.2(14) ~_v=M!_~_~v~",
  "+^ipp&- 8.6.15.3(14) ~_v=M~_~v~",
  "-^ipp&+ 8.6.15.4(14) ~_V=m~_~V~",

// 8.6.16.1-4
  "+^pp(_)&+ 8.6.16.1(12) ~_V=m!_~_~v~",
  "-^pp(_)&- 8.6.16.2(15) ~_v=M!_~_~V~",
  "+^ipp&+ 8.6.16.3(15) ~_v=M~_~V~",
  "-^ipp&- 8.6.16.4(13) ~_V=m~_~v~",

// Updated 2018. Added full roll elements
  'F8.6 "P" Loops w/ half rolls on top',

// 8.6.17.1-4
  "+&rp(^)_- 8.6.17.1(14) ~v'''_~'''p!__''~",
  "-&rp(^)_+ 8.6.17.2(14) ~V'''_~'''P!__''~",
  "+$irp(^)_- 8.6.17.3(14) ~V'''_~'''P!__''~",
  "-$irp(^)_+ 8.6.17.4(14) ~v'''_~'''p!__''~",

// 8.6.18.1-4
  "+&rp(^)^+ 8.6.18.1(13) ~v'''_~'''p!__''~",
  "-&rp(^)^- 8.6.18.2(14) ~V'''_~'''P!__''~",
  "+$irp(^)^+ 8.6.18.3(14) ~V'''_~'''P!__''~",
  "-$irp(^)^- 8.6.18.4(14) ~v'''_~'''p!__''~",

// 8.6.19.1-4
  "+&rp(^)_+ 8.6.19.1(12) ~v'''_~'''P!__''~",
  "-&rp(^)_- 8.6.19.2(15) ~V'''_~'''p!__''~",
  "+$irp(^)_+ 8.6.19.3(15) ~V'''_~'''p!__''~",
  "-$irp(^)_- 8.6.19.4(13) ~v'''_~'''P!__''~",

// 8.6.20.1-4
  "+&rp(^)^- 8.6.20.1(13) ~v'''_~'''P!__''~",
  "-&rp(^)^+ 8.6.20.2(15) ~V'''_~'''p!__''~",
  "+$irp(^)^- 8.6.20.3(15) ~V'''_~'''p!__''~",
  "-$irp(^)^+ 8.6.20.4(13) ~v'''_~'''P!__''~",

// 8.6.21.1-4
  "+_p(^)&- 8.6.21.1(14) ~~_p!_~'''_'''V~",
  "-_p(^)&+ 8.6.21.2(14) ~~_P!_~'''_'''v~",
  "+_ip(^)&- 8.6.21.3(14) ~~_P!_~'''_'''v~",
  "-_ip(^)&+ 8.6.21.4(14) ~~_p!_~'''_'''V~",

// 8.6.22.1-4
  "+_p(^)&+ 8.6.22.1(13) ~~_p!_~'''_'''v~",
  "-_p(^)&- 8.6.22.2(15) ~~_P!_~'''_'''V~",
  "+_ip(^)&+ 8.6.22.3(14) ~~_P!_~'''_'''V~",
  "-_ip(^)&- 8.6.22.4(13) ~~_p!_~'''_'''v~",

// 8.6.23.1-4
  "+^p(^)&+ 8.6.23.1(13) ~~_P!_~'''_'''v~",
  "-^p(^)&- 8.6.23.2(14) ~~_p!_~'''_'''V~",
  "+^ip(^)&+ 8.6.23.3(14) ~~_p!_~'''_'''V~",
  "-^ip(^)&- 8.6.23.4(14) ~~_P!_~'''_'''v~",

// 8.6.24.1-4
  "+^p(^)&- 8.6.24.1(15) ~~_P!_~'''_'''V~",
  "-^p(^)&+ 8.6.24.2(13) ~~_p!_~'''_'''v~",
  "+^ip(^)&- 8.6.24.3(13) ~~_p!_~'''_'''v~",
  "-^ip(^)&+ 8.6.24.4(15) ~~_P!_~'''_'''V~",

// Family 8.7. Q Loops
  'F8.7 "Q" Loops',

// 8.7.1.1-4
  "+_rq(_)_+ 8.7.1.1(11) ~d''_''~r!__~~",
  "-_rq(_)_- 8.7.1.2(16:0) ~D''_''~R!__~~",
  "+_irq_+ 8.7.1.3(15:0) ~D''_''~R_~~",
  "-_irq_- 8.7.1.4(12) ~d''_''~r_~~",

// 8.7.2.1-4
  "+^rq(_)_- 8.7.2.1(17:0) ~d''_''~R!__~~",
  "-^rq(_)_+ 8.7.2.2(14:0) ~D''_''~r!__~~",
  "+^irq_- 8.7.2.3(13) ~D''_''~r_~~",
  "-^irq_+ 8.7.2.4(16:0) ~d''_''~R_~~",

// 8.7.3.1-4
  "+_rq(_)^- 8.7.3.1(12) ~d''_''~r!__~~",
  "-_rq(_)^+ 8.7.3.2(16:0) ~D''_''~R!__~~",
  "+_irq^- 8.7.3.3(15:0) ~D''_''~R_~~",
  "-_irq^+ 8.7.3.4(12) ~d''_''~r_~~",

// 8.7.4.1-4
  "+^rq(_)^+ 8.7.4.1(16:0) ~d''_''~R!__~~",
  "-^rq(_)^- 8.7.4.2(14:0) ~D''_''~r!__~~",
  "+^irq^+ 8.7.4.3(13) ~D''_''~r_~~",
  "-^irq^- 8.7.4.4(17:0) ~d''_''~R_~~",

// 8.7.5.1-4
  "+_q(_)_+ 8.7.5.1(11) ~~_r!_~~_''d~",
  "-_q(_)_- 8.7.5.2(16) ~~_R!_~~_''D~",
  "+_iq_+ 8.7.5.3(15) ~~_R~~_''D~",
  "-_iq_- 8.7.5.4(12) ~~_r~~_''d~",

// 8.7.6.1-4
  "+_q(_)^- 8.7.6.1(13) ~~_r!_~~_''D~",
  "-_q(_)^+ 8.7.6.2(16) ~~_R!_~~_''d~",
  "+_iq^- 8.7.6.3(17) ~~_R~~_''d~",
  "-_iq^+ 8.7.6.4(14) ~~_r~~_''D~",

// 8.7.7.1-4
  "+^q(_)_- 8.7.7.1(16) ~~_R!_~~_''D~",
  "-^q(_)_+ 8.7.7.2(11) ~~_r!_~~_''d~",
  "+^iq_- 8.7.7.3(12) ~~_r~~_''d~",
  "-^iq_+ 8.7.7.4(16) ~~_R~~_''D~",

// 8.7.8.1-4
  "+^q(_)^+ 8.7.8.1(16) ~~_R!_~~_''d~",
  "-^q(_)^- 8.7.8.2(14) ~~_r!_~~_''D~",
  "+^iq^+ 8.7.8.3(13) ~~_r~~_''D~",
  "-^iq^- 8.7.8.4(17) ~~_R~~_''d~",

// Family 8.8. Double bumps
// New for 2012
// bb = pull, pull
// pbb = push, pull
// bpb = pull, push
// pbpb = push, push
  'F8.8 Double Humpty Bumps',

// 8.8.1.1-4
  "+&bb(&)&- 8.8.1.1(18) ~''v~'_'m/~~''_~m/''_~v''~",
  "-&pbpb(&)&+ 8.8.1.2(25:0) -~''V~'_'M/~~''_~M/''_~V''~",
  "+$ipbpb(&)&- 8.8.1.3(25:0) ~''V~'_'M/~~''_~M/''_~V''~",
  "-$ibb(&)&+ 8.8.1.4(18) -~''v~'_'m/~~''_~m/''_~v''~",

// 8.8.2.1-4
  "+&bb(&)&+ 8.8.2.1(19) ~''v~'_'m/~~''_~m/''_~V''~",
  "-&pbpb(&)&- 8.8.2.2(24:0) -~''V~'_'M/~~''_~M/''_~v''~",
  "+$ipbpb(&)&+ 8.8.2.3(24:0) ~''V~'_'M/~~''_~M/''_~v''~",
  "-$ibb(&)&- 8.8.2.4(19) -~''v~'_'m/~~''_~m/''_~V''~",

// 8.8.3.1-4
  "+&bpb(&)&- 8.8.3.1(21:0) ~''v~'_'m/~~''_~M/''_~v''~",
  "-&pbb(&)&+ 8.8.3.2(23) -~''V~'_'M/~~''_~m/''_~V''~",
  "+$ipbb(&)&- 8.8.3.3(23:0) ~''V~'_'M/~~''_~m/''_~V''~",
  "-$ibpb(&)&+ 8.8.3.4(20) -~''v~'_'m/~~''_~M/''_~v''~",

// 8.8.4.1-4
  "+&bpb(&)&+ 8.8.4.1(21:0) ~''v~'_'m/~~''_~M/''_~V''~",
  "-&pbb(&)&- 8.8.4.2(22) -~''V~'_'M/~~''_~m/''_~v''~",
  "+$ipbb(&)&+ 8.8.4.3(21:0) ~''V~'_'M/~~''_~m/''_~v''~",
  "-$ibpb(&)&- 8.8.4.4(22) -~''v~'_'m/~~''_~M/''_~V''~",

// 8.8.5.1-4
  "+&pbb(&)&- 8.8.5.1(21) ~''v~'_'M/~~''_~m/''_~v''~",
  "-&bpb(&)&+ 8.8.5.2(23:0) -~''V~'_'m/~~''_~M/''_~V''~",
  "+$ibpb(&)&- 8.8.5.3(23) ~''V~'_'m/~~''_~M/''_~V''~",
  "-$ipbb(&)&+ 8.8.5.4(20:0) -~''v~'_'M/~~''_~m/''_~v''~",

// 8.8.6.1-4
  "+&pbb(&)&+ 8.8.6.1(21) ~''v~'_'M/~~''_~m/''_~V''~",
  "-&bpb(&)&- 8.8.6.2(22:0) -~''V~'_'m/~~''_~M/''_~v''~",
  "+$ibpb(&)&+ 8.8.6.3(21) ~''V~'_'m/~~''_~M/''_~v''~",
  "-$ipbb(&)&- 8.8.6.4(22:0) -~''v~'_'M/~~''_~m/''_~V''~",

// 8.8.7.1-4
  "+&pbpb(&)&- 8.8.7.1(23:0) ~''v~'_'M/~~''_~M/''_~v''~",
  "-&bb(&)&+ 8.8.7.2(21) -~''V~'_'m/~~''_~m/''_~V''~",
  "+$ibb(&)&- 8.8.7.3(20) ~''V~'_'m/~~''_~m/''_~V''~",
  "-$ipbpb(&)&+ 8.8.7.4(23:0) -~''v~'_'M/~~''_~M/''_~v''~",

// 8.8.8.1-4
  "+&pbpb(&)&+ 8.8.8.1(25:0) ~''v~'_'M/~~''_~M/''_~V''~",
  "-&bb(&)&- 8.8.8.2(20) -~''V~'_'m/~~''_~m/''_~v''~",
  "+$ibb(&)&+ 8.8.8.3(19) ~''V~'_'m/~~''_~m/''_~v''~",
  "-$ipbpb(&)&- 8.8.8.4(25:0) -~''v~'_'M/~~''_~M/''_~V''~",

// Double Humpty Bumps with first half loop large (since 2014)
  "+&Bb(&)&-    8.8.1.1(18)	   ~''v~'_'m~~''_~m/''_~v''~",
  "-&pBpb(&)&+  8.8.1.2(25)	  -~''V~'_'M~~''_~M/''_~V''~",
  "+$ipBpb(&)&- 8.8.1.3(25)	   ~''V~'_'M~~''_~M/''_~V''~",
  "-$iBb(&)&+   8.8.1.4(18)	  -~''v~'_'m~~''_~m/''_~v''~",

  "+&Bb(&)&+    8.8.2.1(19)	   ~''v~'_'m~~''_~m/''_~V''~",
  "-&pBpb(&)&-  8.8.2.2(24)	  -~''V~'_'M~~''_~M/''_~v''~",
  "+$ipBpb(&)&+ 8.8.2.3(24)	   ~''V~'_'M~~''_~M/''_~v''~",
  "-$iBb(&)&-   8.8.2.4(19)	  -~''v~'_'m~~''_~m/''_~V''~",

  "+&Bpb(&)&-   8.8.3.1(21)	   ~''v~'_'m~~''_~M/''_~v''~",
  "-&pBb(&)&+   8.8.3.2(23)	  -~''V~'_'M~~''_~m/''_~V''~",
  "+$ipBb(&)&-  8.8.3.3(23)	   ~''V~'_'M~~''_~m/''_~V''~",
  "-$iBpb(&)&+  8.8.3.4(20)	  -~''v~'_'m~~''_~M/''_~v''~",

  "+&Bpb(&)&+   8.8.4.1(21)	   ~''v~'_'m~~''_~M/''_~V''~",
  "-&pBb(&)&-   8.8.4.2(22)	  -~''V~'_'M~~''_~m/''_~v''~",
  "+$ipBb(&)&+  8.8.4.3(21)	   ~''V~'_'M~~''_~m/''_~v''~",
  "-$iBpb(&)&-  8.8.4.4(22)	  -~''v~'_'m~~''_~M/''_~V''~",

  "+&pBb(&)&-   8.8.5.1(21)	   ~''v~'_'M~~''_~m/''_~v''~",
  "-&Bpb(&)&+   8.8.5.2(23)	  -~''V~'_'m~~''_~M/''_~V''~",
  "+$iBpb(&)&-  8.8.5.3(23)	   ~''V~'_'m~~''_~M/''_~V''~",
  "-$ipBb(&)&+  8.8.5.4(20)	  -~''v~'_'M~~''_~m/''_~v''~",

  "+&pBb(&)&+   8.8.6.1(21)	   ~''v~'_'M~~''_~m/''_~V''~",
  "-&Bpb(&)&-   8.8.6.2(22)	  -~''V~'_'m~~''_~M/''_~v''~",
  "+$iBpb(&)&+  8.8.6.3(21)	   ~''V~'_'m~~''_~M/''_~v''~",
  "-$ipBb(&)&-  8.8.6.4(22)	  -~''v~'_'M~~''_~m/''_~V''~",

  "+&pBpb(&)&-  8.8.7.1(23)	   ~''v~'_'M~~''_~M/''_~v''~",
  "-&Bb(&)&+    8.8.7.2(21)	  -~''V~'_'m~~''_~m/''_~V''~",
  "+$iBb(&)&-   8.8.7.3(20)	   ~''V~'_'m~~''_~m/''_~V''~",
  "-$ipBpb(&)&+ 8.8.7.4(23)	  -~''v~'_'M~~''_~M/''_~v''~",

  "+&pBpb(&)&+  8.8.8.1(25)	   ~''v~'_'M~~''_~M/''_~V''~",
  "-&Bb(&)&-    8.8.8.2(20)	  -~''V~'_'m~~''_~m/''_~v''~",
  "+$iBb(&)&+   8.8.8.3(19)	   ~''V~'_'m~~''_~m/''_~v''~",
  "-$ipBpb(&)&- 8.8.8.4(25)	  -~''v~'_'M~~''_~M/''_~V''~",

// Double Humpty Bumps with second half loop large (since 2014)
  "+&bB(&)&-    8.8.1.1(18)	   ~''v~'_'m/~~''_~m''_~v''~",
  "-&pbpB(&)&+  8.8.1.2(25)	  -~''V~'_'M/~~''_~M''_~V''~",
  "+$ipbpB(&)&- 8.8.1.3(25)	   ~''V~'_'M/~~''_~M''_~V''~",
  "-$ibB(&)&+   8.8.1.4(18)	  -~''v~'_'m/~~''_~m''_~v''~",

  "+&bB(&)&+    8.8.2.1(19)	   ~''v~'_'m/~~''_~m''_~V''~",
  "-&pbpB(&)&-  8.8.2.2(24)	  -~''V~'_'M/~~''_~M''_~v''~",
  "+$ipbpB(&)&+ 8.8.2.3(24)	   ~''V~'_'M/~~''_~M''_~v''~",
  "-$ibB(&)&-   8.8.2.4(19)	  -~''v~'_'m/~~''_~m''_~V''~",

  "+&bpB(&)&-   8.8.3.1(21)	   ~''v~'_'m/~~''_~M''_~v''~",
  "-&pbB(&)&+   8.8.3.2(23)	  -~''V~'_'M/~~''_~m''_~V''~",
  "+$ipbB(&)&-  8.8.3.3(23)	   ~''V~'_'M/~~''_~m''_~V''~",
  "-$ibpB(&)&+  8.8.3.4(20)	  -~''v~'_'m/~~''_~M''_~v''~",

  "+&bpB(&)&+   8.8.4.1(21)	   ~''v~'_'m/~~''_~M''_~V''~",
  "-&pbB(&)&-   8.8.4.2(22)	  -~''V~'_'M/~~''_~m''_~v''~",
  "+$ipbB(&)&+  8.8.4.3(21)	   ~''V~'_'M/~~''_~m''_~v''~",
  "-$ibpB(&)&-  8.8.4.4(22)	  -~''v~'_'m/~~''_~M''_~V''~",

  "+&pbB(&)&-   8.8.5.1(21)	   ~''v~'_'M/~~''_~m''_~v''~",
  "-&bpB(&)&+   8.8.5.2(23)	  -~''V~'_'m/~~''_~M''_~V''~",
  "+$ibpB(&)&-  8.8.5.3(23)	   ~''V~'_'m/~~''_~M''_~V''~",
  "-$ipbB(&)&+  8.8.5.4(20)	  -~''v~'_'M/~~''_~m''_~v''~",

  "+&pbB(&)&+   8.8.6.1(21)	   ~''v~'_'M/~~''_~m''_~V''~",
  "-&bpB(&)&-   8.8.6.2(22)	  -~''V~'_'m/~~''_~M''_~v''~",
  "+$ibpB(&)&+  8.8.6.3(21)	   ~''V~'_'m/~~''_~M''_~v''~",
  "-$ipbB(&)&-  8.8.6.4(22)	  -~''v~'_'M/~~''_~m''_~V''~",

  "+&pbpB(&)&-  8.8.7.1(23)	   ~''v~'_'M/~~''_~M''_~v''~",
  "-&bB(&)&+    8.8.7.2(21)	  -~''V~'_'m/~~''_~m''_~V''~",
  "+$ibB(&)&-   8.8.7.3(20)	   ~''V~'_'m/~~''_~m''_~V''~",
  "-$ipbpB(&)&+ 8.8.7.4(23)	  -~''v~'_'M/~~''_~M''_~v''~",

  "+&pbpB(&)&+  8.8.8.1(25)	   ~''v~'_'M/~~''_~M''_~V''~",
  "-&bB(&)&-    8.8.8.2(20)	  -~''V~'_'m/~~''_~m''_~v''~",
  "+$ibB(&)&+   8.8.8.3(19)	   ~''V~'_'m/~~''_~m''_~v''~",
  "-$ipbpB(&)&- 8.8.8.4(25)	  -~''v~'_'M/~~''_~M''_~V''~",

// Family 8.10. Reversing 1.25 Loops
  'F8.10 Reversing 1 1/4 Loops',

// 8.10.1.1-4
  "+_co&+ 8.10.1.1(19) ~~~_pM''_''V~",
  "-_co&- 8.10.1.2(19) ~~~_Pm''_''v~",
  "+_ico&+ 8.10.1.3(18) ~~~_Pm''_''v~",
  "-_ico&- 8.10.1.4(19) ~~~_pM''_''V~",

// 8.10.2.1-4
  "+_co&- 8.10.2.1(18) ~~~_pM''_''v~",
  "-_co&+ 8.10.2.2(20) ~~~_Pm''_''V~",
  "+_ico&- 8.10.2.3(19) ~~~_Pm''_''V~",
  "-_ico&+ 8.10.2.4(18) ~~~_pM''_''v~",

  /* family 9 placeholder */
  'F9 Rolls and spins',

//############ family 0 figures - non FAI catalog ################
  'F0 non-Aresti catalog',

// wingover
  "+_jw_+ 0.0(8) ~d''_''j2~_~d~",

//Clover leaf (with optional roll in top)
// we use 0.x based
  "+oj_+ 0.1(16) ~''v=4p!_~",
  "+ioj_+ 0.2(13) ~''p!_4v=~",

//Half barrel rolls
// use 0.2.x.y as equivalent to 7.2.x.y
  "+_mj_- 0.2.1.1(10) ~''_v=4v=_~",
  "-_mj_+ 0.2.1.2(12) ~''_V=4V=_~",
  "+_aj_- 0.2.1.3(12) ~''_V=4V=_~",
  "-_aj_+ 0.2.1.4(10) ~''_v=4v=_~",
  "+_mj^+ 0.2.2.1(10) ~''_v=4v=_~",
  "-_mj^- 0.2.2.2(13) ~''_V=4V=_~",
  "+_aj^+ 0.2.2.3(12) ~''_V=4V=_~",
  "-_aj^- 0.2.2.4(11) ~''_v=4v=_~",

  "+^mj_+ 0.2.3.1(12) ~''_V=4V=_~",
  "-^mj_- 0.2.3.2(11) ~''_v=4v=_~",
  "+^aj_+ 0.2.3.3(10) ~''_v=4v=_~",
  "-^aj_- 0.2.3.4(13) ~''_V=4V=_~",
  "+^mj^- 0.2.4.1(12) ~''_V=4V=_~",
  "-^mj^+ 0.2.4.2(10) ~''_v=4v=_~",
  "+^aj^- 0.2.4.3(10) ~''_v=4v=_~",
  "-^aj^+ 0.2.4.4(12) ~''_V=4V=_~",

// 0.2.4.9-12 360 rolling turns with 1/2 roll
  "+4j5- 0.2.4.9(50:0) ~''j45''~",
  "-4j5+ 0.2.4.10(51:0) ~''J45''~",
  "+4jo5- 0.2.4.11(54:0) ~''J45''~",
  "-4jo5+ 0.2.4.12(55:0) ~''j45''~",

//extended tip-head hammer - for any "on spot turn around" (like knife edge up-down)
  "+&th&+ 0.5.1.1(17) ~v~_~u~_''v~",
  "-&th&- 0.5.1.2(23) ~V~_~U~_''V~",
  "+&th&- 0.5.1.3(18) ~v~_~u~_''V~",
  "-&th&+ 0.5.1.4(22) ~V~_~U~_''v~",

//extended vertical up-down lines figure, sort of alternate to the uh figure with a connector on top
  "+&vv(_)&+ 0.11.1(20) ~v~''_~V''_''V~_''~v~",
  "-&vv(_)&- 0.11.2(20) ~V~''_~v''_''v~_''~V~",
  "+$ivv(_)&+ 0.11.3(20) ~V~''_~v''_''v~_''~V~",
  "-$ivv(_)&- 0.11.4(20) ~v~''_~V''_''V~_''~v~",
  "+&hat(_)&+ 0.12.1(20) ~v~''_~v''_''v~_''~v~",
  "-&hat(_)&- 0.12.2(20) ~V~''_~V''_''V~_''~V~",
  "+$ihat(_)&+ 0.12.3(20) ~V~''_~V''_''V~_''~V~",
  "-$ihat(_)&- 0.12.4(20) ~v~''_~v''_''v~_''~v~",


//hammer p loop
  "+&hp(&)_+ 0.5.2.1(20) ~v~_~h''_''~P_''~",
  "-&hp(&)_- 0.5.2.2(20) ~V~_~H''_''~p_''~",
  "+&hp(&)_- 0.5.2.3(20) ~v~_~h''_''~p_''~",
  "-&hp(&)_+ 0.5.2.4(20) ~V~_~H''_''~P_''~",

  "+&hp(&)^- 0.5.3.1(20) ~v~_~h''_''~P_''~",
  "-&hp(&)^+ 0.5.3.2(20) ~V~_~H''_''~p_''~",
  "+&hp(&)^+ 0.5.3.3(20) ~v~_~h''_''~p_''~",
  "-&hp(&)^- 0.5.3.4(20) ~V~_~H''_''~P_''~",

//ip-hammer loop

  "+_iph(&)&+ 0.5.4.1(20) ~~_P~~_~h~~_~v~",
  "-_iph(&)&- 0.5.4.2(20) ~~_p~~_~H~~_~V~",
  "+_iph(&)&- 0.5.4.3(20) ~~_P~~_~h~~_~V~",
  "-_iph(&)&+ 0.5.4.4(20) ~~_p~~_~H~~_~v~",

  "+^iph(&)&+ 0.5.6.1(20) ~~_p~~_~h~~_~v~",
  "-^iph(&)&- 0.5.6.2(20) ~~_P~~_~H~~_~V~",
  "+^iph(&)&- 0.5.6.3(20) ~~_p~~_~h~~_~V~",
  "-^iph(&)&+ 0.5.6.4(20) ~~_P~~_~H~~_~v~",

//ib-hammer
  "+$ibh(&)&+ 0.5.7.1(20) ~~V~_''m~~_~h~~_''v~",
  "-$ibh(&)&+ 0.5.7.2(20) -~~v~_''m~~_~h~~_''v~",
  "+$ibh(&)&- 0.5.7.3(20) ~~V~_''m~~_~h~~_''V~",
  "-$ibh(&)&- 0.5.7.4(20) -~~v~_''m~~_~h~~_''V~",

//ipb-hammer
  "+$ipbh(&)&+ 0.5.8.1(20) ~~V~_''M~~_~h~~_''v~",
  "-$ipbh(&)&+ 0.5.8.2(20) -~~v~_''M~~_~h~~_''v~",
  "+$ipbh(&)&- 0.5.8.3(20) ~~V~_''M~~_~h~~_''V~",
  "-$ipbh(&)&- 0.5.8.4(20) -~~v~_''M~~_~h~~_''V~",

//can also add hib hipb figures
//can also add hammer at the top of a k figure etc, but it would be very hard to judge
//need a figure with a clear vertical exit/entry line.
//the exit from N would be a candidate, but it implies 4-roll lines figures

//triangular!
  "+_tri(_)_+ 0.11.1(20) ~~~d~_~z~''_~''z~_~d~~~",
  "-_tri(_)_- 0.11.2(20) ~~~D~_~Z~''_~''Z~_~D~~~",
  "+_itri(_)_+ 0.11.3(20) ~~~D~_~Z~''_~''Z~_~D~~~",
  "-_itri(_)_- 0.11.4(20) ~~~d~_~z~''_~''z~_~d~~~",
  "+^tri(_)^+ 0.12.1(20) ~~~d~_~Z~''_~''Z~_~d~~~",
  "-^tri(_)^- 0.12.2(20) ~~~D~_~z~''_~''z~_~D~~~",
  "+^itri(_)^+ 0.12.3(20) ~~~D~_~z~''_~''z~_~D~~~",
  "-^itri(_)^- 0.12.4(20) ~~~d~_~Z~''_~''Z~_~d~~~",
  "+^tri(^)_+ 0.13.1(20) ~~~d~_~Z~''_~''z~_~d~~~",
  "-^tri(^)_- 0.13.2(20) ~~~D~_~z~''_~''Z~_~D~~~",
  "+^itri(^)_+ 0.13.3(20) ~~~D~_~z~''_~''Z~_~D~~~",
  "-^itri(^)_- 0.13.4(20) ~~~d~_~Z~''_~''z~_~d~~~",
  "+_tri(^)^+ 0.14.1(20) ~~~d~_~z~''_~''Z~_~d~~~",
  "-_tri(^)^- 0.14.2(20) ~~~D~_~Z~''_~''z~_~D~~~",
  "+_itri(^)^+ 0.14.3(20) ~~~D~_~Z~''_~''z~_~D~~~",
  "-_itri(^)^- 0.14.4(20) ~~~d~_~z~''_~''Z~_~d~~~",

  "+^tri(^)^- 0.15.1(20) ~~~d~_~Z~''_~''z~_~D~~~",
  "-^tri(^)^+ 0.15.2(20) ~~~D~_~z~''_~''Z~_~d~~~",
  "+^itri(^)^- 0.15.3(20) ~~~D~_~z~''_~''Z~_~d~~~",
  "-^itri(^)^+ 0.15.4(20) ~~~d~_~Z~''_~''z~_~D~~~",
  "+^tri(_)_- 0.16.1(20) ~~~d~_~Z~''_~''Z~_~D~~~",
  "-^tri(_)_+ 0.16.2(20) ~~~D~_~z~''_~''z~_~d~~~",
  "+^itri(_)_- 0.16.3(20) ~~~D~_~z~''_~''z~_~d~~~",
  "-^itri(_)_+ 0.16.4(20) ~~~d~_~Z~''_~''Z~_~D~~~",
  "+_tri(^)_- 0.17.1(20) ~~~d~_~z~''_~''Z~_~D~~~",
  "-_tri(^)_+ 0.17.2(20) ~~~D~_~Z~''_~''z~_~d~~~",
  "+_itri(^)_- 0.17.3(20) ~~~D~_~Z~''_~''z~_~d~~~",
  "-_itri(^)_+ 0.17.4(20) ~~~d~_~z~''_~''Z~_~D~~~",
  "+_tri(_)^- 0.18.1(20) ~~~d~_~z~''_~''z~_~D~~~",
  "-_tri(_)^+ 0.18.2(20) ~~~D~_~Z~''_~''Z~_~d~~~",
  "+_itri(_)^- 0.18.3(20) ~~~D~_~Z~''_~''Z~_~d~~~",
  "-_itri(_)^+ 0.18.4(20) ~~~d~_~z~''_~''z~_~D~~~",

// other extended figures
//extended immelman, has 3 roll positions
  "+_xm(_)_+ 0.101.1(20) ~d=_d=_d=_d=~",
  "-_xm(_)_- 0.101.2(20) ~D=_D=_D=_D=~",
  "+_ixm(_)_+ 0.101.3(20) ~D=_D=_D=_D=~",
  "-_ixm(_)_- 0.101.4(20) ~d=_d=_d=_d=~",
  "+_xm(^)_+ 0.102.1(20) ~d=_d=_D=_D=~",
  "-_xm(^)_- 0.102.2(20) ~D=_D=_d=_d=~",
  "+_ixm(^)_+ 0.102.3(20) ~D=_D=_d=_d=~",
  "-_ixm(^)_- 0.102.4(20) ~d=_d=_D=_D=~",
  "+_xm(_)^+ 0.103.1(20) ~d=_d=_d=_D=~",
  "-_xm(_)^- 0.103.2(20) ~D=_D=_D=_d=~",
  "+_ixm(_)^+ 0.103.3(20) ~D=_D=_D=_d=~",
  "-_ixm(_)^- 0.103.4(20) ~d=_d=_d=_D=~",
  "+^xm(_)_+ 0.104.1(20) ~d=_D=_D=_D=~",
  "-^xm(_)_- 0.104.2(20) ~D=_d=_d=_d=~",
  "+^ixm(_)_+ 0.104.3(20) ~D=_d=_d=_d=~",
  "-^ixm(_)_- 0.104.4(20) ~d=_D=_D=_D=~",

  "+_xa(_)_+ 0.201.1(20) ~D=_D=_D=_D=~",
  "-_xa(_)_- 0.201.2(20) ~d=_d=_d=_d=~",
  "+_ixa(_)_+ 0.201.3(20) ~d=_d=_d=_d=~",
  "-_ixa(_)_- 0.201.4(20) ~D=_D=_D=_D=~",
  "+_xa(^)_+ 0.202.1(20) ~D=_D=_d=_d=~",
  "-_xa(^)_- 0.202.2(20) ~d=_d=_D=_D=~",
  "+_ixa(^)_+ 0.202.3(20) ~d=_d=_D=_D=~",
  "-_ixa(^)_- 0.202.4(20) ~D=_D=_d=_d=~",
  "+_xa(_)^+ 0.203.1(20) ~D=_D=_D=_d=~",
  "-_xa(_)^- 0.203.2(20) ~d=_d=_d=_D=~",
  "+_ixa(_)^+ 0.203.3(20) ~d=_d=_d=_D=~",
  "-_ixa(_)^- 0.203.4(20) ~D=_D=_D=_d=~",
  "+^xa(_)_+ 0.204.1(20) ~D=_d=_D=_D=~",
  "-^xa(_)_- 0.204.2(20) ~d=_D=_d=_d=~",
  "+^ixa(_)_+ 0.204.3(20) ~d=_D=_d=_d=~",
  "-^ixa(_)_- 0.204.4(20) ~D=_d=_D=_D=~",

//+_xv(&)_+  1.301.1(20,20,20,20) = ~_~v~_~V~_~
//+_xv(&)^-  1.302.1(20,20,20,20) = ~_~v~_~v~_~
//+^xv(&)_-  1.303.1(20,20,20,20) = ~_~V~_~V~_~
//+^xv(&)^+  1.304.1(20,20,20,20) = ~_~V~_~v~_~

//+_xd(_)_+  1.401.1(20,20,20,20) = ''_d~_~D_''
//+_xd(^)_-  1.402.1(20,20,20,20) = ''_d~_~d_''
//+^xd(_)_-  1.403.1(20,20,20,20) = ''_D~_~d_''
//+^xd(^)_+  1.404.1(20,20,20,20) = ''_D~_~D_''

// formation figures
// loop and turn
  "+oaj+ 0.301.1(20) ~oj1~",
  "+oajj+ 0.301.1(20) ~oj2~",
  "+oajjj+ 0.301.1(20) ~oj3~",
  "+oajjjj+ 0.301.1(20) ~oj4~",

//############ family 9 values ################

// 4       2   3     1       5     6     7     9
  "v4 9.1.1.1(6:9)",
  "v2 9.1.1.2(8:12)",
  "v3 9.1.1.3(10:0)",
  "v1 9.1.1.4(12:0)",
  "v5 9.1.1.5(14:0)",
  "v6 9.1.1.6(15:0)",
  "v7 9.1.1.7(17:0)",
  "v9 9.1.1.8(18:0)",
  "d4 9.1.2.1(4:6)",
  "d2 9.1.2.2(6:9)",
  "d3 9.1.2.3(8:0)",
  "d1 9.1.2.4(10:15)",
  "d5 9.1.2.5(11:0)",
  "d6 9.1.2.6(12:0)",
  "d7 9.1.2.7(14:0)",
  "d9 9.1.2.8(15:0)",
  "4 9.1.3.1(2:3)",
  "2 9.1.3.2(4:6)",
  "3 9.1.3.3(6:9)",
  "1 9.1.3.4(8:12)",
  "5 9.1.3.5(9:14)",
  "6 9.1.3.6(10:15)",
  "7 9.1.3.7(11:17)",
  "9 9.1.3.8(12:18)",
  "id4 9.1.4.1(2:3)",
  "id2 9.1.4.2(4:6)",
  "id3 9.1.4.3(6:9)",
  "id1 9.1.4.4(8:12)",
  "id5 9.1.4.5(9:0)",
  "id6 9.1.4.6(10:0)",
  "id7 9.1.4.7(11:0)",
  "id9 9.1.4.8(12:0)",
  "iv4 9.1.5.1(2:3)",
  "iv2 9.1.5.2(4:6)",
  "iv3 9.1.5.3(6:9)",
  "iv1 9.1.5.4(8:0)",
  "iv5 9.1.5.5(9:0)",
  "iv6 9.1.5.6(10:0)",
  "iv7 9.1.5.7(11:0)",
  "iv9 9.1.5.8(12:0)",

// 22    23     24
  "v22 9.2.1.4(13:0)",
  "v32 9.2.1.6(17:0)",
  "v42 9.2.1.8(21:0)",
  "d22 9.2.2.4(11:0)",
  "d32 9.2.2.6(14:0)",
  "d42 9.2.2.8(18:0)",
  "22 9.2.3.4(9:14)",
  "32 9.2.3.6(12:18)",
  "42 9.2.3.8(15:23)",
  "id22 9.2.4.4(9:14)",
  "id32 9.2.4.6(12:0)",
  "id42 9.2.4.8(15:0)",
  "iv22 9.2.5.4(9:0)",
  "iv32 9.2.5.6(12:0)",
  "iv42 9.2.5.8(15:0)",

// 3 point rolls, non Aresti, K factors for glider were 17, 28, 17
// 33          63
  "33 9.3.3.4(0:0)",
  "63 9.3.3.8(0:0)",
  "id33 9.3.4.4(0:0)",

// 42   43     44    45    46    47    48
  "v24 9.4.1.2(9:14)",
  "v34 9.4.1.3(12:0)",
  "v44 9.4.1.4(15:0)",
  "v54 9.4.1.5(18:0)",
  "v64 9.4.1.6(20:0)",
  "v74 9.4.1.7(23:0)",
  "v84 9.4.1.8(25:0)",
  "d24 9.4.2.2(7:11)",
  "d34 9.4.2.3(10:0)",
  "d44 9.4.2.4(13:0)",
  "d54 9.4.2.5(15:0)",
  "d64 9.4.2.6(17:0)",
  "d74 9.4.2.7(20:0)",
  "d84 9.4.2.8(22:0)",
  "24 9.4.3.2(5:8)",
  "34 9.4.3.3(8:12)",
  "44 9.4.3.4(11:17)",
  "54 9.4.3.5(13:20)",
  "64 9.4.3.6(15:23)",
  "74 9.4.3.7(17:26)",
  "84 9.4.3.8(19:28)",
  "id24 9.4.4.2(5:8)",
  "id34 9.4.4.3(8:12)",
  "id44 9.4.4.4(11:17)",
  "id54 9.4.4.5(13:0)",
  "id64 9.4.4.6(15:0)",
  "id74 9.4.4.7(17:0)",
  "id84 9.4.4.8(19:0)",
  "iv24 9.4.5.2(5:8)",
  "iv34 9.4.5.3(8:0)",
  "iv44 9.4.5.4(11:0)",
  "iv54 9.4.5.5(13:0)",
  "iv64 9.4.5.6(15:0)",
  "iv74 9.4.5.7(17:0)",
  "iv84 9.4.5.8(19:0)",

// 8      84 86 88 810 812 814 816
  "v8 9.8.1.1(7:11)",
  "v28 9.8.1.1(7:11)",
  "v48 9.8.1.2(11:0)",
  "v68 9.8.1.3(15:0)",
  "v88 9.8.1.4(19:0)",
  "v108 9.8.1.5(23:0)",
  "v128 9.8.1.6(26:0)",
  "v148 9.8.1.7(30:0)",
  "v168 9.8.1.8(33:0)",
  "d8 9.8.2.1(5:8)",
  "d28 9.8.2.1(5:8)",
  "d48 9.8.2.2(9:14)",
  "d68 9.8.2.3(13:0)",
  "d88 9.8.2.4(17:0)",
  "d108 9.8.2.5(20:0)",
  "d128 9.8.2.6(23:0)",
  "d148 9.8.2.7(27:0)",
  "d168 9.8.2.8(30:0)",
  "8 9.8.3.1(3:5)",
  "28 9.8.3.1(3:5)",
  "48 9.8.3.2(7:11)",
  "68 9.8.3.3(11:17)",
  "88 9.8.3.4(15:23)",
  "108 9.8.3.5(18:27)",
  "128 9.8.3.6(21:32)",
  "148 9.8.3.7(24:36)",
  "168 9.8.3.8(27:41)",
  "id8 9.8.4.1(3:5)",
  "id28 9.8.4.1(3:5)",
  "id48 9.8.4.2(7:11)",
  "id68 9.8.4.3(11:0)",
  "id88 9.8.4.4(15:0)",
  "id108 9.8.4.5(18:0)",
  "id128 9.8.4.6(21:0)",
  "id148 9.8.4.7(24:0)",
  "id168 9.8.4.8(27:0)",
  "iv8 9.8.5.1(3:5)",
  "iv28 9.8.5.1(3:5)",
  "iv48 9.8.5.2(7:11)",
  "iv68 9.8.5.3(11:0)",
  "iv88 9.8.5.4(15:0)",
  "iv108 9.8.5.5(18:0)",
  "iv128 9.8.5.6(21:0)",
  "iv148 9.8.5.7(24:0)",
  "iv168 9.8.5.8(27:0)",

// 2    3    1  5   6  7  9
  "+v2f 9.9.1.2(15:18)",
  "+v3f 9.9.1.3(15:0)",
  "+v1f 9.9.1.4(15:0)",
  "+v5f 9.9.1.5(17:0)",
  "+v6f 9.9.1.6(19:0)",
  "+v7f 9.9.1.7(21:0)",
  "+v9f 9.9.1.8(23:0)",
  "+d2f 9.9.2.2(13:15)",
  "+d3f 9.9.2.3(13:17)",
  "+d1f 9.9.2.4(13:19)",
  "+d5f 9.9.2.5(15:0)",
  "+d6f 9.9.2.6(16:0)",
  "+d7f 9.9.2.7(18:0)",
  "+d9f 9.9.2.8(20:0)",
  "+2f 9.9.3.2(11:12)",
  "+3f 9.9.3.3(11:14)",
  "+1f 9.9.3.4(11:16)",
  "+5f 9.9.3.5(13:19)",
  "+6f 9.9.3.6(14:21)",
  "+7f 9.9.3.7(16:24)",
  "+9f 9.9.3.8(17:25)",
  "+id2f 9.9.4.2(11:12)",
  "+id3f 9.9.4.3(11:14)",
  "+id1f 9.9.4.4(11:16)",
  "+id5f 9.9.4.5(13:19)",
  "+id6f 9.9.4.6(14:21)",
  "+id7f 9.9.4.7(16:24)",
  "+id9f 9.9.4.8(17:25)",
  "+iv2f 9.9.5.2(11:12)",
  "+iv3f 9.9.5.3(11:14)",
  "+iv1f 9.9.5.4(11:16)",
  "+iv5f 9.9.5.5(13:19)",
  "+iv6f 9.9.5.6(14:21)",
  "+iv7f 9.9.5.7(16:24)",
  "+iv9f 9.9.5.8(17:25)",
//
  "-v2f 9.9.6.2(17:18)",
  "-v3f 9.9.6.3(17:0)",
  "-v1f 9.9.6.4(17:0)",
  "-v5f 9.9.6.5(20:0)",
  "-v6f 9.9.6.6(22:0)",
  "-v7f 9.9.6.7(24:0)",
  "-v9f 9.9.6.8(26:0)",
  "-d2f 9.9.7.2(15:18)",
  "-d3f 9.9.7.3(15:0)",
  "-d1f 9.9.7.4(15:0)",
  "-d5f 9.9.7.5(17:0)",
  "-d6f 9.9.7.6(19:0)",
  "-d7f 9.9.7.7(21:0)",
  "-d9f 9.9.7.8(23:0)",
  "-2f 9.9.8.2(13:15)",
  "-3f 9.9.8.3(13:17)",
  "-1f 9.9.8.4(13:19)",
  "-5f 9.9.8.5(15:22)",
  "-6f 9.9.8.6(16:24)",
  "-7f 9.9.8.7(18:27)",
  "-9f 9.9.8.8(20:30)",
  "-id2f 9.9.9.2(13:15)",
  "-id3f 9.9.9.3(13:17)",
  "-id1f 9.9.9.4(13:19)",
  "-id5f 9.9.9.5(15:22)",
  "-id6f 9.9.9.6(16:24)",
  "-id7f 9.9.9.7(18:27)",
  "-id9f 9.9.9.8(20:30)",
  "-iv2f 9.9.10.2(13:12)",
  "-iv3f 9.9.10.3(13:14)",
  "-iv1f 9.9.10.4(13:16)",
  "-iv5f 9.9.10.5(15:19)",
  "-iv6f 9.9.10.6(16:21)",
  "-iv7f 9.9.10.7(18:24)",
  "-iv9f 9.9.10.8(20:25)",

// 2      3      1       5     6     7     8
  "-v2if 9.10.1.2(17:21)",
  "-v3if 9.10.1.3(17:0)",
  "-v1if 9.10.1.4(17:0)",
  "-v5if 9.10.1.5(20:0)",
  "-v6if 9.10.1.6(22:0)",
  "-v7if 9.10.1.7(24:0)",
  "-v9if 9.10.1.8(26:0)",
  "-d2if 9.10.2.2(15:18)",
  "-d3if 9.10.2.3(15:20)",
  "-d1if 9.10.2.4(15:22)",
  "-d5if 9.10.2.5(17:0)",
  "-d6if 9.10.2.6(19:0)",
  "-d7if 9.10.2.7(21:0)",
  "-d9if 9.10.2.8(23:0)",
  "-2if 9.10.3.2(13:15)",
  "-3if 9.10.3.3(13:17)",
  "-1if 9.10.3.4(13:19)",
  "-5if 9.10.3.5(15:22)",
  "-6if 9.10.3.6(16:24)",
  "-7if 9.10.3.7(18:27)",
  "-9if 9.10.3.8(20:30)",
  "-id2if 9.10.4.2(13:15)",
  "-id3if 9.10.4.3(13:17)",
  "-id1if 9.10.4.4(13:19)",
  "-id5if 9.10.4.5(15:22)",
  "-id6if 9.10.4.6(16:24)",
  "-id7if 9.10.4.7(18:27)",
  "-id9if 9.10.4.8(20:30)",
  "-iv2if 9.10.5.2(13:15)",
  "-iv3if 9.10.5.3(13:17)",
  "-iv1if 9.10.5.4(13:19)",
  "-iv5if 9.10.5.5(15:22)",
  "-iv6if 9.10.5.6(16:24)",
  "-iv7if 9.10.5.7(18:27)",
  "-iv9if 9.10.5.8(20:30)",

  "+v2if 9.10.6.2(19:21)",
  "+v3if 9.10.6.3(19:0)",
  "+v1if 9.10.6.4(19:0)",
  "+v5if 9.10.6.5(22:0)",
  "+v6if 9.10.6.6(24:0)",
  "+v7if 9.10.6.7(27:0)",
  "+v9if 9.10.6.8(29:0)",
  "+d2if 9.10.7.2(17:21)",
  "+d3if 9.10.7.3(17:0)",
  "+d1if 9.10.7.4(17:0)",
  "+d5if 9.10.7.5(19:0)",
  "+d6if 9.10.7.6(21:0)",
  "+d7if 9.10.7.7(24:0)",
  "+d9if 9.10.7.8(26:0)",
  "+2if 9.10.8.2(15:18)",
  "+3if 9.10.8.3(15:20)",
  "+1if 9.10.8.4(15:22)",
  "+5if 9.10.8.5(17:25)",
  "+6if 9.10.8.6(19:28)",
  "+7if 9.10.8.7(21:31)",
  "+9if 9.10.8.8(23:34)",
  "+id2if 9.10.9.2(15:18)",
  "+id3if 9.10.9.3(15:20)",
  "+id1if 9.10.9.4(15:22)",
  "+id5if 9.10.9.5(17:25)",
  "+id6if 9.10.9.6(19:28)",
  "+id7if 9.10.9.7(21:31)",
  "+id9if 9.10.9.8(23:34)",
  "+iv2if 9.10.10.2(15:15)",
  "+iv3if 9.10.10.3(15:17)",
  "+iv1if 9.10.10.4(15:19)",
  "+iv5if 9.10.10.5(17:22)",
  "+iv6if 9.10.10.6(19:24)",
  "+iv7if 9.10.10.7(21:27)",
  "+iv9if 9.10.10.8(23:30)",

//  1    5    6    7    9
  "iv1s 9.11.1.4(5:5)",
  "iv5s 9.11.1.5(4:6)",
  "iv6s 9.11.1.6(3:7)",
  "iv7s 9.11.1.7(3:8)",
  "iv9s 9.11.1.8(3:8)",
  "iv1is 9.12.1.4(7:7)",
  "iv5is 9.12.1.5(6:8)",
  "iv6is 9.12.1.6(5:9)",
  "iv7is 9.12.1.7(5:10)",
  "iv9is 9.12.1.8(5:10)",

// super slow roll, glider only

  "02 9.13.3.2(0:8)",
  "01 9.13.3.4(0:16)",
  "06 9.13.3.6(0:20)",

// barrel roll
  "@1 9.14.3.4(0:0)",
  "@9 9.14.3.8(0:0)"     // no comma after last

// close figs variable
];


// config.js


// **************************************************************
// *
// *           BASE VARIABLES
// *
// * These variables can be used to change the look of sequences
// * and even the way they are parsed.
// *
// * Changing these variables is at your own risk and might stop
// * the software from functioning!
// *
// **************************************************************

// **************
// Define active version number of OpenAero
// It is of format yyyy.x.z
// A new x should be used for versions that create sequences not
// fully backward compatible with the previous version

// platform holds various platform variables
var platform = {
  // platform.cordova is set to true here. It is set to false in
  // cordova.js when not compiled on Cordova
  cordova:   true,
  // set platform for Android, Chrome, iOS, Windows and UWP
  android:   /Android/i.test(navigator.userAgent),
  chrome:    false, // ((typeof chrome !== 'undefined') && chrome.fileSystem),
  ios:       /i(Pad|Phone|Pod)/i.test(navigator.userAgent),
  windows10: /Windows NT 1/.test(navigator.userAgent),
  windowsStore: 'ms-windows-store://pdp/?productid=9PDSX9ZDRB9B',
  uwp:       false, //window.Windows
};

/**********************************************************************
 *
 * Sequence drawing configuration
 *
 * Define lengths, radii etc
 *
 **********************************************************************/

// basic line element size
var lineElement = 10;
// basic curve size
var curveRadius = 40;
var rollSymbolSizes = {'medium': 8, 'large': 12};
const snapElement = 8
const spinElement = 8
function setRollSymbolSizes (sizeDescription) {
  var rollSymbolSize = rollSymbolSizes[sizeDescription];
  // roll curve size
  window.rollcurveRadius = rollSymbolSize * 2.5;
  // basic Snap element size
  window.snapElement = rollSymbolSize;
  // basic Spin element size
  window.spinElement = rollSymbolSize;
  // derived line element sizes, prevents many calculations during runtime
  window.lineElement075 = lineElement * 0.75;
  window.lineElement12 = lineElement * 1.2;
  window.lineElement2 = lineElement * 2;
  window.lineElement24 = lineElement * 2.4;
  window.lineElement3 = lineElement * 3;
  // derived snap element sizes, prevents many calculations during runtime
  window.snapElement017 = snapElement * 0.17;
  window.snapElement075 = snapElement * 0.75;
  window.snapElement12 = snapElement * 1.2;
  window.snapElement15 = snapElement * 1.5;
  window.snapElement2 = snapElement * 2;
  window.snapElement24 = snapElement * 2.4;
  window.snapElement3 = snapElement * 3;
  window.snapElementText = 0.5 + snapElement / 16;
  // derived spin element sizes, prevents many calculations during runtime
  window.spinElement12 = spinElement * 1.2;
  window.spinElement2 = spinElement * 2;
  window.spinElement24 = spinElement * 2.4;
  window.spinElement3 = spinElement * 3;
  window.spinElementText = 0.5 + spinElement / 16;
}
setRollSymbolSizes('medium');
// define golden ratio
Math.GR = 1.618;
// define A4 page ratio
Math.PageRatio = 1.4143;
// Tau is 2 times PI. Saves calculations during runtime
Math.Tau = Math.PI * 2;
// degToRad is Pi / 180. Saves calculations for degree to rad conversions
Math.degToRad = Math.PI / 180;
Math.radToDeg = 180 / Math.PI;
// define the offset for figures in the y axis in degrees
var yAxisOffsetDefault = 30;
// define the scale factor on the y axis for perspective drawing
var yAxisScaleFactor = 0.7;
// how much to flatten turns in the Y axis
var flattenTurn = 0.7;
// set scaleLine object to prevent calculations in makeLine
var scaleLine = {'x':1, 'y':1};
// newTurnPerspective is a checkbox in settings
var newTurnPerspective = { checked: false };
// how far apart the starts of figures should at least be
var minFigStartDist = lineElement * 3;
var minFigStartDistSq = minFigStartDist * minFigStartDist;

// *************
// Define styles and font sizes
// *************

// Default roll font size
var rollFontSize = 20;

// style holds the style objects
// !!! don't use 'entry' or 'exit', they are reserved !!!
var style = {
  // Positive line style
  'pos' : 'stroke: black; stroke-width: 1.5px; fill: none; vector-effect: non-scaling-stroke;',
  //'chooserPos' : 'stroke: black; stroke-width: 3px; fill: none;',
  // Negative line style
  'neg' : 'stroke-dasharray: 5, 3; stroke: red; stroke-width: 1.5px; fill: none; vector-effect: non-scaling-stroke;',
  'negBW' : 'stroke-dasharray: 4, 4; stroke: black; stroke-width: 1.5px; fill: none; vector-effect: non-scaling-stroke;',
  //'chooserNeg' : 'stroke-dasharray: 10, 6; stroke: red; stroke-width: 3px; fill: none;',
  // Black filled path style
  'blackfill' : 'stroke: black; stroke-width: 1px; fill: black;',
  // Positive filled path style
  'posfill' : 'stroke: black; stroke-width: 1.5px; fill: white;',
  // Negative filled path style
  'negfill' : 'stroke: black; stroke-width: 1.5px; fill: red;',
  'negfillBW' : 'stroke: black; stroke-width: 1.5px; fill: black;',
  // Dotted path style
  'dotted' : 'stroke-dasharray: 1, 3; stroke: black; stroke-width: 1px; fill: none; vector-effect: non-scaling-stroke;',
  // hiddenCurve
  'hiddenCurve' : 'stroke: transparent; stroke-width: 0; fill: none;',
  // Illegal figure cross style
  'illegalCross' : 'stroke: red; stroke-width: 3px; fill: none;',
  // Illegal figure box style
  'illegalBox' : 'stroke: black; stroke-width: 1px; fill: none;',
  // Autocorrect path style
  'corr' : 'stroke: red; stroke-width: 2px; fill: none;',
  // Autocorrect filled path style
  'corrfill' : 'stroke: red; stroke-width: 2px; fill: red;',
  // Roll text style
  'rollText' : 'font-family: Arial, Sans; font-size: ' + rollFontSize +
    'px; font-weight: bold; fill: red;',
  // Figure Number
  'figNbr_09' : 'font-family: Verdana, Helvetica, Sans; font-size: 14px; font-weight: bold; fill: black;',
  'figNbr_10' : 'font-family: Verdana, Helvetica, Sans; font-size: 12px; font-weight: bold; fill: black;',
  // Text block style
  'textBlock' : 'font-family: verdana, Helvetica, Sans; font-size: 20px; fill: black;',
  'textBlockBorder' : 'stroke: black; stroke-width: 1px; fill: none;',
  'textBlockBorderBold' : 'stroke: black; stroke-width: 2px; fill: none;',
  'textBlockBorderBoldRed' : 'stroke: red; stroke-width: 2px; fill: none;',
  // Mini Form A styles
  'miniFormA' : 'font-family: verdana, Helvetica, Sans; font-size: 10px; fill: black;',
  'miniFormABold' : 'font-family: verdana, Helvetica, Sans; font-size: 10px; font-weight: bold; fill: black;',
  'miniFormAMax' : 'font-family: verdana, Helvetica, Sans; font-size: 14px; fill: black;',
  'miniFormAModifiedK' : 'font-family: verdana, Helvetica, Sans; font-size: 10px; color: red; fill: red;',
  'miniFormASmall' : 'font-family: verdana, Helvetica, Sans; font-size: 8px; fill: black;',
  'miniFormATotal' : 'font-family: verdana, Helvetica, Sans; font-size: 16px; font-weight: bold; fill: black;',
  // Form A styles
  'formATextTiny' : 'font-family: verdana, Helvetica, Sans; font-size: 8px; fill: black;',
  'formATextSmall' : 'font-family: verdana, Helvetica, Sans; font-size: 10px; fill: black;',
  'formAText' : 'font-family: verdana, Helvetica, Sans; font-size: 12px; fill: black;',
  'formATextBold' : 'font-family: verdana, Helvetica, Sans; font-size: 12px; font-weight: bold; fill: black;',
  'formATextBold8px' : 'font-family: verdana, Helvetica, Sans; font-size: 8px; font-weight: bold; fill: black;',
  'formATextBold9px' : 'font-family: verdana, Helvetica, Sans; font-size: 9px; font-weight: bold; fill: black;',
  'formATextBold10px' : 'font-family: verdana, Helvetica, Sans; font-size: 10px; font-weight: bold; fill: black;',
  'formATextBold11px' : 'font-family: verdana, Helvetica, Sans; font-size: 11px; font-weight: bold; fill: black;',
  'formATextBold12px' : 'font-family: verdana, Helvetica, Sans; font-size: 12px; font-weight: bold; fill: black;',
  'formATextBold13px' : 'font-family: verdana, Helvetica, Sans; font-size: 13px; font-weight: bold; fill: black;',
  'formATextMedium' : 'font-family: verdana, Helvetica, Sans; font-size: 15px; fill: black;',
  'formATextLarge' : 'font-family: verdana, Helvetica, Sans; font-size: 18px; fill: black;',
  'formATextXL' : 'font-family: verdana, Helvetica, Sans; font-size: 21px; fill: black;',
  'formATextHuge' : 'font-family: verdana, Helvetica, Sans; font-size: 40px; fill: black;',	// Ajout Modif GG 2017
  'formATextBoldHuge' : 'font-family: verdana, Helvetica, Sans; font-size: 40px; font-weight: bold; fill: black;',
  'formLine' : 'stroke: black; stroke-width: 1px; fill: none;',
  'formLineBold' : 'stroke: black; stroke-width: 4px; fill: none;',
  // Print styles
  'formBackground' : 'fill: white;',
  'modifiedK' : 'font-family: monospace; font-size: 8px; color: red; fill: red;',
  'printNotes' : 'font-family: verdana, Helvetica, Sans; font-size: 24px; fill: black;',
  'sequenceString' : 'font-family: monospace; font-size: 8px; color: blue; fill: blue; word-wrap: break-word;',
  'sequenceStringBW' : 'font-family: monospace; font-size: 8px; color: grey; fill: grey; word-wrap: break-word;',
  'windArrow' : 'stroke: black; stroke-width: 1.5px; fill: white;',
  // Selection styles
  'selectedFigureBox' : 'stroke: #0000ff; stroke-width: 1; stroke-opacity: 0.7; fill: transparent',
  'selectedFigureHandle' : 'stroke: #0000ff; stroke-width: 1; fill: #0000ff; fill-opacity: 0.2'
}

/**********************************************************************
 *
 * Aresti and roll handling configuration
 *
 **********************************************************************/

// Superfamily definitions
// For every category the list of SF is defined below. The order MATTERS!
// The SF will be decided by the first aresti fig nr match
// Superfamilies are also used for creation of figure group proposals
var superFamilies = {
  unlimited : [
    [/^2\./,'2'],
    [/^5\./,'5'],
    [/^6\./,'6'],
    [/^1\./,'7'],
    [/^3\./,'7'],
    [/^7\./,'7'],
    [/^8\./,'7'],
    [/^0\./,'7']
  ],
  advanced : [
    [/^9\.11\./,'3'],
    [/^9\.12\./,'3'],
    [/^9\.9\./, '4'],
    [/^9\.10\./,'4'],
    [/^2\./,    '2'],
    [/^5\./,    '5'],
    [/^6\./,    '6'],
    [/^1\./,    '7'],
    [/^3\./,    '7'],
    [/^7\./,    '7'],
    [/^8\./,    '7'],
    [/^0\./,    '7']
  ]
};
superFamilies.yak52 = superFamilies.advanced;
superFamilies.intermediate = superFamilies.advanced;
superFamilies.glider = superFamilies.advanced;

// available rolls
var rollTypes = [
  ':none',
  '4:1/4',
  '2:1/2',
  '3:3/4',
  '1:1',
  '5:1 1/4',
  '6:1 1/2',
  '7:1 3/4',
  '9:2',
  '22:2x2',
  '32:3x2',
  '42:4x2'];
for (let i = 2; i < 9; i++) {
  rollTypes.push(i + '4:' + i + 'x4');
}
rollTypes.push('8:2x8');
for (let i = 2; i < 9; i++) {
  rollTypes.push((i*2) + '8:' + (i*2) + 'x8');
}

/**********************************************************************
 *
 * Sequence string configuration
 *
 **********************************************************************/

// ***************
// Define patterns for the user's OpenAero drawing string
// ***************

// Never use '#' as a pattern! It is used in various places in OpenAero
// as a placeholder for switch operations.
var userpat = {
  'comment' : '"',
  'additional' : '=',
  'curveTo' : '(',
  'flipNumber' : '|',
  'flipYaxis' : '/',
  'forward' : '~',
  'forwardshorten' : '<',
  'lineshorten' : '`',
  'longforward' : '+',
  'movedown' : '^',
  'moveforward' : '>',
  'moveto' : '[',
  'opproll' : ',',
  'rollext' : '.',
  'rollextshort' : "'",
  'sameroll' : ';',
  'scale' : '%',
  'subSequence' : '//',
  'switchDirX' : '>',
  'switchDirY' : '^',
  'switchFirstRoll' : ';>'
}

// ***************
// Define patterns for the software figure string
// ***************

var figpat = {
  'forward' : '\'',
  'longforward' : '~',
  'fullroll' : '_',
  'halfroll' : '^',
  'anyroll' : '&',
  'spinroll' : '$',
  'hammer' : 'h',
  'pushhammer' : 'H',
  'tailslidecanopy' : 't',
  'tailslidewheels' : 'T',
  'pointTip' : 'u',
  'pushPointTip' : 'U'
};

var drawAngles = {
  'd':45, 'v':90, 'z':135, 'm':180,
  'c':225, 'p':270, 'r':315, 'o':360,
  'D':-45, 'V':-90, 'Z':-135, 'M':-180,
  'C':-225, 'P':-270, 'R':-315, 'O':-360
};

var rollAttitudes = {'0':'', '45':'d', '90':'v', '135':'d',
  '180':'', '225':'id', '270':'iv', '315':'id'
};

// ****************
// define Regex patterns for drawing and sequence parsing
// ****************

const regexChangeDir = new RegExp ('[\\' + userpat.switchDirX + '\\' + userpat.switchDirY + ']')
// match all comments
const regexSwitchDirX = new RegExp ('\\' + userpat.switchDirX)
const regexSwitchDirY = new RegExp ('\\' + userpat.switchDirY)
const regexAdditional = new RegExp ('\\' + userpat.additional)
const regexTurn = /[0-9+-]j[io0-9+-]/
const regexRollBeforeBase = new RegExp ('^[\\+-][\\' + figpat.halfroll +
  '\\'  + figpat.fullroll + '\\' + figpat.anyroll + '\\' +
  figpat.spinroll + '\\' + figpat.longforward + ']'); // /^[\+\-][_\^\&\$\~]/
const regexTurnsAndRolls = new RegExp ('[\\d\\(\\)\\' + figpat.halfroll +
  '\\' + figpat.fullroll + '\\' + figpat.anyroll + '\\' +
  figpat.spinroll + '\\' + figpat.longforward + ']+', 'g') // /[\d\(\)_\^\&\$\~]+/g


/* Define entry/exit speeds of figures as follows:
* L for all descending entries and climbing exits
* H for all climbing entries and all descending exits
* N for all others
*/
const   regexSpeedConv = {
  'v' : /d?[vzmcpro]|dd|d\^[DVZMCPRO]/g,
  'V' : /D?[VZMCPRO]|DD|D\^[dvzmcpro]/g
}

const regexSpeed = {
  glider: {
    entry: {
      L : /^(\+([DV]|\^[dv])|-([dv]|\^[DV]))/,
      H : /^(\+([dv]|\^[DV])|-([DV]|\^[dv]))/
    },
    exit: {
      L : /(([DV]|[dv]\^)\+)|(([dv]|[DV]\^)-)$/,
      H : /(([dv]|[DV]\^)\+)|(([DV]|[dv]\^)-)$/
    }
  },
  power: {
    entry: {
      L : /^(\+([DV]|\^[dv])|-([dv]|\^[DV]))/,
      H : /^(\+([dv]|\^[DV])|-([DV]|\^[dv]))/
    },
    exit: {
      L : /(([DV]|[dv]\^)\+)|(([dv]|[DV]\^)-)$/,
      H : /(([dv]|[DV]\^)\+)|(([DV]|[dv]\^)-)$/
    }
  }
}

/* optionally, count 45 only as N. However, as this is decided on base
 * figure level, there is no way to differentiate e.g. 45 up with 4x4
 * which is clearly a H

  power: {
    entry: {
      L : /^(\+([DV]|\^[dv])|-([dv]|\^[DV]))/,
      H : /^(\+(v|\^V)|-(V|\^v))/
    },
    exit: {
      L : /(([V]|[v]\^)\+)|(([v]|[V]\^)-)$/,
      H : /(([dv]|[DV]\^)\+)|(([DV]|[dv]\^)-)$/
    }
  }

 */

/** end regex definitions */

// define IOC (International Olympic Commitee) countries for flags
var iocCountries = {"AD":"AND","AE":"UAE","AF":"AFG","AG":"ANT",
  "AI":"AIA","AL":"ALB","AM":"ARM","AO":"ANG","AQ":"ATA",
  "AR":"ARG","AS":"ASA","AT":"AUT","AU":"AUS","AW":"ARU",
  "AZ":"AZE","BA":"BIH","BB":"BAR","BD":"BAN","BE":"BEL","BF":"BUR",
  "BG":"BUL","BH":"BRN","BI":"BDI","BJ":"BEN","BM":"BER",
  "BN":"BRU","BO":"BOL","BR":"BRA","BS":"BAH","BT":"BHU",
  "BW":"BOT","BY":"BLR","BZ":"BIZ","CA":"CAN","CD":"COD",
  "CF":"CAF","CG":"CGO","CH":"SUI","CI":"CIV","CK":"COK","CL":"CHI",
  "CM":"CMR","CN":"CHN","CO":"COL","CR":"CRC","CU":"CUB","CV":"CPV",
  "CY":"CYP","CZ":"CZE","DE":"GER","DJ":"DJI","DK":"DEN",
  "DM":"DMA","DO":"DOM","DZ":"ALG","EC":"ECU","EE":"EST","EG":"EGY",
  "ER":"ERI","ES":"ESP","ET":"ETH","FI":"FIN","FJ":"FIJ",
  "FM":"FSM","FR":"FRA","GA":"GAB","GB":"GBR",
  "GD":"GRN","GE":"GEO","GH":"GHA",
  "GM":"GAM","GN":"GUI","GQ":"GEQ","GR":"GRE",
  "GT":"GUA","GU":"GUM","GW":"GBS","GY":"GUY","HK":"HKG",
  "HN":"HON","HR":"CRO","HT":"HAI","HU":"HUN","ID":"INA",
  "IE":"IRL","IL":"ISR","IN":"IND","IQ":"IRQ",
  "IR":"IRI","IT":"ITA","JM":"JAM","JO":"JOR",
  "JP":"JPN","KE":"KEN","KG":"KGZ","KH":"CAM","KI":"KIR","KM":"COM",
  "KN":"SKN","KP":"PRK","KR":"KOR","KW":"KUW","KY":"CAY","KZ":"KAZ",
  "LA":"LAO","LB":"LIB","LC":"LCA","LI":"LIE","LK":"SRI","LR":"LBR",
  "LS":"LES","LT":"LTU","LU":"LUX","LV":"ESA","LY":"LBA","MA":"MAR",
  "MC":"MON","MD":"MDA","ME":"MNE","MG":"MAD","MH":"MHL",
  "MK":"MKD","ML":"MLI","MM":"MYA","MN":"MGL",
  "MR":"MTN","MT":"MLT","MU":"MRI","MV":"MDV",
  "MW":"MAW","MX":"MEX","MY":"MAS","MZ":"MOZ","NA":"NAM",
  "NE":"NIG","NG":"NGR","NI":"NCA","NL":"NED","NO":"NOR",
  "NP":"NEP","NR":"NRU","NZ":"NZL","OM":"OMA","PA":"PAN",
  "PE":"PER","PG":"PNG","PH":"PHI","PK":"PAK","PL":"POL",
  "PR":"PUR","PS":"PLE","PT":"POR","PW":"PLW",
  "PY":"PAR","QA":"QAT","RO":"ROU","RS":"SRB","RU":"RUS",
  "RW":"RWA","SA":"KSA","SB":"SOL","SC":"SEY","SD":"SUD","SE":"SWE",
  "SG":"SIN","SI":"SLO","SK":"SVK","SL":"SLE",
  "SM":"SMR","SN":"SEN","SO":"SOM","SR":"SUR","ST":"STP","SV":"ESA",
  "SY":"SYR","SZ":"SWZ","TD":"CHA","TG":"TGO",
  "TH":"THA","TJ":"TJK","TL":"TLS","TM":"TKM","TN":"TUN",
  "TO":"TGA","TR":"TUR","TT":"TRI","TV":"TUV","TW":"TPE","TZ":"TAN",
  "UA":"UKR","UG":"UGA","US":"USA","UY":"URU","UZ":"UZB",
  "VC":"VIN","VE":"VEN","VG":"IVB","VI":"ISV","VN":"VIE",
  "VU":"VAN","WS":"SAM","YE":"YEM","ZA":"RSA",
  "ZM":"ZAM","ZW":"ZIM"};
// also for key and value reversed
var iocCountriesReverse = [];
for (let key in iocCountries) iocCountriesReverse[iocCountries[key]] = key;

// define iso countries for flags
var isoCountries = {"AD":"AND","AE":"ARE","AF":"AFG","AG":"ATG",
  "AI":"AIA","AL":"ALB","AM":"ARM","AN":"ANT","AO":"AGO","AQ":"ATA",
  "AR":"ARG","AS":"ASM","AT":"AUT","AU":"AUS","AW":"ABW","AX":"ALA",
  "AZ":"AZE","BA":"BIH","BB":"BRB","BD":"BGD","BE":"BEL","BF":"BFA",
  "BG":"BGR","BH":"BHR","BI":"BDI","BJ":"BEN","BL":"BLM","BM":"BMU",
  "BN":"BRN","BO":"BOL","BR":"BRA","BS":"BHS","BT":"BTN","BV":"BVT",
  "BW":"BWA","BY":"BLR","BZ":"BLZ","CA":"CAN","CC":"CCK","CD":"COD",
  "CF":"CAF","CG":"COG","CH":"CHE","CI":"CIV","CK":"COK","CL":"CHL",
  "CM":"CMR","CN":"CHN","CO":"COL","CR":"CRI","CU":"CUB","CV":"CPV",
  "CX":"CXR","CY":"CYP","CZ":"CZE","DE":"DEU","DJ":"DJI","DK":"DNK",
  "DM":"DMA","DO":"DOM","DZ":"DZA","EC":"ECU","EE":"EST","EG":"EGY",
  "EH":"ESH","ER":"ERI","ES":"ESP","ET":"ETH","FI":"FIN","FJ":"FJI",
  "FK":"FLK","FM":"FSM","FO":"FRO","FR":"FRA","GA":"GAB","GB":"GBR",
  "GD":"GRD","GE":"GEO","GF":"GUF","GG":"GGY","GH":"GHA","GI":"GIB",
  "GL":"GRL","GM":"GMB","GN":"GIN","GP":"GLP","GQ":"GNQ","GR":"GRC",
  "GS":"SGS","GT":"GTM","GU":"GUM","GW":"GNB","GY":"GUY","HK":"HKG",
  "HM":"HMD","HN":"HND","HR":"HRV","HT":"HTI","HU":"HUN","ID":"IDN",
  "IE":"IRL","IL":"ISR","IM":"IMN","IN":"IND","IO":"IOT","IQ":"IRQ",
  "IR":"IRN","IS":"ISL","IT":"ITA","JE":"JEY","JM":"JAM","JO":"JOR",
  "JP":"JPN","KE":"KEN","KG":"KGZ","KH":"KHM","KI":"KIR","KM":"COM",
  "KN":"KNA","KP":"PRK","KR":"KOR","KW":"KWT","KY":"CYM","KZ":"KAZ",
  "LA":"LAO","LB":"LBN","LC":"LCA","LI":"LIE","LK":"LKA","LR":"LBR",
  "LS":"LSO","LT":"LTU","LU":"LUX","LV":"LVA","LY":"LBY","MA":"MAR",
  "MC":"MCO","MD":"MDA","ME":"MNE","MF":"MAF","MG":"MDG","MH":"MHL",
  "MK":"MKD","ML":"MLI","MM":"MMR","MN":"MNG","MO":"MAC","MP":"MNP",
  "MQ":"MTQ","MR":"MRT","MS":"MSR","MT":"MLT","MU":"MUS","MV":"MDV",
  "MW":"MWI","MX":"MEX","MY":"MYS","MZ":"MOZ","NA":"NAM","NC":"NCL",
  "NE":"NER","NF":"NFK","NG":"NGA","NI":"NIC","NL":"NLD","NO":"NOR",
  "NP":"NPL","NR":"NRU","NU":"NIU","NZ":"NZL","OM":"OMN","PA":"PAN",
  "PE":"PER","PF":"PYF","PG":"PNG","PH":"PHL","PK":"PAK","PL":"POL",
  "PM":"SPM","PN":"PCN","PR":"PRI","PS":"PSE","PT":"PRT","PW":"PLW",
  "PY":"PRY","QA":"QAT","RE":"REU","RO":"ROU","RS":"SRB","RU":"RUS",
  "RW":"RWA","SA":"SAU","SB":"SLB","SC":"SYC","SD":"SDN","SE":"SWE",
  "SG":"SGP","SH":"SHN","SI":"SVN","SJ":"SJM","SK":"SVK","SL":"SLE",
  "SM":"SMR","SN":"SEN","SO":"SOM","SR":"SUR","ST":"STP","SV":"SLV",
  "SY":"SYR","SZ":"SWZ","TC":"TCA","TD":"TCD","TF":"ATF","TG":"TGO",
  "TH":"THA","TJ":"TJK","TK":"TKL","TL":"TLS","TM":"TKM","TN":"TUN",
  "TO":"TON","TR":"TUR","TT":"TTO","TV":"TUV","TW":"TWN","TZ":"TZA",
  "UA":"UKR","UG":"UGA","UM":"UMI","US":"USA","UY":"URY","UZ":"UZB",
  "VA":"VAT","VC":"VCT","VE":"VEN","VG":"VGB","VI":"VIR","VN":"VNM",
  "VU":"VUT","WF":"WLF","WS":"WSM","YE":"YEM","YT":"MYT","ZA":"ZAF",
  "ZM":"ZMB","ZW":"ZWE"};
// also for key and value reversed
var isoCountriesReverse = [];
for (let key in isoCountries) isoCountriesReverse[isoCountries[key]] = key;






// set y axis offset to the default
var yAxisOffset = 0
var Direction = 0
var Attitude = 0
var activeForm = 'G'
var figCheckLine = []
var figureStart = []
var additionals = 0
var figures = []
var activeSequence = {
  'text': '',
  'figures': [],
  'xml': null,
  'undo': [],
  'redo': [],
  'addUndo': true,
}
var OLAN = {
  bumpBugCheck: false,
  bumpBugFigs: [],
  nBugFigs: [],
  sequence: false,
  inFigureXSwitchFig: Infinity
}
var firstFigure = true
var scale = 1
var curvePerspective = true
var True_Drawing_Angle
var NegLoad = 0
var updateAxisDir = true
var goRight = true
var perspective_param = getEllipseParameters(yAxisOffsetDefault, yAxisScaleFactor)
var svgNS = "http://www.w3.org/2000/svg"
var xlinkNS = "http://www.w3.org/1999/xlink"
var unknownFigureLetter = false
var additionalFig = { 'max': 0, 'totalK': 0 };    // Additional figures, max and K
var SVGRoot = null
var goFront = true
var fig = []
var arestiToFig = {};
var userText = []
var figBaseLookup = [];
var figGroup = [];
var rollFig = {};
var rollArestiToFig = {};
var alertMsgs = [];
var X = 0 // X is x position on canvas
var Y = 0 // Y is y position on canvas
var selectedFigure = { 'id': null }
var TrueCoords = null;
var GrabPoint = null;




// dirAttToAngle creates an angle to draw from the values for direction
// and attitude.
// 0 or higher angles mean theta was in the right half, negative angles
// mean theta was in the left half => necessary for correct looping shapes
function dirAttToAngle(dir, att) {
  while (dir < 0) dir += 360;
  while (dir >= 360) dir -= 360;
  // Create offset for the Y-axis, determined by yAxisOffset
  var theta
  if (dir < 90) {
    theta = (dir * (yAxisOffset / 90));
  } else if (dir < 180) {
    theta = (dir - 90) * ((180 - yAxisOffset) / 90) + yAxisOffset;
  } else if (dir < 270) {
    theta = (dir - 180) * (yAxisOffset / 90) + 180;
  } else {
    theta = (dir - 270) * ((180 - yAxisOffset) / 90) + yAxisOffset + 180;
  }
  // No Y-axis correction for pure verticals
  if ((att === 90) || (att === 270)) {
    theta = ((theta < 90) || (theta > 270)) ? 0 : 180;
  }
  // Check for right or left half, calculate angle and make negative for left half
  var angle
  if ((theta < 90) || (theta > 270)) {
    angle = (theta + att) * Math.degToRad;
    if (angle > Math.Tau) {
      angle -= Math.Tau;
    } else if (angle < 0) {
      angle += Math.Tau;
    }
  } else {
    angle = (theta - att) * Math.degToRad;
    if (angle >= 0) {
      angle -= Math.Tau;
    } else if (angle < -Math.Tau) {
      angle += Math.Tau;
    }
  }
  return angle;
}

// changeDir changes Direction global by value
// and checks it stays within 0-359
function changeDir(value) {
  Direction += value;
  while (Direction < 0) Direction += 360;
  while (Direction >= 360) Direction -= 360;
}

// changeAtt changes Attitude global by value
// and checks it stays within 0-359
function changeAtt(value) {
  Attitude += value;
  while (Attitude < 0) Attitude += 360;
  while (Attitude >= 360) Attitude -= 360;
  // update goRight for attitude changes. We monitor the exit direction
  // but also the direction through the top or bottom when ending vert.
  // Don't update this time when updateAxisDir is false, used for
  // double bumps
  if (updateAxisDir) {
    if (((Direction === 0) || (Direction === 180)) && (value !== 0)) {
      goRight = (Direction === 0) ? false : true;
      if ((Attitude > 90) && (Attitude < 270)) {
        goRight = !goRight;
      } else if ((Attitude === 90) || (Attitude === 270)) {
        if ((Attitude === 90) === (value < 0)) goRight = !goRight;
      }
    }
  } else {
    updateAxisDir = true;
  }
}

// makeFigStart creates figure start marker
function makeFigStart(params) {
  var seqNr = params.seqNr;
  var first = params.first;

  var pathsArray = [];
  var angle = dirAttToAngle(Direction, Attitude);
  // Create a marker for possible automatic repositioning of the figure
  // start
  pathsArray.push({ 'figureStart': true });
  // Create the first figure mark if applicable
  var ref_rayon = 9;
  var open = Math.PI / 6;
  var rayon = ref_rayon;

  if (first && (activeForm !== 'A') && (activeForm !== 'G')) {
    pathsArray.push({ 'path': 'm 3,-6 a7,7 0 1 1 -6,0', 'style': 'pos' });
  }
  // Add the figure number, except on Form A
  if (seqNr && (activeForm !== 'A')) {
    pathsArray.push({
      'text': seqNr,
      'style': 'figNbr_09',
      'x': 0,
      'y': -8,
      'text-anchor': 'middle'
    });
  }
  // Make the marker
  pathsArray.push({
    'path': 'm -4,0 a4,4 0 1 1 0,0.01',
    'style': 'blackfill',
    'dx': Math.cos(angle) * 4,
    'dy': - Math.sin(angle) * 4
  })
  return pathsArray;
}

// makeFigStop creates figure stop
function makeFigStop(lastFig) {
  var pathArray = { style: 'pos' };
  var angle = (Direction + 90) * Math.degToRad;
  var dx = roundTwo(Math.cos(angle) * lineElement / scale);
  var dy = - roundTwo(Math.sin(angle) * lineElement / scale);
  if (lastFig) {
    var angle2 = dirAttToAngle(Direction, Attitude);
    var dx2 = roundTwo(Math.cos(angle2) * lineElement);
    var dy2 = - roundTwo(Math.sin(angle2) * lineElement);
    pathArray.path = 'm ' + (dx2 + dx * 2) + ',' + (dy2 + dy * 2) +
      ' l ' + (-4 * dx) + ',' + (-4 * dy);
    pathArray.dx = dx2;
    pathArray.dy = dy2;
  } else {
    pathArray.path = 'l ' + dx + ',' + dy + ' l ' + (-2 * dx) +
      ',' + (-2 * dy);
    pathArray.dx = 0;
    pathArray.dy = 0;
  }
  return Array(pathArray);
}

// makeFigSpace creates space after figure
// scaling should not affect movement, so divide by scale
function makeFigSpace(extent) {
  var angle = dirAttToAngle(Direction, Attitude);
  return Array({
    'path': '',
    'style': 'neg',
    'dx': roundTwo(Math.cos(angle) * (lineElement / scale) * extent),
    'dy': roundTwo(-Math.sin(angle) * (lineElement / scale) * extent)
  });
}

// makeVertSpace creates vertical space
// scaling should not affect movement, so divide by scale
function makeVertSpace(extent) {
  return Array({
    'path': '',
    'style': 'neg',
    'dx': 0,
    'dy': (lineElement / scale) * extent
  });
}

// makeLine creates lines
// Params:
// 0: line length
// 1: handle
// 2: style
function makeLine(Params) {
  var Extent = Params[0];
  var angle = dirAttToAngle(Direction, Attitude);
  var dx = roundTwo(Math.cos(angle) * lineElement * Extent);
  var dy = - roundTwo(Math.sin(angle) * lineElement * Extent);
  if (((Direction === 90) || (Direction === 270)) && curvePerspective) {
    dx = roundTwo(yAxisScaleFactor * dx);
    if (!((Attitude === 90) || (Attitude === 270))) {
      dy = roundTwo(yAxisScaleFactor * dy);
    }
    if (((Attitude === 45) || (Attitude === 315)) || ((Attitude === 225) || (Attitude === 135))) {
      angle -= yAxisOffset * Math.degToRad;
      dx = roundTwo(scaleLine.x * Math.cos(angle) * lineElement * Extent);
      if (yAxisOffset > 90) {
        dy = roundTwo((-scaleLine.y * Math.cos(angle) + Math.sin(angle)) * lineElement * Extent);
      } else {
        dy = - roundTwo((scaleLine.y * Math.cos(angle) + Math.sin(angle)) * lineElement * Extent);
      }
      True_Drawing_Angle = Math.atan(-dy / dx);
      if (dx < 0) True_Drawing_Angle += Math.PI;
    }
  } else True_Drawing_Angle = angle;
  return Array({
    'path': 'l ' + dx + ',' + dy,
    'style': style[Params[2]] ? Params[2] : (NegLoad === 0) ? 'pos' : 'neg',
    'class': 'line',
    'handle': Params[1],
    'dx': dx,
    'dy': dy
  });
}

// makeMove is similar to makeLine but only moves the pointer and
// creates no lines
function makeMove(Params) {
  var Extent = Params[0];
  var angle = dirAttToAngle(Direction, Attitude);
  return Array({
    'path': '',
    'style': '',
    'dx': roundTwo(Math.cos(angle) * lineElement * Extent),
    'dy': -roundTwo(Math.sin(angle) * lineElement * Extent)
  });
}

// makeCorner creates sharp corners. Actually it only changes direction,
// no lines are created
function makeCorner(param) {
  // make sure param is an Integer
  param = parseInt(param);
  changeAtt(param);
  NegLoad = (param >= 0) ? 0 : 1;
  return Array({
    'path': '',
    'style': (NegLoad === 0) ? 'pos' : 'neg'
  });
}


// getEllipseParameters gets the ellipse radius and orientation from
// the perspective angle and the Y axis scale factor
function getEllipseParameters(P_Angle, Y_Scale) {
  var V_orient, V_r_min, V_r_max, H_orient, H_r_min, H_r_max
  if ((P_Angle === 30) && (Y_Scale === 0.7)) {
    return {
      'x_radius': 0.559,
      'y_radius': 1.085,
      'rot_angle': 14.67,
      'H_x_radius': 1.184,
      'H_y_radius': 0.296,
      'H_rot_angle': -9.41
    };
  }
  if (Y_Scale === 1) {
    V_orient = (90 - P_Angle) / 2;
    V_r_min = Math.sqrt(1 - Math.sin(P_Angle * Math.degToRad));
    V_r_max = Math.sqrt(1 + Math.sin(P_Angle * Math.degToRad));
    H_orient = - P_Angle / 2;
    H_r_min = Math.sqrt(1 - Math.sin((90 - P_Angle) * Math.degToRad));
    H_r_max = Math.sqrt(1 + Math.sin((90 - P_Angle) * Math.degToRad));
    return {
      'x_radius': V_r_min,
      'y_radius': V_r_max,
      'rot_angle': V_orient,
      'H_x_radius': H_r_max,
      'H_y_radius': H_r_min,
      'H_rot_angle': H_orient
    };
  }
  var A = Y_Scale * Math.cos(P_Angle * Math.degToRad);
  var B = Y_Scale * Math.sin(P_Angle * Math.degToRad);
  // Parameters for perspective of elements in the vertical plane
  // (loops or loop parts)
  var theta = (Math.PI + Math.atan(-2 * B / (1 - Y_Scale * Y_Scale))) / 2;
  V_orient = roundTwo(90 - (180 * Math.atan((Math.sin(theta) +
    B * Math.cos(theta)) / (A * Math.cos(theta))) / Math.PI));
  V_r_max = roundTwo(Math.sqrt(Math.pow(A * Math.cos(theta), 2) +
    Math.pow(Math.sin(theta) + B * Math.cos(theta), 2)));
  theta += Math.PI / 2;
  V_r_min = roundTwo(Math.sqrt(Math.pow(A * Math.cos(theta), 2) +
    Math.pow(Math.sin(theta) + B * Math.cos(theta), 2)));
  // Parameters for perspective of elements in the horizontal plane
  // (turns or rolling turns)
  theta = Math.atan(2 * A / (1 - Y_Scale * Y_Scale)) / 2;
  H_r_max = roundTwo(Math.sqrt(Math.pow(Math.cos(theta) +
    A * Math.sin(theta), 2) + Math.pow(B * Math.sin(theta), 2)));
  theta += Math.PI / 2;
  H_r_min = roundTwo(Math.sqrt(Math.pow(Math.cos(theta) +
    A * Math.sin(theta), 2) + Math.pow(B * Math.sin(theta), 2)));
  H_orient = roundTwo(-90 - 180 * Math.atan((B * Math.sin(theta)) /
    (Math.cos(theta) + A * Math.sin(theta))) / Math.PI);
  // Returns both vertical and horizontal planes parameters
  return {
    'x_radius': V_r_min,
    'y_radius': V_r_max,
    'rot_angle': V_orient,
    'H_x_radius': H_r_max,
    'H_y_radius': H_r_min,
    'H_rot_angle': H_orient
  };
}

// dirAttToXYAngle modified from dirAttToAngle to just care about angle
// in a vertical "plan".
// dirAttToAngle creates an angle to draw from the values for direction
// and attitude
// 0 or higher angles mean theta was in the right half, negative angles
// mean theta was in the left half => necessary for correct looping shapes
function dirAttToXYAngle(dir, att) {
  while (dir < 0) dir += 360;
  while (dir >= 360) dir -= 360;
  // Create offset for the Y-axis, determined by yAxisOffset
  var theta = (dir < 180) ? 0 : 180;
  // No Y-axis correction for pure verticals
  if ((att === 90) || (att === 270)) {
    theta = ((theta < 90) || (theta > 270)) ? 0 : 180;
  }
  // Check for right or left half, calculate angle and make negative for
  // left half
  let angle
  if ((theta < 90) || (theta > 270)) {
    angle = (theta + att) * Math.degToRad;
    if (angle > Math.Tau) {
      angle -= Math.Tau;
    } else if (angle < 0) {
      angle += Math.Tau;
    }
  } else {
    angle = (theta - att) * Math.degToRad;
    if (angle >= 0) {
      angle -= Math.Tau;
    } else if (angle < -Math.Tau) {
      angle += Math.Tau;
    }
  }
  True_Drawing_Angle = angle;
  return angle;
}

// dirAttToGGAngle modified from dirAttToAngle to neutralize perspective
// angle adjustments.
// dirAttToAngle creates an angle to draw from the values for direction
// and attitude
// 0 or higher angles mean theta was in the right half, negative angles
// mean theta was in the left half => necessary for correct looping shapes
function dirAttToGGAngle(dir, att) {
  while (dir < 0) dir += 360;
  while (dir >= 360) dir -= 360;
  // Don't create offset for the Y-axis related to yAxisOffset
  var theta = dir;  // perspective neutralisation
  // No Y-axis correction for pure verticals
  if ((att === 90) || (att === 270)) {
    theta = ((theta < 90) || (theta > 270)) ? 0 : 180;
  }
  // Check for right or left half, calculate angle and make negative for
  // left half
  let angle
  if ((theta < 90) || (theta > 270)) {
    angle = (theta + att) * Math.degToRad;
    if (angle > Math.Tau) {
      angle -= Math.Tau;
    } else if (angle < 0) {
      angle += Math.Tau;
    }
  } else {
    angle = (theta - att) * Math.degToRad;
    if (angle >= 0) {
      angle -= Math.Tau;
    } else if (angle < -Math.Tau) {
      angle += Math.Tau;
    }
  }
  return angle;
}

// myGetBBox accepts an element and returns the bBox for the element and
// bBoxes for it's child elements
function myGetBBox(e) {
  var bBox = e.getBBox();
  // add right, bottom and nodes
  bBox.right = bBox.x + bBox.width;
  bBox.bottom = bBox.y + bBox.height;
  bBox.nodes = [];
  var nodes = e.childNodes;
  for (var i = 0; i < nodes.length; i++) {
    bBox.nodes[i] = nodes[i].getBBox();
    // add right and bottom
    bBox.nodes[i].right = bBox.nodes[i].x + bBox.nodes[i].width;
    bBox.nodes[i].bottom = bBox.nodes[i].y + bBox.nodes[i].height;
  }
  return bBox;
}

// makeCurve creates curves of up to 359 degrees
// This is used for all looping shapes
// param is the angle in whole degrees
function makeCurve(param) {
  // make sure param is an Integer
  var params
  if (param.angle) {
    params = param;
    param = parseInt(param.angle);
  } else {
    params = {};
    param = parseInt(param);
  }

  // Define some variables
  var
    pathArray = [],
    Extent = Math.abs(param),
    PullPush = (param >= 0) ? 0 : 1,
    Radius = curveRadius,
    longCurve = (Extent > 180) ? 1 : 0,
    // Calculate at which angle the curve starts
    radStart = dirAttToAngle(Direction, Attitude),
    radStartXY = dirAttToXYAngle(Direction, Attitude);

  NegLoad = PullPush;

  if (params.style) {
    pathArray.style = params.style;
  } else {
    pathArray.style = (NegLoad === 0) ? 'pos' : 'neg';
  }

  changeAtt(param);

  // Calculate at which angle the curve stops
  var radStop = dirAttToAngle(Direction, Attitude);
  var radStopXY = dirAttToXYAngle(Direction, Attitude);
  // See if we are curving left or right, depending on radStart and PullPush
  var curveRight = (radStart >= 0) ? 0 : 1;
  if (PullPush === 1) curveRight = 1 - curveRight;
  var dx, dy
  if (curveRight === 0) {
    dx = (Math.sin(radStop) - Math.sin(radStart)) * Radius;
    dy = (Math.cos(radStop) - Math.cos(radStart)) * Radius;
  } else {
    dx = (Math.sin(radStop + Math.PI) - Math.sin(radStart + Math.PI)) * Radius;
    dy = (Math.cos(radStop + Math.PI) - Math.cos(radStart + Math.PI)) * Radius;
  }
  var sweepFlag = curveRight
  // Make the path and move the cursor
  if (((Direction === 90) || (Direction === 270)) && curvePerspective) {
    curveRight = (radStartXY >= 0) ? 0 : 1;
    if (PullPush === 1) curveRight = 1 - curveRight;
    dx = yAxisScaleFactor * ((Math.sin(radStopXY) - Math.sin(radStartXY))) * Radius;
    dy = (Math.cos(radStopXY) - Math.cos(radStartXY)) * Radius;
    if (curveRight === 1) {
      dx = -dx;
      dy = -dy;
    }
    var Rot_axe_Ellipse = (yAxisOffset < 90) ? perspective_param.rot_angle : -perspective_param.rot_angle;
    var X_axis_Radius = perspective_param.x_radius * Radius;
    var Y_axis_Radius = perspective_param.y_radius * Radius;
    dy -= dx * Math.sin(yAxisOffset * Math.degToRad);
    dx = dx * Math.cos(yAxisOffset * Math.degToRad);
    pathArray.path = 'a' + roundTwo(X_axis_Radius) + ',' +
      roundTwo(Y_axis_Radius) + ' ' + Rot_axe_Ellipse + ' ' + longCurve +
      ' ' + sweepFlag + ' ' + roundTwo(dx) + ',' + roundTwo(dy);
  } else {
    pathArray.path = 'a' + Radius + ',' + Radius + ' 0 ' + longCurve +
      ' ' + sweepFlag + ' ' + roundTwo(dx) + ',' + roundTwo(dy);
  }
  pathArray.dx = dx;
  pathArray.dy = dy;
  return Array(pathArray);
}

// makeRollTopLine creates the small lines around rolls in the top
function makeRollTopLine() {
  var pathArray = [];
  var angle = dirAttToAngle(Direction, Attitude + 90);
  var dx = roundTwo(Math.cos(angle) * window.lineElement075);
  var dy = - roundTwo(Math.sin(angle) * window.lineElement075);
  pathArray.path = 'l ' + dx + ',' + dy + ' l ' + (-2 * dx) +
    ',' + (-2 * dy);
  pathArray.style = 'pos';
  pathArray.dx = pathArray.dy = 0;
  return Array(pathArray);
}

// Code for making (rolling) turns
// This has to be changed in the future to improve the look of the code
// For now we keep it like this as it does work

// makeTurnArc creates arc segments for turns and rolling circles.
// Size is in DRAWN rads
function makeTurnArc(rad, startRad, stopRad, pathsArray) {
  while (startRad < 0) startRad += Math.Tau;
  while (startRad >= Math.Tau) startRad -= Math.Tau;
  while (stopRad < 0) stopRad += Math.Tau;
  while (stopRad >= Math.Tau) stopRad -= Math.Tau;

  var sign = (rad >= 0) ? 1 : -1;

  if (!newTurnPerspective.checked) {
    // calculate where we are in the ellipse
    let radEllipse = Math.atan(-1 / (Math.tan(startRad) / flattenTurn));
    // as the atan function only produces angles between -PI/2 and PI/2 we
    // may have to correct for full ellipse range
    if ((startRad > Math.PI) && (startRad < Math.Tau)) {
      radEllipse += Math.PI;
    }
    const startX = Math.cos(radEllipse) * curveRadius;
    const startY = - (Math.sin(radEllipse) * curveRadius * flattenTurn);
    // calculate where we go to in the ellipse
    radEllipse = Math.atan(-1 / (Math.tan(stopRad) / flattenTurn));
    if ((stopRad > Math.PI) && (stopRad < Math.Tau)) {
      radEllipse += Math.PI;
    }
    const stopX = Math.cos(radEllipse) * curveRadius;
    const stopY = - (Math.sin(radEllipse) * curveRadius * flattenTurn);
    const dx = roundTwo(stopX - startX) * sign;
    const dy = roundTwo(stopY - startY) * sign;
    const sweepFlag = (rad > 0) ? 0 : 1;
    const longCurve = (Math.abs(rad) < Math.PI) ? 0 : 1;
    if ((Attitude > 90) && (Attitude < 270)) {
      pathsArray.push({
        'path': 'a ' + curveRadius + ',' +
          roundTwo(curveRadius * flattenTurn) + ' 0 ' + longCurve + ' ' +
          sweepFlag + ' ' + dx + ',' + dy, 'style': 'neg', 'dx': dx, 'dy': dy
      });
    } else {
      pathsArray.push({
        'path': 'a ' + curveRadius + ',' +
          roundTwo(curveRadius * flattenTurn) + ' 0 ' + longCurve + ' ' +
          sweepFlag + ' ' + dx + ',' + dy, 'style': 'pos', 'dx': dx, 'dy': dy
      });
    }
  } else {
    // Always draw in perspactive (rolling) turns, no matter which is the
    // value of curvePerspective
    var Rot_axe_Ellipse = (yAxisOffset < 90) ? perspective_param.H_rot_angle : -perspective_param.H_rot_angle;
    var X_curveRadius = roundTwo(perspective_param.H_x_radius * curveRadius);
    var Y_curveRadius = roundTwo(perspective_param.H_y_radius * curveRadius);
    let dy = yAxisScaleFactor * (Math.cos(stopRad) - Math.cos(startRad));
    const dx = roundTwo((Math.sin(stopRad) - Math.sin(startRad) - dy * Math.cos(yAxisOffset * Math.degToRad)) * curveRadius) * sign;
    dy = roundTwo(dy * Math.sin(yAxisOffset * Math.degToRad) * curveRadius) * sign;
    const sweepFlag = (rad > 0) ? 0 : 1;
    const longCurve = (Math.abs(rad) < Math.PI) ? 0 : 1;
    if ((Attitude > 90) && (Attitude < 270)) {
      pathsArray.push({
        'path': 'a ' + X_curveRadius + ',' +
          Y_curveRadius + ' ' + Rot_axe_Ellipse + ' ' + longCurve + ' ' +
          sweepFlag + ' ' + dx + ',' + dy, 'style': 'neg', 'dx': dx, 'dy': dy
      });
    } else {
      pathsArray.push({
        'path': 'a ' + X_curveRadius + ',' +
          Y_curveRadius + ' ' + Rot_axe_Ellipse + ' ' + longCurve + ' ' +
          sweepFlag + ' ' + dx + ',' + dy, 'style': 'pos', 'dx': dx, 'dy': dy
      });
    }
  }
  return pathsArray;
}

// makeTurnDots creates dotted arc segments for turns and rolling circles.
// Size is in DRAWN rads
function makeTurnDots(rad, startRad, stopRad, pathsArray) {
  while (startRad >= Math.Tau) startRad -= Math.Tau;
  while (stopRad >= Math.Tau) stopRad -= Math.Tau;

  const sign = (rad >= 0) ? 1 : -1;
  if (!newTurnPerspective.checked) {
    // calculate where we are in the ellipse
    let radEllipse = Math.atan(-1 / (Math.tan(startRad) / flattenTurn));
    // as the atan function only produces angles between -PI/2 and PI/2
    // we may have to correct for full ellipse range
    if ((startRad > Math.PI) && (startRad < Math.Tau)) {
      radEllipse += Math.PI;
    }
    const startX = Math.cos(radEllipse) * curveRadius;
    const startY = - (Math.sin(radEllipse) * curveRadius * flattenTurn);
    // calculate where we go to in the ellipse
    radEllipse = Math.atan(-1 / (Math.tan(stopRad) / flattenTurn));
    if ((stopRad > Math.PI) && (stopRad < Math.Tau)) {
      radEllipse += Math.PI;
    }
    const stopX = Math.cos(radEllipse) * curveRadius;
    const stopY = - (Math.sin(radEllipse) * curveRadius * flattenTurn);
    const dx = roundTwo(stopX - startX) * sign;
    const dy = roundTwo(stopY - startY) * sign;
    const sweepFlag = (rad > 0) ? 0 : 1;
    const longCurve = (Math.abs(rad) < Math.PI) ? 0 : 1;
    pathsArray.push({
      'path': 'a ' + curveRadius + ',' +
        roundTwo(curveRadius * flattenTurn) + ' 0 ' + longCurve + ' ' +
        sweepFlag + ' ' + dx + ',' + dy, 'style': 'dotted'
    });
  } else {
    // Always draw in perspactive (rolling) turns, no matter which is
    // the value of curvePerspective
    var Rot_axe_Ellipse = (yAxisOffset < 90) ? perspective_param.H_rot_angle : -perspective_param.H_rot_angle;
    var X_curveRadius = roundTwo(perspective_param.H_x_radius * curveRadius);
    var Y_curveRadius = roundTwo(perspective_param.H_y_radius * curveRadius);
    let dy = yAxisScaleFactor * (Math.cos(stopRad) - Math.cos(startRad));
    const dx = roundTwo((Math.sin(stopRad) - Math.sin(startRad) - dy * Math.cos(yAxisOffset * Math.degToRad)) * curveRadius) * sign;
    dy = roundTwo(dy * Math.sin(yAxisOffset * Math.degToRad) * curveRadius) * sign;
    const sweepFlag = (rad > 0) ? 0 : 1;
    const longCurve = (Math.abs(rad) < Math.PI) ? 0 : 1;
    pathsArray.push({
      'path': 'a ' + X_curveRadius + ',' +
        Y_curveRadius + ' ' + Rot_axe_Ellipse + ' ' + longCurve + ' ' +
        sweepFlag + ' ' + dx + ',' + dy, 'style': 'dotted'
    });
  }
  return pathsArray;
}

// makeTurnRoll creates rolls in rolling turns. Basically a minimal version of makeRoll
// param is the amount of roll degrees
function makeTurnRoll(param, rad) {
  const rollcurveRadius = window.rollcurveRadius
  if (!!newTurnPerspective.checked) {
    // Define the size of the arrow and its tip
    var arrow_tip_width = 5;
    var arrow_tip_length = Math.PI / 4.5;
    var arrow_length = Math.PI / 9;
    var turn_rollcurveRadius = rollcurveRadius * 2;
  }
  var pathsArray = [];
  var extent = Math.abs(param);
  var sign = param > 0 ? 1 : -1;
  var sweepFlag = param > 0 ? 1 : 0;
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  var dx, dy, radPoint
  if (!newTurnPerspective.checked) {
    // Make the tip shape
    radPoint = rad + sign * (Math.PI / 3.5);
    const dxTip = ((Math.cos(radPoint) - radCos) * rollcurveRadius);
    const dyTip = -((Math.sin(radPoint) - radSin) * rollcurveRadius);
    let path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' ';
    radPoint = rad + sign * (Math.PI / 6);
    dx = (((Math.cos(radPoint) * (rollcurveRadius + 4)) - (radCos * rollcurveRadius))) - dxTip;
    dy = -(((Math.sin(radPoint) * (rollcurveRadius + 4)) - (radSin * rollcurveRadius))) - dyTip;
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    dx = (((Math.cos(radPoint) * (rollcurveRadius - 4)) - (radCos * rollcurveRadius))) - dx - dxTip;
    dy = -(((Math.sin(radPoint) * (rollcurveRadius - 4)) - (radSin * rollcurveRadius))) - dy - dyTip;
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' z';
    pathsArray.push({ 'path': path, 'style': 'blackfill' });

    // Calculate at which angle the curve starts and stops
    radPoint = (extent >= 360) ? rad - sign * (Math.PI / 6) : rad;
    dx = (Math.cos(radPoint) - radCos) * rollcurveRadius - dxTip;
    dy = -(Math.sin(radPoint) - radSin) * rollcurveRadius - dyTip;
    // Make the curved path
    path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' a ' +
      rollcurveRadius + ',' + rollcurveRadius + ' 0 0 ' + sweepFlag +
      ' ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    pathsArray.push({ 'path': path, 'style': 'pos' });
  } else {
    var persp_Sin = Math.sin(yAxisOffset * Math.degToRad);
    var persp_Cos = Math.cos(yAxisOffset * Math.degToRad);
    // get ellipse parameters
    var rot_axe_Ellipse = (yAxisOffset < 90) ? perspective_param.H_rot_angle : -perspective_param.H_rot_angle;
    var X_rollcurveRadius = roundTwo(perspective_param.H_x_radius * turn_rollcurveRadius);
    var Y_rollcurveRadius = roundTwo(perspective_param.H_y_radius * turn_rollcurveRadius);
    // Make the tip shape
    radPoint = rad + sign * arrow_tip_length;
    const dxTip = ((Math.cos(radPoint) - radCos) * turn_rollcurveRadius);
    const dyTip = -((Math.sin(radPoint) - radSin) * turn_rollcurveRadius);
    var el_dxTip = dxTip - yAxisScaleFactor * dyTip * persp_Cos;
    var el_dyTip = yAxisScaleFactor * dyTip * persp_Sin;
    let path = 'm ' + roundTwo(el_dxTip) + ',' + roundTwo(el_dyTip) + ' ';
    radPoint = rad + sign * arrow_length;
    dx = (((Math.cos(radPoint) * (turn_rollcurveRadius + arrow_tip_width)) - (radCos * turn_rollcurveRadius))) - dxTip;
    dy = -(((Math.sin(radPoint) * (turn_rollcurveRadius + arrow_tip_width)) - (radSin * turn_rollcurveRadius))) - dyTip;
    var el_dx = dx - yAxisScaleFactor * dy * persp_Cos;
    var el_dy = yAxisScaleFactor * dy * persp_Sin;
    path += 'l ' + roundTwo(el_dx) + ',' + roundTwo(el_dy) + ' ';
    dx = (((Math.cos(radPoint) * (turn_rollcurveRadius - arrow_tip_width)) - (radCos * turn_rollcurveRadius))) - dx - dxTip;
    dy = -(((Math.sin(radPoint) * (turn_rollcurveRadius - arrow_tip_width)) - (radSin * turn_rollcurveRadius))) - dy - dyTip;
    el_dx = dx - yAxisScaleFactor * dy * persp_Cos;
    el_dy = yAxisScaleFactor * dy * persp_Sin;
    path += 'l ' + roundTwo(el_dx) + ',' + roundTwo(el_dy) + ' z';
    pathsArray.push({ 'path': path, 'style': 'blackfill' });

    // Calculate at which angle the curve starts and stops
    radPoint = (extent >= 360) ? rad - sign * arrow_length : rad;
    dx = (Math.cos(radPoint) - radCos) * turn_rollcurveRadius - dxTip;
    dy = -(Math.sin(radPoint) - radSin) * turn_rollcurveRadius - dyTip;
    el_dx = dx - yAxisScaleFactor * dy * persp_Cos;
    el_dy = yAxisScaleFactor * dy * persp_Sin;
    // Make the curved path
    path = 'm ' + roundTwo(el_dxTip) + ',' + roundTwo(el_dyTip) + ' a ' +
      X_rollcurveRadius + ',' + Y_rollcurveRadius + ' ' + rot_axe_Ellipse + ' 0 ' + sweepFlag +
      ' ' + roundTwo(el_dx) + ',' + roundTwo(el_dy) + ' ';
    pathsArray.push({ 'path': path, 'style': 'pos' });
  }
  return pathsArray;
}

// makeTurn builds turns and rolling circles from the draw instructions
// parsed from fig[i].draw
function makeTurn(draw) {
  // parse base
  var pathsArray = [];
  // Check if we are in an in/out or out/in roll
  let regex = /io|IO/;
  var switchRollDir = regex.test(draw);
  var sign = 1;
  var numbers = draw.replace(/[^\d]+/g, '');
  var extent = parseInt(numbers.charAt(0)) * 90;
  // Set the default exit direction
  var rollDir
  if ((extent === 90) || (extent === 270)) {
    var dirChange = sign * extent;
    changeDir(dirChange);
    if ((Direction === 0) || (Direction === 180)) {
      // Set depending on goRight on X axis
      if ((((Direction === 0) === (Attitude === 0)) !== goRight) === (!/^[CL]/.test(activeForm))) {
        sign = -sign;
        rollDir = -rollDir;
      }
    } else {
      // Set towards viewer on Y axis
      if (((Direction === 90) === (Attitude === 0)) === (!/^[CL]/.test(activeForm))) {
        sign = -sign;
        rollDir = -rollDir;
      }
    }
    changeDir(-dirChange);
  } else {
    if ((Direction === 0) || (Direction === 180)) {
      // towards viewer X-to-X axis
      if (((Direction === 0) === (Attitude < 180)) === (!/^[CL]/.test(activeForm))) {
        sign = -sign;
        rollDir = -rollDir;
      }
    } else {
      // according goRight Y-to-Y axis
      if ((((Direction === 90) === (Attitude === 0)) !== goRight) === (!/^[CL]/.test(activeForm))) {
        sign = -sign;
        rollDir = -rollDir;
      }
    }
  }
  // Check if the exit direction is flipped
  if (draw.charAt(0) === userpat.moveforward) {
    sign = -sign;
  }

  // See if we start with an outside roll
  regex = /J/;
  rollDir = regex.test(draw) ? -sign : sign;
  // See if we start inverted, this will also flip the drawing direction
  if (Attitude === 180) rollDir = -rollDir;

  // use for wingover
  var newAttitude = Attitude;
  if (extent === 180) {
    newAttitude = 360 - Attitude;
    if (newAttitude === 360) newAttitude = 0;
  }

  // See if direction change is called for by the preparsed draw string
  var stopRad, startRad
  if (newAttitude !== Attitude) {
    // for wingover, HACK
    stopRad = dirAttToXYAngle(Direction + (sign * extent), newAttitude);
    startRad = dirAttToXYAngle(Direction, Attitude);
  } else {
    stopRad = dirAttToAngle(Direction + (sign * extent), newAttitude);
    startRad = dirAttToAngle(Direction, Attitude);
  }
  if (stopRad < 0) stopRad += Math.Tau;
  if (startRad < 0) startRad += Math.Tau;
  const startRadSave = startRad;
  var rad = sign * stopRad - sign * startRad;
  if (rad <= 0) rad += Math.Tau;
  if (numbers.length > 1) {
    // rolling turns
    var steps = 0;
    var rolls = 0;
    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] === '5') {
        rolls += 0.5;
        steps++;
      } else {
        rolls += parseInt(numbers[i]);
        steps += parseInt(numbers[i]);
      }
    }
    var step = rad / steps;
    var halfStepSigned = sign * (step / 2);
    var rollPos = 1;
    for (let i = 0; i < rad; i += step) {
      pathsArray = makeTurnArc(halfStepSigned, startRad, startRad + halfStepSigned, pathsArray);
      startRad += halfStepSigned;
      let rollPaths;
      if (numbers[rollPos] === '5') {
        rollPaths = makeTurnRoll(180 * rollDir, startRad);
        changeDir(180);
        changeAtt(180);
        if (switchRollDir) rollDir = -rollDir;
      } else {
        rollPaths = makeTurnRoll(360 * rollDir, startRad);
        if (switchRollDir) rollDir = -rollDir;
      }
      for (var j = 0; j < rollPaths.length; j++) {
        pathsArray.push(rollPaths[j]);
      }
      pathsArray = makeTurnArc(halfStepSigned, startRad, startRad + halfStepSigned, pathsArray);
      startRad += halfStepSigned;
      rollPos++;
    }
    if (extent !== 360) {
      pathsArray = makeTurnDots(sign * (Math.Tau - rad), stopRad, startRadSave, pathsArray);
    }
    changeDir(sign * extent);
  } else {
    // regular turns
    if (extent !== 360) {
      pathsArray = makeTurnArc(sign * rad, startRad, stopRad, pathsArray);
      pathsArray = makeTurnDots(sign * (Math.Tau - rad), stopRad, startRad, pathsArray);
      // build turn extent text with degree sign in unicode
      // not always exactly centered: fixme: improve code
      var dx, dy
      if (!newTurnPerspective.checked) {
        dx = -sign * (Math.sin(stopRad)) * curveRadius;
        dy = -sign * (Math.cos(stopRad)) * curveRadius * flattenTurn;
      } else {
        var X_curveRadius = roundTwo(perspective_param.H_x_radius * curveRadius);
        var Y_curveRadius = roundTwo(perspective_param.H_y_radius * curveRadius);
        dx = -sign * (Math.sin(stopRad)) * X_curveRadius;
        dy = -sign * (Math.cos(stopRad)) * Y_curveRadius + rollFontSize / 3;
      }

      pathsArray.push({
        'text': extent + "\u00B0",
        'style': 'rollText',
        'x': dx,
        'y': dy,
        'text-anchor': 'middle'
      });
      changeDir(sign * extent);
      // used for wingover
      Attitude = newAttitude;
    } else {
      pathsArray = makeTurnArc(sign * Math.PI, startRad, startRad + Math.PI, pathsArray);
      changeDir(180);
      pathsArray = makeTurnArc(sign * Math.PI, startRad + Math.PI, startRad, pathsArray);
      changeDir(180);
    }
  }
  return pathsArray;
}

// makeRollText is a helper function for makeRoll, makeSnap and makeSpin.
// It creates the text (2x8, 3/4 etc) and also comments next to rolls,
// snaps and spins.
// It returns the text as a pathArray, or false for no text
function makeRollText(extent, stops, sign, comment, radSin, radCos) {
  // don't flip text by default and start with empty comment text
  var flipText = false;
  var text = '';
  var dx, dy
  const rollcurveRadius = window.rollcurveRadius

  // check for roll comment
  if (comment) {
    // check for roll text flip
    if (comment[0] === userpat.flipNumber) {
      flipText = true;
      comment = comment.substring(1);
    }
    text = comment;
  }

  if ((extent % 180) || (stops > 0) || (text.length > 0)) {
    if (stops > 0) {
      if (text.length > 0) text = ' ' + text;
      if (extent !== 360) text = 'x' + stops + text;
      text = (extent / (360 / stops)) + text;
    } else if (extent % 180) {
      if (text.length > 0) text = ' ' + text;
      text = ((extent % 360) / 90) + '/4' + text;
    }
    if (flipText) {
      if (extent >= 360) { // make room for 'tail' of roll symbol
        dx = sign * (radSin * (rollcurveRadius + ((rollFontSize / 5) * text.length)));
        dy = sign * (radCos * (rollcurveRadius + rollFontSize / 2)) + (rollFontSize / 5) + 1;
      } else {             // no tail
        dx = sign * (radSin * (rollcurveRadius / 2 + ((rollFontSize / 5) * text.length)));
        dy = sign * (radCos * (rollcurveRadius / 2 + rollFontSize / 2)) + (rollFontSize / 5) + 1;
      }
    } else {
      if (extent > 360) { // make room for roll connect line
        dx = -sign * (radSin * (rollcurveRadius + 4 + ((rollFontSize / 4) * text.length)));
        dy = -sign * (radCos * (rollcurveRadius + 4 + rollFontSize / 3)) + (rollFontSize / 4) + 2;
      } else {
        dx = -sign * (radSin * (rollcurveRadius + ((rollFontSize / 4) * text.length)));
        dy = -sign * (radCos * (rollcurveRadius + rollFontSize / 3)) + (rollFontSize / 4) + 2;
      }
    }
  }

  if (text !== '') {
    return ({ 'text': text, 'style': 'rollText', 'x': dx, 'y': dy, 'text-anchor': 'middle' });
  } else {
    return false;
  }
}

// makeRoll creates aileron rolls
// params is an array:
// [0] is the amount of degrees. A negative value changes the direction of roll
// [1] is hesitations in fractions of full roll
// [2] is optional roll in top argument, false or non-present = not in top, true = in top
// [3] is optional glider super slow roll argument, true = slow roll
// [4] is optional autocorrect roll argument, true = autocorrect roll
// [5] is optional comment
// [6] is optional generic roll symbol	1 = roll, 2 = half roll, 3 = any roll, 4 = any roll or spin
// Example: (270,4) would be a 3x4 roll
function makeRoll(params) {
  const rollcurveRadius = window.rollcurveRadius
  var
    pathsArray = [],
    stops = params[1],
    extent = Math.abs(params[0]),
    sign = params[0] > 0 ? 1 : -1,
    sweepFlag = params[0] > 0 ? 1 : 0;
  if (params.length > 2) var rollTop = params[2];
  var rad = dirAttToAngle(Direction, Attitude);
  if (((Attitude === 45) || (Attitude === 315)) || ((Attitude === 225) || (Attitude === 135))) {
    rad = True_Drawing_Angle;
  }
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  // distinguish for autocorrect rolls
  var style
  if (params[4]) {
    style = ['corrfill', 'corr'];
    // draw circle around roll
    pathsArray.push({
      'path': 'm -' + (rollcurveRadius * 1.2) + ',0 a' +
        (rollcurveRadius * 1.2) + ',' + (rollcurveRadius * 1.2) +
        ' 0 1 1 0,0.01', 'style': style[1]
    });
  } else {
    style = ['blackfill', 'pos'];
  }
  let dxTip, dyTip, radPoint, dx, dy
  while (extent > 0) {
    // Make the tip shape
    radPoint = rad + sign * (Math.PI / 3.5);
    dxTip = ((Math.cos(radPoint) - radCos) * rollcurveRadius);
    dyTip = -((Math.sin(radPoint) - radSin) * rollcurveRadius);
    let path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' ';
    radPoint = rad + sign * (Math.PI / 6);
    dx = (((Math.cos(radPoint) * (rollcurveRadius + 4)) - (radCos * rollcurveRadius))) - dxTip;
    dy = -(((Math.sin(radPoint) * (rollcurveRadius + 4)) - (radSin * rollcurveRadius))) - dyTip;
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    dx = (((Math.cos(radPoint) * (rollcurveRadius - 4)) - (radCos * rollcurveRadius))) - dx - dxTip;
    dy = -(((Math.sin(radPoint) * (rollcurveRadius - 4)) - (radSin * rollcurveRadius))) - dy - dyTip;
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' z';
    // only show arrow tip when params[6] is not trueish
    if (!params[6]) pathsArray.push({ 'path': path, 'style': style[0] });

    // Make the second tip for glider super slow rolls
    if (params[3]) {
      radPoint = rad + sign * (Math.PI / 2.5);
      dxTip = ((Math.cos(radPoint) - radCos) * rollcurveRadius);
      dyTip = -((Math.sin(radPoint) - radSin) * rollcurveRadius);
      path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' ';
      radPoint = rad + sign * (Math.PI / 3.5);
      dx = (((Math.cos(radPoint) * (rollcurveRadius + 4)) - (radCos * rollcurveRadius))) - dxTip;
      dy = -(((Math.sin(radPoint) * (rollcurveRadius + 4)) - (radSin * rollcurveRadius))) - dyTip;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = (((Math.cos(radPoint) * (rollcurveRadius - 4)) - (radCos * rollcurveRadius))) - dx - dxTip;
      dy = -(((Math.sin(radPoint) * (rollcurveRadius - 4)) - (radSin * rollcurveRadius))) - dy - dyTip;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' z';
      pathsArray.push({ 'path': path, 'style': style[0] });
    }

    // Calculate at which angle the curve starts and stops
    radPoint = (extent >= 360) ?
      rad - sign * (Math.PI / (params[6] ? 3.5 : 6)) : // make roll symmetrical or not
      rad;
    dx = (Math.cos(radPoint) - radCos) * rollcurveRadius - dxTip;
    dy = -(Math.sin(radPoint) - radSin) * rollcurveRadius - dyTip;
    // Make the curved path
    path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' a ' +
      rollcurveRadius + ',' + rollcurveRadius + ' 0 0 ' + sweepFlag +
      ' ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    pathsArray.push({ 'path': path, 'style': style[1] });

    if (params[6] > 2) {	// if "any roll" or spin => Draw the 2 little lines for "any rolls" and, if spin, the drawing "spin allowed".
      var
        G_len = roundTwo(rollcurveRadius / 2),
        G_pos = roundTwo(rollcurveRadius / 5);	// Length and spacing of the 2 little lines for "any rolls".
      path = 'm ' + (dxTip + (dxTip > 0 ? G_pos : -G_pos)) +
        ',' + (dyTip + 0.5 * G_len) + ' v ' + -G_len +
        ' m ' + (dxTip > 0 ? G_pos : -G_pos) + ',0 v ' + G_len;
      pathsArray.push({ 'path': path, 'style': style[1] });
      if (params[6] === 4) {	// Draw the drawing "spin allowed".
        G_pos = rollcurveRadius / 15;
        path = 'm ' + roundTwo(-dxTip +
          (dx > 0 ? 2 * G_len + G_pos : -2 * G_len - G_pos)) + ',' +
          roundTwo(dy - 0.7 * G_len) + ' h ' + roundTwo(2 * G_len) +
          ' l ' + roundTwo(-2 * G_len) + ',' + roundTwo(G_len);
        pathsArray.push({ 'path': path, 'style': style[1] });
      }
    }

    // Where necessary, show the roll numbers after completing the first
    // roll point and arc.
    // This is only necessary for rolls that are not multiples of 180 or
    // have hesitations
    // Also add any user defined comment here
    if (extent === Math.abs(params[0])) {
      var rollText = makeRollText(
        extent,
        stops,
        sign,
        params[5],
        radSin,
        radCos
      );
      if (rollText) pathsArray.push(rollText);
    }
    // Completed the first (full) roll. Continue for more than 360
    extent -= 360;
    // For more than 360 degrees, draw a line between the rolls and the
    // roll tip connect line
    if (extent > 0) {
      // Make the line between the two rolls.
      // Only move the pointer (no line) for rolls in the top
      if (rollTop) {
        pathsArray = buildShape('Move', [1 / scale], pathsArray);
      } else {
        pathsArray = buildShape('Line', [1 / scale], pathsArray);
      }
      // Get the relative movement by the line and use this to build the
      // tip additional line
      dx = pathsArray[pathsArray.length - 1].dx;
      dy = pathsArray[pathsArray.length - 1].dy;
      // glider super slow roll or regular roll
      radPoint = params[3] ? rad + sign * (Math.PI / 2) : rad + sign * (Math.PI / 3);

      dxTip = (((Math.cos(radPoint) * (rollcurveRadius + 2)) - (radCos * rollcurveRadius)));
      dyTip = -(((Math.sin(radPoint) * (rollcurveRadius + 2)) - (radSin * rollcurveRadius)));
      if (params[3]) {
        // glider super slow roll
        path = 'm ' + roundTwo(dxTip + dx / 2) + ',' + roundTwo(dyTip + dy / 2) +
          ' l ' + roundTwo(-dx * 1.5) + ',' + roundTwo(-dy * 1.5);
      } else {
        // regular roll
        path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' l ' +
          roundTwo(-dx) + ',' + roundTwo(-dy);
      }
      pathsArray.push({ 'path': path, 'style': style[1] });
    }
  }
  return pathsArray;
}

// makeSnap creates snap rolls
// params is an array:
// [0] is the amount of degrees. A negative value changes the direction of snap
// [1] indicates pos or neg snap. 0=pos 1=neg
// [2] is optional roll in top argument, false or non-present = not in top, true = in top
// [3] is optional comment
// Examples: (270,0) is a 3/4 pos snap. (180,1) is a 1/2 neg snap
function makeSnap(params) {
  var
    pathsArray = [],
    extent = Math.abs(params[0]),
    sign = params[0] > 0 ? 1 : -1,
    rad = dirAttToAngle(Direction, Attitude);
  if (((Attitude === 45) || (Attitude === 315)) || ((Attitude === 225) || (Attitude === 135))) {
    rad = True_Drawing_Angle;
  }
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  if (params.length > 2) var rollTop = params[2];

  // tipFactor makes sure the tip symbol is exactly on the tip,
  // considering default line thickness
  var tipFactor = window.snapElement2 / (window.snapElement2 + 0.75);

  var dxTip, dyTip, dx, dy
  while (extent > 0) {
    // Make the base shape
    dxTip = -radSin * (window.snapElement2 + 0.75) * sign;
    dyTip = -radCos * (window.snapElement2 + 0.75) * sign;
    let path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' ';
    dx = radCos * snapElement;
    dy = -radSin * snapElement;
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    pathsArray.push({ 'path': path, 'style': 'pos' });
    path = 'm ' + roundTwo(dxTip * tipFactor) + ',' + roundTwo(dyTip * tipFactor) + ' ';
    if (extent >= 360) { // full snap symbol
      dx = (radCos * window.snapElement12) + (radSin * window.snapElement3 * sign);
      dy = (- radSin * window.snapElement12) + (radCos * window.snapElement3 * sign);
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = (- radCos) * window.snapElement24;
      dy = radSin * window.snapElement24;
    } else { // half snap symbol
      dx = (radCos * snapElement) + (radSin * window.snapElement2 * sign);
      dy = (- radSin * snapElement) + (radCos * window.snapElement2 * sign);
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = (- radCos) * window.snapElement2;
      dy = radSin * window.snapElement2;
    }
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' z';
    const pathStyle = (params[1] === 0) ? 'posfill' : 'negfill';
    pathsArray.push({
      'path': path,
      'style': pathStyle,
      'dx': radCos * window.snapElement075,
      'dy': -radSin * window.snapElement075
    });

    // Where necessary, show the roll numbers after completing the first
    // roll point and arc.
    // This is only necessary for rolls that are not multiples of 180
    if (extent === Math.abs(params[0])) {
      var rollText = makeRollText(
        extent,
        0,
        sign,
        params[3],
        radSin * window.snapElementText,
        radCos * window.snapElementText
      );
      if (rollText) pathsArray.push(rollText);
    }
    // Completed the first (full) roll. Continue for more than 360
    extent -= 360;
    // For more than 360 degrees, draw a line between the rolls and the
    // roll tip connect line
    if (extent > 0) {
      // Save the status of the load variable, don't want to change that
      // during the roll
      var saveLoad = NegLoad;
      // Make the line between the two rolls
      // Only move the pointer for rolls in the top
      if (rollTop) {
        pathsArray = buildShape('Move', [window.snapElement017 / scale], pathsArray);
      } else {
        pathsArray = buildShape('Line', [window.snapElement017 / scale], pathsArray);
      }
      NegLoad = saveLoad;
      // Get the relative movement by the line and use this to build the
      // tip additional line
      dx = pathsArray[pathsArray.length - 1].dx + radCos * window.snapElement2;
      dy = pathsArray[pathsArray.length - 1].dy - radSin * window.snapElement2;
      dxTip = -radSin * window.snapElement24 * sign + radCos * snapElement;
      dyTip = -radCos * window.snapElement24 * sign - radSin * snapElement;
      path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' l ' +
        roundTwo(-dx) + ',' + roundTwo(-dy);
      pathsArray.push({ 'path': path, 'style': 'pos' });
    }
  }

  return pathsArray;
}

// makeSpin creates spins
// params is an array:
// [0] is the amount of degrees. A negative value changes the direction of spin
// [1] indicates pos or neg spin. 0=pos 1=neg
// [2] is optional roll in top argument, false or non-present = not in top, true = in top
// [3] is optional comment
// Examples: (270,0) is a 3/4 pos spin. (540,1) is a 1 1/2 neg spin
function makeSpin(params) {
  var pathsArray = [];
  var extent = Math.abs(params[0]);
  var sign = params[0] > 0 ? 1 : -1;
  var rad = dirAttToAngle(Direction, Attitude);
  if (params.length > 2) var rollTop = params[2];
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  const spinElement2 = window.spinElement2

  // tipFactor makes sure the tip symbol is exactly on the tip,
  // considering default line thickness
  var tipFactor = spinElement2 / (spinElement2 - 0.75);

  var dxTip, dyTip, dx, dy
  while (extent > 0) {
    // Make the base shape
    // First make the tip line
    dxTip = -radSin * (spinElement2 - 0.75) * sign;
    dyTip = -radCos * (spinElement2 - 0.75) * sign;

    let path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' ';
    dx = radCos * spinElement;
    dy = -radSin * spinElement;
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    pathsArray.push({ 'path': path, 'style': 'pos' });
    // Next make the triangle
    path = 'm ' + roundTwo(dxTip * tipFactor) + ',' + roundTwo(dyTip * tipFactor) + ' ';
    if (extent >= 360) {
      dx = (radCos * spinElement * 1.5) + (radSin * window.spinElement3 * sign);
      dy = (- radSin * spinElement * 1.5) + (radCos * window.spinElement3 * sign);
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = (- radCos) * spinElement * 1.5;
      dy = radSin * spinElement * 1.5;
    } else {
      dx = (radCos * spinElement) + (radSin * spinElement2 * sign);
      dy = (- radSin * spinElement) + (radCos * spinElement2 * sign);
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = (- radCos) * spinElement;
      dy = radSin * spinElement;
    }
    path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' z';
    let pathStyle
    if (params[1] === 0) pathStyle = 'posfill'; else pathStyle = 'negfill';
    pathsArray.push({
      'path': path,
      'style': pathStyle,
      'dx': radCos * spinElement,
      'dy': -radSin * spinElement
    });
    // Where necessary, show the roll numbers after completing the first
    // roll point and arc.
    // This is only necessary for spins that are not multiples of 180
    if (extent === Math.abs(params[0])) {
      var rollText = makeRollText(
        extent,
        0,
        sign,
        params[3],
        radSin * window.spinElementText,
        radCos * window.spinElementText
      );
      if (rollText) pathsArray.push(rollText);
    }
    // Completed the first (full) spin. Continue for more than 360
    extent -= 360;
    // For more than 360 degrees, draw a line between the spins and the
    // spin tip connect line
    if (extent > 0) {
      // Make the line between the two rolls. Always positive for now
      // Only move the pointer for rolls in the top
      if (rollTop) {
        pathsArray = buildShape('Move', [0.5 / scale], pathsArray);
      } else {
        pathsArray = buildShape('Line', [0.5 / scale], pathsArray);
      }
      // Get the relative movement by the line and use this to build the
      // tip additional line
      dx = pathsArray[pathsArray.length - 1].dx + radCos * spinElement2;
      dy = pathsArray[pathsArray.length - 1].dy - radSin * spinElement2;
      dxTip = -radSin * window.spinElement24 * sign + radCos * spinElement;
      dyTip = -radCos * window.spinElement24 * sign - radSin * spinElement;
      path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' l ' +
        roundTwo(-dx) + ',' + roundTwo(-dy);
      pathsArray.push({ 'path': path, 'style': 'pos' });
    }
  }
  return pathsArray;
}

// makeShoulderRoll creates shoulder rolls
// params is an array:
// [0] is the amount of degrees. A negative value changes the direction
// [1] indicates pos or neg. 0=pos 1=neg
// [2] is optional roll in top argument, false or non-present = not in
//     top, true = in top
// [3] is optional comment
function makeShoulderRoll(params) {
  var pathsArray = [];
  var extent = Math.abs(params[0]);
  var sign = params[0] > 0 ? 1 : -1;
  var rad = dirAttToAngle(Direction, Attitude);
  if (((Attitude === 45) || (Attitude === 315)) || ((Attitude === 225) || (Attitude === 135))) {
    rad = True_Drawing_Angle;
  }
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  if (params.length > 2) var rollTop = params[2];

  let dx, dy, dxTip, dyTip
  const snapElement15 = window.snapElement15
  while (extent > 0) {
    // Make the base shape
    dx = roundTwo((radCos * snapElement15) - (radSin * snapElement15 * sign));
    dy = roundTwo(-(radCos * snapElement15 * sign) - (radSin * snapElement15));
    let path = 'a' + snapElement15 + ',' + snapElement15 + ' 0 0,' +
      (sign === 1 ? 1 : 0) + ' ' + dx + ',' + dy;
    dx = roundTwo((radCos * snapElement15) + (radSin * snapElement15 * sign));
    dy = roundTwo((radCos * snapElement15 * sign) - (radSin * snapElement15));
    path += ' l ' + dx + ',' + dy + ' ';

    if (extent >= 360) { // full shoulder roll symbol
      dx = radSin * window.snapElement075 * sign;
      dy = radCos * window.snapElement075 * sign;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = -radCos * window.snapElement3;
      dy = radSin * window.snapElement3;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    }
    path += 'z';
    const pathStyle = (params[1] === 0) ? 'posfill' : 'negfill';
    pathsArray.push({
      'path': path,
      'style': pathStyle,
      'dx': radCos * window.snapElement3,
      'dy': -radSin * window.snapElement3
    });

    // Where necessary, show the roll numbers after completing the first
    // roll point and arc.
    // This is only necessary for rolls that are not multiples of 180
    if (extent === Math.abs(params[0])) {
      var rollText = makeRollText(
        extent,
        0,
        sign,
        params[3],
        radSin,
        radCos
      );
      if (rollText) pathsArray.push(rollText);
    }
    // Completed the first (full) roll. Continue for more than 360
    extent -= 360;
    // For more than 360 degrees, draw a line between the rolls and the
    // roll tip connect line
    if (extent > 0) {
      // Save the status of the load variable, don't want to change that
      // during the roll
      var saveLoad = NegLoad;
      // Make the line between the two rolls
      // Only move the pointer for rolls in the top
      if (rollTop) {
        pathsArray = buildShape('Move', [0.5 / scale], pathsArray);
      } else {
        pathsArray = buildShape('Line', [0.5 / scale], pathsArray);
      }
      NegLoad = saveLoad;
      // Get the relative movement by the line and use this to build the
      // tip additional line
      dx = pathsArray[pathsArray.length - 1].dx + radCos * window.snapElement3;
      dy = pathsArray[pathsArray.length - 1].dy - radSin * window.snapElement3;
      dxTip = -radSin * window.snapElement2 * sign + radCos * snapElement;
      dyTip = -radCos * window.snapElement2 * sign - radSin * snapElement;
      path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' l ' +
        roundTwo(-dx) + ',' + roundTwo(-dy);
      pathsArray.push({ 'path': path, 'style': 'pos' });
    }
  }
  return pathsArray;
}

// makeRuade creates ruades
// params is an array:
// [0] is the amount of degrees. A negative value changes the direction
// [1] indicates pos or neg. 0=pos 1=neg
// [2] is optional roll in top argument, false or non-present = not in
//     top, true = in top
// [3] is optional comment
function makeRuade(params) {
  var pathsArray = [];
  var extent = Math.abs(params[0]);
  var sign = params[0] > 0 ? 1 : -1;
  var rad = dirAttToAngle(Direction, Attitude);
  if (((Attitude === 45) || (Attitude === 315)) || ((Attitude === 225) || (Attitude === 135))) {
    rad = True_Drawing_Angle;
  }
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  if (params.length > 2) var rollTop = params[2];

  var dx, dy, dxTip, dyTip
  const snapElement15 = window.snapElement15
  while (extent > 0) {
    // Make the base shape
    dx = roundTwo(-radSin * snapElement15 * sign);
    dy = roundTwo(-radCos * snapElement15 * sign);
    let path = 'l' + dx + ',' + dy;
    dx = roundTwo((radCos * window.snapElement3) + (radSin * snapElement15 * sign));
    dy = roundTwo((radCos * snapElement15 * sign) - (radSin * window.snapElement3));
    path += ' l ' + dx + ',' + dy + ' ';

    if (extent >= 360) { // full ruade symbol
      dx = radSin * window.snapElement075 * sign;
      dy = radCos * window.snapElement075 * sign;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = -radCos * window.snapElement3;
      dy = radSin * window.snapElement3;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    }
    path += 'z';
    const pathStyle = (params[1] === 0) ? 'posfill' : 'negfill';
    pathsArray.push({
      'path': path,
      'style': pathStyle,
      'dx': radCos * window.snapElement3,
      'dy': -radSin * window.snapElement3
    });

    // Where necessary, show the roll numbers after completing the first
    // roll point and arc.
    // This is only necessary for rolls that are not multiples of 180
    if (extent === Math.abs(params[0])) {
      var rollText = makeRollText(
        extent,
        0,
        sign,
        params[3],
        radSin,
        radCos
      );
      if (rollText) pathsArray.push(rollText);
    }
    // Completed the first (full) roll. Continue for more than 360
    extent -= 360;
    // For more than 360 degrees, draw a line between the rolls and the
    // roll tip connect line
    if (extent > 0) {
      // Save the status of the load variable, don't want to change that
      // during the roll
      var saveLoad = NegLoad;
      // Make the line between the two rolls
      // Only move the pointer for rolls in the top
      if (rollTop) {
        pathsArray = buildShape('Move', [0.5 / scale], pathsArray);
      } else {
        pathsArray = buildShape('Line', [0.5 / scale], pathsArray);
      }
      NegLoad = saveLoad;
      // Get the relative movement by the line and use this to build the
      // tip additional line
      dx = pathsArray[pathsArray.length - 1].dx + radCos * window.snapElement3;
      dy = pathsArray[pathsArray.length - 1].dy - radSin * window.snapElement3;
      dxTip = -radSin * window.snapElement2 * sign + radCos * snapElement;
      dyTip = -radCos * window.snapElement2 * sign - radSin * snapElement;
      path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' l ' +
        roundTwo(-dx) + ',' + roundTwo(-dy);
      pathsArray.push({ 'path': path, 'style': 'pos' });
    }
  }
  return pathsArray;
}

// makeLomcevak creates Lomcevaks
// params is an array:
// [0] is the amount of degrees. A negative value changes the direction
// [1] indicates pos or neg. 0=pos 1=neg
// [2] is optional roll in top argument, false or non-present = not in
//     top, true = in top
// [3] is optional comment
function makeLomcevak(params) {
  var pathsArray = [];
  var stops = params[1];
  var extent = Math.abs(params[0]);
  var sign = params[0] > 0 ? 1 : -1;
  var rad = dirAttToAngle(Direction, Attitude);
  if (((Attitude === 45) || (Attitude === 315)) || ((Attitude === 225) || (Attitude === 135))) {
    rad = True_Drawing_Angle;
  }
  // calculate sin and cos for rad once to save calculation time
  var radSin = Math.sin(rad);
  var radCos = Math.cos(rad);
  if (params.length > 2) var rollTop = params[2];

  let dx, dy, dxTip, dyTip
  const snapElement15 = window.snapElement15
  while (extent > 0) {
    // Make the base shape
    dx = roundTwo(-radSin * snapElement15 * sign);
    dy = roundTwo(-radCos * snapElement15 * sign);
    let path = 'l' + dx + ',' + dy;
    dx = roundTwo(radCos * window.snapElement3);
    dy = roundTwo(-radSin * window.snapElement3);
    path += 'l' + dx + ',' + dy;
    dx = roundTwo(-(radCos * snapElement15) + (radSin * snapElement15 * sign));
    dy = roundTwo((radCos * snapElement15 * sign) + (radSin * snapElement15));
    path += 'a' + snapElement15 + ',' + snapElement15 + ' 0 0,' +
      (sign === 1 ? 0 : 1) + ' ' + dx + ',' + dy;
    dx = roundTwo(radCos * snapElement15);
    dy = roundTwo(-radSin * snapElement15);
    path += 'l' + dx + ',' + dy;

    if (extent >= 360) { // full Lomcevak symbol
      dx = radSin * window.snapElement075 * sign;
      dy = radCos * window.snapElement075 * sign;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
      dx = -radCos * window.snapElement3;
      dy = radSin * window.snapElement3;
      path += 'l ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ';
    }
    path += 'z';
    const pathStyle = (params[1] === 0) ? 'posfill' : 'negfill';
    pathsArray.push({
      'path': path,
      'style': pathStyle,
      'dx': radCos * window.snapElement3,
      'dy': -radSin * window.snapElement3
    });

    // Where necessary, show the roll numbers after completing the first
    // roll point and arc.
    // This is only necessary for rolls that are not multiples of 180
    if (extent === Math.abs(params[0])) {
      var rollText = makeRollText(
        extent,
        0,
        sign,
        params[3],
        radSin,
        radCos
      );
      if (rollText) pathsArray.push(rollText);
    }
    // Completed the first (full) roll. Continue for more than 360
    extent -= 360;
    // For more than 360 degrees, draw a line between the rolls and the
    // roll tip connect line
    if (extent > 0) {
      // Save the status of the load variable, don't want to change that
      // during the roll
      var saveLoad = NegLoad;
      // Make the line between the two rolls
      // Only move the pointer for rolls in the top
      if (rollTop) {
        pathsArray = buildShape('Move', [0.5 / scale], pathsArray);
      } else {
        pathsArray = buildShape('Line', [0.5 / scale], pathsArray);
      }
      NegLoad = saveLoad;
      // Get the relative movement by the line and use this to build the
      // tip additional line
      dx = pathsArray[pathsArray.length - 1].dx + radCos * window.snapElement3;
      dy = pathsArray[pathsArray.length - 1].dy - radSin * window.snapElement3;
      dxTip = -radSin * window.snapElement2 * sign + radCos * snapElement;
      dyTip = -radCos * window.snapElement2 * sign - radSin * snapElement;
      path = 'm ' + roundTwo(dxTip) + ',' + roundTwo(dyTip) + ' l ' +
        roundTwo(-dx) + ',' + roundTwo(-dy);
      pathsArray.push({ 'path': path, 'style': 'pos' });
    }
  }
  return pathsArray;
}

// makeHammer creates hammerhead tops
// extent is the size of the line before the hammerhead top.
// We will move that much down before continuing drawing
function makeHammer(extent) {
  var pathArray = {
    style: 'pos',
    class: 'hammerTip',
    dx: 0,
    dy: lineElement * extent
  };
  Attitude = 270;
  changeDir(180);
  if ((Direction === 90) || (Direction === 270)) {
    const dy = roundTwo((1 - scaleLine.y) * lineElement);
    const dx = roundTwo(scaleLine.x * lineElement);
    pathArray.path = "l " + (dx) + "," + (dy);
  } else {
    pathArray.path = "l " + (lineElement) + "," + (lineElement);
  }
  return Array(pathArray);
}

// makeTailslide creates tailslide tops
// param gives the type as a string
function makeTailslide(param) {
  var pathsArray = [[], []];
  var sweepFlag = 1;
  NegLoad = 0;
  var angle = dirAttToAngle(Direction, Attitude);
  if (param === figpat.tailslidewheels) angle = -angle;
  sweepFlag = (angle > 0) ? 1 : 0;
  pathsArray[0].style = (param === figpat.tailslidecanopy) ? 'pos' : 'neg';
  var Radius = curveRadius;
  let dx = (angle > 0) ? -Radius : Radius;
  let dy = Radius;
  // Make the path and move the cursor
  var Rot_axe_Ellipse
  var X_axis_Radius
  var Y_axis_Radius
  if (((Direction === 90) || (Direction === 270)) && curvePerspective) {
    Rot_axe_Ellipse = (yAxisOffset < 90) ? perspective_param.rot_angle : -perspective_param.rot_angle;
    X_axis_Radius = perspective_param.x_radius * Radius;
    Y_axis_Radius = perspective_param.y_radius * Radius;
    dx *= scaleLine.x;
    dy -= dx * scaleLine.y;
    if (yAxisOffset > 90) dx = -dx;

    pathsArray[0].path = 'a' + roundTwo(X_axis_Radius) + ',' +
      roundTwo(Y_axis_Radius) + ' ' + Rot_axe_Ellipse + ' 0 ' +
      sweepFlag + ' ' + roundTwo(dx) + ',' + roundTwo(dy);
  } else {
    pathsArray[0].path = 'a' + Radius + ',' + Radius + ' 0 0 ' +
      sweepFlag + ' ' + dx + ',' + dy;
  }
  pathsArray[0].dx = dx;
  pathsArray[0].dy = dy;
  Radius = curveRadius / 2;
  dx = (angle > 0) ? Radius : -Radius;
  dy = Radius;
  if (((Direction === 90) || (Direction === 270)) && curvePerspective) {
    Rot_axe_Ellipse = (yAxisOffset < 90) ? perspective_param.rot_angle : -perspective_param.rot_angle;
    X_axis_Radius = perspective_param.x_radius * Radius;
    Y_axis_Radius = perspective_param.y_radius * Radius;
    dx *= scaleLine.x;
    dy -= dx * scaleLine.y;
    if (yAxisOffset > 90) dx = -dx;

    pathsArray[1].path = 'a' + roundTwo(X_axis_Radius) + ',' +
      roundTwo(Y_axis_Radius) + ' ' + Rot_axe_Ellipse + ' 0 ' +
      sweepFlag + ' ' + roundTwo(dx) + ',' + roundTwo(dy);
  } else {
    pathsArray[1].path = 'a' + Radius + ',' + Radius + ' 0 0 ' +
      sweepFlag + ' ' + dx + ',' + dy;
  }
  pathsArray[1].style = 'pos';
  pathsArray[1].dx = dx;
  pathsArray[1].dy = dy;
  Attitude = 270;
  return pathsArray;
}

// makePointTip creates pointed tops (non-Aresti)
// extent is the size of the line before the top.
// We will move that much down before continuing drawing
function makePointTip(extent) {
  changeAtt(180);
  changeDir(180);
  return Array({
    path: 'M 0,0', // make sure it is "drawn", as a drag handle marker
    style: 'pos',
    class: 'pointTip',
    dx: 0,
    dy: lineElement * extent
  });
}

// makeTextBlock makes text blocks, for example from comments.
// It also handles assignment of figure letter codes for Free (un)known
// operations.
function makeTextBlock(text) {
  var pathsArray = [];
  var rotate = false;

  // handle special code for Free (Un)known figure designation
  var match = text.match(/^@[A-Z]/);
  if (match) {
    text = text.replace(match[0], '');
    unknownFigureLetter = match[0].replace('@', '');
  }
  // handle special code for Unknown additional
  if (text.match(/^additional$/)) {
    text = '';
    unknownFigureLetter = 'L';
  }

  // remove letter if it is an additional figure and none are allowed
  if ((unknownFigureLetter === 'L') && !additionalFig.max) {
    unknownFigureLetter = false;
  }

  if (text !== '') {

    // Parse special codes
    // Not all OLAN special codes are supported yet. They will be
    // filtered out
    var lineNr = 0;
    var line = '';
    var header = true;
    var styleId = 'textBlockBorder';

    for (var i = 0; i < text.length; i++) {
      switch (text[i]) {
        // anywhere tags
        case ('\\'): // new line
          line = '';
          lineNr++;
          break;
        case ('#'): // aresti numbers and K
          break;
        case ('&'): // figure string
          break;
        // header only tags
        case ('_'): // put text block UNDER subsequent figure
        case ('~'): // put text block ABOVE subsequent figure
          if (header) {
            break;
          }
        case ('['): // make text block bold ( [ ) or red ( [[ )
          if (header) {
            styleId = 'textBlockBorderBold';
            if (text[i + 1]) {
              if (text[i + 1] === '[') {
                styleId = 'textBlockBorderBoldRed';
                i++;
              }
            }
            break;
          }
        case ('^'): // place block vertically
          if (header) {
            rotate = 90;
            break;
          }
        // no tag, add character
        default:
          line += text[i];
          header = false;
      }
    }

    var h = 200;
    var w = 50;

    // calculate where the center of the box should be
    // rotation is taken into account
    // current angle = angle to box center
    var angle = dirAttToAngle(Direction, Attitude);
    // find distance from box edge to center in this direction
    var s = Math.sin(angle);
    var c = Math.cos(angle);
    if (s === 0) { // horizontal
      var d = w / 2;
    } else if (c === 0) { // vertical
      var d = h / 2;
    } else { // other, choose shortest
      var d = Math.min(Math.abs((w / 2) / c), Math.abs((h / 2) / s));
    }

    // set top-left x and y of the text box
    var x = roundTwo((c * d) - (w / 2));
    var y = - roundTwo((s * d) + (h / 2));

    // set dx and dy for starting the figure after the text box
    var dx = roundTwo(2 * c * d);
    var dy = - roundTwo(2 * s * d);

    // draw rectangle around text
    pathsArray.push({
      'path': 'm ' + (x - 3) + ',' + (y - 3) + ' l ' + (w + 6) +
        ',0 l 0,' + (h + 6) + ' l' + -(w + 6) + ',0 z', 'style': styleId
    });

    // when the text will be rotated, re-adjust x and y so the
    // un-rotated text will be in the right position before rotation
    if (rotate) {
      var x = roundTwo((c * d) - (h / 2));
      var y = - roundTwo((s * d) + (w / 2));
    }
    pathsArray.push({ 'textBlock': 'textBlock', 'x': x, 'y': y, 'dx': dx, 'dy': dy, 'r': rotate });

    // put space after block
    pathsArray.push(makeFigSpace(2)[0]);
  } else {
    pathsArray.push({ 'dx': 0, 'dy': 0 });
  }
  return pathsArray;
}


// makeFormGrid creates a grid of figures
function makeFormGrid(sequenceText) {
  const width = 800;
  const cols = 1

  var
    cw = parseInt(width / cols),
    ch = parseInt(cw * Math.GR),
    x = 0,
    y = 0,
    col = 0,
    scaleMin = Infinity, // will hold the smallest Fig scale
    modifiedK = [],
    sortFigures = [],
    entryExit = {
      Entry: { l: 0, n: 0, h: 0, L: 0, N: 0, H: 0 },
      Exit: { l: 0, n: 0, h: 0, L: 0, N: 0, H: 0 }
    };

  // draw all real figures, ordered as selected
  for (var i = 0; i < figures.length; i++) {
    if (figures[i].aresti) {
      sortFigures.push({ id: i, orderBy: figures[i]['seqNr'] });
    }
  }

  for (var i = 0; i < sortFigures.length; i++) {
    var f = figures[sortFigures[i].id]

    // draw rectangle
    // console.log('drawRectangle', drawRectangle(x, y, cw, ch, 'formLine'))

    // draw figure
    var fh = 10 - y - 10;
    // set X and Y to 0 to prevent roundoff errors in figure drawing
    // and scaling
    const X = 0
    const Y = 0
    drawFullFigure(sortFigures[i].id, f.paths[0].figureStart)
    var bBox = f.bBox;
    const thisFig = { elementId: 'figure' + sortFigures[i].id }
    // set figure size to column width - 20
    var scale = Math.min((cw - 20) / bBox.width, (fh - 20) / bBox.height);
    if (scale < scaleMin) scaleMin = scale;
    // move each figure to grid element center and scale appropriately
    f.tx = roundTwo(x - (bBox.x * scale) + ((cw - bBox.width * scale) / 2));
    f.ty = roundTwo(y - (bBox.y * scale) + ((fh - bBox.height * scale) / 2));
    f.fh = roundTwo(fh);
    f.viewScale = roundTwo(scale);
    thisFig.transform = 'translate(' +
      f.tx + ',' + f.ty + ') scale(' + f.viewScale + ')'
    if (i < (sortFigures.length - 1)) {
      x += cw;
      col++;
      if (col >= cols) {
        x = col = 0;
        y += ch;
      }
    }

    entryExit.Entry[fig[f.figNr].entryExitPower[0]]++;
    entryExit.Exit[fig[f.figNr].entryExitPower[1]]++;
  }

  // go through the figures again, set maximum scale to scaleMin * 2
  // and recenter horizontally when necessary
  for (var i = 0; i < sortFigures.length; i++) {
    var f = figures[sortFigures[i].id];
    var thisFig = { elementId: 'figure' + sortFigures[i].id }
    var scale = f.viewScale;
    if (scale > (scaleMin * 2)) {
      var scale = scaleMin * 2;
      var bBox = f.bBox;
      var x = roundTwo(f.tx + (bBox.x * f.viewScale) - ((cw - bBox.width * f.viewScale) / 2));
      var y = roundTwo(f.ty + (bBox.y * f.viewScale) - ((f.fh - bBox.height * f.viewScale) / 2));
      thisFig.transformattribute = 'translate(' +
        roundTwo(x - (bBox.x * scale) + ((cw - bBox.width * scale) / 2)) +
        ',' + roundTwo(y - (bBox.y * scale) + ((f.fh - bBox.height * scale) / 2)) +
        ') scale(' + roundTwo(scale) + ')'
    }
  }

  /* Go through the grid figures to obtain a reasonable estimate of
     * the required number of additional figures.
     *
     * In parseFiguresFile all figures get a two letter code describing
     * entry and exit attitude and speed. Letters used are L(ow),
     * N(eutral) and H(igh). Lowercase is used for inverted attitudes.
     * Speeds are determined by selection defined in config.js.
     *
     * Here we match these until no more matches are possible
     */

  var sets = [];
  for (var i = 0; i < figures.length; i++) {
    if (figures[i].aresti) sets.push(fig[figures[i].figNr].entryExit);
  }

}

/* getFigureSets creates sets of figures that match.
 *
 * In parseFiguresFile all figures get a two letter code describing
 * entry and exit attitude and speed. Letters used are L(ow),
 * N(eutral) and H(igh). Lowercase is used for inverted attitudes.
 * Speeds are determined by selection defined in config.js.
 *
 * Here we match these until no more matches are possible.
 *
 * sets    : figures entry/exit of form [xx, xx, xx, ...]
 * maxSize : maximum size per set (defaults to Infinity)
 *
 * The return is an array of the form
 * [[fignr, fignr, ...], [fignr, fignr, ...], ...]
 */

// draw a shape
function buildShape(shapeName, Params, paths) {
  // if the paths array was not provided, create an empty one
  if (!paths) var paths = []
  // define the pathsArray by executing the correct makeShape function
  switch (shapeName) {
    case 'FigStart':
      var pathsArray = makeFigStart(Params);
      break;
    case 'FigStop':
      var pathsArray = makeFigStop(Params);
      break;
    case 'FigSpace':
      var pathsArray = makeFigSpace(Params);
      break;
    case 'VertSpace':
      var pathsArray = makeVertSpace(Params);
      break;
    case 'Line':
      var pathsArray = makeLine(Params);
      break;
    case 'Move':
      var pathsArray = makeMove(Params);
      break;
    case 'Corner':
      var pathsArray = makeCorner(Params);
      break;
    case 'Curve':
      var pathsArray = makeCurve(Params);
      break;
    case 'RollTopLine':
      var pathsArray = makeRollTopLine(Params);
      break;
    case 'TurnRoll':
      var pathsArray = makeTurnRoll(Params);
      break;
    case 'Turn':
      var pathsArray = makeTurn(Params);
      break;
    case 'Roll':
      var pathsArray = makeRoll(Params);
      break;
    case 'Snap':
      var pathsArray = makeSnap(Params);
      break;
    case 'Spin':
      var pathsArray = makeSpin(Params);
      break;
    case 'ShoulderRoll':
      var pathsArray = makeShoulderRoll(Params);
      break;
    case 'Ruade':
      var pathsArray = makeRuade(Params);
      break;
    case 'Lomcevak':
      var pathsArray = makeLomcevak(Params);
      break;
    case 'Hammer':
      var pathsArray = makeHammer(Params);
      break;
    case 'Tailslide':
      var pathsArray = makeTailslide(Params);
      break;
    case 'PointTip':
      var pathsArray = makePointTip(Params);
      break;
    case 'TextBlock':
      var pathsArray = makeTextBlock(Params);
      break;
  }
  // walk through the returned paths
  for (var i = 0; i < pathsArray.length; i++) {
    paths.push(pathsArray[i]);
  }
  return paths
}


// drawShape draws a shape from a pathArray
// selectFigure will be true if the figure to be drawn is one selected
// by hover
// prev, if provided, should hold the contents and bounding box of a
// previous text element to avoid.
function drawShape(pathArray, svgElement, prev) {
  var cur = false;
  svgElement = svgElement || SVGRoot.getElementById('sequence');
  // decide if we are drawing a path or text or starting a figure
  if (pathArray.path) {
    var path = document.createElementNS(svgNS, "path");
    path.setAttribute('d', 'M ' + roundTwo(X) + ',' + roundTwo(Y) +
      ' ' + pathArray.path);
    path.setAttribute('style', style[pathArray.style]);
    if (pathArray.class) path.setAttribute('class', pathArray.class);
    if (pathArray.handle && svgElement.id) {
      path.setAttribute('id', svgElement.id + '-' + pathArray.handle);
    }
    // option for rotating paths. Not used yet but may become usefull
    // for fitting rolls and snaps to curve in top or bottom of loop
    if ('rotate' in pathArray) {
      path.setAttribute('transform', 'rotate(' + pathArray.rotate +
        ' ' + X + ' ' + Y + ')');
    }
    svgElement.appendChild(path);
  } else if (pathArray.text) {
    var text = drawText(
      pathArray.text,
      roundTwo(X + pathArray.x),
      roundTwo(Y + pathArray.y),
      pathArray.style,
      pathArray['text-anchor'],
      false,
      svgElement
    );

    // Following is used to check subsequent rolls for overlap and
    // apply corrections. We use two ellipses sized to fit the text BBox
    // to determine the correct distance.

    // If we are dealing with a rollText of 5 characters or less, create
    // cur. Longer texts are just too hard to separate consistently.
    if ((pathArray.text.length <= 5) && (pathArray.style === 'rollText')) {
      cur = text.getBBox();
      cur.t = text;
    }
    if (prev && cur) {
      // increase hor and decrease vert spacing
      var cx1 = parseFloat(cur.x + (cur.width / 2));
      var cy1 = parseFloat(cur.y + (cur.height / 2));
      var cx2 = parseFloat(prev.x + (prev.width / 2));
      var cy2 = parseFloat(prev.y + (prev.height / 2));
      // find the line between the centers of the ellipses
      var dx = parseFloat(cx2 - cx1);
      var dy = parseFloat(cy2 - cy1);
      var d = Math.sqrt(dx * dx + dy * dy);
      var cos = dx / d;
      var sin = dy / d;
      var rfCor = rollFontSize / 8;
      // find x and y on the ellipses in the direction of the line
      // connecting the centers
      var ex1 = cos * (cur.width / 2 + rfCor);
      var ey1 = sin * (cur.height / 2 - rfCor);
      var ex2 = cos * (prev.width / 2 + rfCor);
      var ey2 = sin * (prev.height / 2 - rfCor);
      var overlap = (Math.sqrt(ex1 * ex1 + ey1 * ey1) +
        Math.sqrt(ex2 * ex2 + ey2 * ey2)) - d;
      if (overlap > 0) {
        // move previous and current figure opposite by 50% of overlap
        overlap = overlap / 2;
        prev.t.setAttribute('x',
          roundTwo(parseFloat(prev.t.getAttribute('x')) + cos * overlap));
        prev.t.setAttribute('y',
          roundTwo(parseFloat(prev.t.getAttribute('y')) + sin * overlap));
        text.setAttribute('x', roundTwo(X + pathArray.x - cos * overlap));
        text.setAttribute('y', roundTwo(Y + pathArray.y - sin * overlap));
      }
    }
  } else if (pathArray.textBlock) {
    var tb = pathArray.textBlock;
    var spans = tb.getElementsByTagName('tspan');
    // set all tspan x values
    for (var i = 0; i < spans.length; i++) {
      spans[i].setAttribute('x', roundTwo(X + pathArray.x));
    }
    // set text x value
    tb.setAttribute('x', roundTwo(X + pathArray.x));
    tb.setAttribute('y', roundTwo(Y + pathArray.y));
    // when a rotation is specified, rotate around center
    svgElement.appendChild(tb);
    if (pathArray.r) {
      var box = tb.getBBox();
      tb.setAttribute('transform', 'rotate (' + pathArray.r + ',' +
        roundTwo(box.x + box.width / 2) + ',' +
        roundTwo(box.y + box.height / 2) + ')');
    }
  } else if (pathArray.figureStart) {
    // Check if figure starts do not overlap when this is not the first
    // figure
    if (figureStart.length > 0) {
      // count is used to make sure there is never an infinite loop
      var count = 0;
      do {
        // Walk through the figure starts and see if we find any distance
        // lower than minimum with the one we're making now
        var overlap = false;
        for (var i = 0; i < figureStart.length; i++) {
          var distSq = (figureStart[i].x - X) * (figureStart[i].x - X) +
            (figureStart[i].y - Y) * (figureStart[i].y - Y);
          if (distSq < minFigStartDistSq) {
            // found one that's too close. Move the start down and run again
            Y = parseInt(figureStart[i].y + Math.sqrt(minFigStartDistSq - ((figureStart[i].x - X) * (figureStart[i].x - X))) + 1);
            overlap = true;
            count++;
            break; // break for loop
          }
        }
      } while (overlap && (count < 10000));
      Y = roundTwo(Y);
    }
    // Put this figure's start in the figureStart array for checking
    // against the next one
    figureStart.push({ 'x': X, 'y': Y });
  }
  if ('dx' in pathArray) X = roundTwo(X + pathArray.dx);
  if ('dy' in pathArray) Y = roundTwo(Y + pathArray.dy);
  return cur;
}

// drawRectangle draws a rectangle at position x, y in style styleId
// When an svg object is provided, it will be used i.s.o. the standard
// sequenceSvg
// The function returns the drawn rectangle
function drawRectangle(x, y, width, height, styleId) {
  var path = document.createElementNS(svgNS, "rect");
  path.setAttribute('x', x);
  path.setAttribute('y', y);
  path.setAttribute('width', width);
  path.setAttribute('height', height);
  path.setAttribute('style', style[styleId]);
  return path;
}

// drawText draws any text at position x, y in style styleId with
// optional anchor, id, svg
function drawText(text, x, y, styleId, anchor, id) {
  var newText = document.createElementNS(svgNS, "text");
  if (id) newText.setAttribute('id', id);
  if (style && styleId) newText.setAttribute('style', style[styleId]);
  if (anchor) newText.setAttribute('text-anchor', anchor);
  newText.setAttribute('x', roundTwo(x));
  newText.setAttribute('y', roundTwo(y));
  var textNode = document.createTextNode(text);
  newText.appendChild(textNode);
  return newText;
}

// drawCircle draws a circle
function drawCircle(attributes) {
  var circle = document.createElementNS(svgNS, "circle");
  for (var key in attributes) circle.setAttribute(key, attributes[key]);
  return circle
}

// drawImage draws an image
function drawImage(attributes) {
  var image = document.createElementNS(svgNS, 'image');
  for (var key in attributes) {
    if (key === 'href') {
      image.setAttributeNS(xlinkNS, 'href', attributes.href);
    } else image.setAttribute(key, attributes[key]);
  }
  return image;
}

// draw an aresti number text with a figure
function drawArestiText(figNr, aresti) {
  drawText(aresti, X, Y, 'start', 'text-' + figNr);
}

// drawFullFigure draws a complete Aresti figure in the sequenceSvg
// or in an other svg object when provided
function drawFullFigure(i, draggable) {
  // Mark the starting position of the figure
  figures[i].startPos = { 'x': X, 'y': Y };
  figures[i].draggable = false;
  var paths = figures[i].paths;
  // return for no-draw figures
  if (!paths) return;
  // Create a group for the figure, draw it and apply to the SVG
  var group = document.createElementNS(svgNS, "g");
  group.setAttribute('id', 'figure' + i);
  var bBox = false;
  for (var j = 0; j < paths.length; j++) {
    var box = drawShape(paths[j], group, bBox);
    if (box) bBox = box;
  }

  figures[i].bBox = myGetBBox(group);

  if ((selectedFigure.id === i) && draggable) setFigureSelected(i);
}

// roundTwo returns a number rounded to two decimal places.
// Use for all drawing functions to prevent rendering errors and keep
// SVG file size down
function roundTwo(nr) {
  return parseFloat(parseFloat(nr).toFixed(2))
}

// buildIllegal builds the symbol for an illegal figure
function buildIllegal(i) {
  // create space before the figure
  var paths = [];
  paths = buildShape('FigSpace', 2, paths);
  var angle = dirAttToAngle(Direction, Attitude);
  var dx = Math.cos(angle) * lineElement * 6;
  var dy = - Math.sin(angle) * lineElement * 6;
  paths.push({
    'path': 'l ' + dx + ',' + (dy - lineElement * 6) + ' m ' +
      -dx + ',' + -dy + ' l ' + dx + ',' + (dy + lineElement * 6), 'style': 'illegalCross'
  });
  paths.push({
    'path': 'l ' + dx + ',' + dy + ' l 0,' + (-lineElement * 6) +
      ' l ' + -dx + ',' + -dy + ' z', 'style': 'illegalBox', 'dx': dx, 'dy': dy
  });
  // Create empty space after illegal figure symbol
  paths = buildShape('FigSpace', 2, paths);
  figures[i].paths = paths;
  figures[i].unknownFigureLetter = unknownFigureLetter;
}

// buildMoveTo builds the symbol for a moveto and moves the cursor
// scaling should not affect movement, so divide by scale
function buildMoveTo(dxdy, i) {
  var paths = [];
  if (/^[CL]/.test(activeForm)) dxdy[0] = -dxdy[0]
  paths.push({
    'path': 'l ' + roundTwo(dxdy[0] * (lineElement / scale)) +
      ',' + roundTwo(dxdy[1] * (lineElement / scale)),
    'style': 'dotted',
    'dx': dxdy[0] * (lineElement / scale),
    'dy': dxdy[1] * (lineElement / scale)
  })
  // Create space after the figure
  figures[i].paths = buildShape('FigSpace', 2, paths)
  figures[i].moveTo = dxdy
}

// buildCurveTo builds the symbol for a curveto and moves the cursor
// scaling should not affect movement, so divide by scale
function buildCurveTo(dxdy, i) {
  var paths = [];
  if (/^[CL]/.test(activeForm)) dxdy[0] = -dxdy[0];
  var angle = dirAttToAngle(Direction, Attitude);
  var dist = Math.sqrt(dxdy[0] * dxdy[0] + dxdy[1] * dxdy[1]);
  var dx = Math.cos(angle) * (lineElement / scale) * dist / 2;
  var dy = - Math.sin(angle) * (lineElement / scale) * dist / 2;

  paths.push({
    'path': 'c ' + roundTwo(dx) + ',' + roundTwo(dy) + ' ' +
      roundTwo(dxdy[0] * (lineElement / scale) - dx) + ',' +
      roundTwo(dxdy[1] * (lineElement / scale) - dy) + ' ' +
      roundTwo(dxdy[0] * (lineElement / scale)) + ',' +
      roundTwo(dxdy[1] * (lineElement / scale)),
    'style': 'dotted',
    'dx': (dxdy[0] * (lineElement / scale)),
    'dy': (dxdy[1] * (lineElement / scale))
  });
  // Create space after the figure
  figures[i].paths = buildShape('FigSpace', 2, paths);
  figures[i].curveTo = dxdy;
}

// buildMoveForward moves the cursor forward
function buildMoveForward(extent, i) {
  if (extent > 0) {
    figures[i].paths = buildShape('FigSpace', extent);
    figures[i].moveForward = extent;
  }
}

// buildMoveDown moves the cursor downward
function buildMoveDown(extent, i) {
  if (extent > 0) {
    figures[i].paths = buildShape('VertSpace', extent);
    figures[i].moveDown = extent;
  }
}

// buildFigure parses a complete figure as defined by the figNrs and
// figString and puts it in array figures. It also creates a figCheckLine
// for each figure that can be used for sequence validity checking
// figNrs         = Possible figures that matched pattern
// figString      = User defined string for figure
// seqNr          = Sequence figure number
// figStringIndex = index of figure (figures[figStringIndex])
// figure_chooser = Optional argument, true if we are building the
//                  figure for the figure chooser
export function buildFigure(figNrs, figString, seqNr, figStringIndex, figure_chooser) {
  var
    figNr = figNrs[0],
    roll = [],
    rollSums = [],
    rollPatterns = [],
    rollInfo = [],
    // lowKFlick is set when the K for flick should be low:
    // vertical down after hammerhead, tailslide or after roll element
    lowKFlick = false,
    // entryAxis identifies the axis on which the figure starts
    entryAxis = ((Direction === 0) || (Direction === 180)) ? 'X' : 'Y',
    entryAtt = Attitude,
    entryDir = Direction,
    description = [];

  // goFront is set to true for every new figure that starts to front
  if (((Direction === 90) && (Attitude === 0)) || ((Direction === 270) && (Attitude === 180))) {
    goFront = false;
  } else {
    goFront = true;
  }
  // In the first part we handle everything except (rolling) turns
  var bareFigBase = fig[figNr].base.replace(/[\+\-]+/g, '');

  if (!regexTurn.test(fig[figNr].base)) {
    // First we split the figstring in it's elements, the bareFigBase
    // is empty for rolls on horizontal.
    if (bareFigBase !== '') {
      // prevent splitting on bareFigBase inside comments
      if (figString.match(/"/)) {
        var inComment = false;
        var l = bareFigBase.length;
        for (var i = 0; i <= (figString.length - l); i++) {
          if (!inComment && (figString.substring(i, i + l) === bareFigBase)) {
            var splitLR = [
              figString.substring(0, i),
              figString.substring(i + l)
            ];
            break;
          } else if (figString[i] === '"') {
            inComment = !inComment;
          }
        }
      } else {
        var splitLR = figString.split(bareFigBase);
      }
    } else {
      var splitLR = Array(figString);
    }
    // start with an empty set of rolls
    for (var i = 0; i < fig[figNr].rolls.length; i++) {
      rollPatterns[i] = '';
    }
    // Find the roll patterns
    const regEx = /[\+<\~\^\>]+/g;
    rollPatterns[0] = splitLR[0].replace(regEx, '');
    if (splitLR.length > 1) {
      const moreRolls = splitLR[1].replace(regEx, '').split(')');
      for (var i = 0; i < moreRolls.length; i++) {
        if (moreRolls[i].match(/\(/)) {
          rollPatterns[i + 1] = moreRolls[i].replace(/\(/g, '');
        } else {
          rollPatterns[fig[figNr].rolls.length - 1] = moreRolls[i];
        }
      }
    }

    // Parse the roll patterns and find out where to put rolls, flicks,
    // spins and tumbles
    // We need to do this before building the figure because it can
    // affect our choice of figure
    for (var i = 0; i < rollPatterns.length; i++) {
      roll[i] = [];
      rollInfo[i] = {
        'gap': [0],
        'pattern': [],
        'flip': [],
        'flipNumber': [],
        'comment': []
      };
      var rollPattern = rollPatterns[i];
      rollSums[i] = 0;
      let rollSign = (/^[CL]/.test(activeForm)) ? -1 : 1;
      let extent = 0;
      var subRoll = 0;
      var flipRoll = false;
      var flipNumber = false;
      var inComment = false;
      var comment = '';
      if (rollPattern !== '') {
        for (j = 0; j < rollPattern.length; j++) {
          // check if we are not in a roll comment section
          if (!inComment) {
            var char = rollPattern.charAt(j);
            switch (char) {
              case (userpat.comment):
                // start of comment
                inComment = true;
                break;
              case (userpat.rollext):
                // Long line before or after roll, twice the length of
                // the same pattern in figures.js
                roll[i].push({ 'type': 'line', 'extent': 3, 'load': NegLoad });
                rollInfo[i].gap[subRoll] += 3;
                break;
              case (userpat.rollextshort):
                // Short line before or after roll, twice the length of
                // the same pattern in figures.js
                roll[i].push({ 'type': 'line', 'extent': 1, 'load': NegLoad });
                rollInfo[i].gap[subRoll]++;
                break;
              case (userpat.lineshorten):
                // Make line before or after roll shorter
                roll[i].push({ 'type': 'line', 'extent': -1, 'load': NegLoad });
                rollInfo[i].gap[subRoll]--;
                break;
              case (userpat.opproll):
                // Switch roll direction
                rollSign = -rollSign;
                flipRoll = !flipRoll;
                break;
              case 'f':
              case 's':
              case 'e':
              case 'u':
              case 'l':
                // Add single flicks, spins and tumbles
                // When there was a roll before, add a line first
                var type = {
                  'f': 'possnap',
                  's': 'posspin',
                  'e': 'posShoulderRoll',
                  'u': 'posRuade',
                  'l': 'posLomcevak'
                }[char];
                if (extent > 0) {
                  roll[i].push({ 'type': 'line', 'extent': 2, 'load': NegLoad });
                }
                roll[i].push({
                  'type': type,
                  'extent': rollSign * 360,
                  'pattern': '1' + char,
                  'flip': flipRoll,
                  'flipNumber': flipNumber,
                  'comment': comment
                });
                extent = 360;
                rollInfo[i].pattern[subRoll] = char;
                rollInfo[i].flip[subRoll] = flipRoll;
                rollInfo[i].flipNumber[subRoll] = flipNumber;
                rollInfo[i].comment[subRoll] = comment;
                flipNumber = false;
                comment = '';
                subRoll++;
                rollInfo[i].gap[subRoll] = 0;
                break;
              case 'i':
                // Add single inverted flicks, spins and tumbles
                // When there was a roll before, add a line first
                var type = {
                  'f': 'negsnap',
                  's': 'negspin',
                  'e': 'negShoulderRoll',
                  'u': 'negRuade',
                  'l': 'negLomcevak'
                }[rollPattern.charAt(j + 1)];
                if (type) {
                  if (extent > 0) {
                    roll[i].push({ 'type': 'line', 'extent': 2, 'load': NegLoad });
                  }
                  j++;
                  roll[i].push({
                    'type': type,
                    'extent': rollSign * 360,
                    'pattern': '1i' + rollPattern.charAt(j),
                    'flip': flipRoll,
                    'flipNumber': flipNumber,
                    'comment': comment
                  });
                  rollInfo[i].pattern[subRoll] = 'i' + rollPattern.charAt(j);
                  extent = 360;
                  rollInfo[i].flip[subRoll] = flipRoll;
                  rollInfo[i].flipNumber[subRoll] = flipNumber;
                  rollInfo[i].comment[subRoll] = comment;
                  flipNumber = false;
                  comment = '';
                  subRoll++;
                  rollInfo[i].gap[subRoll] = 0;
                }
                break;
              // Handle all different kinds of rolls and their notation
              default:
                if (parseInt(rollPattern[j])) {
                  var startJ = j;
                  let stops = 0;
                  // When there was a roll before, add a line first
                  if (extent > 0) {
                    var regex = /[fs]/;
                    if (regex.test(rollPattern)) {
                      roll[i].push({ 'type': 'line', 'extent': 2, 'load': NegLoad })
                    } else {
                      roll[i].push({ 'type': 'line', 'extent': 1, 'load': NegLoad })
                    }
                  }

                  if (rollPattern[j] === '9') {
                    extent = 720;
                    // 9 is a double roll. But for non-Aresti it can be
                    // followed by more numbers to indicate more roll.
                    // This can not be used for hesitations
                    // e.g. 96 is 3 1/2 roll
                    if (document.getElementById('nonArestiRolls').checked) {
                      while (rollPattern.charAt(j + 1).match(/[12345679]/)) {
                        j++;
                        extent += parseInt({
                          1: 360, 2: 180, 3: 270,
                          4: 90, 5: 450, 6: 540, 7: 630,
                          9: 720
                        }[rollPattern[j]]);
                      }
                    }
                  } else if (rollPattern.charAt(j + 1).match(/[0-8]/)) { // use charAt to prevent end-of-string error
                    // special case: more than 8x8 roll
                    if (rollPattern.charAt(j + 2).match(/[1-9]/)) { // use charAt to prevent end-of-string error
                      stops = parseInt(rollPattern[j + 2]);
                      extent = parseInt(rollPattern.substring(j, j + 2)) * (360 / stops);
                      j++;
                    } else {
                      if (rollPattern[j + 1] !== '0') {
                        stops = parseInt(rollPattern[j + 1]);
                        extent = parseInt(rollPattern[j]) * (360 / stops);
                      }
                    }
                    j++;
                  } else if (rollPattern[j] === '1') {
                    extent = 360;
                  } else if (rollPattern[j] === '4') {
                    extent = 90;
                  } else if (rollPattern[j] === '8') {
                    stops = 8;
                    extent = 90;
                  } else {
                    extent = 90 * parseInt(rollPattern[j]);
                  }
                  var illegalSnapSpin = false;
                  switch (rollPattern.charAt(j + 1)) {
                    case 'i':
                      var type = {
                        'f': 'negsnap',
                        's': 'negspin',
                        'e': 'negShoulderRoll',
                        'u': 'negRuade',
                        'l': 'negLomcevak'
                      }[rollPattern.charAt(j + 2)];
                      if (type) {
                        j += 2;
                        if (!stops) {
                          roll[i].push({
                            'type': type,
                            'extent': rollSign * extent,
                            'pattern': rollPattern.substring(startJ, j + 1),
                            'flip': flipRoll,
                            'flipNumber': flipNumber,
                            'comment': comment
                          });
                        } else {
                          illegalSnapSpin = rollPattern.substring(j - 1, j + 1);
                        }
                      }
                      break;
                    case 'f':
                    case 's':
                    case 'e':
                    case 'u':
                    case 'l':
                      var type = {
                        'f': 'possnap',
                        's': 'posspin',
                        'e': 'posShoulderRoll',
                        'u': 'posRuade',
                        'l': 'posLomcevak'
                      }[rollPattern.charAt(j + 1)];
                      j++;
                      if (!stops) {
                        roll[i].push({
                          'type': type,
                          'extent': rollSign * extent,
                          'pattern': rollPattern.substring(startJ, j + 1),
                          'flip': flipRoll,
                          'flipNumber': flipNumber,
                          'comment': comment
                        });
                      } else {
                        illegalSnapSpin = rollPattern.substring(j, j + 1);
                      }
                      break;
                    default:
                      roll[i].push({
                        'type': 'roll',
                        'extent': rollSign * extent,
                        'stops': stops,
                        'pattern': rollPattern.substring(startJ, j + 1),
                        'flip': flipRoll,
                        'flipNumber': flipNumber,
                        'comment': comment
                      });
                  }
                  if (illegalSnapSpin) {
                    alertMsgs.push('(' + seqNr + ') ' +
                      rollPattern.substring(startJ, j + 1) +
                      userText.illegalFig +
                      rollPattern.substring(startJ, startJ + 1) +
                      illegalSnapSpin);
                    extent = 0;
                  }
                  rollSums[i] += rollSign * extent;
                  if (extent !== 0) {
                    rollInfo[i].pattern[subRoll] = rollPattern.substring(startJ, j + 1);
                    rollInfo[i].flip[subRoll] = flipRoll;
                    rollInfo[i].flipNumber[subRoll] = flipNumber;
                    rollInfo[i].comment[subRoll] = comment;
                    subRoll++;
                    rollInfo[i].gap[subRoll] = 0;
                  }
                  flipNumber = false;
                  comment = '';
                } else if (rollPattern[j] === '0') {
                  // Glider Super Slow roll
                  var startJ = j;
                  // When there was a roll before, add a line first
                  if (extent > 0) {
                    roll[i].push({ 'type': 'line', 'extent': 2, 'load': NegLoad });
                    extent = 0;
                  }
                  j++;
                  switch (rollPattern[j]) {
                    case '2':
                      extent = 180;
                      break;
                    case '1':
                      extent = 360;
                      break;
                    case '6':
                      extent = 540;
                      break;
                  }
                  rollSums[i] += rollSign * extent;
                  if (extent !== 0) {
                    rollInfo[i].pattern[subRoll] = rollPattern.substring(startJ, j + 1);
                    rollInfo[i].flip[subRoll] = flipRoll;
                    rollInfo[i].flipNumber[subRoll] = flipNumber;
                    rollInfo[i].comment[subRoll] = comment;
                    subRoll++;
                    rollInfo[i].gap[subRoll] = 0;
                    roll[i].push({
                      'type': 'slowroll',
                      'extent': rollSign * extent,
                      'stops': 0,
                      'pattern': rollPattern.substring(startJ, j + 1),
                      'flip': flipRoll,
                      'flipNumber': flipNumber,
                      'comment': comment
                    });
                  }
                  flipNumber = false;
                  comment = '';
                }
            }
          } else {
            // in comment, end it or add character to comment
            if (rollPattern[j] === userpat.comment) {
              inComment = false;
            } else {
              // flip roll number if pattern present at comment start
              if ((comment === '') && (rollPattern[j] === userpat.flipNumber)) {
                flipNumber = true;
              }
              comment += rollPattern[j];
            }
          }
        }
      }
    }
    // Set the number of the first roll for drawing later on
    // To do this we check the fig.pattern for a roll match before the base
    var rollnr = regexRollBeforeBase.test(fig[figNr].pattern) ? 0 : 1;

    // If there are multiple figNrs we check the rolls to see which one
    // matches best. It's very important that with different figures with
    // the same figure base the roll total is equal in the figures.js file
    if (figNrs.length > 1) {
      // Set rollCorrMin to infinity to start with so the
      // first correction will allways be smaller
      var rollCorrMin = Infinity;
      for (var i = 0; i < figNrs.length; i++) {
        var rollCorr = 0;
        for (var j = 0; j < roll.length; j++) {
          if (fig[figNrs[i]].rolls[j] === 2) {
            // half roll symbol at this position in fig[xx].rolls[yy]
            rollCorr += Math.abs((rollSums[j] + 180) % 360);
          } else if (/[^34]/.test(fig[figNrs[i]].rolls[j])) {
            // full or no roll symbol at this position in fig[xx].rolls[yy]
            // 'no roll' can be no roll line at all (rolls[j] undefined)
            // or only line without roll allowed as in some P-loops
            // (rolls[j] = 9)
            rollCorr += Math.abs(rollSums[j] % 360);
          }
        }
        if (rollCorr < rollCorrMin) {
          rollCorrMin = rollCorr;
          figNr = figNrs[i];
          var rollnr = regexRollBeforeBase.test(fig[figNr].pattern) ? 0 : 1;
        }
      }
      var figureDraw = fig[figNr].draw;
    } else var figureDraw = fig[figNrs[0]].draw;
  } else var figureDraw = fig[figNrs[0]].draw;
  // The chosen figure is now final, so we can:
  // * assign Aresti Nr and K Factor to the figure
  // * fix the figNr in the figures object
  // * remove rolls where only line shortening/lengthening is allowed
  var arestiNrs = new Array(fig[figNr].aresti);
  if (!fig[figNr].kRules) {
    var kFactors = [parseInt(fig[figNr].kPwrd)];
  } else var kFactors = [parseInt(fig[figNr].kRules)];
  figures[figStringIndex].figNr = figNr;
  figCheckLine[seqNr] = fig[figNr].aresti;

  // consolidate gaps for lines without rolls
  for (var i = 0; i < roll.length; i++) {
    if (fig[figNr].rolls[i] === 9) {
      for (var j = 0; j < roll[i].length; j++) {
        if (roll[i][j].type !== 'line') {
          roll[i].splice(j, 1);
          j--;
        }
      }
      rollSums[i] = 0;
      var gap = 0;
      for (var j = 0; j in rollInfo[i].gap; j++) gap += parseInt(rollInfo[i].gap[j]);
      rollInfo[i] = { gap: [gap], pattern: [], comment: [] };
    }
  }
  // If we are not in Form A we check for entry and exit extension
  // and shortening and apply them
  var entryExt = 0;
  var exitExt = 0;
  if (activeForm !== 'A') {
    // get position for the base (1) OR the roll(s) on a horizontal (2)
    if (bareFigBase !== '') {
      var basePos = figString.indexOf(bareFigBase);
    } else {
      stringLoop:
        for (var i = 0; i < figString.length; i++) {
          switch (figString[i]) {
            case userpat.forward:
            case userpat.forwardshorten:
            case '-':
              break;
            default:
              basePos = i;
              break stringLoop;
          }
        }
    }
    for (var i = 0; i < figString.length; i++) {
      if (figString.charAt(i) === userpat.forward) {
        if (i < basePos) {
          figureDraw = figpat.forward + figureDraw;
          entryExt++;
        } else {
          figureDraw += figpat.forward;
          exitExt++;
        }
      } else if (figString.charAt(i) === userpat.forwardshorten) {
        if (i < basePos) {
          figureDraw = userpat.forwardshorten + figureDraw;
          entryExt--;
        } else {
          figureDraw += userpat.forwardshorten;
          exitExt--;
        }
      }
    }
  }

  // Now we go through the drawing instructions
  var
    lineLength = 0,
    lineSum = 0,
    lineDraw = false,
    rollTop = false,
    afterRoll = false,
    comment = false,
    hiddenCurvePaths = [],
    smallCurve = 0,
    // Build the start of figure symbol
    paths = buildShape('FigStart',
      {
        'seqNr': seqNr,
        'first': firstFigure
      },
      paths),
    entryLine = true;

  // add any paths that were already provided
  // (e.g. for autocorrect red circle)
  if (figures[figStringIndex].paths) {
    for (var i = 0; i < figures[figStringIndex].paths.length; i++) {
      paths.push(figures[figStringIndex].paths[i]);
    }
  }

  for (var i = 0; i < figureDraw.length; i++) {
    // set correct load
    if ((figureDraw[i] === figpat.longforward) || (figureDraw[i] === figpat.forward)) {
      if ((Attitude !== 90) && (Attitude !== 270)) {
        NegLoad = ((Attitude > 90) && (Attitude < 270)) ? 1 : 0;
      }
    }
    // Sum continuous lines in the figure
    switch (figureDraw.charAt(i)) {
      // Make long lines
      case (figpat.longforward):
        lineSum += 4;
        lineDraw = true;
        break;
      case (userpat.rollext):
        lineSum += 3;
        lineDraw = true;
        break;
      // Make short lines
      case (figpat.forward):
      case (userpat.rollextshort):
        lineSum++;
        lineDraw = true;
        break;
      // shorten entry and exit lines
      case (userpat.forwardshorten):
        lineSum--;
        break;
      // When a roll is encountered, continue there for line
      // lengthening and/or shortening
      case (figpat.fullroll):
        afterRoll = true;
        break;
      // just skip any negative entry/exit
      case ('-'):
        break;
      // When something else than a roll or line is encountered, build
      // the line. Do not make any existing line shorter than 1
      default:
        if (lineDraw || afterRoll) {
          if (lineDraw) {
            lineSum = Math.max(lineSum, 1);
            var params = [lineSum];
            lineLength += lineSum;
          } else var params = [0.02];
          if (afterRoll) {
            params.push('roll' + (rollnr - 1) + '-gap' + gapnr);
            roll[rollnr - 1].lineLengthAfter = lineSum;
          }
          if (entryLine) {
            params.push('entry');
            figures[figStringIndex].entryLength = lineSum;
            entryLine = false;
          }

          paths = buildShape('Line', params, paths);
          lineDraw = false;
        }
        lineSum = 0;
        afterRoll = false;
    }
    // Take care of everything but lines
    switch (figureDraw.charAt(i)) {
      // Make hammerheads
      case (figpat.hammer):
      case (figpat.pushhammer):
        paths = buildShape('Hammer', lineLength, paths);
        // down line after hammer always positive
        NegLoad = false;
        lowKFlick = true;
        break;
      // Make tailslides
      case (figpat.tailslidecanopy):
      case (figpat.tailslidewheels):
        paths = buildShape('Tailslide', figureDraw.charAt(i), paths);
        lowKFlick = true;
        break;
      case (figpat.pointTip):
      case (figpat.pushPointTip):
        paths = buildShape('PointTip', lineLength, paths);
        break;
      // Make rolls, including any line lenghthening and/or shortening
      case (figpat.fullroll):
        rollInfo[rollnr].attitude = Attitude;
        rollInfo[rollnr].negLoad = NegLoad;
        // Make a space on the figCheckLine before every possible roll
        figCheckLine[seqNr] = figCheckLine[seqNr] + ' ';
        // mark rolls in the top
        if (rollTop) rollInfo[rollnr].rollTop = true;
        if (roll[rollnr]) {
          var rollPaths = [];
          let rollSum = 0;
          let rollDone = false;
          let attChanged = 0;
          var gapnr = 0;
          for (j = 0; j < roll[rollnr].length; j++) {
            // Build line elements after all extensions and shortenings have been processed
            if (roll[rollnr][j].type !== 'line') {
              if (lineDraw) {
                // set a fixed distance for rolls in the top
                if (rollTop) {
                  rollPaths = buildShape('Move', [1.2 / scale], rollPaths);
                } else {
                  lineSum = Math.max(lineSum, 1);
                  lineLength += lineSum;
                  // add roll paths with id for handle
                  rollPaths = buildShape(
                    'Line',
                    [lineSum, 'roll' + rollnr + "-gap" + gapnr],
                    rollPaths
                  );
                  gapnr++;

                  roll[rollnr][j].lineLength = lineSum;
                }
                lineDraw = false;
              } else if (!rollTop) {
                // add roll paths tiny line with id for handle, except
                // for rolls in the top
                rollPaths = buildShape(
                  'Line',
                  [0.02, 'roll' + rollnr + "-gap" + j],
                  rollPaths
                );
                roll[rollnr][j].lineLength = 0.02;
              }

              lineSum = 0;
            }

            /***** handle rolls and lines *****/

              // Find which roll in figures.js matches. There should only
              // be one. Then add Aresti nr and K factor
            var rollAtt = rollAttitudes[Attitude];
            // Find the correct snap/load combination
            // Snaps started knife edge are judged as
            // 'pos from neg'/'neg from pos' with foot-down entry
            if ((roll[rollnr][j].type === 'possnap') || (roll[rollnr][j].type === 'negsnap')) {
              if (((Attitude === 90) || (Attitude === 270)) && lowKFlick) {
                // Handle snaps on verticals where lowKFlick is set
                rollAtt = (roll[rollnr][j].type === 'possnap') ? '+' + rollAtt : '-' + rollAtt;
              } else if (((rollSum % 180) === 0) || (Attitude === 90) || (Attitude === 270)) {
                // Handle snaps on verticals and from non-knife-edge
                rollAtt = (NegLoad === 0) ? '+' + rollAtt : '-' + rollAtt;
              } else {
                // Handle snaps from knife edge. Use rollSum to find the
                // previous amount of roll. Use switches to determine
                // correct virtual load/attitude combination
                var inv = ((Attitude > 90) && (Attitude < 270));
                inv = (inv === (((Math.abs(rollSum) - 90) % 360) === 0));
                inv = (inv === ((rollSum * roll[rollnr][j].extent) > 0));
                // 1) Foot up for pos start, foot down for neg start
                // 2) Foot down for pos start, foot up for neg start
                rollAtt = inv ? '+' + rollAtt : '-' + rollAtt;
              }
            }
            // set lowKFlick to true after anything but a line
            if (roll[rollnr][j].type !== 'line') lowKFlick = true;
            var thisRoll = rollFig[rollAtt + roll[rollnr][j].pattern];
            if (thisRoll) {
              arestiNrs.push(thisRoll.aresti);
              if (thisRoll.kRules) {
                kFactors.push(thisRoll.kRules);
              } else {
                kFactors.push(thisRoll.kPwrd);
              }
              // Check if there was a roll before the current one and
              // - add ; or , as appropriate for checking
              // - when the roll type is the same and not opposite,
              //   check if nonArestiRolls is checked. Otherwise,
              //   present warning
              // - check if there are not more than two subsequent rolls
              //   when nonArestiRolls is not checked
              var k = j - 1;
              while (k > -1) {
                if (roll[rollnr][k].type !== 'line') {
                  if ((roll[rollnr][j].extent / roll[rollnr][k].extent) > 0) {
                    figCheckLine[seqNr] += ';';
                  } else {
                    figCheckLine[seqNr] += ',';
                  }
                  break;
                }
                k--;
              }
              figCheckLine[seqNr] += thisRoll.aresti;
            }

            /** add the roll drawings to rollPaths */
            switch (roll[rollnr][j].type) {
              // Sum line elements
              case ('line'):
                lineSum += roll[rollnr][j].extent;
                // don't set lineDraw to true for shortenings. This will
                // prevent drawing a line of minimum length 1 when no
                // line was present in the figure drawing instructions
                if (roll[rollnr][j].extent > 0) lineDraw = true;
                break;
              // Build roll elements
              case ('roll'):
                rollPaths = buildShape('Roll', Array(
                  roll[rollnr][j].extent,
                  roll[rollnr][j].stops,
                  rollTop,
                  false,
                  false,
                  roll[rollnr][j].comment,
                  figure_chooser ? fig[figNr].rolls[rollnr] : false
                  ),
                  rollPaths);
                lineLength += parseInt((Math.abs(roll[rollnr][j].extent) - 1) / 360) * (10 / lineElement);
                break;
              case ('slowroll'):
                rollPaths = buildShape('Roll', Array(
                  roll[rollnr][j].extent,
                  roll[rollnr][j].stops,
                  rollTop,
                  true,
                  false,
                  roll[rollnr][j].comment), rollPaths);
                lineLength += parseInt((Math.abs(roll[rollnr][j].extent) - 1) / 360) * (10 / lineElement);
                break;
              case ('possnap'):
                rollPaths = buildShape('Snap', Array(
                  roll[rollnr][j].extent,
                  0,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case ('negsnap'):
                rollPaths = buildShape('Snap', Array(
                  roll[rollnr][j].extent,
                  1,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case 'posShoulderRoll':
                rollPaths = buildShape('ShoulderRoll', Array(
                  roll[rollnr][j].extent,
                  0,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case 'negShoulderRoll':
                rollPaths = buildShape('ShoulderRoll', Array(
                  roll[rollnr][j].extent,
                  1,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case 'posRuade':
                rollPaths = buildShape('Ruade', Array(
                  roll[rollnr][j].extent,
                  0,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case 'negRuade':
                rollPaths = buildShape('Ruade', Array(
                  roll[rollnr][j].extent,
                  1,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case 'posLomcevak':
                rollPaths = buildShape('Lomcevak', Array(
                  roll[rollnr][j].extent,
                  0,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case 'negLomcevak':
                rollPaths = buildShape('Lomcevak', Array(
                  roll[rollnr][j].extent,
                  1,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (window.snapElement075 / lineElement);
                break;
              case ('posspin'):
                rollPaths = buildShape('Spin', Array(
                  roll[rollnr][j].extent,
                  0,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (spinElement / lineElement);
                break;
              case ('negspin'):
                rollPaths = buildShape('Spin', Array(
                  roll[rollnr][j].extent,
                  1,
                  rollTop,
                  roll[rollnr][j].comment),
                  rollPaths);
                lineLength += Math.abs(parseInt(roll[rollnr][j].extent / 360)) * (spinElement / lineElement);
            }

            /** update attitude and direction after roll */
            if (roll[rollnr][j].type !== 'line') {
              rollSum += roll[rollnr][j].extent;
              rollDone = true;
              // Half rolls and all rolls in the vertical change
              // direction and possibly attitude
              if (((rollSum + 180) % 360) === 0) {
                Attitude = 180 - Attitude;
                if (Attitude < 0) Attitude += 360;
                if (/^[CL]/.test(activeForm)) {
                  changeDir(-Math.abs(rollSum));
                } else {
                  changeDir(Math.abs(rollSum));
                }
                rollSum = 0;
                attChanged = 180 - attChanged;
                // correct load for half rolls in top of loop
                if (rollTop) NegLoad = 1 - NegLoad;
              }
              // check correct load after rolls that are not vertical or
              // in top of loop
              if ((Attitude !== 90) && (Attitude !== 270) && (!rollTop)) {
                NegLoad = (((Attitude > 90) && (Attitude < 270))) ? 1 : 0;
              }
            }
          }
          // completed all rolls of this roll position
          // set lowKFlick to false for next roll position
          lowKFlick = false;

          rollSum = rollSums[rollnr];
          let autoCorr
          // See if we have to autocorrect the rolls
          if (fig[figNr].rolls[rollnr] === 1) {
            autoCorr = -(rollSum % 360);
            // When a line is standing by to be built, build it before
            // doing the autocorrect
            if (autoCorr !== 0) {
              if (lineDraw) {
                // set a fixed distance for rolls in the top
                if (rollTop) {
                  rollPaths = buildShape('Move', [1.2 / scale], rollPaths);
                } else {
                  if (lineSum > 0) {
                    lineLength += lineSum;
                    rollPaths = buildShape('Line', [lineSum], rollPaths);
                  } else {
                    lineLength++;
                    rollPaths = buildShape('Line', [1], rollPaths);
                  }
                }
                lineSum = 0;
                lineDraw = false;
              }
              // Also build a line if a roll was done before
              if (rollDone) {
                // set a fixed distance for rolls in the top
                if (rollTop) {
                  rollPaths = buildShape('Move', [1.2 / scale], rollPaths);
                } else {
                  rollPaths = buildShape('Line', [2], rollPaths);
                  lineLength += 2;
                }
              }
            }
            if (attChanged === 180) {
              Attitude = 180 - Attitude;
              if (Attitude < 0) Attitude += 360;
              changeDir(180);
            }
          } else if (fig[figNr].rolls[rollnr] === 2) {
            autoCorr = -((rollSum + 180) % 360);
            // When a line is standing by to be built, build it before
            // doing the autocorrect
            if (autoCorr !== 0) {
              if (lineDraw) {
                // set a fixed distance for rolls in the top
                if (rollTop) {
                  rollPaths = buildShape('Move', [1.2 / scale], rollPaths);
                } else {
                  if (lineSum > 0) {
                    lineLength += lineSum;
                    rollPaths = buildShape('Line', [lineSum], rollPaths);
                  } else {
                    lineLength++;
                    rollPaths = buildShape('Line', [1], rollPaths);
                  }
                }
                lineSum = 0;
                lineDraw = false;
              }
              // Also build a line if a roll was done before
              if (rollDone) {
                // set a fixed distance for rolls in the top
                if (rollTop) {
                  rollPaths = buildShape('Move', [1.2 / scale], rollPaths);
                } else {
                  rollPaths = buildShape('Line', [2], rollPaths);
                  lineLength += 2;
                }
              }
            }
            // Half rolls change direction, attitude and load
            if (attChanged === 0) {
              Attitude = 180 - Attitude;
              if (Attitude < 0) Attitude += 360;
              changeDir(180);
            }
          } else {
            autoCorr = 0;
          }
          // Add autocorrect roll
          if (autoCorr !== 0) {
            rollPaths = buildShape('Roll', Array(autoCorr, 0, false, false, true), rollPaths);
            // Find which roll in figures.js matches. There should only be one.
            // Then add Aresti nr and K factor
            var rollAtt = rollAttitudes[Attitude];
            switch (Math.abs(autoCorr)) {
              case (90):
                var addRoll = rollAtt + '4';
                break;
              case (180):
                var addRoll = rollAtt + '2';
                break;
              case (270):
                var addRoll = rollAtt + '3';
                break;
              default:
                var addRoll = false;
            }
            if (addRoll) {
              arestiNrs.push(rollFig[addRoll].aresti);
              if (rollFig[addRoll].kRules) {
                kFactors.push(rollFig[addRoll].kRules);
              } else kFactors.push(rollFig[addRoll].kPwrd);
            }
            alertMsgs.push('(' + seqNr + ') ' + userText.autocorrectRoll);
          }
          // Add the second curve segment after a roll in the top
          // Invert the angle when it was a half roll
          // Move the pointer to where the roll should be. Start it offset
          // so it is centered on the top (especially for multiple rolls)
          let dx, dy, dxRolls, dyRolls
          if (rollTop) {
            if (fig[figNr].rolls[rollnr] === 2) {
              rollTopAngleAfter = -rollTopAngleAfter;
            }
            var topLineAngle = (rollTopAngleAfter > 0) ? 45 : -45;
            paths = buildShape('Curve', topLineAngle, paths);
            if (rollPaths.length) {
              // draw the second marker when there was a roll in the top
              paths = buildShape('RollTopLine', '', paths);
            } else {
              // no roll, remove first marker
              paths[paths.length - 3].path = '';
            }
            paths = buildShape('Curve', rollTopAngleAfter - topLineAngle, paths);
            // Retrieve the movement by the two curve paths
            dx = paths[paths.length - 1].dx + paths[paths.length - 3].dx;
            dy = paths[paths.length - 1].dy + paths[paths.length - 3].dy;
            dxRolls = 0
            dyRolls = 0;

            // Retrieve the roll path movements
            for (var k = 0; k < rollPaths.length; k++) {
              if (rollPaths[k].dx) dxRolls += rollPaths[k].dx;
              if (rollPaths[k].dy) dyRolls += rollPaths[k].dy;
            }
            /** fixme: would be nice to have rolls and snaps follow the curve of the
             *  loop. No consistent method found yet. */
            paths.push({
              'path': '',
              'style': '',
              'dx': -dx - (dxRolls / 2),
              'dy': -dy - (dyRolls / 2)
            });
          }

          // Add all the roll paths
          for (var k = 0; k < rollPaths.length; k++) {
            paths.push(rollPaths[k]);
          }
          // Move back to the right place at the end of the curve after
          // a roll in the top
          if (rollTop) {
            paths.push({
              'path': '',
              'style': '',
              'dx': dx - (dxRolls / 2),
              'dy': dy - (dyRolls / 2)
            });
          }
        }

        // The roll drawing has past, so make sure the rollTop variable
        // is set to false
        rollTop = false;
        rollnr++;
        break;
      // handle clovers (figures with fixed 1/4 roll up or down)
      case ('4'):
        paths = buildShape('Roll', Array(90, 0), paths);
        break;
      // (rolling) turns are handled here. We pass the turn part and any
      // direction changers of the draw string.
      // Other parsing is in the makeTurn function
      case ('j'):
      case ('J'):
        if (regexChangeDir.test(figString)) {
          figures[figStringIndex].switchX = true;
          var prefix = (/^[CL]/.test(activeForm)) ? '' : userpat.moveforward;
        } else {
          figures[figStringIndex].switchX = false;
          var prefix = (/^[CL]/.test(activeForm)) ? userpat.moveforward : '';
        }
        paths = buildShape('Turn', prefix +
          figureDraw.replace(/[^jioJIO\d]+/g, ''), paths);
        var regex = /[jioJIO\d]+/;
        while (regex.test(figureDraw.charAt(i))) i++;
        i--;
        break;
      // Handle angle and curve drawing. In here we will also decide the
      // goRight parameter that decides how we roll vertical
      default:
        if (figureDraw.charAt(i) in drawAngles) {
          var angle = parseInt(drawAngles[figureDraw.charAt(i)]);
          // add text to description
          description.push((angle > 0 ? "Pull %s degrees" : "Push %s degrees").replace('%s', Math.abs(angle)));
          // Draw sharp angles for corners less than 180 unless
          // specifically told to make a curve by '=' symbol
          if ((Math.abs(angle) < 180) && (figureDraw.charAt(i + 1) !== '=')) {
            paths = buildShape('Corner', angle, paths);
          } else {
            // Draw curve. Half size when followed by '/' symbol
            if (figureDraw.charAt(i + 1) === '/') {
              curveRadius = curveRadius / 2;
            }
            // Check if the next roll should be in the top
            rollTop = (figureDraw[i + 1] === '!') ? true : false;
            if ((Math.abs(angle) < 360) && !rollTop) {
              paths = buildShape('Curve', angle, paths);
            } else if (rollTop) {
              // Split any loop with a roll in top so we can use the second
              // part later to determine the top
              // We do this by finding the point closest to the center of
              // looping shape that has Attitude 0 or 180
              const attTop = Attitude + (angle / 2);
              let diffTop = ((attTop / 180) - parseInt((attTop + 90) / 180)) * 180;
              if (Math.abs(diffTop) > 90) {
                diffTop = ((attTop / 180) - parseInt((attTop - 90) / 180)) * 180;
              }
              const angleTop = (angle / 2) - diffTop;

              var topLineAngle = (angleTop > 0) ? 45 : -45;
              paths = buildShape('Curve', angleTop - topLineAngle, paths);
              paths = buildShape('RollTopLine', '', paths);
              paths = buildShape('Curve', topLineAngle, paths);
              var rollTopAngleAfter = angle - angleTop;
            } else {
              // Split full loops in several parts for drawing. Only used
              // for loops without a roll in the top. Useful for vertical 8s

              // Option 1: We add an invisible part to clarify initial loop.

              if (figureDraw.charAt(i + 1) === '«') {
                paths = buildShape('Line', [2, false, 'hiddenCurve'], paths);
                paths = buildShape('Curve', { angle: angle * 0.05, style: 'hiddenCurve' }, paths);
                paths = buildShape('Curve', angle * 0.95, paths);
              } else if (figureDraw.charAt(i + 1) === '»') {
                paths = buildShape('Curve', angle * 0.95, paths);
                paths = buildShape('Curve', { angle: angle * 0.05, style: 'hiddenCurve' }, paths);
                paths = buildShape('Line', [2, false, 'hiddenCurve'], paths);
              } else {
                paths = buildShape('Curve', { angle: angle * 0.5 }, paths);
                paths = buildShape('Curve', { angle: angle * 0.5 }, paths);
              }

              /*
                            // Option 2: Vary curve size in each half
                            if (figureDraw.charAt(i + 1) === '@') smallCurve++;
                            if (smallCurve === 1) curveRadius -= 1.5;
                              paths = buildShape ('Curve', angle * 0.5, paths);
                              if (smallCurve === 1) curveRadius += 1.5;
                                          if (smallCurve === 2) curveRadius -= 1.5;
                            paths = buildShape ('Curve', angle * 0.5, paths);
                            if (smallCurve === 2) curveRadius += 1.5;
                            */
            }
            // if applicable, reset curveRadius when done
            if (figureDraw.charAt(i + 1) === '/') curveRadius *= 2;
          }
          NegLoad = (angle < 0) ? 1 : 0;
          // The lineLength may be necessary for e.g. hammerhead and is
          // set to 0 after any angle
          lineLength = 0;
          // unset lowKFlick after every curve
          lowKFlick = false;
        }
    }
  }

  /*
    // Check for hiddenCurvePaths and set first and last back to normal.
    // This will ensure the middle ones hidden in case of some
    // vertical 8 figures
    if (hiddenCurvePaths.length) {
          paths [hiddenCurvePaths[0]].style =
              paths [hiddenCurvePaths[0] + 1].style;
          paths [hiddenCurvePaths[hiddenCurvePaths.length - 1]].style =
              paths [hiddenCurvePaths[hiddenCurvePaths.length - 1] - 1].style;
      }
      */

  // Check for hiddenCurvePaths and set middle two to normal.
  // This will ensure the middle ones hidden in case of some
  // vertical 8 figures
  /*
    if (hiddenCurvePaths.length) {
          paths [hiddenCurvePaths[1]].style =
              paths [hiddenCurvePaths[1] - 1].style;
          paths [hiddenCurvePaths[hiddenCurvePaths.length - 2]].style =
              paths [hiddenCurvePaths[hiddenCurvePaths.length - 2] + 1].style;
      }
      */

  // Draw any remaining line, we can leave the variables 'dirty' because
  // there is no more processing after this
  if (lineDraw) {
    paths = buildShape('Line', [Math.max(lineSum, 1), 'exit'], paths);
    figures[figStringIndex].exitLength = Math.max(lineSum, 1);
  }

  // Make the end of figure symbol
  paths = buildShape('FigStop', '', paths);
  // Create empty space after each figure
  paths = buildShape('FigSpace', 2, paths);

  // Replace double spaces or a space at the end on the figCheckLine
  // with the fake Aresti code for no roll 0.0.0.0
  while (figCheckLine[seqNr].match(/(  )|( $)/)) {
    figCheckLine[seqNr] = figCheckLine[seqNr].replace('  ', ' 0.0.0.0 ');
    figCheckLine[seqNr] = figCheckLine[seqNr].replace(/ $/, ' 0.0.0.0');
  }

  // The figure is complete. Create the final figure object for later
  // processing such as drawing Forms and point & click figure editing
  figures[figStringIndex].description = description;
  figures[figStringIndex].paths = paths;
  figures[figStringIndex].aresti = arestiNrs;
  figures[figStringIndex].k = kFactors;
  figures[figStringIndex].seqNr = seqNr;
  figures[figStringIndex].rolls = roll;
  figures[figStringIndex].rollInfo = rollInfo;
  figures[figStringIndex].scale = Math.round((scale - 1) * 10);
  figures[figStringIndex].entryExt = entryExt;
  figures[figStringIndex].exitExt = exitExt;
  figures[figStringIndex].entryAxis = entryAxis;
  figures[figStringIndex].entryAtt = entryAtt;
  figures[figStringIndex].entryDir = entryDir;
  figures[figStringIndex].exitAtt = Attitude;
  figures[figStringIndex].exitDir = Direction;
  figures[figStringIndex].unknownFigureLetter = unknownFigureLetter;
  figures[figStringIndex].checkLine = figCheckLine[seqNr];
  figures[figStringIndex].superFamily = 'ignored';
  unknownFigureLetter = false;

  // set OLAN.inFigureXSwitchFig (used for OLAN sequence autocorrect) to
  // Infinity when we exit on X axis
  if ((Direction === 0) || (Direction === 180)) {
    OLAN.inFigureXSwitchFig = Infinity;
  }
}

// updateXYFlip changes all > to ^ and vv from figure 'n' (defined by
// comparePreviousSequence) to the end.
// But only on Free (Un)known figures with matching Figure Letters. It is
// done only for these as otherwise unexpected effects when copy-pasting
// whole sequences may occur.
// When doing this, the Figure Letters will be identical by definition,
// as the sequence is checked identical as of 'n'.
function updateXYFlip(m, n) {
  var sub = false;
  var dmn = m - n;
  if (activeSequence.figures[n]) {
    var text = activeSequence.text.substring(0, activeSequence.figures[n].stringStart);
    for (var i = n; i < activeSequence.figures.length; i++) {
      var s = activeSequence.figures[i].string;
      if (s.match(/^e(u|d|j|ja)$/)) sub = true;
      // Only act on figures that previously had an unknownFigureLetter.
      // This will also prevent matching moves, such as "12>", ">", "^^"
      // Also, do not flip XY in new subsequences
      if (activeSequence.previousFigures[i + dmn].unknownFigureLetter && !sub) {
        // switch > and ^. Use temporary placeholder #
        s = s.replace(regexSwitchDirY, '#').
        replace(regexSwitchDirX, userpat.switchDirY).
        replace(/#/g, userpat.switchDirX);
      }
      activeSequence.figures[i].string = s;
      text += s + ' ';
    }
  }
}

// setFigureSelected sets the active figure and applies color filter
function setFigureSelected(figNr) {
  if (figNr === false) figNr = null;
  // define header element for info
  var header = document.getElementById('figureHeader');

  if (activeForm !== 'FU') {
    // if any figure was previously selected, remove that filter
    var selFig = SVGRoot.getElementById('figure' + selectedFigure.id);
    if (selFig) {
      var nodes = selFig.childNodes;
      for (var i = nodes.length - 1; i >= 0; i--) {
        var s = nodes[i].getAttribute('style');
        if (s) {
          nodes[i].setAttribute('style',
            s.replace(/#ff00a0/g, 'black').replace(/#ff1090/g, 'red')
          );
        }
        if (nodes[i].id &&
          nodes[i].id.match(/^(.*-handle|magnifier|selectedFigureBox)$/)) {
          selFig.removeChild(nodes[i]);
        }
      }
    }

    if (figNr !== null && figures[figNr].aresti) {
      // fill selectedFigure with BBox values
      var el = SVGRoot.getElementById('figure' + figNr);

      header.innerHTML = userText.editingFigure + figures[figNr].seqNr +
        ' (' + figures[figNr].k.reduce(function (a, v) { return a + v; }) + 'K)';

      if (el) {
        var
          svgScale = roundTwo(SVGRoot.viewBox.baseVal.width /
            (SVGRoot.width.baseVal.value || 1)) || 1,
          showHandles = document.getElementById('showHandles').checked &&
            !(activeForm === 'G'),
          nodes = el.childNodes,
          length = nodes.length;
        selectedFigure = el.getBBox();
        // apply color filter
        for (var i = 0; i < length; i++) {
          var s = nodes[i].getAttribute('style');
          if (s) {
            nodes[i].setAttribute('style',
              s.replace(/black/g, '#ff00a0').replace(/red/g, '#ff1090')
            );
          }
          // add editing handles where applicable. They are centered on
          // the element. Somewhat larger circles for touch devices to
          // improve grabbing, corrected for svg scaling
          if (nodes[i].id && showHandles) {
            var bBox = nodes[i].getBBox();
            drawCircle({
              'cx': roundTwo(bBox.x + bBox.width / 2),
              'cy': roundTwo(bBox.y + bBox.height / 2),
              'r': platform.touch ? 12 * svgScale : 8,
              'style': style.selectedFigureHandle,
              'cursor': 'move',
              'id': nodes[i].id + '-handle'
            }, el);
          }
        }
        // add scale handle
        if (showHandles) {
          drawImage({
            x: selectedFigure.x + selectedFigure.width - 6,
            y: selectedFigure.y - 9,
            width: 28 * svgScale,
            height: 28 * svgScale,
            'id': 'magnifier',
            cursor: 'move',
            href: 'img/magnifier.svg'
          }, el);
        }
      }
    }
  } else {
    var el = document.getElementsByClassName('fuFig' + figNr)[0];
    if (el) el.classList.add('active');
    header.innerHTML = userText.editingFigure;
  }

  // set selectedFigure.id
  selectedFigure.id = figNr;
}

// parseSequence parses the sequence character string
export function parseSequence(figsToParse) {
  var
    seqNr = 1,
    subSequence = false,
    comments = false,
    figure = '',
    formBDirection = 0,
    match = false;

  // Clear the figCheckLine array
  figCheckLine = [];
  // Clear the figureStart array
  figureStart = [];

  additionals = 0;

  // Get the split string from activeSequence
  figures = figsToParse;

  for (var i = 0; i < figsToParse.length; i++) {

    figure = figsToParse[i].string;
    // always start figure LTR for Figures in grid view. But this means
    // we have to correct direction switchers if the figure would start
    // on Y axis on Form B
    formBDirection += Direction;
    if (formBDirection >= 360) formBDirection -= 360;
    if ((formBDirection === 90) || (formBDirection === 270)) {
      // switch > and ^. Use temporary placeholder #
      figure = figsToParse[i].string.replace(regexSwitchDirY, '#').
      replace(regexSwitchDirX, userpat.switchDirY).
      replace(/#/g, userpat.switchDirX);
      figsToParse[i].entryAxisFormB = 'Y';
    }
    Direction = (Attitude === 0) ? 0 : 180;


    // replace the second '-' and up (in row) by longforward (reverse for speed)
    // e.g. ---h- will be ++-h-
    // don't know a way to do this with regex...
    var multipleMinus = false;
    // if the figure is only - and extensions, e.g. --, disregard last minus
    var skipMinus = RegExp('^[-\\' + userpat.forward + '\\' +
      userpat.lineshorten + ']*$').test(figure) ? true : false;
    for (var j = figure.length - 1; j >= 0; j--) {
      if (figure[j] === '-') {
        if (multipleMinus) {
          figure = figure.substring(0, j) + userpat.longforward +
            figure.substring(j + 1);
        } else if (skipMinus) {
          skipMinus = false;
        } else multipleMinus = true;
      } else multipleMinus = false;
    }


    if (match = figure.match(/^e(ja?|d|u)$/)) {
      match = match[0];
      Attitude = 0;
      // sequence entry options
      if (match === 'eja') {
        // Crossbox away entry 'figure'
        Direction = 90;
      } else if (match === 'ej') {
        // Crossbox entry 'figure'
        Direction = 270;
      } else if (match === 'ed') {
        // Downwind entry 'figure'
        if (/^[CL]/.test(activeForm)) {
          Direction = 0;
          goRight = true;
        } else {
          Direction = 180;
          goRight = false;
        }
      } else if (match === 'eu') {
        // Upwind entry 'figure'
        if (/^[CL]/.test(activeForm)) {
          Direction = 180;
          goRight = false;
        } else {
          Direction = 0;
          goRight = true;
        }
      }
      if (!firstFigure) {
        subSequence = match;
        firstFigure = true;
      }
      figures[i].subSequence = match;
    } else {
      var base = figure
      // To determine the base we remove all non-alphabet characters (except -)
      var base = base.replace(/[^a-zA-Z\-]+/g, '');
      // Handle the very special case where there's only an upright (1)
      // or inverted (2) spin
      if (base.replace(/-/g, '') === 's') {
        figure = figure.replace(/(\d*)s/, "iv$1s");
        base = base.replace('s', 'iv');
      } else if (base.replace(/-/g, '') === 'is') {
        figure = figure.replace(/(\d*)is/, "iv$1is");
        base = base.replace('is', 'iv');
      }
      // To continue determining the base we remove all snap, spin and
      // tumble characters. Handle special case of non-Aresti tri figure
      base = base.replace('tri', '#').replace(/i?[fseul]/g, '').replace('#', 'tri');
      // Handle simple horizontal rolls that change from upright to
      // inverted or vv
      if (base === '-') {
        if (figure.replace(/[^a-zA-Z0-9\-\+]+/g, '').charAt(0) === '-') {
          base += '+';
        } else {
          base = '+' + base;
        }
        // Handle everything else
      } else if ((base !== '') || figure.match(/^[^\[\(]*[0-9fseul]/)) {
        // begin the base with a '+' if there is no '-'
        if (base.charAt(0) !== '-') base = '+' + base;
        // end the base with a '+' if there is no '-'
        if (base.charAt(base.length - 1) !== '-') base += '+';
      }
      // set subSequence to true for subsequence 'figures'
      if (base.match(/^\+e(j|ja|d|u)\+$/) && !firstFigure) {
        subSequence = true;
      }
      // Autocorrect the entry attitude for figures after the first
      // (sub)figure where necessary
      if (!(firstFigure || subSequence)) {
        // Start upright
        if (Attitude === 0) {
          if (base[0] === '-') {
            Attitude = 180;
            changeDir(180);
            // don't show warning in Grid view
            if (activeForm !== 'G') {
              alertMsgs.push('(' + seqNr + ') ' + userText.setUpright);
              // draw circle around figure start
              figures[i].paths = [{
                'path': 'm -' + (window.rollcurveRadius * 1.2) +
                  ',0 a' + (window.rollcurveRadius * 1.2) + ',' + (window.rollcurveRadius * 1.2) +
                  ' 0 1 1 0,0.01', 'style': 'corr'
              }];
            }
          }
          // Start inverted
        } else if (Attitude === 180) {
          if (base[0] === '+') {
            Attitude = 0;
            changeDir(180);
            // don't show warning in Grid view
            if (activeForm !== 'G') {
              alertMsgs.push('(' + seqNr + ') ' + userText.setInverted);
              // draw circle around figure start
              figures[i].paths = [{
                'path': 'm -' + (window.rollcurveRadius * 1.2) +
                  ',0 a' + (window.rollcurveRadius * 1.2) + ',' + (window.rollcurveRadius * 1.2) +
                  ' 0 1 1 0,0.01', 'style': 'corr'
              }];
            }
          }
        }
      }
      // Handle turns and rolling turns. They do have numbers in the base
      if (base.match(/^.j[^w]/)) {
        base = figure.replace(/[^a-zA-Z0-9\-\+]+/g, '');
        if (base.charAt(0) !== '-') base = '+' + base;
        if (base.charAt(base.length - 1) !== '-') base += '+';
      }
      // Retrieve the figNrs (if any) from array figBaseLookup
      var figNrs = figBaseLookup[base];
      if (figNrs) {
        // When the first figure starts negative we make sure the
        // Attitude is inverted and the DRAWING direction stays the same
        if ((firstFigure || subSequence) && (base.charAt(0) === '-')) {
          Attitude = 180;
          changeDir(-180);
        }
        // switch default goRight for every figure that starts on X axis
        if ((Direction === 0) || (Direction === 180)) {
          goRight = ((Direction === 180) === (Attitude === 0));
        }
        // build the figure into the figures object
        buildFigure(figNrs, figure, seqNr, i);
        // check if this is a additional
        if (regexAdditional.test(figure)) {
          additionals++;
          figures[i].additional = true;
        } else if (figures[i].unknownFigureLetter) {
          if (figures[i].unknownFigureLetter === 'L') {
            additionals++;
            figures[i].additional = true;
          }
        }

        // increase sequence figure number
        seqNr++;
        firstFigure = false;
      } else if (base !== '') {
        // No viable figure found, therefore Illegal
        buildIllegal(i);
        if (i === (figures.length - (activeForm === 'FU' ? 2 : 1))) {
          alertMsgs.push(userText.illegalAtEnd);
        } else {
          alertMsgs.push(userText.illegalBefore + seqNr);
        }
      }
    }

    // set the exitAxis for every figure, including draw only
    figures[i].exitAxis = ((Direction === 0) || (Direction === 180)) ? 'X' : 'Y';
  }

}

// newSvg creates a new, minimal svg
function newSvg() {
  var svg = document.createElementNS(svgNS, "svg");
  svg.setAttribute("xmlns", svgNS);
  svg.setAttribute("version", "1.2");
  svg.setAttribute("baseProfile", "basic");
  prepareSvg(svg);
  return svg;
}

// rebuildSequenceSvg deletes and recreates the svg that holds the sequence
// SVGRoot is a global SVG object
function rebuildSequenceSvg() {
  var container = document.getElementById("svgContainer");

  SVGRoot = newSvg();
  SVGRoot.setAttribute("xmlns:xlink", xlinkNS);
  SVGRoot.setAttribute("id", "sequenceSvg");
  SVGRoot.setAttribute("viewBox", "0 0 800 600");
  SVGRoot.setAttribute("viewport-fill", "white");

  // container.appendChild(SVGRoot);

  // these svg points hold x and y values...
  // very handy, but they do not display on the screen
  TrueCoords = SVGRoot.createSVGPoint();
  GrabPoint = SVGRoot.createSVGPoint();
}

// prepareSvg clears a provided svg and prepares it for figure addition
function prepareSvg(svg) {
  while (svg.childNodes.length) svg.removeChild(svg.lastChild);
  var group = document.createElementNS(svgNS, "g")
  group.setAttribute('id', 'sequence');
  svg.appendChild(group);
}

// changeReferenceSequence is called when the reference sequence is
// changed
export function parse(sequenceString) {
  parseFiguresFile()

  const figures = split(sequenceString).map(f => ({ string: f }))

  activeSequence.figures = figures

  // Parse sequence
  activeForm = 'G';
  Attitude = 0
  Direction = 0

  parseSequence(figures)

  makeFormGrid(sequenceString);

  return figures
}

function sanitizeSpaces(line, noLT) {
  line = line.replace(/[\t]/g, ' ').replace(/\s\s+/g, ' ');
  if (!noLT) line = line.trim();
  return line;
}

// parseFiguresFile parses the figures file and stores it in several
// arrays for fast retrieval
function parseFiguresFile() {
  var
    arestiNrs = {};
  var groupRegex = new RegExp('^F[0-9]'),
    figGroupSelector = document.getElementById('figureGroup'),
    figGroupNr = 0,
    entryExit = '',
    entryExitSplit = [];

  // add the Queue 'figure group' at the beginning
  figGroup[0] = { 'family': '*', 'name': userText.figureQueue };
  // add an option for the group to the figure selector
  var option = document.createElement('option');
  option.setAttribute('value', 0);
  option.id = 't_figureQueue';
  option.classList.add('userText');
  if (figGroupSelector) figGroupSelector.appendChild(option);

  var figMainGroup = '';
  var groupClasses = ['familyA', 'familyB'];
  var groupClass = 1;
  for (let i = 0; i < figs.length; i++) {
    // Clean up the lines
    var line = sanitizeSpaces(figs[i]);
    // Split the remainder on the space. Figure group lines and
    // Family 9 should now have two elements, the others three
    var splitLine = line.split(" ");
    // check if we are dealing with a 'figure group' line
    if (groupRegex.test(line)) {
      // increase figGroupNr counter
      figGroupNr++;
      // parse family and name
      figGroup[figGroupNr] = {
        'family': splitLine[0].replace(/^F/, ''),
        'name': splitLine[1]
      };
      // add an option for the group to the figure selector
      var option = document.createElement('option');
      option.setAttribute('value', figGroupNr);
      option.innerHTML = line.replace(/^F/, '');
      if (figMainGroup !== line[1]) {
        figMainGroup = line[1];
        groupClass = 1 - groupClass;
      }
      option.classList.add(groupClasses[groupClass]);
      if (figGroupSelector) figGroupSelector.appendChild(option);
    } else {
      if (splitLine[0]) {
        // Next we split the Aresti and K-factors part
        var arestiK = splitLine[1].split("(");
        var kFactors = arestiK[1].replace(")", "").split(":");
        if (!arestiK[1].match(/:/)) kFactors[1] = kFactors[0];
        // Split K factors on the colon; kFactors[0] is for Powered,
        // kFactors[1] is for Gliders
        fig[i] = {
          'aresti': arestiK[0],
          'kPwrd': kFactors[0],
          'kGlider': kFactors[1],
          'group': figGroupNr,
          'pattern': splitLine[0]
        };
        (arestiToFig[arestiK[0]] || (arestiToFig[arestiK[0]] = [])).push(i);
        // We will extract roll elements for everything but roll figures
        // and (rolling) turns
        var theBase
        if (regexTurn.test(splitLine[0])) {
          // handle (rolling) turns
          theBase = splitLine[0];
          if (theBase in figBaseLookup) {
            figBaseLookup[theBase].push(i);
          } else {
            figBaseLookup[theBase] = [i];
          }
          fig[i].base = theBase;
          fig[i].draw = splitLine[2];
          fig[i].entryExit = fig[i].entryExitGlider = fig[i].entryExitPower =
            theBase.replace(/[^-+]*/g, '').replace(/-/g, 'n').replace(/\+/g, 'N');
        } else if (splitLine.length > 2) {
          // handle everything except (rolling) turns and rolls
          theBase = splitLine[0].replace(regexTurnsAndRolls, '');
          if (theBase in figBaseLookup) {
            figBaseLookup[theBase].push(i);
          } else {
            figBaseLookup[theBase] = [i];
          }
          fig[i].base = theBase;
          fig[i].draw = splitLine[2];
          // Find which rolls are possible in this figure, handle the
          // empty base of rolls on horizontal
          var rollbase
          if (theBase.replace(/[\+\-]+/g, '') !== '') {
            rollbase = splitLine[0].split(theBase.replace(/[\+\-]+/g, ''));
          } else {
            rollbase = Array(splitLine[0].replace(/[\+\-]+/g, ''));
          }
          var rolls = rollbase.join(')').replace(/[\(\+\-]+/g, '').split(')');
          fig[i].rolls = [];
          for (let r = 0; r < rolls.length; r++) {
            switch (rolls[r]) {
              case (figpat.fullroll):
                fig[i].rolls[r] = 1;
                break;
              case (figpat.halfroll):
                fig[i].rolls[r] = 2;
                break;
              case (figpat.anyroll):
                fig[i].rolls[r] = 3;
                break;
              case (figpat.spinroll):
                fig[i].rolls[r] = 4;
                break;
              case (figpat.longforward):
                fig[i].rolls[r] = 9;
                break;
              default:
                fig[i].rolls[r] = 0;
            }
          }
          // create entry/exit speed and attitude codes
          entryExit = fig[i].draw.replace(/[^dvzmcpro_]/gi, '');
          // assure only half rolls remain
          entryExitSplit = entryExit.split('_');
          for (var j = 0; j < entryExitSplit.length; j++) {
            // if first roll does not exist, combine this drawing part
            // with the next roll (e.g. +id^-)
            if (fig[i].rolls[0] === 0) {
              if (fig[i].rolls[j + 1] === 2) entryExitSplit[j] += '^';
            } else {
              if (fig[i].rolls[j] === 2) entryExitSplit[j] += '^';
            }
          }
          // add attitude info
          entryExit = theBase.charAt(0) + entryExitSplit.join('') +
            theBase.charAt(theBase.length - 1);
          // simplify
          entryExit = entryExit.replace(regexSpeedConv.v, 'v').replace(regexSpeedConv.V, 'V');

          // getCode gets correct letter code, depending on attitude,
          // aClass (aerobatic class) and entry or exit
          function getCode(att, aClass, ee) {
            if (regexSpeed[aClass][ee].L.test(entryExit)) {
              return (att === '+') ? 'L' : 'l';
            } else if (regexSpeed[aClass][ee].H.test(entryExit)) {
              return (att === '+') ? 'H' : 'h';
            } else return (att === '+') ? 'N' : 'n';
          }

          fig[i].entryExitGlider = getCode(theBase[0], 'glider', 'entry') +
            getCode(theBase[theBase.length - 1], 'glider', 'exit');
          fig[i].entryExitPower = getCode(theBase[0], 'power', 'entry') +
            getCode(theBase[theBase.length - 1], 'power', 'exit');
          fig[i].entryExit = fig[i].entryExitPower;
        } else {
          // Handle rolls
          delete (fig[i]); // no fig object for rolls
          rollFig[splitLine[0]] = {
            aresti: arestiK[0],
            kPwrd: parseInt(kFactors[0]),
            kGlider: parseInt(kFactors[1])
          };
          (rollArestiToFig[arestiK[0]] || (rollArestiToFig[arestiK[0]] = [])).push(splitLine[0]);
        }
      }
    }
  }
  // select first figure group
  if (figGroupSelector) figGroupSelector.value = 1;
}

export const split = sequenceString =>
  sequenceString
    .replace(/(\r\n|\n|\r)/gm, ' ') // remove all line breaks from the sequence reference
    .replace(/[`.~'+]/gm, '') // remove all style figures
    .replace(/(\([^ ]*\)|[^ ]*%|"[^ ]*")/gm, '') // remove trick positions and comment figures
    .replace(/([ ]{2,})/gm, ' ')
    .trim()
    .split(' ')
