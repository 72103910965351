import { createTheme } from '@mui/material/styles'
import { renderTimeViewClock } from '@mui/x-date-pickers'

const palette = {
  background: {
    default: '#eaeff1',
    card: '#fafafa',
    grey1: 'rgba(236,239,241,.38)',
    menu: '#051e34',
  },
  grey: {
    300: '#E4E6EB',
  },
  primary: {
    light: '#63ccff',
    main: '#1a73e8',
    dark: '#006db3',
    divider: 'rgb(42, 72, 101)',
  },
  secondary: {
    main: '#F05',
  },
}

const theme = createTheme({
  palette,
  typography: {
    useNextVariants: true,
    fontFamily: 'Noto Sans',
    h1: {
      fontSize: '4rem',
      marginBottom: '2rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    subtitle1: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  components: {
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: 500,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: '#ffffff',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 8,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
  },
})

export default theme
