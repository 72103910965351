import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'


export const CTAButton = props => {
  const style = {
    fontSize: { xs: '1rem', lg: '1.3rem' },
    color: props.disabled ? 'white' : 'grey.100',
    fontWeight: 600,
    textTransform: 'initial',
    bgcolor: props.disabled ? 'grey.200' : 'primary.main',
    mx: 'auto',
  }
  return (
    <Button variant='contained' className={props.className} sx={{ ...style, ...props.sx }} {...props} />
  )
}

CTAButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  sx: PropTypes.object,
}

export default CTAButton
