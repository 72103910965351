import React, { useEffect, useMemo, useState } from 'react'
import { Grid, CircularProgress, Button, Box } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format, formatDistanceToNow, differenceInDays } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { Check, Clear, Edit } from '@mui/icons-material'
import EditQualificationDialog from './EditQualificationDialog'
import { QUALIFICATION_COLLECTION, STATUS_ACTIVATED, USER_COLLECTION } from '../../_constants/globals'
import { pick } from 'lodash'
import { getQualificationStatus } from '../../_helpers/qualificationHelper'
import QualificationIcon from '../_library/QualificationIcon'
import { useLocation } from 'react-router-dom'
import { parseParams } from '../../_helpers/urlHelper'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'
import { serverTimestamp } from 'firebase/firestore'


const STORAGE_KEY = 'allQualificationsTableState'
const AllQualificationsTable = () => {
  
  const profile = useAuth().getProfile()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const dbQualifications = qualificationHooks.getDocs()
  const location = useLocation()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [editQualification, setEditQualification] = useState(null)
  
  useEffect(() => {
    qualificationHooks.listen({
      where: [['archived', '!=', true]],
      orderBy: [
        ['archived', 'asc'],
        ['_createdAtTime', 'desc'],
      ],
    })
  }, [profile.id])
  
  const alertFilter = useMemo(() => parseParams(location.search).filter, [location.search])
  
  // Select only the first element for each typeRef
  // The input must be an array sorted by desc _createdAtTime
  const data = useMemo(
    () => dbQualifications?.map(val => {
      const { id, user, type, identValue, grantDate, expireDate, attachmentUrl, checkingComment, checker } = val
      return [
        id,
        getQualificationStatus(val),
        user,
        user?.status,
        type?.shortName,
        identValue,
        grantDate,
        expireDate,
        attachmentUrl,
        checkingComment,
        checker,
        val,
      ]
    }), [dbQualifications])
  
  const localState = window.localStorage.getItem(STORAGE_KEY) &&
    JSON.parse(window.localStorage.getItem(STORAGE_KEY))
  
  return (
    <Box sx={{
      m: 2,
      '& .MuiTableCell-body': {
        fontSize: '0.9rem',
        p: 1,
      },
    }}>
      {!data
        ? <Grid container item xs={12} justifyContent='center' sx={{ mt: '30px' }}>
          <CircularProgress size={20} />
        </Grid>
        
        : <MUIDataTable
          title='Validités'
          data={data}
          columns={[
            { name: 'id', options: { filter: false, sort: false, display: 'false' } },
            {
              name: 'Alerte',
              options: {
                filter: true,
                sort: true,
                customBodyRender: status => <QualificationIcon qualificationStatus={status} />,
                filterType: 'multiselect',
                filterList: alertFilter && [alertFilter] || localState?.filterList[1] || [],
              },
            },
            {
              name: 'Pilote',
              options: {
                customBodyRender: u => u?.firstname + ' ' + u?.lastname,
                filterList: localState?.filterList[2] || [],
              },
            },
            { name: 'Status', options: { filterList: [STATUS_ACTIVATED], filterType: 'multiselect' } },
            { name: 'Type', options: { filter: true, sort: true, filterList: localState?.filterList[4] || [], filterType: 'multiselect' } },
            { name: 'Identifiant', options: { filter: false, sort: false, display: 'false' } },
            {
              name: 'Obtention',
              options: {
                filter: false,
                sort: true,
                display: 'false',
                customBodyRender: date => date && format(date, 'dd-MM-yyyy'),
              },
            },
            {
              name: 'Limite de validité',
              options: {
                filter: false,
                sort: true,
                customBodyRender: date => {
                  if (!date) return null
                  let expires
                  const differenceInCalendarDays = date &&  differenceInDays(date, new Date())
                  if (!date) expires = ''
                  else if (differenceInCalendarDays < 0) expires = '️⛔ expired'
                  else if (differenceInCalendarDays > 0 && differenceInCalendarDays < 60) expires = '⚠️ expires'
                  else expires = '✅️ expires'
                  return expires + ' ' + formatDistanceToNow(date, { addSuffix: true }) + ' (' + format(date, 'dd-MM-yyyy') + ')'
                },
              },
            },
            {
              name: 'Document',
              options: {
                filter: false,
                sort: true,
                // eslint-disable-next-line react/display-name
                customBodyRender: url => url && <a href={url} target='_blank' rel='noopener noreferrer' >Preview</a>,
              },
            },
            { name: 'Commentaire', options: { filter: false, sort: true, display: 'false' } },
            { name: 'Checker', options: { filter: true, sort: true, display: 'false', customBodyRender: u => u && u.firstname + ' ' + u.lastname } },
            {
              name: 'Actions',
              options: {
                filter: false,
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: qualification =>
                  <>
                    <DeleteButton deleteAction={() => qualificationHooks.deleteDoc(qualification.id)} />
                    <Button
                      onClick={() => setEditQualification(qualification)}
                      title='modifier la validité'
                      sx={{ minWidth: '40px' }}
                    >
                      <Edit />
                    </Button>
                    {profile.roles.includes('admin') && // eslint-disable-line react/prop-types
                      <Button
                        onClick={() => qualificationHooks.updateDoc(qualification.id, { checkerRef: userHooks.getDocRef(profile.id), checkedAtTime: serverTimestamp() })}
                        title='vérifier la validité'
                        sx={{ minWidth: '40px' }}
                      >
                        {qualification.checkerRef ? <Clear /> : <Check /> }
                      </Button>
                    }
                  </>,
              },
            },
          ]}
          options={{
            selectableRows: 'none',
            onTableChange: (action, tableState) => {
              if (action === 'propsUpdate') return
              window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
            },
          }}
        />
      }
      {editQualification && <EditQualificationDialog onClose={() => setEditQualification(null)} qualification={editQualification} />}
    </Box>
  )
}

export default AllQualificationsTable
