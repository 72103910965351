import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserId } from '../../store/actions/dataActions'
import { useFirestore } from '../../hooks/useFirestore'
import { USER_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'


export const UserSelector = () => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const dispatch = useDispatch()
  
  return (
    <FormControl variant='standard' sx={{ m: 1, verticalAlign: 'baseline' }}>
      <InputLabel id='user-select-label' sx={{ color: 'white' }}>User</InputLabel>
      <Select
        labelId='user-select-label'
        id='user-select'
        value={selectedUserId || profile.id}
        onChange={e => dispatch(selectUserId(e.target.value))}
        label='User'
        sx={{ minWidth: '120px', borderColor: 'secondary.main' }}
      >
        {users?.map(u => <MenuItem key={u.id} value={u.id}>{u.firstname} {u.lastname}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default UserSelector
