import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectYear } from '../../store/actions/dataActions'
import { getYear } from 'date-fns'


export const YearSelector = () => {
  
  const selectedYear = useSelector(state => state.data.selectedYear)
  const dispatch = useDispatch()
  
  const years = [...Array(getYear(Date.now()) - 2017 + 1).keys()].map(y => y + 2017).reverse()
  
  return (
    <FormControl variant='standard' sx={{ m: 1, verticalAlign: 'baseline' }}>
      <InputLabel id='year-select-label' sx={{ color: 'white' }}>Année</InputLabel>
      <Select
        variant='standard'
        labelId='year-select-label'
        id='year-select'
        value={selectedYear || ''}
        onChange={e => dispatch(selectYear(e.target.value))}
        sx={{ minWidth: 120, borderColor: 'secondary.main' }}
      >
        {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
        <MenuItem value='all'>Toutes les années</MenuItem>
      </Select>
    </FormControl>
  )
}

export default YearSelector
