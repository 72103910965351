import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Avatar as MuiAvatar, Button } from '@mui/material'
import { useStorage } from '../../hooks/useStorage'
import VisuallyHiddenInput from '../_library/VisuallyHiddenInput'


const AircraftAvatarEditor = ({ aircraft }) => {
  
  const storage = useStorage('aircraft-pictures')

  const [loading, setLoading] = useState(false)
  const [prevPhotoUrl] = useState(aircraft.photoUrl)
  
  useEffect(() => {
    if (aircraft.photoUrl !== prevPhotoUrl){
      setLoading(false)
    }
  }, [aircraft.photoUrl, prevPhotoUrl])

  const handleSubmit = event => {
    setLoading(true)
    const file = event.target.files[0]
    return storage.uploadFileAction(file, `${ aircraft.id }-${ (new Date()).getMilliseconds() }.${ file.name.split('.').pop() }`)
  }

  return (
    <div>
      <Typography color='textSecondary' gutterBottom sx={{
        color: 'rgba(0,0,0,.54)',
        display: 'inline-block',
        lineHeight: '20px',
        width: '200px',
      }}>Photo de profil</Typography>
      <Button
        component='label'
        variant='contained'
        tabIndex={-1}
        disabled={loading}
        onClick={handleSubmit}
      >
        <MuiAvatar
          alt={aircraft.name}
          src={aircraft.photoUrl}
          sx={{
            width: 35,
            height: 35,
            display: 'inline-flex',
            verticalAlign: 'middle',
          }}
        />
        <VisuallyHiddenInput type='file' />
      </Button>
    </div>
  )
}

AircraftAvatarEditor.propTypes = {
  aircraft: PropTypes.object.isRequired,
}

export default AircraftAvatarEditor
