import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { eachMonthOfInterval, format, isSameMonth } from 'date-fns'
import { fr } from 'date-fns/locale'
import Loading from '../_library/Loading'
import { Box } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'
import { PB_LESSON_COLLECTION } from '../../_constants/globals'
import { BarChart } from '@mui/x-charts'


export const DistributionGraph = ({ progressBookId, sx }) => {
  
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION)
  const dbLessons = lessonHooks.getDocs()
  
  const lessons = useMemo(() => dbLessons?.filter(l => l.progressBookRef?.id === progressBookId), [dbLessons, progressBookId])
  const data = useMemo(() => {
    if (!lessons) return null
    else if (lessons.length === 0) return []
    else
      return eachMonthOfInterval({
        start: lessons[0].date < Date.now() ? lessons[0].date : Date.now(),
        end: Date.now(),
      })
        .map(month => ({
          month: format(month, 'MMMM yyyy', { locale: fr }),
          lessons: lessons.filter(l => isSameMonth(month, l.docDate)).length,
        }))
  }, [lessons])
  
  if (!lessons) return <Loading />
  else return (
    <Box sx={{ width: '100%', height: 200, ...sx }}>
      <BarChart
        dataset={data}
        xAxis={[{
          id: 'barCategories',
          scaleType: 'band',
          dataKey: 'month',
        }]}
        series={[{ dataKey: 'lessons' }]}
      />
    </Box>
  )
}

DistributionGraph.propTypes = {
  progressBookId: PropTypes.string.isRequired,
  sx: PropTypes.object,
}

export default DistributionGraph
