import { differenceInCalendarMonths } from 'date-fns'

export const getQualificationStatus = qualification => {
  const differenceInMonths = differenceInCalendarMonths(qualification.expireDate, Date.now())
  if (differenceInMonths < 0) {
    return 'expired'
  }
  else if (!qualification.attachmentUrl && !['cotisation', 'rev'].includes(qualification.type?.slug)) {
    return 'missingAttachment'
  }
  else if (differenceInMonths < 3) {
    return 'expireSoon'
  }
  else if (!qualification.checkerRef) {
    return 'tobeChecked'
  }
  else {
    return 'valid'
  }
}
