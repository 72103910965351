import React from 'react'
import { Card, CardContent, Typography, FormControl, Select, MenuItem, Divider, Grid, FormControlLabel,
  Checkbox, Box } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import InlineEditor from '../_library/InlineEditor'
import InlineAvatarEditor from './InlineAvatarEditor'
import { DatePicker } from '@mui/x-date-pickers'
import { USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'

const GeneralPanel = () => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const updateProfileAction = data => userHooks.updateDoc(profile.id, data)
  
  return (
    <Box sx={{ my: 3, mx: 'auto', maxWidth: 900 }}>
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Identité</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
        <CardContent>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Nom' value={profile.lastname} handleSubmit={val => updateProfileAction({ lastname: val })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Prénom' value={profile.firstname} handleSubmit={val => updateProfileAction({ firstname: val })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography color='textSecondary' gutterBottom display='inline-block' width='200px'>
              Sexe
            </Typography>
            <FormControl variant='outlined'>
              <Select
                variant='standard'
                id='sex-select'
                value={profile.gender || 'woman'}
                onChange={e => updateProfileAction({ gender: e.target.value })}
                margin='dense'>
                <MenuItem value='woman'>Féminin</MenuItem>
                <MenuItem value='man'>Masculin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography color='textSecondary' gutterBottom display='inline-block' width='200px'>
              Date de naissance
            </Typography>
            <DatePicker
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='birthdayDate'
              value={profile.birthdayDate ? profile.birthdayDate : new Date()}
              onChange={val => updateProfileAction({ birthdayDate: val })}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Nationalité' value={profile.nationality} handleSubmit={val => updateProfileAction({ nationality: val })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineAvatarEditor />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Coordonnées</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
        <CardContent>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Email' value={profile.email} handleSubmit={val => updateProfileAction({ email: val })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Téléphone' value={profile.phone} handleSubmit={val => updateProfileAction({ phone: val })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={profile.hideCoordinates || false}
                  onChange={e => updateProfileAction({ hideCoordinates: e.target.checked })}
                  color='primary'
                />
              }
              label='Cacher mes coordonnées aux membres MPV'
            />
          </Grid>
          <Divider variant='fullWidth' light sx={{ m: '1rem -16px' }}/>
          <Typography variant='body1'>Adresse</Typography>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Rue' value={profile.address.street} handleSubmit={val => updateProfileAction({ address: { ...profile.address, street: val } })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Code postal' value={profile.address.zipcode} handleSubmit={val => updateProfileAction({ address: { ...profile.address, zipcode: val } })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Ville' value={profile.address.city} handleSubmit={val => updateProfileAction({ address: { ...profile.address, city: val } })}/>
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Pays' value={profile.address.country} handleSubmit={val => updateProfileAction({ address: { ...profile.address, country: val } })}/>
          </Grid>
        </CardContent>
      </Card>
      
      <Divider light />
      {/* TODO Add 'delete my account' button */}
    </Box>
  )
}

export default GeneralPanel
