import React, { useMemo, useState } from 'react'
import { Add } from '@mui/icons-material'
import { Box, Button, Fab, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import ActivityCard from './ActivityCard'
import WriteActivityDialog from './WriteActivityDialog'
import { ACTIVITY_COLLECTION, ACTIVITY_STATUS_ENDED } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const TrainingPanel = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const dbActivities = activityHooks.getDocs()
  
  const [showArchived, setShowArchived] = useState(false)
  const [openNewActivityDialog, setOpenNewActivityDialog] = useState(false)
  
  const filteredActivities = useMemo(
    () => dbActivities?.filter(a => showArchived || a.status !== ACTIVITY_STATUS_ENDED),
    [dbActivities, showArchived],
  )
  
  return (
    <Box sx={{ pb: '100px' }}>
      <Button fullWidth onClick={() => setShowArchived(prev => !prev)}>{showArchived ? 'Masquer' : 'Afficher'} les stages archivés</Button>
      <Box sx={{ maxWidth: '1054px', mx: 'auto', p: 1, display: 'flex', flexWrap: 'wrap' }}>
        {filteredActivities
          ? filteredActivities.map(a => <ActivityCard key={a.id} activity={a} />)
          : Array.from(new Array(4)).map((_, i) =>
            <Skeleton key={i} variant='rounded' animation='wave' width='330px' height='250px' sx={{ m: 1 }} />,
          )
        }
      </Box>
      {adminMode && <Fab
        color='secondary'
        aria-label='add'
        onClick={() => setOpenNewActivityDialog(true)}
        sx={{
          position: 'fixed',
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
        }}
      >
        <Add />
      </Fab>}
      {openNewActivityDialog && <WriteActivityDialog open createMode onClose={() => setOpenNewActivityDialog(false)} />}
    </Box>
  )
}

export default TrainingPanel
