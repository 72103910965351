import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import { format } from 'date-fns'


const CalendarItem = ({ date, sx }) => (
  <Card sx={{ height: 80, width: 80, ...sx }}>
    <CardHeader sx={{ bgcolor: '#F3425F', height: '25%', p: 0 }} />
    <CardContent sx={{ p: 0 }}>
      <Typography fontSize='40px' textAlign='center' sx={{ verticalAlign: 'middle' }}>{format(date, 'dd')}</Typography>
    </CardContent>
  </Card>
)

CalendarItem.propTypes = {
  date: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default CalendarItem
