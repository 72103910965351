import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth'
import { FormControl, FormHelperText, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import FileUpload from '../_library/FileUpload'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'
import { QUALIFICATION_COLLECTION, QUALIFICATION_TYPE_COLLECTION } from '../../_constants/globals'


const QualificationFields = ({ type, onChange }) => {
  
  const profile = useAuth().getProfile()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  const dbQualifications = qualificationHooks.getDocs()
  const qualificationTypeHooks = useFirestore(QUALIFICATION_TYPE_COLLECTION)
  
  const [subtype, setSubtype] = useState(null)
  const [number, setNumber] = useState(null)
  const [grantDate, setGrantDate] = useState(null)
  const [expireDate, setExpireDate] = useState(null)
  const [attachmentUrl, setAttachmentUrl] = useState(null)
  const [errors] = useState({})
  
  useEffect(() => {onChange({ subtype })}, [subtype])
  useEffect(() => {onChange({ number })}, [number])
  useEffect(() => {onChange({ grantDate })}, [grantDate])
  useEffect(() => {onChange({ expireDate })}, [expireDate])
  useEffect(() => {onChange({ attachmentUrl })}, [attachmentUrl])
  useEffect(() => {
    onChange({ typeRef: qualificationTypeHooks.getDocRef(type.id) })
    const qualification = dbQualifications.find(q => q.typeRef.id === type.id)
    if (qualification) {
      onChange({ id: qualification.id })
      setSubtype(qualification.subtype)
      setNumber(qualification.number)
      setGrantDate(qualification.grantDate)
      setExpireDate(qualification.expireDate)
      setAttachmentUrl(qualification.attachmentUrl)
    }
  }, [dbQualifications, type])
  
  return (
    <Grid item container xs={12} mb={1}>
      <Grid item sm={3} xs={12}>
        <Typography color='textSecondary' lineHeight='20px' sx={{ color: 'rgba(0,0,0,.54)' }}>
          {type.name}
        </Typography>
      </Grid>
      <Grid item sm={9} xs={12}>
        <Stack direction='row' spacing={1}>
          {type.fields.includes('subtype') && <FormControl variant='outlined' error={Boolean(errors.gender)} sx={ { verticalAlign: 'initial', mr: 2 }}>
            <Select
              id={type.slug + '-subtype'}
              variant='standard'
              value={subtype || 'no'}
              onChange={e => setSubtype(e.target.value)}
              margin='dense'>
              <MenuItem value='no'><em>Aucune license</em></MenuItem>
              {type.subtypes.map(subtype => <MenuItem key={subtype} value={subtype}>{subtype}</MenuItem>)}
            </Select>
            {errors.subtype && <FormHelperText>{errors.subtype}</FormHelperText>}
          </FormControl>}
          {type.fields.includes('identValue') && <TextField
            id={type.slug + '-identValue'}
            variant='standard'
            label='Numéro'
            value={number || ''}
            onChange={e => setNumber(e.target.value)}
            error={Boolean(errors.number)}
            helperText={errors.number}
          />}
          {type.fields.includes('grantDate') && <DatePicker
            id={type.slug + '-grantDate'}
            inputFormat='dd-MM-yyyy'
            margin='dense'
            label='Date de délivrance'
            value={grantDate || null}
            onChange={setGrantDate}
          />}
          {type.fields.includes('expireDate') && <DatePicker
            id={type.slug + '-expireDate'}
            inputFormat='dd-MM-yyyy'
            margin='dense'
            label='Date d&apos;expiration'
            value={expireDate || null}
            onChange={setExpireDate}
          />}
          {type.fields.includes('attachmentUrl') && <FileUpload
            id={type.slug + '-attachmentUrl'}
            folder={type.slug}
            placeholder='Photo'
            fileName={profile.lastname + '-' + type.slug}
            fileUrl={attachmentUrl}
            onComplete={setAttachmentUrl}
            sx={{ verticalAlign: 'middle', display: 'inline-block' }}
          />}
        </Stack>
      </Grid>
    </Grid>
  )
}

QualificationFields.propTypes = {
  type: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default QualificationFields
