import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material'
import { FLIGHT_COLLECTION } from '../../_constants/globals'
import { format } from 'date-fns'
import useAuth from '../../hooks/useAuth'
import { useFirestore } from '../../hooks/useFirestore'


const FlightConnector = ({ lesson, handleChange, error }) => {
  
  const profile = useAuth().getProfile()
  const flightHooks = useFirestore(FLIGHT_COLLECTION, { storeAs: 'flightsToConnect-' + lesson.id })
  const flights = flightHooks.getDocs()
  
  useEffect(() => {
    if ((lesson.pilotRef && lesson.instructorRef)) {
      flightHooks.listen({
        where: [
          ['pilotRef', '==', lesson.pilotRef],
          ['instructorRef', '==', lesson.instructorRef || lesson.instructorRef[1]],
        ],
        orderBy: [['startDate', 'asc']],
      })
    }
  }, [lesson.pilotRef, lesson.instructorRef])
  
  const filteredFlights = useMemo(
    () => flights?.filter(f => !f.lessonRef || lesson?.flightRef?.id === f.id || lesson?.id === 'new')
      .map(f => ({
        ...f,
        otherGuy: f.pilotRef.id === profile.id ? f.instructor?.firstname : f.pilot?.firstname,
      })),
    [flights, profile.id, lesson],
  )
  
  return (
    <FormControl variant='standard'>
      <InputLabel id='flight-select-helper-label'>Vol</InputLabel>
      <Select
        id='flight-select-helper'
        labelId='flight-select-helper-label'
        variant='standard'
        value={lesson?.flightRef ? lesson.flightRef.id || lesson.flightRef[1] : ''}
        onChange={e => handleChange(e.target.value, flights)}
        error={error}>
        <MenuItem value=''><em>None</em></MenuItem>
        {filteredFlights?.map(flight => <MenuItem key={flight.id} value={flight.id}>
          Le {format(flight.startDate, 'dd-MM-yyyy')} à {format(flight.startDate, 'HH:mm')} avec {flight.otherGuy}
        </MenuItem>)}
      </Select>
      <FormHelperText error={error}>Sélectionne un vol pour le lier à cette leçon</FormHelperText>
    </FormControl>
  )
}

FlightConnector.propTypes = {
  lesson: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

export default FlightConnector
