import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, CardActions, Card, CardContent, Typography, FormControl, Select, MenuItem, FormHelperText,
  Grid, TextField, Divider } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import InlineAvatarEditor from '../profile/InlineAvatarEditor'
import { differenceInYears } from 'date-fns'
import { USER_COLLECTION } from '../../_constants/globals'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'


const labelStyle = {
  color: 'rgba(0,0,0,.54)',
  display: 'inline-block',
  lineHeight: '20px',
  width: 200,
}
const IdentityForm = ({ next, back }) => {
  
  const profile = useAuth().getProfile()
  const userHooks = useFirestore(USER_COLLECTION)
  
  const [firstname, setFirstname] = useState(profile.firstname)
  const [lastname, setLastname] = useState(profile.lastname)
  const [gender, setGender] = useState(profile.gender)
  const [birthdayDate, setBirthdayDate] = useState(profile.birthdayDate ? profile.birthdayDate : new Date())
  const [birthplace, setBirthplace] = useState(profile.birthplace)
  const [nationality, setNationality] = useState(profile.nationality || 'FR')
  const [phone, setPhone] = useState(profile.phone)
  const [street, setStreet] = useState(profile.address?.street)
  const [zipcode, setZip] = useState(profile.address?.zipcode)
  const [city, setCity] = useState(profile.address?.city)
  const [country, setCountry] = useState(profile.address?.country || 'France')
  const [errors, setErrors] = useState({})
  
  const handleSubmit = () => {
    setErrors({})
    if (!firstname) setErrors(err => ({ ...err, firstname: 'Le prénom doit être rempli' }))
    if (!lastname) setErrors(err => ({ ...err, lastname: 'Le nom doit être rempli' }))
    if (!gender) setErrors(err => ({ ...err, gender: 'Il faut sélectionner un sexe' }))
    if (differenceInYears(new Date(), birthdayDate) < 18)
      setErrors(err => ({
        ...err,
        birthdayDate: 'Ce formulaire n\'est pas accessible aux mineurs. Merci de prendre contact avec un responsable du club',
      }))
    if (!birthplace) setErrors(err => ({ ...err, birthplace: 'Le lieu de naissance doit être rempli' }))
    if (!nationality) setErrors(err => ({ ...err, nationality: 'La nationalité doit être rempli' }))
    if (!phone) setErrors(err => ({ ...err, phone: 'Le numéro de téléphone doit être rempli' }))
    if (!street) setErrors(err => ({ ...err, street: 'Le champ doit être rempli' }))
    if (!zipcode) setErrors(err => ({ ...err, zipcode: 'Le champ doit être rempli' }))
    if (!city) setErrors(err => ({ ...err, city: 'Le champ doit être rempli' }))
    if (!country) setErrors(err => ({ ...err, country: 'Le champ doit être rempli' }))
    if (firstname && lastname && gender && differenceInYears(new Date(), birthdayDate) >= 18 && birthplace && nationality
      && phone && street && zipcode && city && country)
      return userHooks.updateDoc(profile.id, { firstname, lastname, gender, birthdayDate, birthplace, nationality, phone, address: { street, zipcode, city, country } })
        .then(next)
  }
  
  return (
    <Card variant='outlined' sx={{ my: 2, mx: 'auto', maxWidth: 900 }}>
      <CardContent>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>Prénom</Typography>
          <TextField
            variant='standard'
            value={firstname || ''}
            onChange={e => setFirstname(e.target.value)}
            error={!!errors.firstname}
            helperText={errors.firstname} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' sx={labelStyle}>Nom</Typography>
          <TextField
            variant='standard'
            value={lastname || ''}
            onChange={e => setLastname(e.target.value)}
            error={!!errors.lastname}
            helperText={errors.lastname} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>Sexe</Typography>
          <FormControl variant='outlined' error={!!errors.gender}>
            <Select
              variant='standard'
              id='sex-select'
              value={gender || ''}
              onChange={e => setGender(e.target.value)}
              margin='dense'>
              <MenuItem value=''>&nbsp;</MenuItem>
              <MenuItem value='woman'>Féminin</MenuItem>
              <MenuItem value='man'>Masculin</MenuItem>
            </Select>
            {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>
            Date de naissance
          </Typography>
          <DatePicker
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='birthdayDate'
            value={birthdayDate}
            onChange={setBirthdayDate}
            componentsProps={{ textField: { error: !!errors.birthdayDate, helperText: errors.birthdayDate } }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>
            Lieu de naissance
          </Typography>
          <TextField
            variant='standard'
            value={birthplace || ''}
            onChange={e => setBirthplace(e.target.value)}
            error={!!errors.birthplace}
            helperText={errors.birthplace} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>
            Nationalité
          </Typography>
          <TextField
            variant='standard'
            value={nationality}
            onChange={e => setNationality(e.target.value)}
            error={!!errors.nationality}
            helperText={errors.nationality} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <InlineAvatarEditor />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
            Téléphone
          </Typography>
          <TextField
            variant='standard'
            value={phone || ''}
            onChange={e => setPhone(e.target.value)}
            error={Boolean(errors.phone)}
            helperText={errors.phone} />
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Typography variant='body1'>Adresse</Typography>
        <Grid item xs={12} mb={1}>
          <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
            Numéro et rue
          </Typography>
          <TextField
            variant='standard'
            value={street || ''}
            onChange={e => setStreet(e.target.value)}
            error={Boolean(errors.street)}
            helperText={errors.street} />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
            Code postal
          </Typography>
          <TextField
            variant='standard'
            value={zipcode || ''}
            onChange={e => setZip(e.target.value)}
            error={Boolean(errors.zipcode)}
            helperText={errors.zipcode} />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
            Ville
          </Typography>
          <TextField
            variant='standard'
            value={city || ''}
            onChange={e => setCity(e.target.value)}
            error={Boolean(errors.city)}
            helperText={errors.city} />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px' sx={{ color: 'rgba(0,0,0,.54)' }}>
            Pays
          </Typography>
          <TextField
            variant='standard'
            value={country}
            onChange={e => setCountry(e.target.value)}
            error={Boolean(errors.country)}
            helperText={errors.country} />
        </Grid>
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button size='large' onClick={back}>Précédent</Button>
        <Button size='large' color='primary' onClick={handleSubmit}>Valider</Button>
      </CardActions>
    </Card>
  )
}

IdentityForm.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default IdentityForm
