import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Typography, Card, CardContent } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser'


const styles = () => ({
  root: {
    minWidth: 275,
    marginBottom: '2rem',
    padding: 10,
  },
  cardTitle: {
    marginBottom: '1rem',
  },
})

const AnnouncementCard = ({ classes, settings }) =>
  settings?.announcement
    ? <Card className={classes.root}>
      <CardContent>
        <Typography variant='subtitle1' className={classes.cardTitle}>
          <FontAwesomeIcon icon={faBullhorn} style={{ verticalAlign: 'baseline' }} /> Annonce
        </Typography>
        <Typography variant='body1'>
          {parse(settings.announcement)}
        </Typography>
      </CardContent>
    </Card>
    : null


const mapStateToProps = state => ({
  settings: state.firestore.settings?.default,
})

AnnouncementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(AnnouncementCard)
