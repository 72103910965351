import { combineReducers } from 'redux'
import dataReducer from './reducers/dataReducer'
import notificationReducer from './reducers/notificationReducer'
import firestoreSlice from './slices/firestoreSlice'
import profileSlice from './slices/profileSlice'


export default combineReducers({
  data: dataReducer,
  notifications: notificationReducer,
  profile: profileSlice,
  firestore: firestoreSlice,
})
