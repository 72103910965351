import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, DialogActions, DialogContent, FormControl, InputLabel, Select, MenuItem, Grid, FormHelperText,
  Typography, ToggleButtonGroup, ToggleButton } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { addMinutes, roundToNearestMinutes } from 'date-fns'
import UserSelect from '../_library/UserAutocomplete'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AIRCRAFT_COLLECTION, BOOKING_COLLECTION, FLIGHT_TYPE_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const NewBookingForm = ({ startDateInit, onClose, aircraftInit }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const dbFlightTypes = flightTypeHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const profile = useAuth().getProfile()
  const bookingHooks = useFirestore(BOOKING_COLLECTION)

  const [startDate, setStartDate] = useState(startDateInit || roundToNearestMinutes(new Date(), { nearestTo: 15 }))
  const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState({})
  const [comments, setComments] = useState(null)
  const [flightType, setFlightType] = useState(null)
  const [pilot, setPilot] = useState(null)
  const [instructor, setInstructor] = useState(null)
  const [aircraft, setAircraft] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const aircrafts = useMemo(() => dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const availableInstructors = useMemo(() => dbUsers?.filter(u => u.roles?.includes('instructeur')), [dbUsers])

  useEffect(() => startDate && setEndDate(addMinutes(startDate, 45)), [startDate])

  useEffect(() => {
    if (!aircraft && aircraftInit && aircraftInit.activated) setAircraft(aircraftInit)
    else if (!aircraft && aircrafts && profile.favoritAircraftId){
      const favAircraft = aircrafts.find(a => a.id === profile.favoritAircraftId)
      if (favAircraft && favAircraft.activated && favAircraft.bookable)
        setAircraft(favAircraft)
    }
  }, [aircrafts, profile.favoritAircraftId, aircraftInit, aircraft])

  useEffect(function onUsersUpdate() {
    dbUsers && setPilot(dbUsers.find(u => u.id === profile.id))
  }, [dbUsers, profile.id])

  const handleSubmit = () => {
    if (!aircraft)
      setError({ field: 'aircraft', message: 'missing-aircraft' })
    else if (!flightType)
      setError({ field: 'flightType', message: 'missing-type' })
    else {
      setSubmitted(true)
      bookingHooks.addDoc({
        startDate,
        endDate,
        comments,
        aircraftRef: aircraftHooks.getDocRef(aircraft.id),
        instructorRef: userHooks.getDocRef(instructor?.id),
        pilotRef: userHooks.getDocRef(pilot.id),
        flightTypeRef: flightTypeHooks.getDocRef(flightType.id),
        userRef: userHooks.getDocRef(profile.id),
        flightRef: null,
      }).then(onClose)
    }
  }

  return <>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body2'>Début</Typography>
        </Grid>
        <Grid item xs={7}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='startDate-date'
            value={startDate}
            fullWidth
            onChange={setStartDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TimePicker
            variant='inline'
            ampm={false}
            margin='dense'
            id='startDate-time'
            value={startDate}
            onChange={setStartDate}
            minutesStep={5}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>Fin</Typography>
        </Grid>
        <Grid item xs={7}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='endDate-date'
            value={endDate}
            fullWidth
            onChange={setEndDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TimePicker
            variant='inline'
            ampm={false}
            margin='dense'
            id='endDate-time'
            value={endDate}
            onChange={setEndDate}
            minutesStep={5}
          />
        </Grid>
        <Grid item>
          <Typography variant='body2'>Avion</Typography>
          <ToggleButtonGroup
            size='large'
            value={aircraft}
            exclusive
            onChange={(event, value) => setAircraft(value)}
            style={{ border: error.field === 'aircraft' && 'solid red' }}
          >
            {aircrafts && aircrafts.map(aircraft =>
              <ToggleButton key={aircraft.id} value={aircraft} disabled={!aircraft.bookable} sx={{
                '&.MuiToggleButton-root.Mui-selected': {
                  bgcolor: 'primary.light',
                  color: 'black',
                },
              }}>
                {aircraft.name}
              </ToggleButton>,
            )}
          </ToggleButtonGroup>
          {error.field === 'aircraft' && <FormHelperText>{error.message}</FormHelperText>}
        </Grid>
        <Grid item xs={12} id='flightType'>
          <FormControl
            variant='standard'
            required
            sx={{ width: '100%' }}
            error={error.field === 'flightType'}
          >
            <InputLabel id='type-select-label'>Type de vol</InputLabel>
            <Select
              variant='standard'
              labelId='type-select-label'
              id='type-select'
              value={flightType || ''}
              onChange={e => setFlightType(e.target.value)}>
              {dbFlightTypes?.filter(t => profile.flightTypes?.some(slug => slug === t.slug)).map(type =>
                <MenuItem key={type.id} value={type}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>
            {error.field === 'flightType' && <FormHelperText color='red'>{error.message}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid container id='crew' item xs={12} spacing={3}>
          <Typography variant='subtitle1' width='100%' mt={4}>Equipage</Typography>
          <Grid item xs={6} >
            {dbUsers && <UserSelect
              users={dbUsers}
              disabled={!profile.roles?.includes('admin') && !profile.roles?.includes('instructeur')}
              user={pilot || null}
              label='Pilote'
              set={setPilot}
              sx={{ width: '100%' }}
            />}
          </Grid>
          <Grid item xs={6}>
            {availableInstructors && <UserSelect
              users={availableInstructors}
              user={instructor}
              label='Instructeur'
              set={setInstructor}
              sx={{ width: '100%' }}
            />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='comments'
            label='Commentaires'
            fullWidth
            value={comments || ''}
            multiline
            onChange={e => setComments(e.target.value)} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>Annuler</Button>
      <Button onClick={handleSubmit} color='primary' disabled={submitted}>Valider</Button>
    </DialogActions>
  </>
}

NewBookingForm.propTypes = {
  startDateInit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  aircraftInit: PropTypes.object,
}

export default NewBookingForm
