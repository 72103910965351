import React, { useMemo } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import WatchTabs from './WatchTabs'
import { FLIGHT_COLLECTION } from '../../_constants/globals'
import Player from './Player'
import SequenceTrack from './SequenceTrack'
import { useMatch } from 'react-router-dom'
import { WATCH } from '../../_constants/routes'
import { useFirestore } from '../../hooks/useFirestore'


export const WatchPage = () => {
  
  const match = useMatch(WATCH)
  const flightHooks = useFirestore(FLIGHT_COLLECTION)

  const flight = useMemo(() => flightHooks.fetchDoc(flightHooks.getDocRef(match.params.id)), [match.params.id])
  console.log('flight', flight)

  if (!flight) return <CircularProgress size={20} />
  return (
    <Grid container>
      <Grid item xs={12} sm={8} className='video verticallyCenteredContainer'>
        <Player flight={flight} />
        <SequenceTrack flight={flight} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <WatchTabs flight={flight} />
      </Grid>
    </Grid>
  )
}

export default WatchPage
