/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, TextField, ClickAwayListener } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { endOfYear, format, getYear, isThisYear, startOfYear } from 'date-fns'
import Loading from '../_library/Loading'
import MUIDataTable from 'mui-datatables'
import DeleteButton from '../_library/DeleteButton'
import { pick } from 'lodash'
import { ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION, BILLING_TYPE_COLLECTION } from '../../_constants/globals'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'


const STORAGE_KEY = 'allEntryTableState'
const AllEntryTable = () => {
  
  const entryHooks = useFirestore(ACCOUNT_ENTRY_COLLECTION)
  const entries = entryHooks.getDocs()
  const accountHooks = useFirestore(ACCOUNT_COLLECTION)
  const accounts = accountHooks.getDocs()
  const billingTypeHooks = useFirestore(BILLING_TYPE_COLLECTION)
  const billingTypes = billingTypeHooks.getDocs()
  const selectedYear = useSelector(state => state.data.selectedYear)
  
  const [selectedCell, selectCell] = useState(null)
  
  useEffect(() => {
    entryHooks.listen({
      where: selectedYear !== 'all'
        ? [
          ['accountDate', '>=', new Date(selectedYear || getYear(Date.now()), 0, 1, 0, 0)],
          ['accountDate', '<=', new Date(selectedYear || getYear(Date.now()), 11, 31, 23, 59)],
        ]
        : [],
      orderBy: [['accountDate', 'asc']],
    })
    return entryHooks.unsubscribe
  }, [selectedYear])
  
  const data = useMemo(() => entries?.map( val => [
    val,
    val.id,
    val.accountDate,
    val._createdAtTime,
    val.paymentType,
    { id: val.accountRef?.id, ...val.account },
    { id: val.billingTypeRef?.id, ...val.billingType },
    val.comment,
    val.debit || '',
    val.credit || '',
    { id: val.id, validated: val.validated },
  ]), [entries])
  
  const update = (key, val) => entryHooks.updateDoc(selectedCell.id, { [key]: val }).then(() => selectCell(null))
  
  const localState = window.localStorage.getItem(STORAGE_KEY) && JSON.parse(window.localStorage.getItem(STORAGE_KEY))
  
  if (!data) return <Loading />
  return (
    <MUIDataTable
      data={data}
      sx={{
        '& .MuiTableCell-body': {
          fontSize: '0.8rem',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '& .MuiTableCell-head': {
          fontSize: '0.8rem',
        },
      }}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        { name: 'ID', options: { filter: false, sort: false, display: localState?.columns[1].display || 'false' } },
        {
          name: 'accountDate',
          label: 'accountDate',
          options: {
            display: localState?.columns[2].display || 'true',
            filter: true,
            filterList: localState?.filterList[2] || [],
            sortThirdClickReset: true,
            sortDescFirst: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <DatePicker
                    open
                    value={val}
                    onChange={value => update('accountDate', value)}
                    minDate={startOfYear(new Date())}
                    maxDate={endOfYear(new Date())}
                    disabled={!isThisYear(val)}
                  />
                </ClickAwayListener>
                : format(val, 'dd-MM-yyyy HH:mm'),
          },
        },
        {
          name: '_createdAtTime',
          label: '_createdAtTime',
          options: {
            display: localState?.columns[3].display || 'false',
            filter: true,
            filterList: localState?.filterList[3] || [],
            sortThirdClickReset: true,
            sortDescFirst: true,
            customBodyRender: val => val && format(val, 'dd-MM-yyyy HH:mm'),
          },
        },
        {
          name: 'paymentType',
          label: 'Type',
          options: {
            display: localState?.columns[4].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[4] || [],
            sortCompare: order => (a, b) => a.data.name.localeCompare(b.data.name) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('paymentType', e.target.value)} defaultValue={val}>
                    <option value='transfer'>transfer</option>
                    <option value='flux'>flux</option>
                    <option value='flight'>flight</option>
                    <option value='solde'>solde</option>
                  </select>
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'account',
          label: 'Compte',
          options: {
            display: localState?.columns[5].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[5] || [],
            sortThirdClickReset: true,
            sortCompare: order => (a, b) => a.data.name.localeCompare(b.data.name) * (order === 'asc' ? 1 : -1),
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('accountRef', [ACCOUNT_COLLECTION, e.target.value])} defaultValue={val.id}>
                    {accounts?.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
                  </select>
                </ClickAwayListener>
                : typeof val === 'object' ? val.name : val,
          },
        },
        {
          name: 'billingType',
          label: 'Facturation',
          options: {
            display: localState?.columns[6].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[6] || [],
            sortThirdClickReset: true,
            sortCompare: order => (a, b) => a.data.name.localeCompare(b.data.name) * (order === 'asc' ? 1 : -1),
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('billingTypeRef', [BILLING_TYPE_COLLECTION, e.target.value])} defaultValue={val.id}>
                    {billingTypes?.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
                  </select>
                </ClickAwayListener>
                : typeof val === 'object' ? val.name : val,
          },
        },
        {
          name: 'comments',
          label: 'Commentaires',
          options: {
            display: localState?.columns[7].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('comments', e.target.value)}
                    multiline />
                </ClickAwayListener>
                : val || 'null',
          },
        },
        {
          name: 'debit',
          label: 'Débit',
          options: {
            display: localState?.columns[8].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('debit', e.target.value)}
                    type='number' />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'credit',
          label: 'Crédit',
          options: {
            display: localState?.columns[9].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.id === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('credit', e.target.value)}
                    type='number' />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            viewColumns: false,
            // eslint-disable-next-line
            customBodyRender: ({ id, validated }, { rowData }) => isThisYear(rowData[0].accountDate) && <>
              <DeleteButton deleteAction={() => entryHooks.deleteDoc(id)}/>
              <Button title='valider' onClick={() => entryHooks.updateDoc(id, { validated: !validated })}>
                {validated ? <Clear/> : <Check/>}
              </Button>
            </>,
          },
        },
      ]}
      options={{
        selectableRows: 'none',
        downloadOptions: {
          filename: 'entries.csv',
          separator: ';',
          filterOptions: {
            useDisplayedColumnsOnly: false,
            useDisplayedRowsOnly: true,
          },
        },
        onCellClick: (colData, tableMeta) => !selectedCell && selectCell({ ...tableMeta, id: entries[tableMeta.dataIndex].id }),
        onTableChange: (action, tableState) => {
          if (action === 'propsUpdate') return
          window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
        },
        sortOrder: localState?.sortOrder || {},
        rowsPerPage: localState?.rowsPerPage || 10,
        jumpToPage: true,
        rowsPerPageOptions: [10, 50, 100],
      }}
    />
  )
}

export default AllEntryTable
