import { getToken, onMessage } from 'firebase/messaging'
import { arrayUnion, serverTimestamp } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from './useFirestore'
import { DELETE_NOTIFICATION, INFO, NOTIFICATIONS_ALLOWED, RESET_NOTIFICATIONS } from '../store/types'
import { USER_COLLECTION } from '../_constants/globals'
import { messaging } from '../firebase'


export const useNotification = () => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const profile = useSelector(state => state.profile.data)
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)
  
  const deleteNotification = key => dispatch({ type: DELETE_NOTIFICATION, payload: key })
  
  const resetNotifications = () => dispatch({ type: RESET_NOTIFICATIONS })
  
  const requestTokenAction = async () => {
    const permission = await Notification?.requestPermission()
    if (permission === 'granted') {
      const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
      if (currentToken) {
        await userHooks.updateDoc(profile.id, {
          fmcTokens: arrayUnion(currentToken),
          _updatedAt: serverTimestamp(),
        })
        dispatch({ type: NOTIFICATIONS_ALLOWED, payload: true })
        onMessage(messaging, payload => {
          console.log('Message received. ', payload)
          dispatch({ type: INFO, payload: payload.notification.body })
        })
        
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.')
        dispatch({ type: NOTIFICATIONS_ALLOWED, payload: false })
      }
      return currentToken
    } else {
      dispatch({ type: NOTIFICATIONS_ALLOWED, payload: false })
      return null
    }
  }
  
  return { notifications, deleteNotification, resetNotifications, requestTokenAction }
}
