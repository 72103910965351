import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material'
import { PROGRESS_BOOK_COLLECTION, QUALIFICATION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import InlineEditor from '../_library/InlineEditor'
import UserAutocomplete from '../_library/UserAutocomplete'
import { isValid } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'


const ClosureDialog = ({ onClose, progressBook, licence }) => {
  
  const bookHooks = useFirestore(PROGRESS_BOOK_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  
  const [submitted, setSubmitted] = useState(false)
  
  const pilot = useMemo(() => dbUsers?.find(u => u.id === progressBook?.pilotRef.id), [dbUsers])
  const instructors = useMemo(() => dbUsers?.filter(u => u.roles?.includes('instructeur')), [dbUsers])
  const isPDFToBeGenerated = useMemo(
    () => progressBook && licence && pilot
      && progressBook.pilot?.firstname
      && progressBook.pilot?.lastname
      && licence.identValue
      && licence.grantDate
      && pilot.experience?.total
      && progressBook.experience?.cdb
      && progressBook.startDate
      && progressBook.closingDate
      && progressBook.closingPicHours,
    [progressBook, licence, pilot],
  )

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    bookHooks.updateDoc(progressBook.id, { status: 'closing' })
      .finally(() => setSubmitted(false))
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' sx={{
      '& .MuiInput-underline::before': {
        borderBottomColor: 'rgba(0, 0, 0, .1)',
      },
    }}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>Cloture du livret de progression</DialogTitle>
        <DialogContent>
          {progressBook.status === 'open' && <>
            <Typography variant='body1'>
              Si tu penses avoir validé tous les items du livret il ne te reste plus qu&apos;à le cloturer. Il sera ensuite soumis
              à la validation du chef pilote puis tu recevras la synthèse en PDF par email.
            </Typography>
            <Grid item xs={12}>
              <InlineEditor
                title='Prénom'
                value={progressBook.pilot?.firstname}
                handleSubmit={val => userHooks.updateDoc(progressBook.pilotRef.id, { firstname: val })}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor
                title='Nom'
                value={progressBook.pilot?.lastname}
                handleSubmit={val => userHooks.updateDoc(progressBook.pilotRef.id, { lastname: val })}
              />
            </Grid>
            {licence
              ? <>
                <Grid item xs={12}>
                  <InlineEditor
                    title='Numéro de Licence pilote'
                    value={licence.identValue}
                    handleSubmit={val => qualificationHooks.updateDoc(licence.id, { identValue: val })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ display: 'inline-block', width: 200 }} color='textSecondary' gutterBottom>
                    Date d&apos;obtention
                  </Typography>
                  <DatePicker
                    inputFormat='dd-MM-yyyy'
                    margin='dense'
                    id='licence.grantDate'
                    value={licence.grantDate || null}
                    onChange={val => isValid(val) && qualificationHooks.updateDoc(licence.id, { grantDate: val })}
                    renderInput={params => <TextField {...params} />}
                  />
                </Grid>
              </>
              : <Typography variant='body1' color='secondary'>Error : Pilot licence not found</Typography>
            }
            <Grid item xs={12}>
              <InlineEditor
                title='Nombre d&apos;heures de vol avant formation'
                value={pilot.experience?.total}
                handleSubmit={val => userHooks.updateDoc(pilot.id, { experience: { ...pilot.experience, total: val } })}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor
                title='Nombre d&apos;heures de CDB avant formation'
                value={progressBook.experience?.cdb}
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, cdb: val } })}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor
                title='Nombre d&apos;heures de voltige avant formation'
                value={progressBook.experience?.aerobaticsDC}
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, aerobaticsDC: val } })}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor
                title='sur avion type'
                value={progressBook.experience?.airplane}
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { experience: { ...progressBook.experience, airplane: val } })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ display: 'inline-block', width: 200 }} color='textSecondary' gutterBottom>
                Formation débutée le
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='progressBook.startDate'
                value={progressBook.startDate || null}
                onChange={val => isValid(val) && bookHooks.updateDoc(progressBook.id, { startDate: val })}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ display: 'inline-block', width: 200 }} color='textSecondary' gutterBottom>
                Formation terminée le
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='closureDate-date'
                value={progressBook.closingDate || null}
                onChange={val => isValid(val) && bookHooks.updateDoc(progressBook.id, { closingDate: val })}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor
                title='Nombre d&apos;heures de vol en fin de formation'
                value={progressBook.closingPicHours}
                handleSubmit={val => bookHooks.updateDoc(progressBook.id, { closingPicHours: val })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ display: 'inline-block', width: 200 }} color='textSecondary' gutterBottom>
                Instructeur
              </Typography>
              <FormControl variant='outlined'>
                <UserAutocomplete
                  users={instructors}
                  user={{ id: progressBook.closingInstructorRef?.id, ...progressBook.closingInstructor }}
                  set={val => bookHooks.updateDoc(progressBook.id, { closingInstructorRef: val?.id ? userHooks.getDocRef(val.id) : null })}
                  disableClearable
                />
              </FormControl>
            </Grid>
          </>
          }
          {progressBook.status === 'closing' &&
            <Typography variant='body1'>
              Ton livret est en cours de validation. Tu receveras le livret finalisé en format pdf par email si le chef pilote le valide
              sinon ce dernier prendra contact avec toi pour t&apos;indiquer ce qu&apos;il manque.
            </Typography>
          }
          {progressBook.error && <Typography color='secondary'>{progressBook.error}</Typography>}
        </DialogContent>
        <DialogActions>
          {progressBook.status === 'open' && isPDFToBeGenerated && <Button type='submit' disabled={submitted}>Cloturer</Button>}
          <Button onClick={onClose} color='primary'>Fermer</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ClosureDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  progressBook: PropTypes.object.isRequired,
  licence: PropTypes.object,
}

export default ClosureDialog
