// convert object to string and store in localStorage
import { merge } from 'lodash'
import dataReducer from '../store/reducers/dataReducer'

export const saveReduxState = (reducerKey, stateKey, value) => {
  try {
    const savedState = { ...(JSON.parse(localStorage.getItem('persistantState')) || {}) }
    if (!savedState[reducerKey]) savedState[reducerKey] = {}
    savedState[reducerKey][stateKey] = value
    localStorage.setItem('persistantState', JSON.stringify(savedState))
  } catch (e) {
    console.warn(e)
  }
}

// load string from localStorage and convert into an Object
// invalid output must be undefined
export const loadReduxState = () => {
  try {
    const serialisedState = localStorage.getItem('persistantState')
    if (serialisedState === null) return undefined
    return merge({
      data: dataReducer.INITIAL_STATE,
    }, JSON.parse(serialisedState))
  } catch (e) {
    console.warn(e)
    return undefined
  }
}

export const resetLocalStorage = () => {
  try {
    localStorage.clear()
  } catch (e) {
    console.warn(e)
  }
}
