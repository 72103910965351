import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, Button, CardActions, CardContent, Collapse, Tooltip, Box } from '@mui/material'
import { format } from 'date-fns'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import FlightConnector from './FlightConnector'
import { useFirestore } from '../../hooks/useFirestore'
import { FLIGHT_COLLECTION, PB_LESSON_COLLECTION } from '../../_constants/globals'


export const LessonsCard = ({ lessons, items, sx }) => {
  
  const lessonHooks = useFirestore(PB_LESSON_COLLECTION)
  const flightHooks = useFirestore(FLIGHT_COLLECTION)

  const [expand, setExpand] = useState(false)

  const mergeTooltip = useMemo(() => 'Si les deux leçons correspondent au même ensemble briefing-vol, tu peux les fusionner', [])

  const canMerge = (lesson1, lesson2) =>
    format(lesson1.docDate, 'dd-MM-yyyy') === format(lesson2.docDate, 'dd-MM-yyyy')
    && lesson1.instructorRef?.id === lesson2.instructorRef?.id
    && !(lesson1.theoreticalItems && lesson2.theoreticalItems)
    && !(lesson1.practicalItems && lesson2.practicalItems)

  const doMerge  = (lesson1, lesson2) => {
    const batch = lessonHooks.getBatch()
    batch.update(lessonHooks.getDocRef(lesson1.id), {
      theoreticalItems: lesson1.theoreticalItems || lesson2.theoreticalItems,
      practicalItems: lesson1.practicalItems || lesson2.practicalItems,
      flightRef: lesson1.flightRef || lesson2.flightRef || null,
      comment: lesson1.comment || lesson2.comment || null,
    })
    batch.delete(lessonHooks.getDocRef(lesson2.id))
    return batch.commit()
  }

  return (
    <Card sx={{ minWidth: 275, mb: 4, p: 1, ...sx }}>
      <CardContent>
        <Button onClick={() => setExpand(val => !val)}>{expand ? 'Masquer' : 'Afficher'} les {lessons.length} leçons au format FFA</Button>
        <Collapse in={expand} sx={{ width: '100%' }}>
          {lessons.map((lesson, index) => <div key={index}>
            {index > 0 && canMerge(lessons[index - 1], lesson) &&
            <Tooltip title={mergeTooltip}>
              <Button onClick={() => doMerge(lessons[index - 1], lesson)} sx={{
                mx: 'auto',
                display: 'block',
                mt: -2,
                p: 0,
                '& svg': {
                  verticalAlign: 'middle',
                },
              }}>
                <ArrowDropUp />
                Fusionner les leçons
                <ArrowDropDown />
              </Button>
            </Tooltip>
            }
            <Box component='table' sx={{
              width: '100%',
              border: 'solid black 1px',
              mb: 2,
              borderCollapse: 'collapse',
              '& td': {
                p: 1,
                verticalAlign: 'initial',
              },
            }}>
              <tbody>
                <tr>
                  <Box component='td' sx={{ borderRight: 'solid black 1px', width: 400, px: 1 }}>
                    <Typography color='grey.500'>Leçon n°: <Box component='span' sx={{ color: 'black' }}>{index + 1}</Box></Typography>
                    <Typography color='grey.500'>Date: <Box component='span' sx={{ color: 'black' }}>{format(lesson.docDate, 'dd-MM-yyyy')}</Box></Typography>
                    <Typography color='grey.500'>Instructeur: <Box component='span' sx={{ color: 'black' }}>{lesson.instructor?.firstname} {lesson.instructor?.lastname}</Box></Typography>
                    <Typography color='grey.500'>Durée du briefing: <Box component='span' sx={{ color: 'black' }}>{lesson.briefingDuration}</Box></Typography>
                    {lesson.flight
                      ? <>
                        <Typography color='grey.500'>Durée du vol: <Box component='span' sx={{ color: 'black' }}>{lesson.flight.duration}</Box></Typography>
                        <Typography color='grey.500'>Avion: <Box component='span' sx={{ color: 'black' }}>{lesson.flight.aircraft?.name}</Box></Typography>
                        <Typography color='grey.500'>Zone: <Box component='span' sx={{ color: 'black' }}>{lesson.flight.arrival?.ICAO} {lesson.flight.arrival?.name}</Box></Typography>
                      </>
                      : <FlightConnector
                        lesson={lesson}
                        handleChange={flightId => lessonHooks.updateDoc(lesson.id, { flightRef: flightHooks.getDocRef(flightId) })}
                      />
                    }
                  </Box>
                  <td>
                    {lesson.theoreticalItems && <>
                      <Typography color='grey.500'>Items abordés au briefing:</Typography>
                      <ul>
                        {items.map(item => Object.keys(lesson.theoreticalItems).includes(item.id) &&
                          <li key={item.id}>{item.name}</li>,
                        )}
                      </ul>
                    </>}
                    {lesson.practicalItems && <>
                      <Typography color='grey.500'>Figures travaillées pendant le vol:</Typography>
                      <ul>
                        {items.map(item => Object.keys(lesson.practicalItems).includes(item.id) &&
                          <li key={item.id}>{item.name}</li>,
                        )}
                      </ul>
                    </>}
                    {lesson.generalImpression && false && <>
                      <Typography color='grey.500'>Impression générale:</Typography>
                      <ul>
                        {lesson.generalImpression.map((val, item) =>
                          <li key={item}>{item}; {val}</li>,
                        )}
                      </ul>
                    </>}
                    {lesson.comment && <>
                      <Typography color='grey.500'>Commentaires:</Typography>
                      <Typography>{lesson.comment}</Typography>
                    </>}
                  </td>
                </tr>
              </tbody>
            </Box>
          </div>)}
        </Collapse>
      </CardContent>
      {expand && <CardActions>
        <Button color='primary' onClick={() => setExpand(false)}>
          <ArrowDropUp />
        </Button>
      </CardActions>}
    </Card>
  )
}

LessonsCard.propTypes = {
  lessons: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  sx: PropTypes.object,
}

export default LessonsCard
