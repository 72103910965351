import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material'
import FlightDetails from './FlightDetails'
import DeleteButton from '../_library/DeleteButton'
import { useFirestore } from '../../hooks/useFirestore'
import { FLIGHT_COLLECTION } from '../../_constants/globals'


const EditFlightDialog = ({ flight, onClose }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiInput-underline::before': {
          borderBottomColor: 'rgba(0,0,0,.1)',
        },
      }}
    >
      <DialogTitle id='form-dialog-title'>Détails du vol</DialogTitle>
      <DialogContent>
        {flight && <FlightDetails flight={flight} close={onClose} />}
      </DialogContent>
      <DialogActions>
        <DeleteButton deleteAction={() => flightHooks.deleteDoc(flight.id).then(onClose)} text='Supprimer' />
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditFlightDialog.propTypes = {
  flight: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default EditFlightDialog
