import React, { useEffect, useMemo } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectAircraftId } from '../../store/actions/dataActions'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import useAuth from '../../hooks/useAuth'


const AircraftSelector = () => {
  
  const profile = useAuth().getProfile()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const dispatch = useDispatch()
  const adminMode = useSelector(state => state.data.adminMode)
  
  const aircrafts = useMemo(() => dbAircrafts?.filter(a => a.activated || adminMode), [dbAircrafts])
  
  useEffect(() => {
    if (!selectedAircraftId && profile.favoritAircraftId && aircrafts?.length)
      dispatch(selectAircraftId(profile.favoritAircraftId || aircrafts[0]))
  }, [selectedAircraftId, profile.favoritAircraftId, aircrafts])

  return (
    <FormControl variant='standard' sx={{ m: 1, verticalAlign: 'baseline' }}>
      <InputLabel id='aircraft-select-label' sx={{ color: 'white' }}>Avion</InputLabel>
      <Select
        labelId='aircraft-select-label'
        id='aircraft-select'
        value={selectedAircraftId || ''}
        onChange={e => dispatch(selectAircraftId(e.target.value))}
        label='Avion'
        sx={{ minWidth: '120px', borderColor: 'secondary.main' }}
      >
        {aircrafts && aircrafts.map(aircraft => <MenuItem key={aircraft.id} value={aircraft.id}>{aircraft.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default AircraftSelector
