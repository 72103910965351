import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux'


const pilotBooksUrl = 'https://sites.google.com/view/mpvpilotbooks/home'
const rexUrl = 'http://rexffa.fr/Connection/LogOnStructureAccess/1603109052'
const adminDriveUrl = 'https://drive.google.com/drive/folders/1rc-XbbTY7YFSLznDs--Y1i_CD_qvCNSP'
const FleetSiteUrl = 'https://sites.google.com/view/suiviflottempv/suivi-flotte'

const DocsButton = () => {
  
  const adminMode = useSelector(state => state.data.adminMode)

  const [anchor, setAnchor] = useState(null)

  return (
    <>
      <Button onClick={e => setAnchor(e.currentTarget)} color='inherit'>Docs</Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={() => window.open(pilotBooksUrl, '_blank')}>
          Pilot books
        </MenuItem>
        <MenuItem onClick={() => window.open(rexUrl, '_blank')}>
          Formulaire REX
        </MenuItem>
        {adminMode && <MenuItem onClick={() => window.open(adminDriveUrl, '_blank')}>
          Drive admin
        </MenuItem>}
        {adminMode && <MenuItem onClick={() => window.open(FleetSiteUrl, '_blank')}>
          Suivi de flotte
        </MenuItem>}
      </Menu>
    </>
  )
}

export default DocsButton
