import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import PlanningRow from './PlanningRow'
import { AIRCRAFT_COLLECTION, AVAILABILITY_COLLECTION, BOOKING_COLLECTION, FLIGHT_COLLECTION } from '../../_constants/globals'
import { addDays, endOfDay, format, isPast, startOfDay } from 'date-fns'
import Loading from '../_library/Loading'
import { chain } from 'lodash'
import { Box } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'


const hoursStyle = {
  textAlign: 'center',
  fontSize: '11px',
  color: '#777',
}
const pseudoBorder = {
  height: 4,
  width: '100%',
  background: 'transparent',
}
const blank4ElasticLine = {
  verticalAlign: 'bottom',
  borderLeft: '1px solid #ccc',
}
const PlanningTable = ({ day }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const bookingHooks = useFirestore(BOOKING_COLLECTION)
  const dbBookings = bookingHooks.getDocs()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION, { storeAs: 'dailyAvailabilities' })
  const dailyAvailabilities = availabilityHooks.getDocs()
  const flightHooks = useFirestore(FLIGHT_COLLECTION, { storeAs: 'bookingFlights' })
  const dbFlights = flightHooks.getDocs()

  const aircrafts = useMemo(() => dbAircrafts?.filter(a => a.activated), [dbAircrafts])
  const instructors = dailyAvailabilities && chain(dailyAvailabilities).groupBy('userRef.id').toPairs().value()
  
  useEffect(() => {
    bookingHooks.listen({
      where: [['days', 'array-contains', format(day, 'dd-MM-yyyy')]],
      orderBy: [['startDate', 'asc']],
    })
    availabilityHooks.listen({
      where: [['dayDate', '==', startOfDay(day)]],
    })
    flightHooks.listen({
      where: [
        ['startDate', '>=', startOfDay(day)],
        ['startDate', '<=', endOfDay(day)],
      ],
      orderBy: [['startDate', 'asc']],
    })
    return () => {
      bookingHooks.unsubscribe()
      availabilityHooks.unsubscribe()
      flightHooks.unsubscribe()
    }
  }, [day])

  const data = isPast(addDays(day, 1)) ? dbFlights : dbBookings
  
  if (!aircrafts) return <Loading />

  return (
    <Box component='table' sx={{
      tableLayout: 'fixed',
      textAlign: 'right',
      borderSpacing: 0,
      width: '100%',
      '& th': {
        fontSize: '11px',
        color: '#777',
      },
    }}>
      <thead>
        <tr>
          <Box component='th' sx={{ fontWeight: 'normal' }}>H</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>08</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>09</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>10</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>11</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>12</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>13</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>14</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>15</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>16</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>17</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>18</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>19</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>20</Box>
        </tr>
        <Box component='tr' sx={{ fontSize: 0, height: 3 }}>
          <th>
            <Box sx={pseudoBorder} />
          </th>
          <td colSpan='2' className='s'>
            <Box sx={pseudoBorder} />
          </td>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
        </Box>
      </thead>
      <tbody>
        {aircrafts.map(aircraft =>
          <PlanningRow
            key={aircraft.id}
            resource={aircraft}
            day={day}
            bookings={data?.filter(data => data.aircraftRef?.id === aircraft.id)}
            type='aircraft'
          />,
        )}
        {instructors?.map(([key, availabilities]) =>
          <PlanningRow
            key={key}
            resource={{ name: availabilities[0].user?.firstname + ' ' + availabilities[0].user?.lastname, ...availabilities[0].user }}
            availabilities={availabilities}
            day={day}
            bookings={data?.filter(data => data.instructorRef?.id === key)}
            type='instructor'
          />,
        )}
      </tbody>
    </Box>
  )
}

PlanningTable.propTypes = {
  day: PropTypes.object.isRequired,
}

export default PlanningTable
