import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Button, Typography } from '@mui/material'
import { Check } from '@mui/icons-material'
import { split, parse } from '../../_helpers/sequenceHelper'
import DrawTrick from './DrawTrick'
import { useFirestore } from '../../hooks/useFirestore'
import { FLIGHT_COLLECTION } from '../../_constants/globals'


export const SequenceTrack = ({ flight }) => {
  
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  
  const [sequenceString, setSequenceString] = useState(flight.sequence)

  const handleSubmit = e => {
    e.preventDefault()
    flightHooks.updateDoc(flight.id, { sequence: sequenceString })
  }

  console.log('sequence', sequenceString)
  const figures = parse(split(sequenceString).join(' '))
  sequenceString && console.log('figures', figures)
  // sequenceString && console.log('buildFigure', parse(buildFigure(1, 'dh')))

  if (flight.sequence)
    return parse(split(sequenceString).join(' ')).map((fig, index) => <DrawTrick key={index} figure={fig} />)

  else
    return <>
      <Typography variant='body1'>Copie-colle la séquence openaero ci-dessous</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          placeholder="-1% `2h.-~ (-12,0) -2m- (3,0) -iv..'is.."
          label='Openaero sequence'
          variant='outlined'
          value={sequenceString || ''}
          onChange={e => setSequenceString(e.target.value)}
        />
        <Button type='submit'><Check /></Button>
      </form>
    </>

}

SequenceTrack.propTypes = {
  flight: PropTypes.object.isRequired,
}

export default SequenceTrack
