import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material'
import { format, addMinutes } from 'date-fns'


// eslint-disable-next-line react/prop-types
const Label = ({ children }) => (
  <Typography color='textSecondary' gutterBottom display='inline-block' lineHeight='20px' width='200px'>{children}</Typography>
)

// eslint-disable-next-line react/prop-types
const Value = ({ children }) => (
  <Typography display='inline-block'>{children}</Typography>
)

export const LogbookGuide = ({ flight, next }) => {

  const comment = useMemo(() => {
    if (!flight) return
    let res = flight.comment || ''
    if (flight.flightType?.slug.includes('training')) res += ` ${flight.gPos}/-${flight.gNeg}`
    return res + ' ' + flight.counterArrival
  }, [flight])
  
  return !flight
    ? <CircularProgress color='inherit' />
    : <>
      <DialogContent>
        <h1>Guide de remplissage du carnet de route</h1>
        <Grid container sx={{ overflowY: 'scroll' }}>
          <Grid item xs={12}>
            <Label>Date</Label>
            <Value>{format(flight.startDate, 'dd/MM/yy')}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Noms</Label>
            <Value>{
              flight.instructor && flight.flightType?.slug !== 'trainingSolo'
                ? `${flight.pilot.lastname} / ${flight.instructor.lastname}`
                : flight.pilot?.lastname
            }</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Fonction</Label>
            <Value>{flight.instructor && flight.flightType?.slug !== 'trainingSolo' ? 'P/I' : 'P'}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Lieu - Départ</Label>
            <Value>{flight.departure?.ICAO}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Lieu - Arrivée</Label>
            <Value>{flight.arrival?.ICAO}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Heure - Départ</Label>
            <Value>{format(flight.startDate, 'HH\'h\'mm')}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Heure - Arrivée</Label>
            <Value>{format(addMinutes(flight.startDate, flight.duration * 60), 'HH\'h\'mm')}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Heures de vol</Label>
            <Value>{flight.duration}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Nature du vol</Label>
            <Value>{flight.type?.name.includes('Voltige') ? 'Voltige' : 'Privé' }</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Carburant - Départ</Label>
            <Value>{flight.fuelBefore || '/'}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Carburant - Arrivée</Label>
            <Value>{flight.fuelAfter || '/'}</Value>
          </Grid>
          <Grid item xs={12}>
            <Label>Commentaires</Label>
            <Value>{comment}</Value>
          </Grid>
          <Grid item xs={12}>
            <Typography mt={2}>Pense à signer ou à faire signer !</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={next} color='primary'>Suivant</Button>
      </DialogActions>
    </>
}

LogbookGuide.propTypes = {
  flight: PropTypes.object,
  next: PropTypes.func,
}

export default LogbookGuide
